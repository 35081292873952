import AddEditUser from 'pages/users/AddEditUser'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const Profile = () => {
  const user = useSelector(state => get(state, 'user.user'))
  return <AddEditUser currentUserId={user?.id} />
}

export default Profile
