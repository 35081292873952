import notify from 'components/atoms/Notify'

const apiPostCustom = async (url, formData) => {
  const config = {
    method: 'POST',
    body: formData,
  }

  return fetch(`${url}`, config).then(response => {
    // if (response.status !== 200) throw new Error('Some error occurred')
    if (!response.success) {
      notify.error(response.message, null, response.err)
    }
    return response
  })
}

export default apiPostCustom
