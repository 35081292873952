import { useContext, createContext, useState } from 'react'

const UserContext = createContext(null)

const UserContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(true)

  return (
    <UserContext.Provider
      value={{
        darkMode,
        setDarkMode,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () => useContext(UserContext)

export default UserContextProvider
