import classNames from 'classnames'
import queryString from 'query-string'

const WorkspaceWizard = ({ children, steps, currentStep, setCurrentStep, loading, isWorkspaceCredError }) => {
  const qs = queryString.parse(window.location.search)
  const { workspaceId } = qs

  return (
    <div className="intro-y box px-2 mt-6">
      <div className="relative before:hidden before:lg:block before:absolute before:w-[69%] before:h-[3px] before:top-0 before:bottom-0 before:mt-4 before:bg-slate-100 before:dark:bg-darkmode-400 flex flex-col lg:flex-row justify-center px-5 sm:px-20">
        {steps?.map((step, i) => {
          return (
            <div className="intro-x lg:text-center flex items-center lg:block flex-1 z-10" key={i}>
              <button
                className={classNames('w-10 h-10 rounded-full btn btn-primary', step.id === currentStep ? 'bg-dark-700 text-white' : 'bg-slate-100')}
                onClick={e => e.preventDefault()}
              >
                {step.id}
              </button>
              <div className={classNames('lg:w-32 lg:mt-3 ml-3 lg:mx-auto', step.id === currentStep && 'font-medium font-bold')}>{step?.name}</div>
            </div>
          )
        })}
      </div>
      <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
        {children}
        <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
          <button
            className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
            type="submit"
            disabled={loading || isWorkspaceCredError}
            onClick={e => {
              if (currentStep === 2 && !isWorkspaceCredError) {
                e.preventDefault()
                setCurrentStep(currentStep + 1, workspaceId)
              }
            }}
          >
            {loading ? (
              <span className="flex items-start">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.workspace/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <span>Please wait...</span>
              </span>
            ) : (
              <span>{currentStep === 6 ? 'Finish' : 'Next'}</span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default WorkspaceWizard
