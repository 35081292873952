import PageContent from 'components/templates/PageContent'

const Loading = () => {
  return (
    <PageContent center>
      <div className="h-screen flex flex-col items-center justify-center">
        <div className="loader">
          <svg className="circular">
            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="5" strokeMiterlimit="10"></circle>
          </svg>
        </div>
      </div>
    </PageContent>
  )
}

export default Loading
