import { CameraIcon } from '@heroicons/react/solid'
import Input from 'components/atoms/Input'
import LoadingIcon from 'components/atoms/LoadingIcon'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import UserSelect from 'components/molecules/UserSelect'
import { Field, Form, Formik } from 'formik'
import apiGet from 'lib/network/apiGet'
import apiPut from 'lib/network/apiPost'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import validations from 'validations'
import { useSelector } from 'react-redux'
import { forEach, get, startCase, keys } from 'lodash'
import apiDelete from 'lib/network/apiDelete'

const PostForm = ({ isEdit, id }) => {
  const history = useHistory()
  const [creatorData, setCreatorData] = useState()
  const [projectData, setProjectList] = useState(null)

  const [previews, setPreviews] = useState({})
  const [files, setFiles] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [postData, setPostData] = useState(false)
  const [loading, setLoading] = useState(false)

  const getCretoros = async () => {
    const response = await apiGet(`creators`)
    setCreatorData(response?.data)
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    setProjectList(response?.data)
  }

  useEffect(() => {
    getCretoros()
  }, [])

  useEffect(() => {
    if (id) {
      fetchPost(id)
    }
  }, [id])

  const fetchPost = async () => {
    setLoading(true)
    const response = await apiGet(`post/${id}`)
    setPostData(response.data)

    setPreviews({ post: response?.data?.image?.thumbnail })

    delete response?.data?.image?.thumbnail

    forEach(Object.entries(response.data), ([key, value]) => {
      if (value === 'null') {
        delete response.data[key]
      }
    })

    setPostData(response.data)
    setLoading(false)
  }

  const addPost = data => {
    setIsSubmitting(true)
    const formData = new FormData()
    if (files?.post) formData.append('post', files.post)

    if (isEdit) {
      formData.append('id', parseInt(id, 10))
      delete data.id
    }

    forEach(keys(data), key => {
      if (data[key] !== undefined || data[key] !== null) {
        formData.append(key, data[key])
      }
    })

    apiPut(isEdit ? `post/${id}` : 'post/create', formData)
      .then(response => {
        setIsSubmitting(false)
        if (response?.success) {
          if (isEdit) {
            notify.success('Success', 'Post updated successfully')
          } else {
            notify.success('Success', 'Post added successfully')
          }
          history.push(`/posts/${data?.type}`)
        }
      })
      .catch(error => {
        setIsSubmitting(false)
        console.log(error) // eslint-disable-line no-console
      })
  }

  const showPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews({ ...previews, [key]: preview })
  }

  const postRef = useRef(null)

  const types = useSelector(state => get(state, 'user.types', []))
  const postTypes = types?.post || []

  const creatorList = creatorData?.map(creator => ({ id: creator?.id, name: startCase(creator?.displayName) }))

  const deletePost = data => {
    notify.deleteConfirm({
      title: 'Delete post',
      message: 'Are you sure you want to delete this post?',
      onConfirm: async () => {
        const response = await apiDelete(`post/${id}/delete`)
        if (response?.success) {
          notify.success('Post deleted successfully')
          history.push(`/posts/${data?.type}`)
        }
      },
    })
  }

  return (
    <Formik
      validateOnChange={false}
      validationSchema={validations.postSchema}
      initialValues={
        isEdit
          ? {
              title: postData.title,
              content: postData.content || '',
              creator: postData?.creator,
              project: postData?.project,
              type: postData?.type,
              link: postData.link,
              cta: postData.cta,
            }
          : {}
      }
      enableReinitialize
    >
      {props =>
        !loading ? (
          <Form className="space-y-8 m-auto px-8 py-8 rounded-md w-full">
            <div>
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">{!isEdit ? 'Create post' : 'Post details'}</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Create a new interview / podcast / article for a creator</p>
                </div>
              </div>

              <div className="mt-6 sm:mt-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="last-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                    Post Type <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Selection title="Select Post Type" onChange={value => props.setFieldValue('type', value?.id)} list={postTypes} value={props.values?.type} />
                  </div>
                </div>

                <div className="space-y-6 mt-4 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="photo" className="flex flex-col text-sm text-normal text-gray-700">
                      <span>Thumbnail</span>
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="flex items-center">
                        <input
                          id="post"
                          ref={postRef}
                          name="post"
                          className="hidden"
                          type="file"
                          onChange={event => {
                            showPreview('post', event.target.files[0])
                            setFiles({ ...files, post: event.target.files[0] })
                          }}
                        />
                        <div
                          onClick={() => postRef.current.click()}
                          className={`bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-2 border-dashed border-gray-300 rounded-md cursor-pointer w-56 ${
                            previews.post ? 'h-full' : 'h-20'
                          }`}
                        >
                          {previews?.post ? <img alt="" src={previews.post} /> : <CameraIcon className="text-gray-400 h-8 w-8" />}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                      Title of the Post <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Input
                        type="text"
                        name="title"
                        placeholder="Title of the post"
                        id="title"
                        autoComplete="title"
                        className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="about" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                      Content
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Field
                        as="textarea"
                        id="content"
                        placeholder="Write a description of the post"
                        name="content"
                        rows={6}
                        className="max-w-lg shadow-sm block w-full focus:ring-dark-500 focus:border-dark-500 sm:text-sm border border-gray-200 rounded-md"
                        defaultValue={''}
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="last-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                      Creator <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <UserSelect title="Select Creator" onChange={value => props.setFieldValue('creator', value?.id)} list={creatorList} value={props.values?.creator} />
                    </div>
                  </div>
                  {projectData?.length > 0 && (
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="last-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Project <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Selection title="Select Project" onChange={value => props.setFieldValue('project', value?.id)} list={projectData} value={props.values?.project} />
                      </div>
                    </div>
                  )}

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                      Media Link
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Input
                        type="text"
                        name="link"
                        placeholder="Media file link (ie: cloudflare, viemo or any mp3, mp4 link etc)"
                        id="link"
                        autoComplete="mediaLink"
                        className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                      Call to action link
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Input
                        type="text"
                        placeholder="Link to blog post/article/podcast"
                        name="cta"
                        id="cta"
                        autoComplete="cta"
                        className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-5 border-t border-gray-300">
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => addPost(props.values)}
                  className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                >
                  {isSubmitting ? (
                    <span className="flex items-start">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.post/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span>Please wait...</span>
                    </span>
                  ) : (
                    <span>{!isEdit ? 'Add' : 'Save'}</span>
                  )}
                </button>
                {isEdit && (
                  <button
                    onClick={() => {
                      deletePost(props.values)
                    }}
                    type="button"
                    className="inline-flex text-red-500 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                  >
                    Delete Post
                  </button>
                )}
              </div>
            </div>
          </Form>
        ) : (
          <div className="w-full h-[500px] flex items-center justify-center">
            <LoadingIcon />
          </div>
        )
      }
    </Formik>
  )
}

export default PostForm
