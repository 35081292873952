import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import Workspace from 'pages/workspaces/Workspace'
import apiGet from 'lib/network/apiGet'
import { useSelector } from 'react-redux'
import { get, forEach } from 'lodash'
import queryString from 'query-string'

const AddEditWorkspace = ({ readOnly }) => {
  const qs = queryString.parse(window.location.search)

  const history = useHistory()
  const [isEdit, setIsEdit] = useState(null)
  const [id, setId] = useState(useParams().id || qs?.workspaceId)
  const [workspaceData, setWorkspaceData] = useState(null)
  const [loading, setLoading] = useState(false)

  const user = useSelector(state => get(state, 'user.user'))

  const fetchWorkspace = async () => {
    setLoading(true)
    const response = await apiGet(`workspace/${id}`, null)
    if (response.success) {
      forEach(Object.entries(response.data), ([key, value]) => {
        if (value === 'null') {
          delete response.data[key]
        }
      })

      const { data } = response
      setWorkspaceData(data)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      fetchWorkspace()
      setIsEdit(true)
    }
  }, [id])

  useEffect(() => {
    if (!id && qs?.workspaceId) {
      setId(qs?.workspaceId)
    }
  }, [qs?.workspaceId])

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <button
            onClick={() => {
              history.push('/workspaces')
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Go Back
          </button>
        </div>
      }
      title={isEdit ? 'Edit Workspace' : 'Add New Workspace'}
      breadcrumbs={[
        { name: 'Workspace List', pathname: '/workspaces' },
        { name: isEdit ? 'Workspace Details' : 'Add New Workspace', pathname: '/workspaces/add', current: true },
      ]}
    >
      <div className="flex overflow-auto">
        <div className="bg-white flex w-full">
          <div className="px-0 w-full">
            <Switch>
              <Route
                path="/workspaces/edit/:id"
                component={() => (
                  <Workspace setLoading={setLoading} readOnly={readOnly} workspaceData={workspaceData} loading={loading} id={id} isEdit={isEdit} fetchWorkspace={fetchWorkspace} />
                )}
              />
              {user.isPlatformAdmin && !readOnly && (
                <Route
                  path="/workspaces/add"
                  component={() => (
                    <Workspace
                      setLoading={setLoading}
                      fetchWorkspace={fetchWorkspace}
                      isWizard={true}
                      setId={setId}
                      workspaceData={workspaceData}
                      loading={loading}
                      id={id}
                      isEdit={isEdit}
                    />
                  )}
                />
              )}
            </Switch>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AddEditWorkspace
