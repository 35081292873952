import Layout from 'containers/Layout'
import { useHistory } from 'react-router-dom'
import apiGet from 'lib/network/apiGet'
import { useEffect, useState } from 'react'
import moment from 'moment'
import Button from 'components/atoms/Button'
import { startCase, map } from 'lodash'

const Events = () => {
  const [eventData, setEventData] = useState({})
  const history = useHistory()

  useEffect(() => {
    fetchEvent()
  }, [])

  const fetchEvent = async () => {
    const response = await apiGet(`events`, { size: 100, page: 1 })
    setEventData(response?.data)
  }

  return (
    <Layout
      background="#f4f5f9"
      breadcrumbs={[{ name: 'Event', link: '/events' }]}
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Button
            onClick={() => {
              history.push('/events/add')
            }}
          >
            Add new Event
          </Button>
        </div>
      }
      title={`List of Events`}
    >
      <div className="hidden bg-[#f4f5f9] sm:block">
        <div className="align-middle inline-block min-w-full">
          <table className="min-w-full table-auto">
            <thead>
              <tr className="border-gray-200">
                <th className="px-6 py-4 border-b border-gray-200 bg-gray-50 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">Event</th>
                <th className="px-6 py-4 border-b border-gray-200 bg-gray-50 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">Location</th>
                <th className="px-6 py-4 border-b border-gray-200 bg-gray-50 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">Description</th>
                <th className="hidden whitespace-nowrap md:table-cell px-6 py-4 border-b border-gray-200 bg-gray-50 text-right text-xs text-normal text-gray-500 uppercase tracking-wider">
                  Last updated
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {map(eventData, event => {
                return (
                  <tr onClick={() => history.push(`/events/edit/${event.id}`)} key={event.id}>
                    <td style={{ width: '10%' }} className="px-6 py-3 text-sm text-gray-500 font-medium truncate">
                      {startCase(event?.name)}
                    </td>
                    <td style={{ width: '10%' }} className="px-6 py-3 text-sm text-gray-500 truncate">
                      {startCase(event?.location).substring(0, 20)}...
                    </td>
                    <td style={{ width: '10%' }} className="px-6 py-3 text-sm text-gray-500 truncate">
                      {startCase(event?.description).substring(0, 100)}...
                    </td>
                    <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">{moment(event?.updatedAt).fromNow()}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default Events
