import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, InformationCircleIcon, XIcon } from '@heroicons/react/outline'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { random, upperFirst, isObject, values } from 'lodash'
import { Fragment, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

const Alert = ({ title, message, id, type, error }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 100)
  }, [])

  useEffect(() => {
    setTimeout(
      () => {
        setShow(false)
      },
      message?.length > 100 ? 6000 : 3000
    )

    setTimeout(() => {
      document.getElementById(id)?.remove()
    }, 4000)
  }, [])
  return (
    <div aria-live="assertive" className="fixed z-50 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:-translate-y-12"
          enterTo="translate-y-0 opacity-100 sm:translate-y-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 translate-y-0 sm:translate-y-0"
          leaveTo="opacity-0 translate-y-2 sm:-translate-y-12"
        >
          <div className="max-w-sm w-full z-50 bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {type === 'success' && <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
                  {type === 'error' && <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
                  {type === 'warning' && <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm text-normal text-gray-900">{title}</p>
                  {message ? <p className="mt-1 text-sm text-gray-500">{message}</p> : null}
                  {error && isObject(error)
                    ? values(error)?.map(item => {
                        return (
                          <p className="mt-1 text-sm text-gray-500" key={item}>
                            {upperFirst(item)}
                          </p>
                        )
                      })
                    : error}
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white z-50 rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      setShow(false)
                      setTimeout(() => {
                        document.getElementById(id).remove()
                      }, 100)
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}

const Confirm = ({ id, type = 'delete', onConfirm, title, autoClose, message, dangerText }) => {
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)

  useEffect(() => {
    if (autoClose) {
      setTimeout(() => {
        setOpen(true)
      }, 100)

      setTimeout(() => {
        setOpen(false)
      }, 3000)

      document.getElementById(id)?.remove()
    } else {
      setOpen(true)
      document.getElementById(id)?.remove()
    }
  }, [])
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div
                  className={classNames(
                    type === 'error' && 'bg-red-100',
                    type === 'alert' && 'bg-gray-100',
                    'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10'
                  )}
                >
                  {type === 'error' && <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
                  {type === 'alert' && <InformationCircleIcon className="h-6 w-6 text-dark-600" aria-hidden="true" />}
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{message}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={classNames(
                    type === 'error' && 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
                    type === 'alert' && 'bg-dark-600 hover:bg-dark-700 focus:ring-dark-500',
                    'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm'
                  )}
                  onClick={() => {
                    onConfirm()
                    setOpen(false)
                  }}
                >
                  {dangerText || 'Confirm'}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setOpen(false)
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const CopyPanel = ({ id, type = 'delete', onConfirm, title, autoClose, message, dangerText }) => {
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)

  useEffect(() => {
    if (autoClose) {
      setTimeout(() => {
        setOpen(true)
      }, 100)

      setTimeout(() => {
        setOpen(false)
      }, 3000)

      document.getElementById(id)?.remove()
    } else {
      setOpen(true)
      document.getElementById(id)?.remove()
    }
  }, [])
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[60%] sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {title}
                  </Dialog.Title>
                </div>
              </div>
              <div className="mt-5">
                <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 sm:mt-0 flex sm:ml-0">
                      <div className="mt-1 w-[80%] sm:mt-0">{message}</div>
                      <button
                        onClick={onConfirm}
                        type="button"
                        className="inline-flex absolute right-8 top-[53%] items-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const notify = {
  success: (title, message) => {
    if (title) {
      const id = random(0, 100000)
      const el = document.createElement('DIV')
      el.id = id
      ReactDOM.render(<Alert type="success" title={title} message={message} id={id} />, document.body.appendChild(el))
    }
  },
  warning: (title, message) => {
    if (title) {
      const id = random(0, 100000)
      const el = document.createElement('DIV')
      el.id = id
      ReactDOM.render(<Alert type="warning" title={title} message={message} id={id} />, document.body.appendChild(el))
    }
  },
  error: (title = '', message = '', error) => {
    if (title) {
      const id = random(0, 100000)
      const el = document.createElement('DIV')
      el.id = id
      ReactDOM.render(<Alert type="error" title={title} error={error} message={message} id={id} />, document.body.appendChild(el))
    }
  },
  deleteConfirm: ({ title, onConfirm, autoClose = false, message, ...rest }) => {
    const id = random(0, 100000)
    const el = document.createElement('DIV')
    el.id = id
    ReactDOM.render(<Confirm type="error" title={title} onConfirm={onConfirm} autoClose={autoClose} message={message} id={id} {...rest} />, document.body.appendChild(el))
  },
  alertConfirm: ({ title, onConfirm, message, ...rest }) => {
    const id = random(0, 100000)
    const el = document.createElement('DIV')
    el.id = id
    ReactDOM.render(<Confirm type="alert" title={title} onConfirm={onConfirm} message={message} id={id} {...rest} />, document.body.appendChild(el))
  },
  copyUrlPanel: ({ title, message, onConfirm, ...rest }) => {
    const id = random(0, 100000)
    const el = document.createElement('DIV')
    el.id = id
    ReactDOM.render(<CopyPanel title={title} message={message} onConfirm={onConfirm} id={id} {...rest} />, document.body.appendChild(el))
  },
}

export default notify
