import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import Geocode from 'react-geocode'
import { debounce } from 'lodash'

const containerStyle = {
  width: '700px',
  height: '402px',
  cursor: 'default',
}

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
Geocode.setLanguage('en')
Geocode.setLocationType('ROOFTOP')

function GoogleMapComponent({ data, setData, close, convertGoogleMapsToBlob }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  const [latLng, setLatLng] = React.useState({ lat: parseFloat(data.lat), lng: parseFloat(data.lng) })
  const [isDragged, setIsDragged] = React.useState(false)
  const [mapCenter, setMapCenter] = React.useState(data?.lng && data?.lat ? { lat: parseFloat(data.lat), lng: parseFloat(data.lng) } : null)

  const [location, setLocation] = useState(data?.location)

  useEffect(() => {
    if (data?.lat && data?.lng) {
      const latLng = {
        lat: parseFloat(data.lat),
        lng: parseFloat(data.lng),
      }
      setLatLng(latLng)
      setMapCenter(latLng)
    }
  }, [data.lat, data.lng])

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!

    const bounds = new window.google.maps.LatLngBounds(mapCenter)
    map.fitBounds(bounds)
    map.setZoom(17)
  }, [])

  const onClick = (...args) => {
    setLatLng({
      lat: args[0].latLng.lat(),
      lng: args[0].latLng.lng(),
    })

    if (isDragged) {
      setMapCenter({
        lat: args[0].latLng.lat(),
        lng: args[0].latLng.lng(),
      })
      setIsDragged(false)
    }
  }

  const onClose = async () => {
    setData({ ...data, lat: latLng.lat, lng: latLng.lng, location })
    convertGoogleMapsToBlob(latLng.lat, latLng.lng)
    close()
  }

  const handleLocation = async value => {
    const response = await Geocode.fromAddress(value).catch(() => null)
    if (response) {
      const { lat, lng } = response.results[0].geometry.location
      const latLng = {
        lat,
        lng,
      }
      setLatLng(latLng)
      setMapCenter(latLng)
    }
  }

  const debounceFn = debounce(handleLocation, 500)

  const handleChange = event => {
    setLocation(event.target.value)
    debounceFn(event.target.value)
  }

  return (
    <>
      {isLoaded ? (
        <>
          <input value={location} onChange={handleChange} placeholder="Type Event Address Address" className="w-full p-2 border border-gray-300 mb-4" />{' '}
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={20}
            center={mapCenter}
            onLoad={!latLng && onLoad}
            onClick={onClick}
            onDragEnd={() => setIsDragged(true)}
            options={{ draggableCursor: 'crosshair', minZoom: 4, maxZoom: 20 }}
          >
            <Marker position={latLng} />
          </GoogleMap>
        </>
      ) : (
        <></>
      )}
      <div className="float-right mt-3">
        <button
          type="button"
          onClick={() => onClose()}
          className="text-end py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none "
        >
          Close
        </button>
      </div>
    </>
  )
}

export default React.memo(GoogleMapComponent)
