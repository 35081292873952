import Layout from 'containers/Layout'
import { useEffect, useState } from 'react'
import Selection from 'components/molecules/Selection'
import apiGet from 'lib/network/apiGet'
import { SearchIcon } from '@heroicons/react/outline'
import { debounce, forEach, groupBy, isEmpty, keys } from 'lodash'
import classNames from 'classnames'
import notify from 'components/atoms/Notify'
import apiDelete from 'lib/network/apiDelete'
import useModal from 'hooks/useModal'
import { useHistory } from 'react-router-dom'
import useParams from 'hooks/useGetParams'
import { getDefaultProject } from 'lib/utils'
import EmptyState from 'components/pageComponents/EmptyState'

const RandomizationList = () => {
  const [drops, setDrops] = useState([])
  const [data, setData] = useParams({})
  const [projectList, setProjectList] = useState(null)
  const [tokens, setTokens] = useState([])
  const [filter, setFilter] = useState({})
  const history = useHistory()

  useEffect(() => {
    if (data.project) {
      fetchDrops(data.project)
    }
  }, [data.project])

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    const projects = response.data

    setProjectList(projects)
    if (!data.project) setData({ ...data, project: getDefaultProject(projects) })
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  const fetchDrops = async project => {
    const response = await apiGet('drops', { project })
    setDrops(response.data)
    if (!data.drop) setData({ ...data, drop: response.data[0]?.id })
  }

  const fetchTokens = drop => {
    apiGet('tokens', { drop, ...filter }).then(response => {
      setTokens(response.data)
    })
  }

  useEffect(() => {
    if (data.drop) {
      fetchTokens(data.drop)
    }
  }, [data.drop, filter])

  const searchList = async value => {
    if (value.length >= 2) {
      setTokens([])
      setFilter({ ...filter, search: value })
    } else {
      setTokens([])
      keys(filter).length === 0 ? setFilter({}) : setFilter({ ...filter, search: null })
    }
  }

  const searchFn = debounce(e => {
    searchList(e.target.value)
  }, 1000)

  function handleChange(event) {
    searchFn(event)
  }

  const deleteDropTokens = async () => {
    const response = await apiDelete(`tokens/${data.drop}`)
    if (response.success) {
      notify.success('Tokens deleted successfully')
      history.push('/randomization')
    }
  }

  const randomColors = ['#FFC107', '#FF5722', '#2196F3', '#9C27B0', '#FF9800', '#009688', '#795548', '#607D8B']

  const VisualizePattern = ({ tokens }) => {
    const [colors, setColors] = useState([])

    useEffect(() => {
      const colors = {}
      const stages = groupBy(tokens, 'series.name')
      forEach(keys(stages), (key, index) => {
        colors[key] = randomColors[index]
      })

      setColors(colors)
    }, [tokens])

    return (
      <div>
        <div className="flex">
          {keys(colors).map(d => (
            <div key={d} className="flex items-center mr-4">
              <span style={{ background: colors[d] }} className="w-3 rounded-sm block h-3 mr-1"></span>
              <span>{d}</span>
            </div>
          ))}
        </div>
        <div className="mt-5">
          {tokens.map((token, index) => (
            <span key={index} style={{ background: colors[token.series?.name] }} className="w-3 rounded-sm h-3 mr-1 inline-block"></span>
          ))}
        </div>
      </div>
    )
  }

  const [Modal, openModal] = useModal({
    content: <VisualizePattern tokens={tokens} />,
    title: 'Visualize Pattern',
    height: '80vh',
    closeable: true,
    width: '80vw',
  })

  return (
    <Layout title="Pre-randomized Tokens" headerRight={<div></div>}>
      <div className="p-4">
        <Modal />
        <div className="flex justify-between mb-4">
          <div className="flex">
            <Selection
              className="w-[250px] mr-2"
              title="Select Project"
              onChange={value => {
                setData({ ...data, project: value.id })
              }}
              list={projectList}
              value={parseInt(data?.project, 10)}
            />
            <Selection
              className="w-[250px]"
              title="Select Drop"
              onChange={value => {
                setData({ ...data, drop: value.id })
              }}
              list={drops}
              value={parseInt(data?.drop, 10)}
            />
          </div>

          <div className="flex">
            <Selection
              className="w-[250px]"
              title="Select filter"
              onChange={value => {
                if (filter.assigned === value.id) {
                  setFilter({ ...filter, assigned: undefined })
                } else {
                  setFilter({ ...filter, assigned: value.id })
                }
              }}
              list={[
                { name: 'Assigned', id: true },
                { name: 'Unassigned', id: false },
              ]}
              value={filter?.assigned}
            />
            <div className="max-w-xs w-full lg:max-w-xs">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  onChange={handleChange}
                  id="search"
                  name="search"
                  className="block border bg-white border-gray-300 ml-2 w-full h-[32px] pl-10 pr-3 py-2 rounded-md leading-5  placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
                  placeholder="Search"
                  type="search"
                />
              </div>
            </div>
          </div>
        </div>

        {tokens.length && tokens.length > 0 ? (
          <>
            <div className="flex justify-between mb-2">
              {isEmpty(filter.search) && isEmpty(filter.assigned) ? <button onClick={openModal}>Visualize the distribution</button> : <div></div>}
              <button
                className="text-red-500"
                onClick={() => {
                  notify.deleteConfirm({
                    title: 'Are you sure you want to delete this list?',
                    message: 'This list as tokens minted and assigned!',
                    onConfirm: () => {
                      deleteDropTokens()
                    },
                  })
                }}
              >
                Delete this list
              </button>
            </div>
            <table className="bg-white rounded-lg border table-fixed w-full">
              <thead>
                <tr className="w-full">
                  <th className="p-2 border-r  border-b text-left">Token</th>
                  <th className="p-2 border-r border-b text-left">Series</th>
                  <th className="p-2 border-r border-b text-left">Assigned</th>
                </tr>
              </thead>
              <tbody>
                {tokens.map(item => {
                  return (
                    <tr key={item.token} className={classNames(item.assigned && 'bg-green-100')}>
                      <td className="p-2 border-r border-b text-left">#{item?.token}</td>
                      <td className="p-2 border-r border-b text-left">{item?.series?.name}</td>
                      <td className="p-2 border-r border-b text-left">{item.assigned ? item?.user?.displayName : 'Unassigned'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        ) : (
          <EmptyState description="No tokens for selected drop" v2 />
        )}
      </div>
    </Layout>
  )
}

export default RandomizationList
