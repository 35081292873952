import notify from 'components/atoms/Notify'
import apiPost from 'lib/network/apiPost'
import { startCase } from 'lodash'
import moment from 'moment'

const Type = ({ setId, data, identifier, openModal, fetch }) => {
  const deleteConfirm = async id => {
    const response = await apiPost(`types/${id}/delete`)
    if (response.success) {
      notify.success('Type deleted successfully')
      fetch()
    }
  }

  return (
    <div className="">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th style={{ width: '20%' }} scope="col" className="px-6 py-4 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Identifier
                    </th>
                    <th scope="col" className="px-6 py-4 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">
                      Last updated
                    </th>
                    <th scope="col" className="relative px-6 py-4">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map(type => {
                    return (
                      <tr key={type.id} className="bg-white border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{startCase(type.name)}</td>
                        <td className="px-6 py-0 whitespace-nowrap text-sm text-gray-500 ">
                          <span className="block text-gray-900 max-w-[400px] truncate">{startCase(identifier)}</span>
                        </td>
                        <td className="px-6 py-0 whitespace-nowrap text-sm text-gray-500">{moment(type.updatedAt).fromNow()} </td>
                        <td className="relative px-6 py-3 text-right">
                          <span
                            onClick={() => {
                              notify.deleteConfirm({
                                onConfirm: () => deleteConfirm(type.id),
                                dangerText: 'Delete',
                                title: 'Delete Type?',
                                message: 'Are you sure you want to delete this Type',
                              })
                            }}
                            className="px-4 mr-2 py-1 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-red-500"
                          >
                            Delete
                          </span>
                          <span
                            onClick={() => {
                              setId(type.id)
                              openModal()
                            }}
                            className="px-4 py-1 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-dark-400"
                          >
                            Edit
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Type
