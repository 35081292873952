import * as types from 'state/redux/constants'

const initialState = {
  drawer: false,
  toggles: [],
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DRAWER_TOGGLE:
      return {
        ...state,
        drawer: action.data,
      }

    case types.SET_MENU_TOGGLES:
      return {
        ...state,
        toggles: action.data,
      }

    case types.SET_MAIN_MENU_TOGGLES:
      return {
        ...state,
        mainToggles: action.data,
      }

    default:
      return state
  }
}

export default settingsReducer
