import axios from 'axios'
import Button from 'components/atoms/Button'
import LoadingIcon from 'components/atoms/LoadingIcon'
import Selection from 'components/molecules/Selection'
import Layout from 'containers/Layout'
import { uniq, forEach, map, isObject, find, startCase } from 'lodash'
import { useEffect, useState } from 'react'

const NGenAssets = () => {
  const [assets, setAssets] = useState([])
  const [assetsLoading, setAssetsLoading] = useState(false)
  const [listData, setListData] = useState({
    projects: [
      {
        id: 5,
        name: 'Bosco',
        created_at: '2022-06-11T16:20:09.858Z',
        updated_at: '2022-06-11T16:20:09.858Z',
        icon: null,
        hash: null,
        key: null,
        client: null,
      },
      {
        id: 6,
        name: 'Haculla3',
        created_at: '2022-06-15T02:46:30.623Z',
        updated_at: '2022-06-15T02:46:30.623Z',
        icon: null,
        hash: null,
        key: null,
        client: null,
      },
      {
        id: 9,
        name: 'JLin',
        created_at: '2022-07-18T06:58:59.408Z',
        updated_at: '2022-07-18T06:58:59.408Z',
        icon: null,
        hash: null,
        key: null,
        client: null,
      },
      {
        id: 11,
        name: 'Haculla',
        created_at: '2022-09-12T16:29:18.495Z',
        updated_at: '2022-09-12T16:29:18.495Z',
        icon: null,
        hash: null,
        key: null,
        client: null,
      },
      {
        id: 1,
        name: 'baboons',
        created_at: '2021-11-09T16:26:40.666Z',
        updated_at: '2021-11-09T16:26:40.666Z',
        icon: 'baboons.jpg',
        hash: 'k51qzi5uqu5dhpst4b9qce514jvt2cuktv1r7cf2ly7g9jkwjr7r5d5pzi41q3',
        key: 'SecondKey',
        client: 'http://159.89.158.241:5001',
      },
      {
        id: 44,
        name: 'Test Haculla ',
        created_at: '2022-10-17T07:40:22.586Z',
        updated_at: '2022-10-17T07:40:22.586Z',
        icon: null,
        hash: null,
        key: null,
        client: null,
      },
    ],
    categories: [
      {
        id: 2,
        name: 'Sergeant',
        sort_index: 2,
        project_id: 1,
      },
      {
        id: 3,
        name: 'Lieutenant',
        sort_index: 3,
        project_id: 1,
      },
      {
        id: 4,
        name: 'Colonel',
        sort_index: 4,
        project_id: 1,
      },
      {
        id: 5,
        name: 'General',
        sort_index: 5,
        project_id: 1,
      },
      {
        id: 1,
        name: 'Private',
        sort_index: 1,
        project_id: 1,
      },
      {
        id: 6,
        name: 'Joker',
        sort_index: 6,
        project_id: 1,
      },
      {
        id: 7,
        name: 'Bonus',
        sort_index: 7,
        project_id: 1,
      },
      {
        id: 8,
        name: 'one',
        sort_index: 0,
        project_id: 2,
      },
      {
        id: 9,
        name: 'two',
        sort_index: 0,
        project_id: 2,
      },
      {
        id: 10,
        name: 'three',
        sort_index: 0,
        project_id: 2,
      },
      {
        id: 21,
        name: 'Dracula',
        sort_index: 0,
        project_id: 4,
      },
      {
        id: 22,
        name: 'Ghost',
        sort_index: 0,
        project_id: 4,
      },
      {
        id: 23,
        name: 'King Edward',
        sort_index: 0,
        project_id: 4,
      },
      {
        id: 24,
        name: 'Nacho',
        sort_index: 0,
        project_id: 4,
      },
      {
        id: 25,
        name: 'Peanut',
        sort_index: 0,
        project_id: 4,
      },
      {
        id: 28,
        name: 'Excited Face',
        sort_index: 0,
        project_id: 5,
      },
      {
        id: 29,
        name: 'Happy Face',
        sort_index: 0,
        project_id: 5,
      },
      {
        id: 30,
        name: 'Sad Face',
        sort_index: 0,
        project_id: 5,
      },
      {
        id: 26,
        name: 'Angry Face',
        sort_index: 0,
        project_id: 5,
      },
      {
        id: 27,
        name: 'Calm Face',
        sort_index: 0,
        project_id: 5,
      },
      {
        id: 31,
        name: 'Ghost',
        sort_index: 0,
        project_id: 6,
      },
      {
        id: 32,
        name: 'Trap',
        sort_index: 0,
        project_id: 6,
      },
      {
        id: 33,
        name: 'Ed',
        sort_index: 0,
        project_id: 6,
      },
      {
        id: 34,
        name: 'Ghost',
        sort_index: 0,
        project_id: 7,
      },
      {
        id: 35,
        name: 'Cyl',
        sort_index: 0,
        project_id: 7,
      },
      {
        id: 36,
        name: 'Trap',
        sort_index: 0,
        project_id: 7,
      },
      {
        id: 37,
        name: 'Ed',
        sort_index: 0,
        project_id: 7,
      },
      {
        id: 38,
        name: 'Fat',
        sort_index: 0,
        project_id: 7,
      },
      {
        id: 39,
        name: 'Old',
        sort_index: 0,
        project_id: 7,
      },
      {
        id: 40,
        name: 'Nacho',
        sort_index: 0,
        project_id: 7,
      },
      {
        id: 41,
        name: 'Drac',
        sort_index: 0,
        project_id: 7,
      },
      {
        id: 42,
        name: 'Ghost',
        sort_index: 0,
        project_id: 8,
      },
      {
        id: 43,
        name: 'Cyl',
        sort_index: 0,
        project_id: 8,
      },
      {
        id: 44,
        name: 'Trap',
        sort_index: 0,
        project_id: 8,
      },
      {
        id: 45,
        name: 'Ed',
        sort_index: 0,
        project_id: 8,
      },
      {
        id: 46,
        name: 'Fat',
        sort_index: 0,
        project_id: 8,
      },
      {
        id: 47,
        name: 'Old',
        sort_index: 0,
        project_id: 8,
      },
      {
        id: 48,
        name: 'Nacho',
        sort_index: 0,
        project_id: 8,
      },
      {
        id: 49,
        name: 'Drac',
        sort_index: 0,
        project_id: 8,
      },
      {
        id: 50,
        name: 'Default',
        sort_index: 0,
        project_id: 9,
      },
      {
        id: 51,
        name: 'ed',
        sort_index: 0,
        project_id: 10,
      },
      {
        id: 55,
        name: 'Ghost',
        sort_index: 0,
        project_id: 11,
      },
      {
        id: 57,
        name: 'Draculla',
        sort_index: 0,
        project_id: 11,
      },
      {
        id: 58,
        name: 'Edward',
        sort_index: 0,
        project_id: 11,
      },
      {
        id: 59,
        name: 'Ghost',
        sort_index: 0,
        project_id: 44,
      },
    ],
  })

  const [data, setData] = useState({})

  const token = '$2a$10$ZSTRIlhX4IxTqmuh3Y6UX.FUGW3SETHIixEjD.SQaO8l4KJN08vxm'

  const fetchListData = () => {
    axios.get(`https://ngen.opsjet.com/apis/nfts/data?token=${token}`).then(res => {
      setListData({ ...res.data?.data })
    })
  }

  const fetchAssets = () => {
    if (data?.project && data?.category) {
      setAssetsLoading(true)
      axios.get(`https://ngen.opsjet.com/apis/nfts/list?projectid=${data?.project}&category=${data?.category}&token=${token}`).then(res => {
        setAssets(res.data?.data)
        setAssetsLoading(false)
      })
    }
  }

  useEffect(() => {
    fetchAssets()
  }, [data.category])

  useEffect(() => {
    fetchListData()
    return () => {}
  }, [])

  const exportCsv = () => {
    let items = []
    forEach(assets, asset => {
      items = [...items, ...map(asset.attributes, 'trait_type')]
    })
    const attributes = uniq(items)

    const list = map(assets, asset => {
      const obj = {}

      obj.ID = asset.id
      obj.Category = asset.category

      forEach(attributes, attribute => {
        if (attribute !== 'Category') {
          const attr = find(asset.attributes, { trait_type: attribute })
          obj[startCase(attribute)] = attr?.value ?? 'None'
        }
      })

      obj.imageLink = `https://ngen.opsjet.com/apis/nfts/image?category=${asset.category}&id=${asset.id}&projectid=${data.project}&token=${token}`

      return obj
    })

    const csv = convertToCSV(list)
    downloadCSV(csv, assets[0].category)
  }

  // download csv string as file
  const downloadCSV = (csv, filename) => {
    const csvFile = new Blob([csv], { type: 'text/csv' })
    const downloadLink = document.createElement('a')
    downloadLink.download = filename
    downloadLink.href = window.URL.createObjectURL(csvFile)
    downloadLink.style.display = 'none'
    document.body.appendChild(downloadLink)
    downloadLink.click()
  }

  // create a function to convert json to csv
  const convertToCSV = objArray => {
    const array = !isObject(objArray) ? JSON.parse(objArray) : objArray
    let str = ''
    let row = ''

    for (const index in objArray[0]) {
      // Now convert each value to string and comma-separated
      row += index + ','
    }

    row = row.slice(0, -1)
    // append Label row with line break
    str += row + '\r\n'

    for (let i = 0; i < array.length; i++) {
      let line = ''
      for (const index in array[i]) {
        if (line !== '') line += ','
        line += array[i][index]
      }
      str += line + '\r\n'
    }
    return str
  }

  return (
    <Layout>
      <div className="p-4">
        <div className="w-full mb-[120px] flex flex-col">
          <div className="bg-white p-2 rounded-sm w-full">
            <div className="flex items-center border-gray-200 pb-4 justify-between">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Preview NGEN Assets</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Select Project and Category to load assets!</p>
              </div>
            </div>

            {listData?.projects ? (
              <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Select Project
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Selection
                    className="w-[250px]"
                    title="Select Project"
                    onChange={value => {
                      setData({ ...data, project: value.id })
                    }}
                    list={listData?.projects?.map(item => ({ id: item.id, name: item.name }))}
                    value={data.project}
                  />
                </div>
              </div>
            ) : null}

            {data?.project && (
              <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Select Category
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Selection
                    className="w-[250px]"
                    title="Select Category"
                    onChange={value => {
                      setData({ ...data, category: value.id })
                    }}
                    list={listData?.categories?.filter(d => d.project_id === data.project)?.map(item => ({ id: item.id, name: item.name }))}
                    value={data?.category}
                  />
                </div>
              </div>
            )}
            {assets?.length > 0 && (
              <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Export CSV {assets.length ? `(${assets.length})` : null}
                </label>
                <div className="sm:mt-0 -ml-3 sm:col-span-2">
                  <Button onClick={exportCsv}>Download</Button>
                </div>
              </div>
            )}
          </div>

          {(assets?.length > 0 || assetsLoading) && (
            <div className="bg-white p-8 mt-4 rounded-sm w-full border">
              {assetsLoading ? (
                <div className="w-full flex justify-center items-center">
                  <div className="flex">
                    <LoadingIcon />
                    <span>Loading Assets...</span>
                  </div>
                </div>
              ) : null}
              <div className="grid grid-cols-6 gap-3">
                {assets.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="h-[169px] bg-gray-50 w-full">
                        <img
                          placeholder="blur"
                          className="h-auto w-full"
                          src={`https://ngen.opsjet.com/apis/nfts/image?category=${item.category}&id=${item.id}&projectid=${data.project}&token=${token}`}
                          alt=""
                        />
                      </div>
                      <span className="w-full flex justify-center">#{item.id}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default NGenAssets
