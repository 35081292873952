import Layout from 'containers/Layout'
import { useHistory, useParams } from 'react-router-dom'
import useModal from 'hooks/useModal'
import { useEffect, useState } from 'react'
import apiGet from 'lib/network/apiGet'
import AddEditType from './modals/AddEditType'
import Type from './Type'
import Button from 'components/atoms/Button'
import { groupBy, split, startCase } from 'lodash'
import { useDispatch } from 'react-redux'
import { setTypes } from 'state/redux/actions/user.actions'

const Types = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [typeId, setTypeId] = useState(null)

  const { identifier } = useParams()
  const identifierName = startCase(identifier)

  const [typesData, setTypesData] = useState(null)

  const fetchTypes = async () => {
    const response = await apiGet('types')
    dispatch(setTypes(groupBy(response?.data, 'identifier')))
    setTypesData(response?.data.filter(type => type.identifier === identifier))
  }

  const path = split(history.location.pathname, '/').pop()

  useEffect(() => {
    fetchTypes()
  }, [path])

  const [PropertyModal, openPropertyModal] = useModal({
    content: <AddEditType identifier={identifier} fetchData={fetchTypes} id={typeId} setTypeId={setTypeId} />,
    title: typeId ? `Edit Type` : `Add New Type`,
  })

  const openModal = () => {
    setTypeId(null)
    openPropertyModal()
  }

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Button onClick={openModal} type="button">
            Add {identifierName} Type
          </Button>
        </div>
      }
      title={`${identifierName} Types`}
    >
      <Type identifier={identifier} data={typesData} setId={setTypeId} openModal={openPropertyModal} fetch={fetchTypes} />
      <PropertyModal />
    </Layout>
  )
}

export default Types
