import notify from 'components/atoms/Notify'
import isEmpty from 'lodash/isEmpty'
import { parseCookies } from 'nookies'
import qs from 'qs'

const baseUrl = process.env.REACT_APP_BASE_URL

const apiGet = async (url, params, showError = true) => {
  const { accessToken, workspaceID, role, impersonateToken, isImpersonated, impersonateRole } = parseCookies()

  const config = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }

  if (!isEmpty(accessToken)) {
    config.headers = {
      Authorization: isImpersonated && impersonateToken ? impersonateToken : accessToken,
      workspaceID,
      ...config.headers,
    }
    if (role && !isImpersonated) {
      config.headers.role = role
    } else if (isImpersonated && impersonateRole) {
      config.headers.role = impersonateRole
    }
  }

  const query = qs.stringify(params)

  return fetch(`${baseUrl}/admin/${url}?${query}`, config)
    .then(response => {
      return response.json()
    })
    .then(response => {
      // if (response.status !== 200) throw new Error('Some error occurred')
      if (!response.success && showError) {
        notify.error(response.message, null, response.err)
      }
      return response
    })
}

export default apiGet
