import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import { Route, Switch, useParams } from 'react-router-dom'
import apiGet from 'lib/network/apiGet'
import { forEach } from 'lodash'
import Tier from './Tier'

const AddEditTier = () => {
  const [isEdit, setIsEdit] = useState(null)
  const [tierData, setTierData] = useState(null)
  const [loading, setLoading] = useState(false)

  const id = useParams().id

  const fetchTier = async () => {
    setLoading(true)
    const response = await apiGet(`tier/${id}`, null)
    if (response.success) {
      forEach(Object.entries(response.data), ([key, value]) => {
        if (value === 'null') {
          delete response.data[key]
        }
      })

      setTierData(response.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      fetchTier(id)
      setIsEdit(true)
    }
  }, [id])

  return (
    <Layout
      headerRight={<div className="mt-4 flex sm:mt-0 sm:ml-4"></div>}
      title={isEdit ? 'Edit Tier' : 'Add New Tier'}
      breadcrumbs={[
        { name: 'Tier List', pathname: '/tiers' },
        { name: isEdit ? 'Tier Details' : 'Add New Tier', pathname: '/tiers/add', current: true },
      ]}
    >
      <div className="flex p-3 overflow-auto">
        <div className="bg-white flex w-full">
          <div className="px-0 w-full">
            <Switch>
              <Route path="/tiers/edit/:id" component={() => <Tier tierData={tierData} loading={loading} id={id} isEdit={isEdit} fetchTier={fetchTier} />} />
              <Route path="/tiers/add" component={() => <Tier />} />
            </Switch>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AddEditTier
