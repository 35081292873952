import QrReader from 'modern-react-qr-reader'
import notify from 'components/atoms/Notify'
import { useCallback, useEffect, useState } from 'react'
import { includes, isEmpty, truncate } from 'lodash'
import apiGet from 'lib/network/apiGet'
import classNames from 'classnames'
import { IoAlertCircle, IoClose, IoCopyOutline } from 'react-icons/io5'
import { startEndTruncate } from 'utils'

const QR = ({ close, fetchEvents, station, event }) => {
  const [facingMode, setFacingMode] = useState('environment')
  const [qrResult, setQRResult] = useState(null)
  const [showCamera, setShowCamera] = useState(true)
  const [fetchingTokens, setFetchingTokens] = useState(false)
  const [userLoading, setUserLoading] = useState(false)
  const [logId, setLogId] = useState(false)
  const [error, setError] = useState(null)
  const [code, setCode] = useState(null)
  const [access, setAccess] = useState(null)
  const [message, setMessage] = useState(null)
  const [subTitle, setSubTitle] = useState(null)
  const [scanningInProgress, setScanningInProgress] = useState(false)
  const [tier, setTier] = useState(false)

  const handleSwitch = useCallback(() => {
    setFacingMode(prevState => (prevState === 'environment' ? 'user' : 'environment'))
  }, [])

  // useEffect(() => {
  //   handleScan('qr-848717457')
  // }, [])

  const handleScan = hash => {
    if (hash && !scanningInProgress) {
      if (hash && includes(hash, 'qr')) {
        try {
          setScanningInProgress(true)
          apiGet(`read-qr/${hash}`).then(res => {
            const obj = res.data
            if (obj.valid) {
              setQRResult({ ...obj.data, hash })
              setShowCamera(false)
              setScanningInProgress(false)
            } else if (obj.expired) {
              setError('QR code expired. Please scan again - blkexp47')
              setCode(501)
              setShowCamera(false)
              setScanningInProgress(false)
            } else {
              setError('QR code invalid. Please scan again - blkinv52')
              setCode(501)
              setShowCamera(false)
              setScanningInProgress(false)
            }
          })
        } catch (error) {
          setError('QR code is invalid - blkerr59')
          setCode(500)
          setShowCamera(false)
          setScanningInProgress(false)
        }
      } else if (!isEmpty(hash)) {
        setError('QR code is invalid - blkemp65')
        setCode(500)
        setShowCamera(false)
        setScanningInProgress(false)
      }
    }
  }

  const fetchTokens = async () => {
    setUserLoading(true)
    setFetchingTokens(true)
    const response = await apiGet(`qr-validation/${event.id}/${qrResult.hash}`, { station })
    setCode(response.data.code)

    if (response.data.error) {
      setError(response.data.message)
    }

    if (response.data.access) {
      setAccess(response.data.access)
      setMessage(response.data.message)
      setSubTitle(response.data.subtitle)
      setTier(response.data.tier)
    }

    setLogId(response.data?.logId)
    setUserLoading(false)
    setShowCamera(false)
    setFetchingTokens(false)
  }

  useEffect(() => {
    if (qrResult?.id && !userLoading && !fetchingTokens) {
      fetchTokens()
    }
  }, [qrResult])

  const handleError = err => {
    // eslint-disable-next-line no-console
    console.log(err)
  }

  const handleCTA = async allow => {
    const response = await apiGet(`cta-entry/${logId}/${allow}`)
    if (response.data === 'OK') {
      if (allow !== 'closed') {
        if (allow) {
          notify.success('Entry Successful')
        } else {
          notify.error('Entry Denied')
        }
      }

      fetchEvents()
      close()
    }
  }

  const copyText = text => {
    navigator.clipboard.writeText(text)
    notify.success('Copied to clipboard', truncate(text, { length: 35 }))
  }

  return (
    <div className={classNames(access === 'GRANTED' ? 'bg-[#1faa66]' : access === 'DENIED' || error ? 'bg-[#fa6060] min-h-[500px]' : '', 'h-full')}>
      <button
        onClick={() => {
          if (logId) {
            handleCTA('closed')
          }
          close()
        }}
        className="h-8 w-8 flex justify-center items-center bg-white sticky top-4 left-2 z-20 rounded-full"
      >
        <IoClose className="h-5 w-5" />
      </button>
      {qrResult || error ? (
        <article className="responsive-profile-article">
          {/* Profile header */}
          <div className="min-h-[500px] py-4">
            {qrResult?.displayName || qrResult?.email || qrResult?.walletAddress ? (
              <div className="px-12 responsive-profile flex flex-col justify-center items-center py-2">
                <div>
                  {tier?.logo ? (
                    <div className="w-full justify-center flex">
                      <img className="h-[60px]" src={tier?.logo} alt="" />
                    </div>
                  ) : null}
                  <div className="bg-white rounded-lg overflow-hidden">
                    <div className="flex-shrink-0">
                      <div className="relative">
                        <img className="h-[300px] w-[300px]" src={qrResult?.userPhoto || 'https://cdnd.reel8.com/1/profile/3.png'} alt="" />
                        <span className="absolute inset-0 rounded-full" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="ml-0 p-4">
                      {qrResult?.displayName ? <h1 className="text-2xl text-center font-bold text-gray-900">{qrResult?.displayName}</h1> : null}
                      {qrResult?.email ? <p className="text-sm font-medium  text-center  text-gray-500">{qrResult?.email}</p> : null}
                      {qrResult?.walletAddress ? (
                        <p className="text-sm font-medium flex items-center justify-center text-center  text-gray-500">
                          <button id="disable-export" className="hover:text-indigo-500 mr-2" onClick={() => copyText(qrResult?.walletAddress)}>
                            <IoCopyOutline className="ml-2" />
                          </button>
                          {startEndTruncate(qrResult?.walletAddress)}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {error && !userLoading && !access && (
              <div className={classNames(error && !qrResult?.displayName ? 'error-message-full' : '', 'min-h-[500px] flex flex-col items-center justify-center')}>
                <div className="flex items-center justify-center">
                  <IoAlertCircle className="h-10 w-10 text-white" />
                </div>
                <div className="text-center text-lg font-bold text-white">{error}</div>
                <span className="text-center text-lg text-white">{code ? `${code}` : ''}</span>
                <button
                  onClick={() => {
                    setError(null)
                    setQRResult(null)
                    setShowCamera(true)
                  }}
                  className="bg-white hover:bg-dark-700 text-red-500 font-bold py-2 px-4 rounded mt-4"
                >
                  Re-scan
                </button>
              </div>
            )}
            <div className="flex">
              {userLoading && !access ? (
                <span className="flex w-full items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span className="text-lg font-mono">Checking your tokens</span>
                </span>
              ) : null}
            </div>
            {access ? (
              <div className={classNames(access === 'GRANTED' ? 'text-white' : 'text-white', 'text-lg w-full text-center font-medium')}>
                {access === 'GRANTED' ? (
                  <div>
                    <div className="flex flex-col pb-3">
                      <span className="text-center text-xs text-white">{subTitle}</span>
                      <span className="text-lg font-bold">{message}</span>
                      <span className="text-center text-lg text-white">{code ? `${code}` : ''}</span>
                    </div>
                    <div className="flex justify-center pb-4">
                      <button onClick={() => handleCTA(true)} className="bg-white mr-2 text-sm text-[#1faa66] font-bold py-2 px-4 rounded">
                        Allow Entry
                      </button>
                      <button onClick={() => handleCTA(false)} className="bg-white text-sm text-[#fa6060] font-bold py-2 px-4 rounded">
                        Deny Entry
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex flex-col pb-3">
                      <span className="text-lg font-bold">{message}</span>
                      <span className="text-center text-lg text-white">{code ? `${code}` : ''}</span>
                    </div>
                    <div className="flex justify-center pb-4">
                      <button
                        onClick={() => {
                          setAccess(null)
                          setQRResult(null)
                          setShowCamera(true)
                          setUserLoading(false)
                          setMessage(null)
                          setCode(null)
                          setError(null)
                        }}
                        className="bg-white text-sm text-[#fa6060] font-bold py-2 px-4 rounded"
                      >
                        Re-scan
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </article>
      ) : null}
      {showCamera ? (
        <div style={{ position: 'absolute', top: 0, background: 'black', height: '100vh', alignItems: 'center', display: 'flex', width: 500 }} onDoubleClick={handleSwitch}>
          <QrReader className="scanner" showViewFinder={true} delay={100} onError={handleError} onScan={handleScan} facingMode={facingMode} />
        </div>
      ) : null}
    </div>
  )
}

export default QR
