import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import moment from 'moment'
import apiGet from 'lib/network/apiGet'
import { useHistory } from 'react-router-dom'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import { find, split, isEmpty, toUpper } from 'lodash'
import { typeList } from 'pages/smart-contract/Contract'
import { setTiers } from 'state/redux/actions/user.actions'

const Tiers = () => {
  const [tierData, setTierData] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    fetchTiers()
  }, [])

  const fetchTiers = async () => {
    const response = await apiGet(`tiers`, { size: 100, page: 1 })
    setTierData(response?.data)
    dispatch(setTiers(response?.data))
  }

  return (
    <Layout
      breadcrumbs={[{ name: 'Tiers', link: '/tier' }]}
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Button
            onClick={() => {
              history.push('/tiers/add')
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Add new Tier
          </Button>
        </div>
      }
      title="Tiers"
    >
      <div className="hidden bg-gray-50 sm:block">
        <div className="align-middle inline-block min-w-full">
          <table className="min-w-full">
            <thead>
              <tr className="border-gray-200">
                <th className="px-6 py-4 border-b w-[20%] border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="">Tier Name</span>
                </th>
                <th className="px-6 py-4 border-b w-[5%] border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="">Qty</span>
                </th>
                <th className="px-6 py-4 border-b w-[30%] border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="">Asset Type</span>
                </th>
                <th className="px-6 py-4 border-b w-[30%] border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="">Smart Contract</span>
                </th>
                <th className="hidden whitespace-nowrap md:table-cell px-6 py-4 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last updated
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {tierData?.map(tier => {
                return (
                  <tr key={tier.id}>
                    <td className="px-4 py-3  whitespace-nowrap text-sm font-medium text-gray-900">
                      <div onClick={() => history.push(`/tiers/edit/${tier.id}`)} className="flex cursor-pointer items-center space-x-3 lg:pl-2">
                        <span>{tier.name}</span>
                      </div>
                    </td>
                    <td className="px-6 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900">
                      <span>{tier.qty}</span>
                    </td>
                    <td className="px-6 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900">
                      {split(tier.assetType, ',')?.map(d =>
                        !isEmpty(d) ? (
                          <span className="bg-gray-100 text-xs font-regular py-1 px-1.5 mr-1" key={d}>
                            {find(typeList, { id: d })?.name || toUpper(d)}
                          </span>
                        ) : null
                      )}
                    </td>

                    <td className="px-6 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900">
                      <span>
                        {tier?.contracts?.map(d => (
                          <span className="bg-gray-100 text-xs font-regular py-1 px-1.5 mr-1" key={d}>
                            {d?.name}
                          </span>
                        ))}
                      </span>
                    </td>

                    <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">{moment(tier.updatedAt).fromNow()}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default Tiers
