import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import classNames from 'classnames'

const PopupMenu = ({ children, menu: menuGroups = [], onClick }) => {
  return (
    <Menu as="div" className="relative inline-block text-center ">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md py-4 text-sm text-normal text-gray-700 hover:bg-gray-50">{children}</Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right z-20 absolute right-0 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          {menuGroups?.map((item, index) => {
            return (
              <div key={index} className="py-1">
                {item?.map((menuItem, index2) => {
                  return (
                    <Menu.Item onClick={id => onClick(menuItem)} key={index2}>
                      {({ active }) => (
                        <span
                          className={classNames(
                            menuItem.disabled && 'opacity-50 pointer-events-none text-gray-500',
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 cursor-pointer py-2 text-sm'
                          )}
                        >
                          {menuItem.name}
                        </span>
                      )}
                    </Menu.Item>
                  )
                })}
              </div>
            )
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default PopupMenu
