import moment from 'moment'
import { forwardRef } from 'react'
import WithReports from './WithReports'
import { formateDate, startEndTruncate, walletLink } from 'utils'
import { map, join } from 'lodash'
// eslint-disable-next-line
const TransactionLogs = forwardRef(({ ...props }, ref) => {
  const getItemStatus = status => {
    switch (status) {
      case 0:
        return 'Completed'
      case 1:
        return 'Failed'
      case 2:
        return 'Pending'
      case 3:
        return 'Initiated'
      case 4:
        return 'Canceled'
      default:
        return 'Status not available'
    }
  }
  const columns = [
    {
      name: 'Hash',
      width: '10%',
      key: 'stripe',
      sortable: false,
      rendered: item => {
        return (
          <div className="text-xs text-blue-500 font-mono">
            {item.transactionHash ? (
              <a target="_blank" href={`https://${process.env.REACT_APP_ENV === 'production' ? '' : 'goerli.'}etherscan.io/tx/${item?.transactionHash}`} rel="noreferrer">
                View on Explorer ({startEndTruncate(item.transactionHash || '')})
              </a>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      name: 'Type',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <span className={`text-xs font-medium text-gray-500 rounded-md`}>
          {item.type === 'direct' ? 'ETH' : item.type === 'transfer' ? 'NFT TRANSFER' : item.type === 'club-payment' ? 'CLUB PAYMENT' : 'FIAT'}
        </span>
      ),
    },
    {
      name: 'From',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => <span className={`text-xs font-medium text-gray-500 py-1 rounded-md`}>{item.from !== item.to ? startEndTruncate(item.from) : ''}</span>,
    },
    {
      name: 'To',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => <span className={`text-xs font-medium text-gray-500 py-1 rounded-md`}>{startEndTruncate(item.to)}</span>,
    },
    {
      name: 'Status',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <span
          className={`text-xs font-regular ${
            item.status === 0 ? 'bg-green-100 text-green-700' : item.status === 1 ? 'bg-red-100 text-red-700' : item.status === 4 ? 'bg-yellow-100 text-yellow-800' : 'bg-gray-200'
          } px-2 py-1 rounded-md`}
        >
          {getItemStatus(item.status)}
        </span>
      ),
    },
    {
      name: 'User',
      width: '10%',
      key: 'type',
      sortable: false,
      rendered: item => (
        <span className={`text-xs font-medium text-gray-500 py-1 rounded-md`}>
          {item.userId?.displayName} {walletLink(item.userId?.walletAddress || '')}
        </span>
      ),
    },
    {
      name: 'Amount',
      width: '10%',
      key: 'amount',
      sortable: false,
      rendered: item => (
        <span className={`text-xs font-medium text-gray-500 py-1 rounded-md`}>
          {item.type === 'transfer' ? <span>-</span> : ''}
          {item.type === 'club-payment' ? <span>{item.amount || item?.data?.values?.amount / 1e8} CLUB</span> : ''}
          {item.type === 'direct' || item.type === 'moonpay' ? <span>{item.amount?.toFixed(4)} ETH</span> : ''}
          {item.type === 'moonpay' && item.moonpay ? (
            <span className="ml-2">
              ({item?.moonpay?.data?.baseCurrencyAmount} {item?.moonpay?.data?.baseCurrency?.code})
            </span>
          ) : (
            ''
          )}
        </span>
      ),
    },
    {
      name: 'Token IDs',
      width: '10%',
      key: 'tokenId',
      sortable: false,
      rendered: item => <span className={`text-xs font-medium text-gray-500 py-1 rounded-md`}>{join(map(item.nfts, 'tokenId'), ',')}</span>,
    },
    {
      name: 'Transaction Date',
      width: '5%',
      key: 'createdAt',
      align: 'right',
      sortable: true,
      rendered: item => (
        <div className="text-sm text-gray-500">
          ({moment(item.createdAt).fromNow()}) {formateDate(moment(item.createdAt))}
        </div>
      ),
    },
  ]

  return <WithReports {...props} apiURL="transaction-logs/list" defaultFileName="Transaction" forwardedRef={ref} columns={columns} activeFilterName="past_7_days" />
})

export default TransactionLogs
