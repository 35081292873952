import CreateWorkspace from './CreateWorkspace'
import Credentials from '../Credentials'
import SetupWorkspace from './SetupWorkspace'
import Customization from './Customization'
import AuthMethods from './AuthMethods'
import QrConfig from './QrConfig'

const WizardWrapper = ({
  colors,
  splashRef,
  files,
  currentTab,
  props,
  workspaceSquareRef,
  showPreview,
  previews,
  setFiles,
  workspaceRef,
  setPCustomColor,
  primaryColorRef,
  pCustomColor,
  setSCustomColor,
  secondaryColorRef,
  sCustomColor,
  fontColorRef,
  fCustomColor,
  setFCustomColor,
  qrLogoRef,
  setQRConfig,
  qrConfig,
  setPopup,
  popup,
  setWorkspaceData,
  workspaceData,
  eventConfig,
  setEventConfig,
  getFeUrl,
  downloading,
  setDownloading,
  handleDownloadClick,
  events,
  currentStep,
  setIsWorkspaceCredError,
}) => {
  return (
    <>
      {currentStep === 1 && <CreateWorkspace props={props} />}
      {currentStep === 2 && <Credentials isWizard={true} setIsWorkspaceCredError={setIsWorkspaceCredError} />}
      {currentStep === 3 && (
        <SetupWorkspace
          workspaceSquareRef={workspaceSquareRef}
          showPreview={showPreview}
          setFiles={setFiles}
          files={files}
          previews={previews}
          workspaceRef={workspaceRef}
          props={props}
        />
      )}
      {currentTab === 4 && (
        <AuthMethods eventConfig={eventConfig} setEventConfig={setEventConfig} events={events} setWorkspaceData={setWorkspaceData} workspaceData={workspaceData} />
      )}
      {currentStep === 5 && (
        <Customization
          splashRef={splashRef}
          showPreview={showPreview}
          setFiles={setFiles}
          files={files}
          previews={previews}
          colors={colors}
          props={props}
          setPCustomColor={setPCustomColor}
          primaryColorRef={primaryColorRef}
          pCustomColor={pCustomColor}
          setSCustomColor={setSCustomColor}
          secondaryColorRef={secondaryColorRef}
          sCustomColor={sCustomColor}
          setFCustomColor={setFCustomColor}
          fontColorRef={fontColorRef}
          fCustomColor={fCustomColor}
        />
      )}
      {currentStep === 6 && (
        <QrConfig
          qrLogoRef={qrLogoRef}
          showPreview={showPreview}
          setFiles={setFiles}
          files={files}
          previews={previews}
          setQRConfig={setQRConfig}
          qrConfig={qrConfig}
          setPopup={setPopup}
          popup={popup}
          getFeUrl={getFeUrl}
          downloading={downloading}
          setDownloading={setDownloading}
          handleDownloadClick={handleDownloadClick}
        />
      )}
    </>
  )
}

export default WizardWrapper
