import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3CenterLeftIcon, XMarkIcon } from '@heroiconsv2/react/24/outline'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { get, find, size } from 'lodash'
import { IoCalendar, IoInformationCircle, IoLocation } from 'react-icons/io5'
import logo from 'assets/images/opsjet.png'
import apiGet from 'lib/network/apiGet'
import moment from 'moment'
import useModal from 'hooks/useModal'
import QR from 'pages/wp-scanner/ScanQR'
import Selection from 'components/molecules/Selection'
import { setUser } from 'state/redux/actions/user.actions'
import { destroyCookie, setCookie } from 'nookies'
import { useHistory } from 'react-router-dom'
import EmptyState from 'components/pageComponents/EmptyState'
import LoadingIcon from 'components/atoms/LoadingIcon'
import { startEndTruncate } from 'utils'
import ReportIssue from 'pages/wp-scanner/ReportIssue'

const userNavigation = [{ name: 'Sign out', href: '#' }, { name: 'Change Password' }]

const Events = ({ openChangePasswordModal }) => {
  const user = useSelector(state => get(state, 'user.user'))
  const [eventsLoading, setEventsLoading] = useState(true)
  const [station, setStation] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const [sort, setSort] = useState()
  const [event, setEvent] = useState(null)
  const [eventLogs, setEventLogs] = useState([])

  const fetchEvents = () => {
    setEventsLoading(true)
    apiGet(`event-logs/${event?.id}`, { sort }).then(res => {
      if (res.data) {
        setEventLogs(res.data)
        setEventsLoading(false)
      }
    })
  }

  useEffect(() => {
    const event = user?.stations ? user?.stations[0]?.event : null
    const station = user?.stations ? user?.stations[0]?.station : null
    setEvent(event)
    setStation(station)
  }, [user])

  useEffect(() => {
    if (user.dashboardAccess) {
      if (!find(userNavigation, { name: 'Switch to Workspace' }) && size(user.access) > 0) userNavigation.push({ name: 'Switch to Workspace' })
    }
  }, [])

  useEffect(() => {
    if (event?.id) {
      fetchEvents()
    } else {
      setEventsLoading(false)
    }
  }, [event?.id, sort])

  const [Modal, open] = useModal({
    content: <QR event={event} station={station} fetchEvents={fetchEvents} />,
    dimensions: {
      minHeight: 500,
      minWidth: 500,
      height: '100%',
      width: 500,
    },
    responsive: true,
    padding: 0,
  })

  const [ReportIssueModel, openReportIssueModel] = useModal({
    content: <ReportIssue />,
    responsive: true,
  })

  const handleMenu = key => {
    const config = {
      path: '/',
      maxAge: 604800,
      sameSite: true,
    }

    if (key === 'Sign out') {
      dispatch(setUser({}))

      destroyCookie({}, 'accessToken', { path: '/' })
      history.push('/login')
    }

    if (key === 'Switch to Workspace') {
      setCookie(null, 'scanner', false, config)
      window.location.href = '/dashboard'
    }

    if (key === 'Change Password') {
      openChangePasswordModal()
    }
  }

  return (
    <>
      {/* <ChangePasswordModal /> */}
      <div className="relative root_container min-h-screen flex flex-col">
        <Modal />
        <ReportIssueModel />
        <Disclosure as="nav" className="flex-shrink-0 sticky top-0 z-10 bg-white border-b">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-full px-2 sm:px-4 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                  {/* Logo section */}
                  <div className="flex items-center px-2 lg:px-0 xl:w-64">
                    <div className="flex-shrink-0">
                      <img className="h-8 w-auto" src={logo} alt="Your Company" />
                    </div>
                  </div>
                  {/* Search section */}
                  <div className="flex flex-1 justify-center lg:justify-end"></div>
                  <div className="flex lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md  p-2 text-dark-500  focus:outline-none focus:ring-2 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3CenterLeftIcon className="block h-6 w-6" aria-hidden="true" />}
                    </Disclosure.Button>
                  </div>
                  {/* Links section */}
                  <div className="hidden lg:block lg:w-80">
                    <div className="flex items-center justify-end">
                      <Menu as="div" className="relative ml-4 flex-shrink-0">
                        <div>
                          <Menu.Button className="flex rounded-full bg-red-700 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2">
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src={user.userProfile?.profile} alt="" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map(item => (
                              <Menu.Item onClick={e => handleMenu(item.name)} key={item.name}>
                                {({ active }) => (
                                  <a href={item.href} className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="border-t border-gray-200 pt-4 pb-3">
                  <div className="space-y-1 px-2">
                    {userNavigation.map(item => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-[#d8210b] hover:text-red-100"
                        onClick={e => handleMenu(item.name)}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* 3 column wrapper */}
        <div className="mx-auto w-full max-w-full flex-grow lg:flex xl:pl-8">
          {/* Left sidebar & main wrapper */}
          <div className="min-w-0 flex-1 bg-white xl:flex">
            {/* Account profile */}
            <div className="bg-white xl:w-64 max-w-64 xl:flex-shrink-0 xl:border-r xl:border-gray-200">
              <div className="py-6 pl-4 pr-6 sm:pl-6 max-w-64 lg:pl-8 xl:pl-0">
                <div className="flex items-center justify-between max-w-64">
                  <div className="flex-1 space-y-8 w-[100px]">
                    <div className="space-y-8 sm:flex sm:items-center sm:justify-between sm:space-y-0 xl:block xl:space-y-8">
                      {/* Profile */}
                      <div className="flex items-center space-x-3">
                        <div className="h-12 w-12 flex-shrink-0">
                          <img className="h-12 w-12 rounded-full" src={user.userProfile?.profile} alt="" />
                        </div>
                        <div className="space-y-0">
                          <div className="text-sm font-medium text-gray-900">{user.displayName}</div>
                          <a href="#" className="group flex items-center space-x-1">
                            <span className="text-sm font-medium text-gray-500 group-hover:text-gray-900">QR Code Scanner</span>
                          </a>
                        </div>
                      </div>
                      {/* Action buttons */}

                      {!station && <div className="flex-shrink-0 space-x-3">No Station has been assigned to you!</div>}
                      {!event ? <div className="bg-red-200 rounded-md flex justify-center py-2 px-3 text-xs font-medium text-red-700">{!event ? 'No event found' : ''}</div> : null}
                      <div className="flex flex-col sm:flex-row xl:flex-col">
                        <button
                          disabled={!event || !station}
                          onClick={event && station ? open : null}
                          type="button"
                          className={classNames(
                            !event || !station ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#d8210b]',
                            'inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 xl:w-full'
                          )}
                        >
                          SCAN QR
                        </button>
                        <button
                          type="button"
                          onClick={() => openReportIssueModel()}
                          className="mt-3 inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 xl:ml-0 xl:mt-3 xl:w-full"
                        >
                          Report Issue
                        </button>
                      </div>
                    </div>
                    {/* Meta info */}
                    {event?.id && (
                      <div className="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-8 xl:flex-col xl:space-x-0 xl:space-y-6">
                        <div className="flex w-full items-center space-x-2">
                          <Selection
                            disabled={user?.stations?.map(d => d.event)?.length === 1}
                            className="w-full max-w-full"
                            value={event?.id}
                            onChange={event => {
                              setEvent(event)
                              setStation(event.station)
                            }}
                            list={user?.stations?.map(d => ({ ...d?.event, station: d?.station }))}
                          />
                        </div>
                        <div className="flex items-center space-x-2">
                          <IoLocation style={{ fontSize: 16, width: 16, height: 16 }} className=" text-red-400" aria-hidden="true" />
                          <span className="text-sm font-regular text-gray-500">
                            {event.location}
                            {station ? `, ${station}` : ''}
                          </span>
                        </div>
                        <div className="flex items-center space-x-2">
                          <IoCalendar style={{ fontSize: 16, width: 16, height: 16 }} className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          <span className="text-sm font-regular text-gray-500">{moment(event.start).format('ddd, MMM D, Y hh:mm A')}</span>
                        </div>

                        <div className="flex items-start space-x-2">
                          <IoInformationCircle style={{ fontSize: 16, width: 16, height: 16 }} className=" min-h-[18px] min-w-[18px] text-gray-400" aria-hidden="true" />
                          <span className="text-sm font-regular text-gray-500">{event.description}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Projects List */}
            <div className="bg-white lg:min-w-0 lg:flex-1">
              <div className="border-b border-t border-gray-200 pl-4 pr-6 pt-4 pb-4 sm:pl-6 lg:pl-8 xl:border-t-0 xl:pl-6 xl:pt-6">
                <div className="flex items-center">
                  <h1 className="flex-1 text-lg font-medium">Activity</h1>
                  <Selection
                    value={sort}
                    className="w-[200px]"
                    title="Sort By"
                    onChange={e => setSort(e.id)}
                    list={[
                      { name: 'Scanning Time', id: 'createdAt' },
                      { name: 'Entry Granted', id: 'granted' },
                      { name: 'Entry Denied', id: 'denied' },
                    ]}
                  />
                </div>
              </div>
              {eventLogs?.length > 0 ? (
                <ul className="divide-y divide-gray-200 border-b border-gray-200 event-mb-5">
                  {eventLogs.map((project, index) => (
                    <li key={index} className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6">
                      <div className="flex items-center justify-between space-x-4">
                        {/* Repo name and link */}
                        <div className="min-w-0 space-y-3">
                          <div className="flex items-center space-x-3">
                            <span
                              className={classNames(
                                project.entryAllowed === null || project.scanStatus === 'CLOSED' ? 'bg-yellow-100' : project.entryAllowed ? 'bg-green-100' : 'bg-red-100',
                                'h-4 w-4 rounded-full flex items-center justify-center'
                              )}
                              aria-hidden="true"
                            >
                              <span
                                className={classNames(
                                  project.entryAllowed === null || project.scanStatus === 'CLOSED' ? 'bg-yellow-500' : project.entryAllowed ? 'bg-green-400' : 'bg-red-400',
                                  'h-2 w-2 rounded-full'
                                )}
                              />
                            </span>
                            <span>
                              <h2 className="text-sm font-medium">
                                <a href={project.href}>
                                  <span className="absolute inset-0" aria-hidden="true" />
                                  {project.user?.displayName} {project?.user?.email ? ` - ${startEndTruncate(project?.user?.email)}` : ''}{' '}
                                  {project?.user?.walletAddress ? `${project?.user?.displayName ? '- ' : ''}${startEndTruncate(project?.user?.walletAddress)}` : ''} <br />{' '}
                                  {project?.validationType}{' '}
                                </a>
                              </h2>
                              <div className="flex-shrink-0 flex-col items-end space-y-3 sm:hidden">
                                <p className="flex space-x-2 text-sm text-gray-500">
                                  {project.entryAllowed !== null ? (
                                    <span>{!project.entryAllowed ? `Entry was not allowed at ${project.station}` : `Entry was allowed at ${project?.station}`}</span>
                                  ) : (
                                    `Closed`
                                  )}
                                </p>
                                <span>
                                  <span className="text-gray-800 font-medium">{moment(project.createdAt).fromNow()}</span>
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>

                        {/* Repo meta info */}
                        <div className="hidden flex-shrink-0 flex-col items-end space-y-3 sm:flex">
                          <p className="flex space-x-2 text-sm text-gray-500">
                            {project.entryAllowed !== null ? (
                              <span>{!project.entryAllowed ? `Entry was not allowed at ${project.station}` : `Entry was allowed at ${project?.station}`}</span>
                            ) : (
                              <span>Closed</span>
                            )}
                            <span className="text-gray-800 font-medium">{moment(project.createdAt).fromNow()}</span>
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : eventsLoading ? (
                <div className="w-full flex justify-center items-center h-[100px]">
                  <LoadingIcon />
                </div>
              ) : (
                <EmptyState v2 description="No scanning activity" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Events
