import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import moment from 'moment'
import apiGet from 'lib/network/apiGet'
import PopupMenu from 'components/molecules/PopupMenu'
import { useHistory } from 'react-router-dom'
import { IoEllipsisVertical } from 'react-icons/io5'
import { setWorkspaceList } from 'state/redux/actions/user.actions'
import { useDispatch } from 'react-redux'
import Button from 'components/atoms/Button'

const Workspaces = () => {
  const [workspaceData, setWorkspaceData] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    fetchworkspaces()
  }, [])

  const fetchworkspaces = async () => {
    const response = await apiGet('workspaces', { size: 100, page: 1 })
    setWorkspaceData(response?.data)
    dispatch(setWorkspaceList(response?.data))
  }

  const menuOptions = [
    [
      { name: 'Edit', key: 'edit' },
      { name: 'Set Status', disabled: true },
    ],
    [{ name: 'Delete', disabled: true }],
  ]

  const menuClickHandler = (option, id) => {
    if (option.key === 'edit') {
      history.push(`/workspaces/edit/${id}/org-info`)
    }

    if (option.key === 'members') {
      history.push(`/workspaces/edit/${id}/org-members`)
    }
  }

  return (
    <Layout
      breadcrumbs={[{ name: 'Workspaces', link: '/workspaces' }]}
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Button
            onClick={() => {
              history.push('/workspaces/add')
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Add new Workspace
          </Button>
        </div>
      }
      title="Workspaces"
    >
      <div className="hidden sm:block">
        <div className="align-middle inline-block min-w-full">
          <table className="min-w-full">
            <thead>
              <tr className="border-gray-200">
                <th className="px-6 py-4 border-b w-[20%] border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="">Name</span>
                </th>
                <th className="px-6 py-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="">Domain</span>
                </th>
                <th className="px-6 py-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Users</th>
                <th className="hidden whitespace-nowrap md:table-cell px-6 py-4 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last updated
                </th>
                <th className="pr-6 py-4 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider" />
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {workspaceData?.map(workspace => {
                const members = workspace?.workspaceUsers?.slice(0, 4)
                const totalMembers = workspace?.workspaceUsers?.length
                return (
                  <tr key={workspace.id}>
                    <td className="px-4 py-3  whitespace-nowrap text-sm font-medium text-gray-900">
                      <div onClick={() => history.push(`/workspaces/edit/${workspace.id}/workspace-info`)} className="flex cursor-pointer items-center space-x-3 lg:pl-2">
                        <div className="flex items-center">
                          <div className="ml-0">
                            <div className="text-sm font-medium text-gray-900">{workspace.name}</div>
                            <div className="text-sm text-gray-500">{workspace.admin?.displayName}</div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                      <span>{workspace.domain}</span>
                    </td>
                    <td className="px-6 py-3 text-sm text-gray-500 font-medium">
                      <div className="flex items-center space-x-2">
                        <div className="flex flex-shrink-0 -space-x-1">
                          {members?.map(member => (
                            <img
                              key={member.id}
                              className="max-w-none h-6 w-6 rounded-full ring-2 ring-white"
                              src={member.profilePhotoUrl || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'}
                              alt={member.name}
                            />
                          ))}
                        </div>
                        {members > totalMembers ? <span className="flex-shrink-0 text-xs leading-5 font-medium">+{totalMembers - members.length}</span> : null}
                      </div>
                    </td>

                    <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">{moment(workspace.updatedAt).fromNow()}</td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <PopupMenu onClick={option => menuClickHandler(option, workspace.id)} menu={menuOptions}>
                        <IoEllipsisVertical className="h-5 w-5" aria-hidden="true" />
                      </PopupMenu>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default Workspaces
