import { startCase } from 'lodash'
import moment from 'moment'

const FormResponseList = ({ setId, data, openModal, setFormResponse }) => {
  return (
    <div className="">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="overflow-hidden border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th style={{ width: '50%' }} scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Updated At
                    </th>
                    <th scope="col" className="relative px-6 py-4">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map(response => {
                    return (
                      <tr key={response.id} className="bg-white border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{startCase(response.name)}</td>
                        <td className="px-6 py-0 whitespace-nowrap text-sm text-gray-500">{moment(response.updatedAt).fromNow()} </td>
                        <td className="relative px-6 py-3 text-right">
                          <span
                            onClick={() => {
                              setFormResponse(response)
                              openModal()
                            }}
                            className="px-4 py-1 rounded whitespace-nowrap text-xs font-medium cursor-pointer text-white bg-dark-400"
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormResponseList
