import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { get, xor, startCase, find, toUpper, toLower, shuffle, groupBy, forEach, keys, values, filter } from 'lodash'
import { getDefaultProject } from 'lib/utils'

import useModal from 'hooks/useModal'

const Randomization = () => {
  const history = useHistory()

  const [projectList, setProjectList] = useState(null)
  const [tokens, setTokens] = useState([])
  const [tokenCount, setTokensCount] = useState(-1)
  const [data, setData] = useState({
    reserve: 0,
    config: {},
    states: [],
    series: [],
    name: '',
    status: 'closed',
    project: null,
    contract: null,
  })
  const [seriesList, setSeriesList] = useState([])
  const [stages, setStages] = useState([])
  const [drops, setDrops] = useState([])

  const fetchSeries = () => {
    apiGet('series', { project: data.project }).then(response => {
      setSeriesList(response?.data?.map(item => ({ name: item.name, id: item.id })))
    })
  }

  useEffect(() => {
    if (data.project) {
      fetchSeries()
    }
  }, [data.project])

  useEffect(() => {
    if (data.project) {
      fetchDrops(data.project)
    }
  }, [data.project])

  const fetchTokensStatus = () => {
    apiGet('tokens-count', { drop: data.drop }).then(response => {
      setTokensCount(response.data)
    })
  }

  useEffect(() => {
    if (data.drop) {
      fetchTokensStatus(data.drop)
      fetchStages(data.drop)
    }
  }, [data.drop])

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    const projects = response.data

    setProjectList(projects)
    setData({ ...data, project: getDefaultProject(projects) })
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  const fetchDrops = async project => {
    const response = await apiGet('drops', { project })
    setDrops(response.data)
    setData({ ...data, drop: response.data[0]?.id })
  }

  const fetchStages = drop => {
    apiGet('stages', { drop }).then(response => {
      setStages(filter(response.data, item => item.stage !== 'paused' && item.stage !== 'closed'))
    })
  }

  const user = useSelector(state => get(state, 'user.user'))

  const selectedDrop = find(drops, { id: data.drop })

  const generateTokens = () => {
    const config = data.config
    const series = data.series
    const stages = data.stages

    const stageData = {}

    forEach(stages, s => {
      const itemConfig = config[toLower(s)]
      const data = {}
      forEach(series, ser => {
        const stage = Array.from({ length: (itemConfig.count * itemConfig[ser]) / 100 }).map(d => ser)
        data[ser] = stage
      })

      stageData[s] = data
    })

    const randomize = array => {
      let currentIndex = array.length
      let randomIndex

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
      }

      return array
    }

    const tokenizedStages = keys(stageData).map(key => {
      const arr = []
      forEach(series, ser => {
        const stageArr = stageData[key][ser]
        arr.push([...stageArr])
      })

      const flatArr = shuffle(randomize(arr.flat(1), arr.flat(1).length))

      return flatArr
    })

    const tokens = tokenizedStages.flat(1).slice(data.reserve, tokenizedStages.flat(1).length)

    setTokens(tokens)
  }

  const totalQuantity = selectedDrop?.contract?.qty - data.reserve

  const randomColors = ['#FFC107', '#FF5722', '#2196F3', '#9C27B0', '#FF9800', '#009688', '#795548', '#607D8B']

  const VisualizePattern = ({ tokens }) => {
    const [colors, setColors] = useState([])
    useEffect(() => {
      const colors = {}
      const stages = groupBy(tokens)
      forEach(keys(stages), (key, index) => {
        colors[key] = randomColors[index]
      })
      setColors(colors)
    }, [tokens])

    return (
      <div>
        <div className="flex">
          {keys(colors).map(d => (
            <div key={d} className="flex items-center mr-4">
              <span style={{ background: colors[d] }} className="w-3 rounded-sm block h-3 mr-1"></span>
              <span>{find(seriesList, { id: parseInt(d, 10) })?.name}</span>
            </div>
          ))}
        </div>
        <div className="mt-5">
          {tokens.map((token, index) => (
            <span key={token} style={{ background: colors[token] }} className="w-3 rounded-sm h-3 mr-1 inline-block"></span>
          ))}
        </div>
      </div>
    )
  }

  const [Modal, openModal] = useModal({
    content: <VisualizePattern tokens={tokens} />,
    title: 'Visualize Pattern',
    height: '80vh',
    width: '80vw',
    closeable: true,
  })

  const saveTokens = () => {
    if (tokens.length > 0) {
      apiPost('tokens', { tokens, drop: data.drop, reserve: data.reserve }).then(response => {
        if (response.success) notify.success('Tokens saved successfully')
        history.push(`/randomization-list?drop=${data.drop}&project=${data.project}`)
      })
    } else {
      notify.error('Please generate tokens first')
    }
  }

  return (
    <Layout title="Add New Drop">
      <Modal />
      <div className="p-4">
        <div className="w-full mb-[120px] flex flex-col">
          <div className="bg-white p-8 rounded-sm w-full border">
            <div className="flex items-center border-gray-200 pb-4 justify-between">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">NFT Token Randomization</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Select Project, Series and Distribution</p>
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Select Project
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  className="w-[250px]"
                  title="Select Project"
                  onChange={value => {
                    setData({ ...data, project: value.id })
                  }}
                  list={projectList}
                  value={data.project}
                />
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Select Drop
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  className="w-[250px]"
                  title="Select Drop"
                  onChange={value => {
                    setData({ ...data, drop: value.id })
                  }}
                  list={drops}
                  value={data?.drop}
                />
              </div>
            </div>

            {data.drop && tokenCount > 0 && (
              <div className="mt-1 bg-green-100 p-4 rounded-lg sm:mt-0 sm:col-span-2">
                <span>
                  A list of tokens for this drop has already been created. Click{' '}
                  <span
                    onClick={e => {
                      e.preventDefault()
                      history.push(`/randomization-list?project=${data.project}&drop=${data.drop}`)
                    }}
                    className="cursor-pointer text-green-700 underline"
                  >
                    here
                  </span>{' '}
                  to view
                </span>
              </div>
            )}

            {data.drop && tokenCount === 0 && (
              <>
                <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                  <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                    Select NFT Series
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Selection
                      className="w-[500px]"
                      title="Select NFT Series"
                      onChange={value => {
                        setData({ ...data, series: xor(data.series, [value.id]) })
                      }}
                      multiple
                      onAllSelect={values => {
                        setData({ ...data, series: values })
                      }}
                      list={seriesList}
                      value={data?.series}
                    />
                  </div>
                </div>

                <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                  <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                    Select Stages
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Selection
                      className="w-[500px]"
                      title="Select Mint Stages for Randomization"
                      onChange={value => {
                        setData({ ...data, stages: xor(data.stages, [value.id]) })
                      }}
                      onAllSelect={values => {
                        setData({ ...data, stages: values })
                      }}
                      multiple
                      list={stages.map(d => ({ name: d.stage, id: d.stage }))}
                      value={data?.stages}
                    />
                    <span className="mt-2 block font-medium">NOTE: Please select the stages according to the order of minting.</span>
                  </div>
                </div>

                <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                  <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                    Reserve Token
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="number"
                      placeholder="Qty of token to reserve"
                      className="w-[200px] max-w-[250px] mr-3 h-[32px] block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                      title="Select Mint Stages for Randomization"
                      onChange={e => {
                        setData({ ...data, reserve: e.target.value })
                      }}
                      value={data?.reserve}
                    />
                  </div>
                </div>

                <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                  <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                    Stage
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {data.stages?.map((d1, index) => {
                      const config = data?.config || {}
                      const item = config[d1] || {}
                      const percent = item?.percent || 0
                      const count = Math.round(item?.count || 0)

                      const qty = totalQuantity

                      const handleCountChange = e => {
                        const value = e.target.value
                        setData({
                          ...data,
                          config: {
                            ...data.config,
                            [d1]: {
                              ...item,
                              count: value,
                              percent: (value / qty) * 100,
                            },
                          },
                        })
                      }

                      const handlePercentChange = e => {
                        const value = e.target.value

                        setData({
                          ...data,
                          config: {
                            ...data.config,
                            [d1]: {
                              ...item,
                              percent: value,
                              count: (value / 100) * qty,
                            },
                          },
                        })
                      }

                      return (
                        <div key={d1} className="mt-1 max-w-lg bg-gray-100 p-4 rounded-sm border-dotted border-gray-200 border mb-4 sm:mt-0 mr-4 sm:col-span-2">
                          <div className="flex mb-2 items-center">
                            <span className="mr-1">#{index + 1}</span>
                            <span className="inline-flex min-w-[100px] font-mono h-[38px] relative  items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                              {toUpper(d1)}
                            </span>
                            <input
                              onBlur={handlePercentChange}
                              onChange={handlePercentChange}
                              value={Math.round(percent)}
                              placeholder="1"
                              type="number"
                              disabled={!user.isPlatformAdmin}
                              step={1}
                              max={100}
                              min={0}
                              className="border  min-w-[100px] border-gray-300 h-[38px] font-mono rounded-r-none rounded-l-none"
                            />
                            <span className="inline-flex font-mono h-[38px]  relative  items-center px-3 rounded-r-none border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                              % =
                            </span>
                            <input
                              onChange={handleCountChange}
                              onBlur={handleCountChange}
                              value={count}
                              placeholder="1"
                              type="number"
                              disabled={!user.isPlatformAdmin}
                              step={1}
                              max={100}
                              min={0}
                              className="border min-w-[100px] border-l-0 border-gray-300 h-[38px] font-mono rounded-r-none rounded-l-none"
                            />
                            <span className="inline-flex font-mono h-[38px]  relative  items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                              of {qty}
                            </span>
                          </div>
                          {count > 0 && (
                            <>
                              {data?.series?.map((d, index) => {
                                const series = find(seriesList, { id: d })
                                const name = series?.name

                                const stagePercent = item?.[d] || 0
                                const stageCount = item?.[`${d}Count`] || 0

                                const handleStageCountChange = e => {
                                  const value = e.target.value
                                  setData({
                                    ...data,
                                    config: {
                                      ...data.config,
                                      [d1]: {
                                        ...item,
                                        [`${d}Count`]: value,
                                        [d]: (value / count) * 100,
                                      },
                                    },
                                  })
                                }

                                const handlePercentChange = e => {
                                  const value = e.target.value
                                  setData({
                                    ...data,
                                    config: {
                                      ...data.config,
                                      [d1]: {
                                        ...item,
                                        [d]: value,
                                        [`${d}Count`]: (value / 100) * count,
                                      },
                                    },
                                  })
                                }

                                return (
                                  <div key={name} style={{ width: 'calc(100% - 60px)' }} className="flex mb-3 justify-end ml-5">
                                    <span className="inline-flex min-w-[60px] font-mono h-[38px] relative  items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                      {toUpper(name)}
                                    </span>
                                    <input
                                      onChange={handlePercentChange}
                                      onBlur={handlePercentChange}
                                      value={Math.round(stagePercent)}
                                      placeholder="1"
                                      type="number"
                                      disabled={!user.isPlatformAdmin}
                                      step={1}
                                      max={100}
                                      min={0}
                                      className="border  min-w-[60px] border-gray-300 h-[38px] font-mono rounded-r-none rounded-l-none"
                                    />
                                    <span className="inline-flex font-mono h-[38px]  relative  items-center px-3 rounded-r-none border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                      % =
                                    </span>
                                    <input
                                      onChange={handleStageCountChange}
                                      onBlur={handleStageCountChange}
                                      value={Math.floor(stageCount)}
                                      placeholder="1"
                                      type="number"
                                      disabled={!user.isPlatformAdmin}
                                      step={1}
                                      max={100}
                                      min={0}
                                      className="border min-w-[100px] border-l-0 border-gray-300 h-[38px] font-mono rounded-r-none rounded-l-none"
                                    />
                                    <span className="inline-flex font-mono h-[38px]  relative  items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                      of {count}
                                    </span>
                                  </div>
                                )
                              })}
                            </>
                          )}
                        </div>
                      )
                    })}
                    <div className="mt-5 mb-5 border rounded-md max-w-lg flow-root">
                      <ul className="divide-y divide-gray-200">
                        {keys(data?.config).map(d1 => {
                          const percent = Math.round(data?.config[d1]?.percent || 0)
                          const count = data?.config[d1]?.count || 0

                          return (
                            <li key={d1} className="px-4 py-2">
                              <div className="flex items-center space-x-4">
                                <div className="min-w-0 flex-1">
                                  <p className="truncate text-sm font-medium text-gray-900">{startCase(d1)}</p>
                                </div>
                                <div>
                                  <span href="#">
                                    {count} ({percent}%)
                                  </span>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                        <li className="px-4 py-2">
                          <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1">
                              <p className="truncate text-sm font-medium text-gray-900">Total</p>
                            </div>
                            <div>
                              <span href="#">
                                {values(data?.config)
                                  .map(d => d.count)
                                  .reduce((a, b) => a + parseInt(b, 10), 0) - data?.reserve}{' '}
                                (
                                {Math.round(
                                  values(data?.config)
                                    .map(d => d.percent)
                                    .reduce((a, b) => a + parseInt(b, 10), 0)
                                )}
                                %)
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {tokens?.length > 0 && (
                      <div className="mt-10 mb-5 border rounded-md max-w-lg flow-root">
                        <ul className="divide-y divide-gray-200">
                          {keys(groupBy(tokens)).map(d1 => {
                            const ser = find(seriesList, { id: parseInt(d1, 10) })
                            const percent = Math.round((groupBy(tokens)[d1].length / tokens.length) * 100)
                            const count = groupBy(tokens)[d1].length
                            return (
                              <li key={d1} className="px-4 py-2">
                                <div className="flex items-center space-x-4">
                                  <div className="min-w-0 flex-1">
                                    <p className="truncate text-sm font-medium text-gray-900">{startCase(ser?.name)}</p>
                                  </div>
                                  <div>
                                    <span href="#">
                                      {count} ({percent}%)
                                    </span>
                                  </div>
                                </div>
                              </li>
                            )
                          })}
                          <li className="px-4 py-2">
                            <div className="flex items-center space-x-4">
                              <div className="min-w-0 flex-1">
                                <p className="truncate text-sm font-medium text-gray-900">Total</p>
                              </div>
                              <div>
                                <span href="#">
                                  {tokens.length} ({Math.round((tokens.length * 100) / totalQuantity)}%)
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                    <div className="justify-between flex max-w-lg">
                      {tokens.length > 0 ? (
                        <span className="cursor-pointer select-none text-indigo-600" onClick={openModal}>
                          View distribution pattern
                        </span>
                      ) : (
                        <span></span>
                      )}
                      <span className="cursor-pointer select-none text-indigo-600" onClick={generateTokens}>
                        Run the Sequence Randomization
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between ml-[360px] mr-[200px]">
                  {tokens.length > 0 && (
                    <button
                      onClick={saveTokens}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
                    >
                      Save the distribution
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Randomization
