import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { startCase } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const Tools = () => {
  const [currentDrop, setCurrentDrop] = useState(null)
  const [stages, setStages] = useState([])

  useEffect(() => {
    if (currentDrop) {
      fetchStages(currentDrop.id)
    }
  }, [currentDrop])

  const fetchStages = drop => {
    apiGet('stages', { drop }).then(response => {
      const stages = response.data
      setStages(stages.map(d => ({ id: d.stage, name: d.stage })))
    })
  }

  const fetchCurrentDrop = async () => {
    const response = await apiGet(`current-drop`)
    if (response?.success && response?.data?.id) {
      setCurrentDrop(response?.data)
    }
  }

  useEffect(() => {
    fetchCurrentDrop()
  }, [])

  const updateCurrentDropStatus = status => {
    notify.alertConfirm({
      title: 'Are you sure?',
      message: `Are you sure you want to update the status of the current drop to ${status}?`,
      onConfirm: async () => {
        const response = await apiPost(`current-drop`, { id: currentDrop.id, status })
        if (response?.success) {
          notify.success('Status updated successfully')
          fetchCurrentDrop()
        }
      },
    })
  }

  const history = useHistory()

  const syncTransactions = async () => {
    const response = await apiGet(`sync-transactions`)
    if (response?.success) {
      notify.success('Transactions syncing started successfully')
    }
  }

  return (
    <Layout title="Tools">
      <div>
        {currentDrop && (
          <div className="px-8 py-8 pb-0">
            <dl className="grid relative grid-cols-1 divide-gray-200 rounded-lg bg-white shadow md:grid-cols-1 md:divide-y-0 md:divide-x">
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base mb-4 font-normal text-gray-900">
                  {currentDrop?.name} - {currentDrop?.model === 'dutch' ? `${startCase(currentDrop?.model)} Auction pricing model` : 'Flat Pricing model'}
                  {currentDrop.model === 'dutch' && (
                    <span
                      onClick={() => history.push(`/drops/${currentDrop?.id}`)}
                      className="ml-4 right-4 top-4 inline-flex items-center rounded-full border border-gray-200 px-2.5 py-0.5 text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-xs font-medium cursor-pointer"
                    >
                      Switch to flat {currentDrop.ETH} eth
                    </span>
                  )}
                  <div className="right-4 top-4 absolute">
                    <Selection
                      className="w-[150px] h-[40px]"
                      title="Select Status"
                      onChange={e => {
                        updateCurrentDropStatus(e.id)
                      }}
                      list={stages}
                      value={currentDrop.status}
                    />
                  </div>
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-center text-2xl font-semibold text-green-600">
                    <img className="h-5 w-5" src="https://cdn3.emoji.gg/emojis/5819-eth.png" alt="" /> {currentDrop?.currentEth?.toFixed(3)}
                    <span className="ml-2 px-2 py-1 rounded-lg bg-indigo-200  border-indigo-400 text-xs font-normal text-indigo-800">Original rate: {currentDrop.ETH} eth</span>
                    {currentDrop.model === 'dutch' && (
                      <span className="ml-2 px-2 py-1 rounded-lg bg-red-200  text-xs font-normal text-red-800">
                        Reduced by {(100 - (currentDrop.currentEth * 100) / currentDrop.ETH).toFixed(2)}% from original rate since{' '}
                        {moment(currentDrop?.start).format('DD MMM YYYY hh:mm A')}
                      </span>
                    )}
                    {currentDrop.model === 'dutch' && (
                      <span className="ml-2 px-2 py-1 rounded-lg bg-gray-200  text-xs font-normal text-gray-800">
                        Reducing by {currentDrop.reducingPercentage}% every {currentDrop.reducingIntervalInMins} minutes
                      </span>
                    )}
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        )}
        <div className="overflow-auto px-8 py-8">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Tools</h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-lg font-semibold text-gray-900 truncate">Sync Transactions</dt>
              <dd className="mt-1 text-xs text-gray-500">Sync missed transactions from the blockchain</dd>
              <button className="py-1.5 px-4 mt-4 text-white rounded-md text-md bg-dark-700" onClick={syncTransactions}>
                Sync Now
              </button>
            </div>
          </dl>
        </div>
      </div>
    </Layout>
  )
}

export default Tools
