import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import Button from 'components/atoms/Button'
import Layout from 'containers/Layout'
import SingleNFTSeries from 'pages/nft/SingleNFTSeries'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

const settings = [
  { disabled: false, name: 'Single NFT Series', description: 'Create single NFT Series with content access. Ideal for NFT club pass' },
  { disabled: true, name: 'Import from NGen Tool', description: 'Import assets from Internal NGEN Tool and create NFT series' },
  { disabled: true, name: 'Import ZIP', description: 'Import assets from ZIP file' },
]

const AddNFTSeries = () => {
  const [selected, setSelected] = useState(settings[0])
  const [method, setMethod] = useState('Single NFT Series')

  const { id } = useParams()

  return (
    <Layout title="Create NFT Series">
      {method === -1 && (
        <div className="m-4">
          <RadioGroup value={selected} onChange={setSelected}>
            <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
            <div className="bg-white rounded-md -space-y-px">
              {settings.map((setting, settingIdx) => (
                <RadioGroup.Option
                  key={settingIdx}
                  value={setting}
                  disabled={setting.disabled}
                  className={({ checked }) =>
                    classNames(
                      settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                      settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                      checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                      'relative border p-4 flex cursor-pointer focus:outline-none'
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <span
                        className={classNames(
                          checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                          active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                          'h-4 w-4 mt-0.5 cursor-pointer shrink-0 rounded-full border flex items-center justify-center'
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <span className="ml-3 flex flex-col">
                        <RadioGroup.Label as="span" className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium')}>
                          {setting.name}
                        </RadioGroup.Label>
                        <RadioGroup.Description as="span" className={classNames(checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}>
                          {setting.description}
                        </RadioGroup.Description>
                      </span>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          <div className="flex mt-4 w-full justify-end">
            <Button onClick={() => setMethod(selected.name)} className="">
              Next
            </Button>
          </div>
        </div>
      )}
      {method === 'Single NFT Series' ? <SingleNFTSeries id={id} /> : null}
    </Layout>
  )
}

export default AddNFTSeries
