import { useRef, useState } from 'react'
import gallery from 'assets/images/picture.png'
import { split, uniqBy } from 'lodash'
import asyncLoop from 'node-async-loop'
import apiPost from 'lib/network/apiPost'
import { IoCheckmarkCircle, IoPlay } from 'react-icons/io5'
import { bytesToSize } from 'lib/utils'
import apiPostCustom from 'lib/network/apiPostCustom'
import notify from 'components/atoms/Notify'

const AddMedia = ({ close, project, callback, fetchMedia }) => {
  const [files, setFiles] = useState([])
  const uploadRef = useRef(null)
  const [uploadStatus, setUploadStatus] = useState({})
  const [uploading, setUploading] = useState(true)

  const selectFiles = () => {
    uploadRef.current.click()
  }

  const handleFiles = async e => {
    const files = [...e.target.files]
    await Promise.all(
      files.map(async file => {
        const preview = await URL.createObjectURL(file)
        file.preview = preview
      })
    )

    setFiles(oldFiles => uniqBy([...oldFiles, ...files], 'name'))
  }

  const uploadFile = async (file, project, nx) => {
    // if file size is more than 10mb upload to cf
    if (file.size >= 5858772) {
      setUploading(true)
      setUploadStatus(status => ({ ...status, [file.name]: 'uploading' }))

      if (!project) {
        return notify.error('Please select a project')
      }

      const urlResponse = await apiPost('get-upload-url')
      const result = urlResponse?.data?.result
      const uploadURL = result?.uploadURL
      const uid = result?.uid
      let formData = new FormData()

      formData.append('file', file)
      await apiPostCustom(uploadURL, formData)

      const key = uid
      const size = file.size
      const name = file.name
      const mimeType = file.type

      formData = new FormData()

      formData.append('key', key)
      formData.append('project', project)
      formData.append('size', size)
      formData.append('name', name)
      formData.append('mimeType', mimeType)

      const mediaUploadResponse = await apiPost('media', formData)

      if (mediaUploadResponse.success) {
        setUploadStatus(status => ({ ...status, [file.name]: 'uploaded' }))
        nx()
      } else {
        setUploading(false)
        nx()
      }
    } else {
      setUploading(true)
      const formData = new FormData()

      formData.append('file', file)
      formData.append('project', project)

      setUploadStatus(status => ({ ...status, [file.name]: 'uploading' }))
      const response = await apiPost('media', formData)
      if (response.success) {
        fetchMedia(project)
        setUploadStatus(status => ({ ...status, [file.name]: 'uploaded' }))
        nx()
      } else {
        setUploading(false)
      }
    }
  }

  const uploadMedia = () => {
    asyncLoop(
      files,
      (file, nx) => {
        uploadFile(file, project, nx)
      },
      () => {
        close()
        if (callback) {
          callback()
        }
        setUploading(false)
      }
    )
  }

  return (
    <>
      <div className="flex items-center justify-center">
        <input onChange={handleFiles} ref={uploadRef} className="hidden" type="file" multiple accept="video/mp4,image/png,image/jpeg" />

        {files.length > 0 && (
          <div className="flex p-2 w-full">
            {files.map((file, index) => {
              const type = split(file.type, '/')[0]
              return (
                <div key={index} title={file.name} className="flex relative flex-col items-center justify-center">
                  <div key={index} className="grid-item  media-item-reverse p-2 rounded-lg cursor-pointer w-[140px] h-[140px] flex justify-center">
                    <div className="relative flex items-center">
                      {type === 'image' && (
                        <img
                          style={{ maxHeight: 130, objectFit: 'contain', border: '4px solid white' }}
                          className="shadow-md bg-gray-200 w-auto rounded "
                          src={file.preview}
                          alt=""
                        />
                      )}
                      {type === 'video' && (
                        /* eslint-disable-next-line */
                        <video
                          onMouseLeave={e => e.target.pause()}
                          onMouseEnter={e => e.target.play()}
                          style={{ objectFit: 'contain', border: '4px solid white' }}
                          className="shadow-md bg-black w-auto rounded "
                          src={file.preview}
                          alt=""
                        />
                      )}
                      {type === 'video' && (
                        <span style={{ top: 'calc(50% - 16px)', left: 'calc(50% - 16px)' }} className="absolute h-8 w-8 top-[50%] left-[50%] play-button">
                          <IoPlay className="h-4 w-4 text-white shadow-xl" />
                        </span>
                      )}
                    </div>
                  </div>

                  <span className=" w-[140px] flex justify-center text-center text-xs giveMeEllipsis whitespace-normal">{file.name}</span>
                  <span className=" w-[140px] text-center text-[11px] text-gray-400 giveMeEllipsis whitespace-normal">{bytesToSize(file.size)}</span>
                  {uploading && uploadStatus[file.name] === 'uploaded' ? (
                    <span>
                      <IoCheckmarkCircle className="h-4 rounded-full text-green-500 w-4" />
                    </span>
                  ) : null}
                  {uploading && uploadStatus[file.name] === 'uploading' ? (
                    <svg className="animate-spin h-3 w-3 text-black" xmlns="http://www.w3.project/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                </div>
              )
            })}
          </div>
        )}

        {files.length === 0 && (
          <div className="flex justify-center items-center flex-col w-full">
            <img className="h-30 w-30" src={gallery} alt="gallery" />
            <span className="text-lg font-medium mt-4">
              Drop your images and video files here, or{' '}
              <span onClick={selectFiles} className="text-orange-500 cursor-pointer">
                Browse
              </span>
            </span>
            <span className="text-gray-400">Supports mp4, png, jpeg, jpg files</span>
          </div>
        )}
      </div>
      {files.length > 0 && (
        <div className="mt-4 border-t flex justify-between bottom-0 absolute w-full left-0 p-4">
          <div>
            <button
              onClick={selectFiles}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Add Files
            </button>
          </div>
          <div>
            <button
              onClick={close}
              className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
            >
              Close
            </button>
            <button
              onClick={uploadMedia}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Upload
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default AddMedia
