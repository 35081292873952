import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import useModal from 'hooks/useModal'
import { useEffect, useState } from 'react'
import Button from 'components/atoms/Button'
import ConfigFlagForm from './ConfigFlagForm'
import AddEditConfigFlag from './AddEditConfigFlag'

const ConfigFlag = () => {
  const [configData, setConfigData] = useState({})
  const [typeId, setTypeId] = useState(null)

  useEffect(() => {
    fetchConfigFlag()
  }, [typeId])

  const fetchConfigFlag = async () => {
    const response = await apiGet(`config`, { size: 100, page: 1 })
    setConfigData(response?.data?.list)
  }
  const [PropertyModal, openPropertyModal] = useModal({
    content: <AddEditConfigFlag fetchData={fetchConfigFlag} id={typeId} setTypeId={setTypeId} />,
    title: typeId ? `Edit Config Flag` : `Add New Config Flag`,
  })
  const openModal = () => {
    setTypeId(null)
    openPropertyModal()
  }
  return (
    <Layout
      breadcrumbs={[{ name: 'Config Flag', link: '/config-flag' }]}
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Button onClick={openModal}>Add new Config Flag</Button>
        </div>
      }
      title={`List of Config Flags`}
    >
      <ConfigFlagForm data={configData} setId={setTypeId} openModal={openPropertyModal} fetch={fetchConfigFlag} />
      <PropertyModal />
    </Layout>
  )
}

export default ConfigFlag
