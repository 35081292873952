import Button from 'components/atoms/Button'
import LoadingIcon from 'components/atoms/LoadingIcon'
import Selection from 'components/molecules/Selection'
import EmptyState from 'components/pageComponents/EmptyState'
import Table from 'components/pageComponents/Table'
import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import PopupMenu from 'components/molecules/PopupMenu'
import { IoEllipsisVertical } from 'react-icons/io5'
import notify from 'components/atoms/Notify'
import apiDelete from 'lib/network/apiDelete'
import { getDefaultProject } from 'lib/utils'

const Drops = () => {
  const [drops, setDrops] = useState([])
  const [projectList, setProjectList] = useState(null)
  const [project, setProject] = useState(null)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const fetchDrops = async project => {
    setLoading(true)
    const response = await apiGet('drops', { project })
    setDrops(response.data)
    setLoading(false)
  }

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    const projects = response.data
    setProject(getDefaultProject(projects))
    setProjectList(projects)
  }

  const menuClickHandler = (option, id, index) => {
    if (option.key === 'edit') {
      history.push(`/drops/edit/${id}`)
    }

    if (option.key === 'delete') {
      notify.deleteConfirm({
        title: 'Delete Drop',
        message: 'Are you sure you want to delete this Drop?',
        onConfirm: async () => {
          const response = await apiDelete(`/drops/${id}`)
          if (response?.success) {
            notify.success('Drop deleted successfully')
            fetchDrops(project)
          }
        },
      })
    }
  }

  const menuOptions = [[{ name: 'Edit', key: 'edit' }], [{ key: 'delete', name: 'Delete' }]]

  useEffect(() => {
    fetchProjects()
  }, [])

  useEffect(() => {
    if (project) {
      fetchDrops(project)
    }
  }, [project])

  const columns = [
    {
      width: '10%',
      name: 'Drop Name',
      key: 'name',
      sortable: false,
      rendered: item => <span onClick={() => history.push(`/drops/edit/${item.id}`)}>{item.name}</span>,
    },
    {
      width: '10%',
      name: 'Start date',
      key: 'start',
      sortable: false,
      rendered: item => <span>{moment(item.start).format('ddd, DD MMM yyy')}</span>,
    },
    {
      width: '10%',
      name: 'End date',
      key: 'end',
      sortable: false,
      rendered: item => <span>{moment(item.end).format('ddd, DD MMM yyy')}</span>,
    },
    {
      width: '10%',
      name: 'Status',
      key: 'status',
      sortable: false,
      rendered: item => <span className="py-0.5 shadow-md px-2 border rounded-2xl capitalize">{item.status}</span>,
    },
    {
      width: '10%',
      name: 'Contract',
      key: 'contract',
      sortable: false,
      rendered: item => (
        <span onClick={() => item.contract && window.open(`${item.contract.explorer}/address/${item.contract.address}`)} className="text-blue-500">
          {item.contract ? item.contract?.name : 'No Contract Found'}
        </span>
      ),
    },
    {
      width: '10%',
      name: 'Actions',
      align: 'right',
      sortable: false,
      rendered: (item, index) => (
        <PopupMenu onClick={option => menuClickHandler(option, item.id, index)} menu={menuOptions}>
          <IoEllipsisVertical className="h-5 w-5" aria-hidden="true" />
        </PopupMenu>
      ),
    },
  ]

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Selection
            className="w-[250px]"
            title="Select Project"
            onChange={value => {
              setProject(value.id)
            }}
            list={projectList}
            value={project}
          />
          <Button onClick={() => history.push('/drops/add')}>Add Drop</Button>
        </div>
      }
      title="NFT Drops"
    >
      <div className="min-w-full">
        {!loading ? (
          <>
            {drops?.length === 0 ? <EmptyState v2 description="No drops found" /> : <Table hasMore={false} fetchData={() => fetchDrops(project)} data={drops} columns={columns} />}
          </>
        ) : (
          <div className="w-full h-[200px] border-0 flex justify-center items-center">
            <LoadingIcon />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Drops
