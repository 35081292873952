import Input from 'components/atoms/Input'
import { Field, Form, Formik } from 'formik'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useState } from 'react'
import notify from 'components/atoms/Notify'
import { get, startCase } from 'lodash'
import Selection from 'components/molecules/Selection'
import { useSelector } from 'react-redux'

const AddEditProperty = ({ type, close, id, setPropertyId, fetchData }) => {
  const [propertyData, setPropertyData] = useState(null)
  const [projectList, setProjectList] = useState(null)

  const types = useSelector(state => get(state, 'user.types', {}))

  useEffect(() => {
    if (id) {
      fetchProperty()
    }
  }, [id])

  useEffect(() => {
    fetchProjects()
  }, [])

  const fetchProjects = async () => {
    const response = await apiGet('projects/list')
    const projects = response.data
    setProjectList(projects)
  }

  const fetchProperty = async () => {
    const response = await apiGet(`/property/${id}`)
    setPropertyData(response.data)
  }

  const savePropertyData = async propertyData => {
    const data = await apiPost(id ? `properties/${id}` : 'properties/create', propertyData)
    if (data.success) {
      notify.success('Property saved successfully')
      fetchData()
      setPropertyId(null)
      close()
    }
  }

  const initialValues = {
    name: propertyData?.name,
    description: propertyData?.description,
    type: propertyData?.type?.id,
    workspace: propertyData?.workspace?.id,
    svg: propertyData?.svg,
  }

  return (
    <Formik initialValues={id ? initialValues : { type: type.id }} enableReinitialize={true}>
      {props => (
        <Form>
          <div className="mb-8 space-y-4">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  type="text"
                  name="name"
                  placeholder="eg: Movies, Books, etc."
                  id="name"
                  autoComplete="new-password"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                SVG Icon
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Field
                  as="textarea"
                  name="svg"
                  rows={4}
                  placeholder="SVG Icon HTML"
                  id="description"
                  autoComplete="new-password"
                  className="max-w-full block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500  sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Field
                  as="textarea"
                  name="description"
                  rows={4}
                  placeholder="Description about the property"
                  id="description"
                  autoComplete="new-password"
                  className="max-w-full block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500  sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            {projectList?.length > 0 && (
              <div className="sm:grid mt-5 sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Project
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Selection title="Select Project" onChange={value => props.setFieldValue('project', value?.id)} list={projectList?.projects} value={props.values?.project} />
                </div>
              </div>
            )}

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Type
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  disabled
                  onChange={value => props.setFieldValue('type', value.id)}
                  title="Select Type"
                  value={props.values.type}
                  list={types.properties?.map(item => ({ id: item.id, name: startCase(item.name) }))}
                />
              </div>
            </div>
          </div>

          <div className="mt-4 flex justify-end">
            <button
              onClick={() => close()}
              className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
            >
              Close
            </button>
            <button
              onClick={() => savePropertyData(props.values)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddEditProperty
