import moment from 'moment'
import { forwardRef } from 'react'
import WithReports from './WithReports'
import { formateDate, startEndTruncate } from 'utils'
import { Tooltip } from 'antd'
import { IoCopyOutline } from 'react-icons/io5'
import notify from 'components/atoms/Notify'
import { truncate } from 'lodash'

// eslint-disable-next-line
const ExternalUsers = forwardRef(({ ...props }, ref) => {
  const copyText = text => {
    navigator.clipboard.writeText(text)
    notify.success('Copied to clipboard', truncate(text, { length: 35 }))
  }

  const onColumnClick = item => {
    return null
    // window.open(`/dataList/drop/edit/${item.id}`)
  }

  const columns = [
    {
      name: 'Id',
      width: '10%',
      key: 'id',
      rendered: item => (
        <div className="text-[13px] text-gray-500" onClick={() => onColumnClick(item)}>
          {item.id}
        </div>
      ),
    },
    {
      name: 'Email',
      key: 'email',
      width: '25%',
      rendered: item => {
        return (
          <>
            <div>
              {' '}
              <span className="text-[13px]" onClick={() => onColumnClick(item)}>
                {item?.data?.email}
              </span>
              {item?.data?.email ? (
                <button
                  id="disable-export"
                  className="hover:text-indigo-500"
                  onClick={() => {
                    copyText(item?.data?.email)
                  }}
                >
                  <IoCopyOutline className="ml-2" />
                </button>
              ) : null}
            </div>
          </>
        )
      },
    },
    {
      name: 'Wallet',
      key: 'wallet',
      width: '25%',
      rendered: item => (
        <Tooltip title={`${item?.data?.walletAddress}`}>
          <span className="text-[13px]" onClick={() => onColumnClick(item)}>
            {startEndTruncate(item?.data?.walletAddress)}
          </span>
          {item?.data?.walletAddress && (
            <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item?.data?.walletAddress)}>
              <IoCopyOutline className="ml-2" />
            </button>
          )}
        </Tooltip>
      ),
    },
    {
      name: 'Club Location',
      width: '10%',
      key: 'cLocation',
      rendered: item => <span className="text-[13px]">{item?.data?.clubLocation}</span>,
    },
    {
      name: 'IP',
      width: '25%',
      key: 'IP',
      rendered: item => (
        <span className="text-[13px]  text-gray-700 whitespace-normal" onClick={() => onColumnClick(item)}>
          <div className="whitespace-normal">{item?.data?.ipAddress || item?.data?.ip_address}</div>
        </span>
      ),
    },
    {
      name: 'Campaign',
      width: '10%',
      key: 'cName',
      rendered: item => <span className="text-[13px]">{item?.data?.utmCampaign}</span>,
    },
    {
      name: 'Source',
      width: '10%',
      key: 'cSource',
      rendered: item => <span className="text-[13px]">{item?.data?.utmSource}</span>,
    },
    {
      name: 'Medium',
      width: '10%',
      key: 'cMedium',
      rendered: item => <span className="text-[13px]">{item?.data?.utmMedium}</span>,
    },
    {
      name: 'Registered',
      width: 'auto',
      key: 'createdAt',
      rendered: item => <div className="text-[13px] text-gray-500 overflow-scroll ">{formateDate(moment(item.createdAt))}</div>,
    },
  ]

  return (
    <WithReports
      // onRowClick={(item) => window.open(`/users/edit/${item.id}/user-profile`)}
      {...props}
      apiURL="datalist"
      apiDownloadURL="datalist/csv"
      apiQueryString={{ type: 'external', value: 'user' }}
      defaultFileName="External Users"
      forwardedRef={ref}
      columns={columns}
      isExternalUser
    />
  )
})

export default ExternalUsers
