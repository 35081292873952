import Layout from 'containers/Layout'
import { useState, useEffect } from 'react'
import apiGet from 'lib/network/apiGet'
import Selection from 'components/molecules/Selection'
import Button from 'components/atoms/Button'
import useModal from 'hooks/useModal'
import AddMedia from 'pages/media/modals/AddMedia'
import { streamSignedUrl } from 'lib/utils'
import MediaList from 'pages/media/MediaList'

const Media = () => {
  // const history = useHistory();
  const [mediaList, setMediaList] = useState([])

  const [filter, setFilter] = useState({})
  // const [type, setType] = useState(params?.type);
  const [projectList, setProjectList] = useState(null)

  const generateURLs = async content => {
    const items = await Promise.all(
      content.map(async item => {
        const signature = await streamSignedUrl(item.key)
        return {
          ...item,
          url: item.url.replace('<signature>', signature),
          thumbnail: item.thumbnail.replace('<signature>', signature),
        }
      })
    )
    return items
  }

  const fetchContent = async project => {
    const response = await apiGet(`media`, { project, ...filter })
    const items = await generateURLs(response?.data?.media)

    setMediaList(items)
  }

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    const projects = response.data

    setProjectList(projects)
    setFilter(f => ({ ...f, project: projects[0]?.id }))
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  useEffect(() => {
    if (filter.project) {
      fetchContent(filter.project)
    }
  }, [filter.project])

  const [MediaModal, openModal] = useModal({
    content: <AddMedia project={filter.project} fetchMedia={fetchContent} />,
    title: 'Add Media',
    height: '400px',
    width: '1000px',
    closeable: true,
    callback: () => {
      fetchContent(filter.project)
    },
  })

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Selection
            className="w-[250px]"
            title="Select Project"
            onChange={value => {
              setFilter({ ...filter, project: value.id })
            }}
            list={projectList}
            value={filter?.project}
          />
          <Button
            onClick={openModal}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Upload Media
          </Button>
        </div>
      }
      title="Media Gallery"
    >
      <MediaModal />
      {/* Content area */}
      <div className="flex-1 flex flex-col overflow-hidden min-h-[100vh]">
        {/* Main content */}
        <div className="flex-1 flex items-stretch overflow-hidden">
          <main className="flex-1 overflow-y-auto">
            <div className="max-w-full mx-auto px-2">
              <MediaList openModal={openModal} mediaList={mediaList} fetchMedia={fetchContent} project={filter.project} />
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default Media
