import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import { useEffect, useState } from 'react'
import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import moment from 'moment'
import AddEditSlug from './AddEditSlug'
import useModal from 'hooks/useModal'
import notify from 'components/atoms/Notify'
import apiPost from 'lib/network/apiPost'
import Button from 'components/atoms/Button'

const GlobalSlugsList = () => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [recordId, setRecordId] = useState(null)

  useEffect(() => {
    fetchList()
  }, [])

  const fetchList = async () => {
    setLoading(false)

    const response = await apiGet('slugs/list', { size: 10, page: (pagination?.currentPage || 0) + 1 })

    setPagination(response.data?.pagination)
    const data = response.data?.list
    setList([...list, ...data])

    setLoading(true)
  }

  const reFetch = async () => {
    setLoading(false)

    const response = await apiGet('slugs/list', { size: 10, page: pagination?.currentPage || 0 })

    setPagination(response?.data?.pagination)
    const data = response.data?.list
    setList([...data])

    setLoading(true)
  }

  const deleteConfirm = async id => {
    const response = await apiPost(`slugs/${id}/delete`)
    if (response.success) {
      notify.success(response.message)
      reFetch()
    }
  }

  const [RoleModal, openSlugModal] = useModal({
    content: <AddEditSlug fetchData={reFetch} setId={setRecordId} id={recordId} />,
    title: recordId ? 'Edit Slug' : 'Add New Slug',
  })

  const columns = [
    {
      name: 'Path',
      width: '10%',
      key: 'path',
      sortable: false,
      rendered: item => <div className="text-sm text-gray-500">{item.path}</div>,
    },
    {
      name: 'Description',
      width: '10%',
      key: 'description',
      sortable: false,
      rendered: item => <div className="text-sm text-gray-500">{item.description}</div>,
    },
    {
      name: 'Time Stamp',
      width: '5%',
      key: 'createdAt',
      align: 'right',
      sortable: false,
      rendered: item => <div className="text-sm text-gray-500">{moment(item.createdAt).fromNow()}</div>,
    },
    {
      name: 'Actions',
      width: '5%',
      key: 'Actions',
      align: 'right',
      sortable: false,
      rendered: item => (
        <>
          <span
            onClick={() => {
              notify.deleteConfirm({
                onConfirm: () => deleteConfirm(item.id),
                dangerText: 'Delete',
                title: 'Delete slug?',
                message: 'Are you sure you want to delete this slug?',
              })
            }}
            className="px-4 mr-2 py-1 rounded whitespace-nowrap text-sm text-normal cursor-pointer text-white bg-red-500"
          >
            Delete
          </span>
          <span
            onClick={() => {
              setRecordId(item.id)
              openSlugModal()
            }}
            className="px-4 py-1 rounded whitespace-nowrap text-sm text-normal cursor-pointer text-white bg-dark-400"
          >
            Edit
          </span>
        </>
      ),
    },
  ]

  return (
    <Layout
      title="Global Slugs"
      breadcrumbs={[{ name: 'Global Slugs', link: '/global-slugs' }]}
      headerRight={
        <div className="mt-4 flex w-full justify-end sm:mt-0 sm:ml-4">
          <Button onClick={openSlugModal} type="button">
            Add Global Slug
          </Button>
        </div>
      }
    >
      <div className="flex flex-col">
        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                {loading ? (
                  <>
                    {list?.length === 0 ? (
                      <EmptyState v2 description="No Slugs found" />
                    ) : (
                      <Table hasMore={pagination?.currentPage !== pagination?.totalPage} fetchData={() => fetchList(true)} data={list} columns={columns} />
                    )}
                  </>
                ) : (
                  <div className="w-full h-[200px] border-0 flex justify-center items-center">
                    <LoadingIcon />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      <RoleModal />
    </Layout>
  )
}

export default GlobalSlugsList
