import { BellIcon } from '@heroicons/react/outline'
import { BellAlertIcon } from '@heroiconsv2/react/24/outline'
import classNames from 'classnames'
import EmptyState from 'components/pageComponents/EmptyState'
import apiGet from 'lib/network/apiGet'
import moment from 'moment'
import { useEffect, useState } from 'react'

const NotificationDrawer = ({ open }) => {
  const [notifications, setNotifications] = useState([])

  const fetchNotifications = async () => {
    // Fetch notifications from backend
    const response = await apiGet('/notifications', { page: 1, limit: 100, read: true })

    // Set notifications to state
    setNotifications(response?.data?.list)
  }

  useEffect(() => {
    if (open) fetchNotifications()
  }, [open])

  return (
    <div className="">
      {notifications?.length === 0 ? (
        <div>
          <EmptyState icon={<BellIcon className="h-16 text-gray-400 v-16 m-auto" />} v2 description="All Caught Up!" />
        </div>
      ) : (
        <ul className="mb-0 overflow-auto max-h-[400px]">
          {notifications.map(message => (
            <li
              key={message.id}
              className="relative mb-0 flex bg-white py-4 px-4 border-b border-gray-200 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50"
            >
              <div style={{ width: 40, minWidth: 40 }} className="bg-[#1344ce] mr-4 m-w-[40px] h-[40px] w-[40px] flex justify-center items-center rounded-full">
                <BellAlertIcon className="h-6 w-6 text-white" />
              </div>
              <div className="w-full">
                <p className={classNames('text-sm mb-0 text-gray-800 line-clamp-2', !message.read && 'font-semibold')}>{message.message}</p>
                <span className="text-xs mt-1 block text-gray-500">{moment(message.createdAt).fromNow()}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default NotificationDrawer
