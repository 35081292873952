import { CameraIcon } from '@heroicons/react/solid'
import Input from 'components/atoms/Input'
import LoadingIcon from 'components/atoms/LoadingIcon'
import notify from 'components/atoms/Notify'
import { Field, Form, Formik } from 'formik'
import apiDelete from 'lib/network/apiDelete'
import apiPost from 'lib/network/apiPost'
import apiPut from 'lib/network/apiPut'
import { forEach, groupBy, keys, xor, isNumber, isArray, isBoolean, isString, isObject, map, includes } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

const ProjectProfile = ({ loading, projectData, isEdit, id, properties, readOnly }) => {
  const history = useHistory()

  const [previews, setPreviews] = useState({})
  const [files, setFiles] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (projectData) {
      setPreviews({ logo: projectData?.logo })
      setTimeout(() => {
        delete projectData?.logo
      }, 1000)
    }
  }, [projectData])

  const addProject = async data => {
    setIsSubmitting(true)
    const formData = new FormData()

    if (isEdit) {
      formData.append('id', parseInt(id, 10))
    }

    delete data.id

    forEach(keys(data), key => {
      if (data[key] === 'null' || data[key] === undefined || data[key] === null) delete data[key]
      if (isString(data[key]) || isNumber(data[key]) || isBoolean(data[key]) || isArray(data[key])) {
        formData.append(key, data[key])
      }
      if (isObject(data[key]) && !isArray(data[key])) formData.append(key, JSON.stringify(data[key]))
    })

    if (files.logo) formData.append('logo', files.logo)

    if (isEdit) {
      const response = await apiPut(`project/${parseInt(id, 10)}`, formData)
      if (response.success) notify.success('Project updated successfully!')
    } else {
      const response = await apiPost('project', formData)
      if (response.success) {
        notify.success('Project added successfully!')
        history.push('/projects')
      }
    }

    setIsSubmitting(false)
  }

  const showPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews({ ...previews, [key]: preview })
  }

  const logoRef = useRef(null)

  const propertyByGroup = groupBy(properties, 'type.name')

  const deleteProject = () => {
    notify.deleteConfirm({
      title: 'Delete Project',
      message: 'Are you sure you want to delete this Project?. It will delete all the related data',
      onConfirm: async () => {
        const response = await apiDelete(`/project/${id}`)
        if (response?.success) {
          history.push('/projects')
          notify.success('Success', 'Project deleted successfully')
        }
      },
    })
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={
        isEdit
          ? {
              name: projectData.name,
              description: projectData.description || '',
              slug: projectData.slug,
              properties: projectData.properties?.map(property => property.id),
            }
          : {}
      }
      enableReinitialize
    >
      {props =>
        !loading ? (
          <Form className="space-y-8 m-auto px-8 py-8 rounded-md w-full">
            <div>
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">{!isEdit ? 'Setup project' : 'Project details'}</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Create/Update project with name and logo, project properties</p>
                </div>
              </div>

              <div className="mt-6 sm:mt-5">
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="photo" className="flex flex-col text-sm text-normal text-gray-700">
                      <span>Project Photo</span>
                      {/* <span className="text-xs text-gray-400">Recommended resolution: 190px x 100px</span> */}
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="flex items-center">
                        <input
                          id="logo"
                          ref={logoRef}
                          name="logo"
                          placeholder="Upload logo"
                          accept=".jpg,.jpeg,.png"
                          className="hidden"
                          type="file"
                          onChange={event => {
                            showPreview('logo', event.target.files[0])
                            setFiles({ ...files, logo: event.target.files[0] })
                          }}
                        />
                        <div
                          onClick={() => logoRef.current.click()}
                          className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-2 border-dashed border-gray-300 rounded-md cursor-pointer w-56 h-full"
                        >
                          {previews?.logo ? <img alt="" src={previews.logo} /> : <CameraIcon className="text-gray-400 h-8 w-8" />}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                      Name of the Project <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name of the project"
                        autoComplete="name"
                        className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                      Project Slug <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Input
                        type="text"
                        name="slug"
                        id="slug"
                        placeholder="Project slug"
                        autoComplete="slug"
                        className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="last-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                      Properties <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {map(keys(propertyByGroup), group => {
                        return (
                          <div className="bg-gray-50 select-none max-w-lg p-3 border rounded-md mb-4" key={group}>
                            <span className="font-semibold">{group}</span>
                            <div className="flex mt-2">
                              {propertyByGroup[group].map((property, index) => {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => props.setFieldValue('properties', xor([property.id], props.values.properties))}
                                    className="relative flex mr-4 items-start"
                                  >
                                    <div className="flex items-center h-5">
                                      <input
                                        aria-describedby="comments-description"
                                        checked={includes(props.values.properties, property.id)}
                                        type="checkbox"
                                        onChange={() => props.setFieldValue('properties', xor([property.id], props.values.properties))}
                                        className="focus:ring-dark-500 h-4 w-4 text-dark-600 border-gray-300 rounded"
                                      />
                                    </div>
                                    <div className="ml-2 text-sm">
                                      <label htmlFor="comments" className="font-regular text-gray-700">
                                        {property.name}
                                      </label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="about" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                      Description
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Field
                        as="textarea"
                        id="description"
                        placeholder="Description"
                        name="description"
                        rows={6}
                        className="max-w-lg shadow-sm block w-full focus:ring-dark-500 focus:border-dark-500 sm:text-sm border border-gray-200 rounded-md"
                      />
                      <p className="mt-2 text-sm text-gray-500">Write a few sentences about this project</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!readOnly && (
              <div className="pt-5 border-t border-gray-300">
                <div className="flex justify-between">
                  <button
                    onClick={() => addProject(props.values)}
                    className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                  >
                    {isSubmitting ? (
                      <span className="flex items-start">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.project/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Please wait...</span>
                      </span>
                    ) : (
                      <span>{!isEdit ? 'Add' : 'Save'}</span>
                    )}
                  </button>
                  {isEdit && (
                    <button
                      onClick={deleteProject}
                      type="button"
                      className="inline-flex text-red-500 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                    >
                      Delete Project
                    </button>
                  )}
                </div>
              </div>
            )}
          </Form>
        ) : (
          <div className="w-full h-[500px] flex items-center justify-center">
            <LoadingIcon />
          </div>
        )
      }
    </Formik>
  )
}

export default ProjectProfile
