import PageContent from 'components/templates/PageContent'
import { Form, Formik } from 'formik'
import logo from 'assets/images/opsjet.png'
import Input from 'components/atoms/Input'
import apiPost from 'lib/network/apiPost'
import notify from 'components/atoms/Notify'
import ForgotPasswordSchema from 'validations/forgot.validation'
import { useHistory } from 'react-router'

const ForgotPassword = () => {
  const history = useHistory()
  const sendPasswordForgetEmail = async data => {
    const response = await apiPost(`auth/forgot-password`, data)
    if (response?.success) {
      notify.success('Success', 'Password reset email has been sent successfully!')
    }
  }

  return (
    <PageContent center>
      <>
        <div className=" bg-gray-50 flex flex-col min-h-screen justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow" />
            <h2 className="mt-6 mb-6 text-center text-3xl font-extrabold text-gray-900">Forget Your Password</h2>
            <p className="mt-11 text-center text-md text-lg-600">Please enter the email address associated with your account.</p>
          </div>
          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <Formik
                validationSchema={ForgotPasswordSchema}
                enableReinitialize
                initialValues={{
                  email: '',
                }}
                onSubmit={sendPasswordForgetEmail}
              >
                {props => (
                  <Form className="mb-3">
                    <div className="mb-3">
                      <label htmlFor="email" className="block text-sm text-normal text-gray-700">
                        Email address
                      </label>
                      <div className="mt-1  text-gray-700">
                        <Input id="email" name="email" placeholder={'Your Email'} type="email" autoComplete="email" required className="" />
                      </div>
                    </div>
                    <div>
                      <button
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-normal text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                        type="submit"
                      >
                        Reset Password
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="text-sm text-center">
                Go back to{' '}
                <span onClick={() => history.push('/login')} className="cursor-pointer  font-medium mt-3 text-primary-600 hover:text-primary-500">
                  Login
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    </PageContent>
  )
}

export default ForgotPassword
