import useUrlState from '@ahooksjs/use-url-state'
import Form from 'antd/lib/form/Form'
import classNames from 'classnames'
import Input from 'components/atoms/Input'
import LoadingIcon from 'components/atoms/LoadingIcon'
import notify from 'components/atoms/Notify'
import Switch from 'components/atoms/Switch'
import Popover from 'components/molecules/Popover'
import Selection from 'components/molecules/Selection'
import { Field, Formik, useFormik } from 'formik'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import apiPut from 'lib/network/apiPut'
import { find, get, startCase, xor, includes, filter, isEmpty, round } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { IoEye } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Country } from 'country-state-city'
import validations from 'validations'
import { CameraIcon } from '@heroicons/react/solid'
import SmsAuth from './modals/SmsAuth'
import GoogleAuth from './modals/GoogleAuth'
import useModal from 'hooks/useModal'
import InputPhone from 'components/atoms/PhoneInput'
import { usePersistState } from 'hooks/usePersistState'
import { smsTwoFaAuth } from 'validations/twoFaAuth.validation'

const UserProfile = ({ readOnly, loading, refetch, customFields, userData, isEdit, id, roles }) => {
  const [previews, setPreviews] = useState({})
  const [files, setFiles] = useState({})
  const [profile, setProfile] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [twoFaData, setTwoFaData] = useState({})
  const [minutes, setMinutes] = usePersistState(0, 's_seconds')
  const [seconds, setSeconds] = usePersistState(0, 's_minutes')
  const [otpTime, setOtpTime] = usePersistState(new Date().toLocaleString(), 'otp_sent_time')

  const history = useHistory()

  useEffect(() => {
    setPreviews({ profile: userData?.userProfile?.profile, userPhoto: userData?.userPhoto, cover: userData?.userProfile?.cover })
    setProfile({ username: userData?.userProfile?.username, ...userData?.userProfile?.userKyc, twoFactorAuth: userData?.userProfile?.twoFactorAuth })
  }, [userData])

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  const workspaces = useSelector(state => get(state, 'user.workspaces'))
  const user = useSelector(state => get(state, 'user.user'))

  const [googleAuthData, setGoogleAuthData] = useState(null)

  const getQRCode = async () => {
    const response = await apiGet('auth-google-qr')
    setGoogleAuthData(response.data)
  }

  useEffect(() => {
    if (otpTime && seconds > 0) {
      const startOtpTime = new Date(otpTime)
      startOtpTime.setMinutes(startOtpTime.getMinutes() + 1)
      const currentTime = new Date()
      const tempSeconds = round((startOtpTime.getTime() - currentTime.getTime()) / 1000)
      if (tempSeconds > 60) {
        setMinutes(round(tempSeconds / 60))
      } else setMinutes(0)

      if (tempSeconds > 0 && tempSeconds <= 60) {
        setSeconds(tempSeconds)
      } else setSeconds(0)
    }
  }, [otpTime])

  const sendPasswordForgetEmail = async (email, d, wp) => {
    notify.alertConfirm({
      title: `Send password reset link for ${wp.subdomain}.id.club`,
      message: `This will send reset link to ${email} for ${wp.subdomain}.id.club`,
      onConfirm: async () => {
        if (!email) return notify.error('Email', 'Email is not present!')
        const response = await apiPost(`user/forgot-password`, { email, workspace_id: d })
        if (response?.success) {
          notify.success('Password reset email has been sent successfully!')
        }
      },
    })
  }

  const handleTwoFa = props => {
    const { phone, twoFactorAuth, twoFAType } = props
    setTwoFaData({
      ...twoFaData,
      phone,
      twoFactorAuth,
      twoFAType,
    })
    if (twoFactorAuth === false) {
      return disableTwoFaAuth()
    } else if (twoFactorAuth === true) {
      if (twoFAType === 'sms' && phone) {
        return sendTwoFAAuthCode(phone)
      } else if (twoFAType === 'googleAuth') {
        return openGoogleAuthModal()
      }
    }
  }

  const sendTwoFAAuthCode = async phone => {
    if (seconds !== 0 || minutes !== 0) return openSmsModel()
    const payload = {
      phone,
    }

    const response = await apiPost('send-two-fa-code', payload)
    if (response.success) {
      setMinutes(0)
      setSeconds(60)
      setOtpTime(new Date().toLocaleString())
      notify.success('Two FA code has been sent to your phone!')
      openSmsModel()
    }
    setIsSubmitting(false)
  }

  const disableTwoFaAuth = async () => {
    const response = await apiPost('disable-two-factor-auth', {})
    if (response.success) {
      notify.success('Two FA auth has been disabled successfully!')
      refetch()
    }
    setIsSubmitting(false)
  }

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validateOnChange: true,
    validationSchema: smsTwoFaAuth,
    onSubmit: async values => {
      const payload = {
        code: `${values.code}`,
        phone: twoFaData.phone,
      }
      const response = await apiPost('verify-two-fa-code', payload)
      if (response.success) {
        notify.success('SMS Two FA has been enabled successfully')
        closeModal()
        refetch()
      }
    },
  })

  const [SmsAuthModal, openSmsModel, closeModal] = useModal({
    content: (
      <SmsAuth
        sendTwoFAAuthCode={sendTwoFAAuthCode}
        refetch={refetch}
        twoFaData={twoFaData}
        minutes={minutes}
        seconds={seconds}
        setSeconds={setSeconds}
        setMinutes={setMinutes}
        formik={formik}
      />
    ),
    title: 'Verify Phone for 2FA',
    dimensions: {
      height: '237px',
      width: '450px',
    },
  })

  const [GoogleAuthModal, openGoogleAuthModal] = useModal({
    content: <GoogleAuth refetch={refetch} googleAuthData={googleAuthData} />,
    title: 'Enable 2FA',
    dimensions: {
      height: '700px',
      width: '550px',
    },
  })

  const addUpdateUser = async props => {
    const data = props.values
    delete data.id
    delete data.userPhoto

    if (includes(data.roles, 'platform_admin') && !data.workspaces && !userData.isPlatformAdmin) {
      data.workspaces = currentWorkspace.id
    }

    setIsSubmitting(true)

    const formData = new FormData()

    if (files.cover) formData.append('cover', files.cover)
    if (files.profile) formData.append(`profile`, files.profile)
    if (files.userPhoto) formData.append(`userPhoto`, files.userPhoto)
    // eslint-disable-next-line
    Object.keys(data).forEach(key => {
      if (data[key] === 'null' || data[key] === undefined || data[key] === null) delete data[key]
      // eslint-disable-next-line
      if (typeof data[key] === 'string' || typeof data[key] === 'number' || typeof data[key] === 'boolean' || Array.isArray(data[key])) {
        formData.append(key, data[key])
      }
      // eslint-disable-next-line
      if (typeof data[key] === 'object' && !Array.isArray(data[key])) formData.append(key, JSON.stringify(data[key]))
    })
    // eslint-disable-next-line
    Object.keys(profile).forEach(key => {
      if (profile[key] === 'null' || profile[key] === undefined || profile[key] === null) delete profile[key]
      // eslint-disable-next-line
      if (typeof profile[key] === 'string' || typeof profile[key] === 'number' || typeof profile[key] === 'boolean' || Array.isArray(profile[key])) {
        formData.append(key, profile[key])
      }
    })

    if (isEdit) {
      const response = await apiPut(`user/${parseInt(id, 10)}`, formData)
      if (response.success) notify.success('User updated successfully!')
    } else {
      const response = await apiPost('user', formData)
      if (response.success) {
        notify.success('User added successfully!')
        history.push('/users')
      }
    }

    setIsSubmitting(false)
  }

  const deleteUser = () => {
    notify.deleteConfirm({
      title: 'Delete user',
      message: 'Are you sure you want to delete this user?. It will delete all the related data',
      onConfirm: async () => {
        const response = await apiPost(`user/delete/${id}`)
        if (response?.success) {
          history.push('/users')
          notify.success('User deleted successfully')
        }
      },
    })
  }

  const sendAdminInviteLink = () => {
    notify.alertConfirm({
      title: 'Sent admin invite email',
      message: 'Are you sure you want to send email to this user?.',
      onConfirm: async () => {
        const response = await apiPost(`user/admin-invite/${id}`)
        if (response?.success) {
          notify.success(response?.message)
        }
      },
    })
  }

  const removeFromWorkspace = () => {
    notify.deleteConfirm({
      title: 'Delete user',
      message: 'Are you sure you want to delete this user?. It will delete all the related data',
      onConfirm: async () => {
        const formData = new FormData()
        formData.append('accountsWith', [])
        const response = await apiPut(`user/${parseInt(id, 10)}`, formData)
        if (response?.success) {
          refetch(id)
          history.push(`/users/edit/${parseInt(id, 10)}/user-profile`)
          notify.success('User removed from workspace')
        }
      },
    })
  }

  const removeWorkspaceAdmin = role => {
    notify.alertConfirm({
      title: `Remove as ${role.name}`,
      message: `Are you sure you want to remove this user as ${role.name}?`,
      onConfirm: async () => {
        const formData = new FormData()

        const oldRoles = userData?.roles || []
        const oldWorkspaces = userData?.workspaces || []

        formData.append('roles', [
          ...filter(oldRoles, function (o) {
            return o !== role.id
          }),
        ])
        formData.append('workspaces', [...oldWorkspaces, currentWorkspace.id])

        const response = await apiPut(`user/${parseInt(id, 10)}`, formData)
        if (response?.success) {
          refetch(id)
          history.push(`/users/edit/${parseInt(id, 10)}/user-profile`)
          notify.success(`User removed as ${role.name}`)
        }
      },
    })
  }

  const assignWorkspaceAdmin = item => {
    notify.alertConfirm({
      title: `Assign as ${item.name}`,
      message: `Are you sure you want to assign this user as ${item.name}?`,
      onConfirm: async () => {
        const formData = new FormData()

        const oldRoles = userData?.roles || []
        const oldWorkspaces = userData?.workspaces || []

        formData.append('roles', [...oldRoles, item.id])
        formData.append('workspaces', [...oldWorkspaces, currentWorkspace.id])

        const response = await apiPut(`user/${parseInt(id, 10)}`, formData)
        if (response?.success) {
          history.push(`/users/edit/${parseInt(id, 10)}/user-profile`)
          refetch(id)
          notify.success('User assigned as workspace admin')
        }
      },
    })
  }

  const showPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews({ ...previews, [key]: preview })
  }

  const currentWorkspace = useSelector(state => get(state, 'user.workspace'))

  const profileRef = useRef(null)
  const coverRef = useRef(null)

  const generateAdminPasswordLink = async () => {
    const response = await apiPost('auth/admin-password', { id })
    if (response?.success) {
      const resetPasswordUrl = response?.data?.resetPasswordUrl?.replace('{{url}}', window.location.origin)
      notify.copyUrlPanel({
        title: 'Link Generated',
        message: (
          <a className="white-space-nowrap text-ellipsis block overflow-hidden max-w-[80%] ellipse" href={resetPasswordUrl}>
            {resetPasswordUrl}
          </a>
        ),
        onConfirm: async () => {
          navigator.clipboard.writeText(resetPasswordUrl)
          notify.success('Link copied to clipboard')
        },
      })
    }
  }

  const rolesList = roles?.map(role => ({ id: role.name, name: startCase(role.name) }))

  const isOwner = user.id === parseInt(id, 10)

  const showCurrentWorkspaceField = props => includes(props.values?.accountsWith, currentWorkspace?.id)

  const twitterJson = get(userData, 'socialData.twitter')
  const discordJson = get(userData, 'socialData.discord')

  const tabs = [
    { id: 1, name: 'Roles and access', href: '#', current: false },
    { id: 2, name: 'Basic Details', href: '#', current: false },
    { id: 6, name: 'Address Details', href: '#', current: false },
    { id: 3, name: `${currentWorkspace?.name} 2FA Settings`, href: '#', current: true },
    { id: 4, name: `${currentWorkspace?.name} Fields`, href: '#', current: false },
    { id: 7, name: 'Security', href: '#', current: false },
  ]

  const [params, setParams] = useUrlState({ t: tabs[0]?.name })

  const currentTab = parseInt(params.t, 10)
  const setCurrentTab = currentTab => setParams({ ...params, t: currentTab })

  useEffect(() => {
    if (!currentTab) {
      setCurrentTab(tabs[1]?.id)
    }
    if (isOwner && currentTab === 7) {
      getQRCode()
    }
  }, [currentTab])

  return (
    <Formik
      validationSchema={isEdit ? null : validations.AddUserSchema}
      initialValues={isEdit ? userData : { roles: ['user'], cf: {}, accountsWith: [currentWorkspace.id], workspaces: [currentWorkspace.id] }}
      enableReinitialize={true}
      validateOnChange={true}
    >
      {props =>
        !loading ? (
          <div className="w-full mb-[60px] flex flex-col">
            <div className="bg-white flex w-full">
              <Form className="space-y-8 py-8 w-full mx-auto px-8">
                <div>
                  <div className="border-b border-gray-200 pb-5 sm:pb-0">
                    <h3 className="text-xl font-medium leading-6 text-gray-900">User Overview {readOnly ? '(Read-only)' : ''}</h3>
                    <div className="mt-3 sm:mt-4">
                      <div className="hidden sm:block">
                        <nav className="-mb-px flex space-x-8">
                          {tabs
                            ?.filter(d => {
                              if ((d.id === 1 && !includes(user.roles, 'platform_admin')) || (d.id === 7 && !isOwner)) return false
                              return true
                            })
                            .map(tab => (
                              <span
                                key={tab.name}
                                onClick={() => setCurrentTab(tab.id)}
                                className={classNames(
                                  tab.id === currentTab ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                  'whitespace-nowrap pb-4 border-b-2 font-medium text-sm cursor-pointer'
                                )}
                                aria-current={currentTab === tab.id ? 'page' : undefined}
                              >
                                {tab.name}
                              </span>
                            ))}
                        </nav>
                      </div>
                    </div>
                  </div>

                  {currentTab === 1 && (
                    <div className="space-y-6 sm:space-y-5 pt-8">
                      {user.isPlatformAdmin && (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                          <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            Roles
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Selection
                              onChange={value => {
                                props.setFieldValue('roles', xor([value.id], props.values?.roles || []))
                              }}
                              multiple
                              title="Select roles"
                              list={rolesList?.filter(d => {
                                if (includes(props.values?.roles, 'platform_admin') && d.id === 'workspace_admin') return false
                                if (includes(props.values?.roles, 'workspace_admin') && d.id === 'platform_admin') return false
                                return true
                              })}
                              value={props.values?.roles}
                            />
                          </div>
                        </div>
                      )}
                      {!userData?.isPlatformAdmin &&
                        (includes(props.values?.roles, 'concierge') ||
                          includes(props.values?.roles, 'workspace_admin') ||
                          includes(props.values?.roles, 'workspace_manager') ||
                          includes(props.values?.roles, 'event_manager') ||
                          includes(props.values?.roles, 'campaign_manager') ||
                          includes(props.values?.roles, 'workspace_scanner')) &&
                        user.isPlatformAdmin && (
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                              Assign Workspace(s)
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <Selection
                                onChange={value => {
                                  props.setFieldValue('workspaces', xor([value.id], props.values.workspaces || []))
                                }}
                                multiple
                                title="Select workspaces"
                                list={workspaces?.map(item => ({
                                  id: item.id,
                                  name: startCase(item.name),
                                }))}
                                value={props.values?.workspaces}
                              />
                            </div>
                          </div>
                        )}

                      {user.isPlatformAdmin && (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            Workspace (Front-end app) Registered with
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Selection
                              onChange={value => {
                                props.setFieldValue('accountsWith', xor([value.id], props.values.accountsWith || []))
                              }}
                              multiple
                              title="Select workspaces"
                              list={workspaces?.map(item => ({ id: item.id, name: item.name }))}
                              value={props.values?.accountsWith}
                            />
                            {userData && userData?.email && (
                              <h1 className="cursor-pointer text-md font-medium  pl-2 mt-1 block">
                                Send front-end app password reset link for{' '}
                                {props?.values?.accountsWith?.map((d, index) => (
                                  <span
                                    key={index}
                                    className="mr-1 hover:underline text-blue-600 cursor-pointer"
                                    onClick={() => sendPasswordForgetEmail(userData.email, d, find(workspaces, { id: d }))}
                                  >
                                    {find(workspaces, { id: d })?.name}
                                    {index !== props.values.accountsWith.length - 1 ? ',' : ''}
                                  </span>
                                ))}
                              </h1>
                            )}
                          </div>
                        </div>
                      )}

                      {user.isPlatformAdmin &&
                        !readOnly &&
                        (includes(userData?.roles, 'platform_admin') ||
                          includes(userData?.roles, 'workspace_admin') ||
                          includes(userData?.roles, 'workspace_scanner') ||
                          includes(userData?.roles, 'concierge') ||
                          includes(userData?.roles, 'event_manager') ||
                          includes(userData?.roles, 'campaign_manager') ||
                          includes(props.values?.roles, 'platform_admin') ||
                          includes(props.values?.roles, 'workspace_admin') ||
                          includes(props.values?.roles, 'workspace_scanner') ||
                          includes(props.values?.roles, 'event_manager') ||
                          includes(props.values?.roles, 'campaign_manager') ||
                          includes(props.values?.roles, 'concierge')) && (
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                            <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                              Set Admin Password (For Dashboard Access)
                            </label>
                            <button onClick={generateAdminPasswordLink} className="bg-dark-500 p-2 text-white rounded-lg max-w-lg">
                              Generate Admin Invite Link
                            </button>
                          </div>
                        )}

                      {user.isPlatformAdmin &&
                        !readOnly &&
                        (includes(userData?.roles, 'platform_admin') ||
                          includes(userData?.roles, 'workspace_admin') ||
                          includes(userData?.roles, 'workspace_scanner') ||
                          includes(userData?.roles, 'concierge') ||
                          includes(userData?.roles, 'event_manager') ||
                          includes(userData?.roles, 'campaign_manager') ||
                          includes(props.values?.roles, 'platform_admin') ||
                          includes(props.values?.roles, 'workspace_admin') ||
                          includes(props.values?.roles, 'workspace_scanner') ||
                          includes(props.values?.roles, 'event_manager') ||
                          includes(props.values?.roles, 'campaign_manager') ||
                          includes(props.values?.roles, 'concierge')) && (
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                            <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                              Send Admin Invite Email (For Dashboard Access)
                            </label>
                            <button onClick={sendAdminInviteLink} className="bg-dark-500 p-2 text-white rounded-lg max-w-lg">
                              Send Admin Invite Link
                            </button>
                          </div>
                        )}

                      {/* {includes(props.values?.roles, 'workspace_scanner') && (
                        <div className="mb-10">
                          <div className="workspace_box">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Workspace Scanner</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Assign events and stations to this user.</p>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 pb-3 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                              Assign Event(s)
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <Selection
                                onChange={value => {
                                  props.setFieldValue('events', xor([value.id], props.values.events || []))
                                }}
                                multiple
                                title="Select events"
                                list={eventData?.map(item => ({
                                  id: item.id,
                                  name: startCase(item.name),
                                }))}
                                value={props.values?.events}
                              />
                            </div>
                          </div>
                          {props.values.events?.length > 0 && (
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                                Assign Stations(s)
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <Selection
                                  onChange={value => {
                                    props.setFieldValue('stations', xor([value.id], props.values.stations || []))
                                  }}
                                  multiple
                                  title="Select stations"
                                  list={props.values?.events
                                    ?.map(d => split(find(eventData, { id: d })?.stations, ',').map(e => ({ event: d, station: e })))
                                    .flat()
                                    .map(item => ({ id: `${item.station}--${item.event}`, name: item.station }))}
                                  value={props.values?.stations}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )} */}
                    </div>
                  )}

                  {currentTab === 2 && (
                    <div className="space-y-6 sm:space-y-5 pt-8">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:pt-5">
                        <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                          IRL Photo
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex pb-4 items-center">
                            <div className="bg-gray relative h-[200px] w-[150px] border mr-4 border-dashed border-gray-300 rounded-md">
                              <input
                                id="userPhoto"
                                ref={profileRef}
                                name="userPhoto"
                                className="hidden"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={event => {
                                  showPreview('userPhoto', event.target.files[0])
                                  setFiles({ ...files, userPhoto: event.target.files[0] })
                                }}
                              />
                              {previews.userPhoto ? (
                                <span onClick={() => profileRef.current.click()}>
                                  <img src={previews.userPhoto} alt="" className="cursor-pointer object-contain h-[199px] w-[200px] rounded" />
                                </span>
                              ) : (
                                <div
                                  onClick={() => profileRef.current.click()}
                                  className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-gray-300 rounded-md  h-full w-full cursor-pointer"
                                >
                                  {previews.squareLogo ? null : <CameraIcon className="text-gray-400 h-8 w-8" />}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Display name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Input
                            readOnly={user.isWorkspaceAdmin && !isOwner}
                            type="text"
                            name="displayName"
                            placeholder="Display Name"
                            id="displayName"
                            autoComplete="given-name"
                            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Email address
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Input
                            readOnly={user.isWorkspaceAdmin && !isOwner}
                            type="text"
                            placeholder="Email address"
                            name="email"
                            id="email"
                            autoComplete="email"
                            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Phone number
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Input
                            readOnly={user.isWorkspaceAdmin && !isOwner}
                            type="text"
                            placeholder="Phone number"
                            name="phone"
                            id="phone"
                            autoComplete="phone"
                            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Wallet address
                        </label>
                        <div className="mt-1 flex sm:mt-0 w-full max-w-lg sm:col-span-2">
                          <Input
                            readOnly={user.isWorkspaceAdmin}
                            type="text"
                            name="walletAddress"
                            placeholder="Wallet address"
                            extraClassName="w-full"
                            id="walletAddress"
                            autoComplete="walletAddress"
                            className="block shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                          {props.values?.walletAddress && (
                            <div className="text-xs h-[38px] ml-2">
                              {userData?.ethBalance ? (
                                <span className="bg-white h-[38px] relative top-1 w-full whitespace-nowrap border border-gray-300 rounded-md p-2 flex items-center font-medium text-dark-700">
                                  {userData?.ethBalance} ETH
                                </span>
                              ) : null}

                              {/* {userData?.clubBalance ? (
                                <span className="bg-blue-200 p-1 font-medium rounded text-blue-700"> {userData?.clubBalance ? `${userData?.clubBalance} CLUB` : ''}</span>
                              ) : null} */}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                        <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Custodial Wallet address
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            readOnly
                            value={userData?.custodialWallet?.walletAddress}
                            type="text"
                            placeholder="Custodial Wallet address"
                            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                          {user.isPlatformAdmin && userData?.custodialWallet && includes(user.roles, 'platform_admin') && (
                            <div
                              onClick={() => {
                                window.navigator.clipboard.writeText(userData?.custodialWallet?.walletAddress).then(res => {
                                  notify.success('Copied to clipboard')
                                })
                              }}
                              className="mt-2 ml-2 text-xs cursor-pointer underline text-blue-600"
                            >
                              Copy Private Key
                            </div>
                          )}
                        </div>
                      </div>

                      {user.isPlatformAdmin && userData?.delegateWallet && (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            Delegate Wallet address
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              readOnly
                              value={userData?.delegateWallet}
                              type="text"
                              placeholder="Delegate Wallet address"
                              className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      )}
                      {userData?.secondaryWallets?.length && userData?.secondaryWallets?.length > 0 ? (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            Secondary Wallet addresses
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            {userData?.secondaryWallets?.map(wallet => (
                              <input
                                key={wallet.walletAddress}
                                readOnly={true}
                                type="text"
                                value={wallet.walletAddress}
                                className="max-w-lg mb-5 block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                              />
                            ))}
                          </div>
                        </div>
                      ) : null}
                      {userData?.twitterId && (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            Connected Twitter Username
                          </label>
                          <div className="mt-1 flex sm:mt-0 sm:col-span-2">
                            <Input
                              type="text"
                              readOnly
                              id="twitterId"
                              name={`twitterId`}
                              value={`@${userData?.twitterId}`}
                              placeholder="Connected account"
                              extraClassName="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                            />
                            <Popover
                              width={500}
                              content={
                                <div className="json-component">
                                  <pre>
                                    <code>{JSON.stringify(twitterJson, null, 4)}</code>
                                  </pre>
                                </div>
                              }
                            >
                              <button
                                type="button"
                                className="ml-3 outline-none bottom-[-4px] flex items-center justify-center h-[38px] border border-gray-300 shadow-sm rounded-md relative min-w-[38px]"
                              >
                                <IoEye className="h-4 w-4 text-gray-400" />
                              </button>
                            </Popover>
                          </div>
                        </div>
                      )}
                      {userData?.discordId && (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            Connected Discord Username
                          </label>
                          <div className="mt-1 flex sm:mt-0 sm:col-span-2">
                            <Input
                              type="text"
                              readOnly
                              id="discordId"
                              name={`discordId`}
                              value={`@${userData?.discordId}`}
                              placeholder="Connected account"
                              extraClassName="min-w-[200px] block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                            />
                            <Popover
                              width={500}
                              content={
                                <div className="json-component">
                                  <pre>
                                    <code>{JSON.stringify(discordJson, null, 4)}</code>
                                  </pre>
                                </div>
                              }
                            >
                              <button
                                type="button"
                                className="ml-3 bottom-[-4px] flex items-center outline-none justify-center h-[38px] border border-gray-300 shadow-sm rounded-md relative min-w-[38px]"
                              >
                                <IoEye className="h-4 w-4 text-gray-400" />
                              </button>
                            </Popover>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="mt-6 sm:mt-5">
                    {currentTab === 3 && (
                      <div className="opacity-[0.8]">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 mb-6">
                          <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px">
                            2FA Enabled
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2 cursor-not-allowed">
                            <Switch
                              onChange={e => profile.twoFactorAuth && setProfile(p => ({ ...p, twoFactorAuth: e }))}
                              value={profile.twoFactorAuth}
                              disabled={!profile.twoFactorAuth}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px">
                            2FA Type
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <span className="mr-3">
                              <Field className="mr-2" readOnly type="radio" name="twoFAType" value="sms" checked={props.values?.userProfile?.twoFAType === 'sms'} />
                              SMS
                            </span>
                            <span>
                              <Field className="mr-2" readOnly type="radio" name="twoFAType" value="googleAuth" checked={props.values?.userProfile?.twoFAType === 'googleAuth'} />
                              Google Auth
                            </span>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            2FA Phone
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Input
                              readOnly={true}
                              placeholder="Phone number"
                              type="text"
                              name="twoFAPhone"
                              id="twoFAPhone"
                              autoComplete="given-name"
                              value={props.values?.userProfile?.twoFAPhone?.phoneWithCountryCode}
                              className="max-w-lg cursor-not-allowed block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {currentTab === 6 && (
                      <div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            Address Line 1
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Input
                              type="text"
                              name="addressLine1"
                              placeholder={'Address Line 1'}
                              id="addressLine1"
                              autoComplete="given-name"
                              onChange={e => setProfile(p => ({ ...p, addressLine1: e.target.value }))}
                              value={profile?.addressLine1}
                              className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            Address Line 2
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Input
                              type="text"
                              name="addressLine2"
                              id="addressLine2"
                              placeholder={'Address Line 2'}
                              autoComplete="given-name"
                              onChange={e => setProfile(p => ({ ...p, addressLine2: e.target.value }))}
                              value={profile?.addressLine2}
                              className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            Country
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Selection
                              onChange={value => setProfile(p => ({ ...p, country: value.name, countryIsoCode: value.isoCode }))}
                              title={'Select country'}
                              list={Country.getAllCountries()}
                              value={{ name: profile.country }}
                              valueByName
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            State
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Input
                              type="text"
                              name="state"
                              id="state"
                              placeholder={'State'}
                              autoComplete="given-name"
                              onChange={e => setProfile(p => ({ ...p, state: e.target.value }))}
                              value={profile.state}
                              className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            City
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Input
                              type="text"
                              name="city"
                              id="city"
                              placeholder={'City'}
                              autoComplete="given-name"
                              onChange={e => setProfile(p => ({ ...p, city: e.target.value }))}
                              value={profile.city}
                              className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            postal code
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Input
                              onChange={e => setProfile(p => ({ ...p, postalCode: e.target.value }))}
                              value={profile?.postalCode}
                              type="text"
                              name="username"
                              id="twoFAPhone"
                              placeholder="Postal code"
                              autoComplete="given-name"
                              className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>{' '}
                      </div>
                    )}

                    {currentTab === 7 && isOwner && (
                      <div className="opacity-[0.8]">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 mb-6">
                          <label htmlFor="2FA" className="block text-sm text-normal text-gray-700 sm:mt-px">
                            2FA Enabled
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2 cursor-not-allowed">
                            <Switch onChange={e => props.setFieldValue('twoFactorAuth', e)} value={props.values.twoFactorAuth} />
                          </div>
                        </div>
                        {props.values.twoFactorAuth && (
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                            <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px">
                              2FA Type
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <span className="mr-3">
                                <Field className="mr-2" type="radio" name="twoFAType" id="sms" value="sms" />
                                <label htmlFor="sms">SMS</label>
                              </span>
                              <span>
                                <Field className="mr-2" type="radio" name="twoFAType" id="googleAuth" value="googleAuth" />
                                <label htmlFor="googleAuth">Google Auth</label>
                              </span>
                            </div>
                          </div>
                        )}
                        {props.values.twoFAType === 'sms' && props.values.twoFactorAuth && (
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                            <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                              2FA Phone
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <InputPhone
                                disabled={user.phone}
                                value={props.values.phone}
                                onChange={e => {
                                  props.setFieldValue('phone', e)
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {currentTab === 4 && showCurrentWorkspaceField(props) && (
                      <div className="">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center pb-4 pt-4">
                          <label htmlFor="photo" className="block text-sm text-normal text-gray-700">
                            Photo
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="flex items-center">
                              {!previews.profile ? (
                                <span className="h-12 w-12 rounded-full bg-gray-100">
                                  <svg className="h-full w-full  text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                  </svg>
                                </span>
                              ) : (
                                <img className="inline-block h-14 w-14 rounded-full" src={previews.profile} alt="" />
                              )}
                              <input
                                id="profile"
                                ref={profileRef}
                                name="profile"
                                className="hidden"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={event => {
                                  showPreview('profile', event.target.files[0])
                                  setFiles({ ...files, profile: event.target.files[0] })
                                }}
                              />
                              <button
                                onClick={() => profileRef.current.click()}
                                type="button"
                                className="ml-5 bg-white py-2 px-3 border border-gray-300 disabled:bg-gray-100 disabled:text-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                              >
                                Add profile picture
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center pb-4 pt-4 border-t">
                          <label htmlFor="photo" className="block text-sm text-normal text-gray-700">
                            Cover Picture
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="flex items-center bg-gray relative h-[200px] w-[500px] border mr-4 border-dashed border-gray-300 rounded-md">
                              {previews.cover ? (
                                <span onClick={() => coverRef.current.click()}>
                                  <img src={previews.cover} alt="" className="cursor-pointer object-fit h-[200px] w-[500px] rounded" />
                                </span>
                              ) : (
                                <div
                                  onClick={() => coverRef.current.click()}
                                  className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-gray-300 rounded-md  h-full w-full cursor-pointer"
                                >
                                  {previews.cover ? null : <CameraIcon className="text-gray-400 h-8 w-8" />}
                                </div>
                              )}
                              <input
                                id="cover"
                                ref={coverRef}
                                name="profile"
                                className="hidden"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={event => {
                                  showPreview('cover', event.target.files[0])
                                  setFiles({ ...files, cover: event.target.files[0] })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                            {currentWorkspace.name} Username
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Input
                              onChange={e => setProfile(p => ({ ...p, username: e.target.value }))}
                              type="text"
                              name="username"
                              value={profile.username}
                              placeholder="Username"
                              id="username"
                              autoComplete="given-name"
                              className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        {customFields?.map((field, index) => {
                          return (
                            <div key={index} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-5">
                              <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                                {field.label}
                              </label>

                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                {field.type === 'text' || field.type === 'textarea' || field.type === 'number' || field.type === '' ? (
                                  <Field
                                    as={field.type === 'textarea' ? 'textarea' : 'input'}
                                    type={field.id}
                                    value={props.values?.cf[field.id]}
                                    onChange={e => props.setFieldValue('cf', { ...props.values.cf, [field.id]: e.target.value })}
                                    rows={6}
                                    placeholder={field.placeholder}
                                    className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                                  />
                                ) : null}

                                {field.type === 'property' && (
                                  <Selection
                                    onChange={value => {
                                      props.setFieldValue(`cf.${field.id}`, xor([value.id], props.values.cf[field.id] || []))
                                    }}
                                    multiple
                                    title={field.label}
                                    list={userData.propertyList[field.propertyType?.name]?.map(item => ({ id: item.id, name: item.name }))}
                                    value={props.values?.cf[field.id] || []}
                                  />
                                )}
                              </div>
                            </div>
                          )
                        })}
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                          <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px">
                            Is NFT Creator for {currentWorkspace.name}
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Switch onChange={value => props.setFieldValue('nftCreator', value)} value={props.values.nftCreator} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {!readOnly && (
                  <div className="pt-2">
                    <div className="flex justify-between">
                      {currentTab !== 7 ? (
                        <button
                          onClick={async () => {
                            if (props.isValid) {
                              if (!isEdit && !isEmpty(await props.validateForm())) return
                              addUpdateUser(props)
                            }
                          }}
                          className={classNames(
                            !props.isValid && 'cursor-not-allowed opacity-50',
                            'mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500'
                          )}
                        >
                          {isSubmitting ? (
                            <span className="flex items-start">
                              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              <span>Please wait...</span>
                            </span>
                          ) : (
                            <span>{!isEdit ? 'Add' : 'Update'}</span>
                          )}
                        </button>
                      ) : (
                        <button
                          disabled={!props.dirty}
                          onClick={() => (isOwner && currentTab === 7 && props.dirty ? handleTwoFa(props?.values) : null)}
                          className={classNames(
                            !props.isValid && 'cursor-not-allowed opacity-50',
                            'mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500'
                          )}
                        >
                          {isSubmitting ? (
                            <span className="flex items-start">
                              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              <span>Please wait...</span>
                            </span>
                          ) : (
                            <span>{props.values.twoFactorAuth ? 'Enable' : 'Disable'} Two FA Auth</span>
                          )}
                        </button>
                      )}
                      <div>
                        {isEdit && user.isPlatformAdmin && !isOwner && (
                          <button
                            onClick={deleteUser}
                            type="button"
                            className="inline-flex text-red-500 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                          >
                            Delete User
                          </button>
                        )}
                        {isEdit &&
                          !isOwner &&
                          user.isWorkspaceAdmin &&
                          (includes(userData?.roles, 'platform_admin') ||
                            includes(userData?.roles, 'workspace_admin') ||
                            includes(userData?.roles, 'workspace_scanner') ||
                            includes(userData?.roles, 'concierge') ||
                            includes(userData?.roles, 'event_manager') ||
                            includes(props.values?.roles, 'platform_admin') ||
                            includes(props.values?.roles, 'workspace_admin') ||
                            includes(props.values?.roles, 'workspace_scanner') ||
                            includes(props.values?.roles, 'event_manager') ||
                            includes(props.values?.roles, 'concierge')) && (
                            <button
                              onClick={generateAdminPasswordLink}
                              type="button"
                              className="inline-flex mr-2 text-gray-900 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                            >
                              Generate Admin Invite Link
                            </button>
                          )}
                        {isEdit && user.isWorkspaceAdmin && !isOwner && (
                          <div className="inline-flex">
                            <Popover
                              width={200}
                              content={
                                <>
                                  {filter(rolesList, function (o) {
                                    return includes(['concierge', 'workspace_scanner', 'workspace_admin', 'event_manager'], o.id)
                                  })?.map(role => {
                                    return (
                                      <button
                                        key={role.id}
                                        onClick={() => {
                                          if (includes(userData?.roles, role.id)) {
                                            removeWorkspaceAdmin(role)
                                          } else assignWorkspaceAdmin(role)
                                        }}
                                        className={`hover:bg-dark-600 hover:text-white border group flex w-full items-center rounded-md px-2 py-2 text-sm mb-2 ${
                                          includes(userData?.roles, role.id) ? 'bg-dark-600 text-white' : ''
                                        }`}
                                      >
                                        {role.name}
                                      </button>
                                    )
                                  })}
                                </>
                              }
                            >
                              <button
                                type="button"
                                className="inline-flex mr-2 text-gray-900 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                              >
                                Assign / Remove Role
                              </button>
                            </Popover>
                          </div>
                        )}
                        {/* {isEdit && user.isWorkspaceAdmin && !isOwner && includes(userData?.roles, 'workspace_admin') && (
                        <button
                          onClick={removeWorkspaceAdmin}
                          type="button"
                          className="inline-flex mr-2 text-gray-900 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                        >
                          Remove admin
                        </button>
                      )} */}
                        {isEdit && user.isWorkspaceAdmin && !isOwner && (
                          <button
                            onClick={removeFromWorkspace}
                            type="button"
                            className="inline-flex text-red-500 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                          >
                            Remove from Workspace
                          </button>
                        )}
                        {isEdit && user.isWorkspaceAdmin && isOwner && (
                          <button
                            onClick={removeFromWorkspace}
                            type="button"
                            className="inline-flex text-red-500 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                          >
                            Leave Workspace
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <GoogleAuthModal />
                <SmsAuthModal />
              </Form>
            </div>
          </div>
        ) : (
          <div className="w-full h-[500px] flex items-center justify-center">
            <LoadingIcon />
          </div>
        )
      }
    </Formik>
  )
}

export default UserProfile
