import notify from 'components/atoms/Notify'
import isEmpty from 'lodash/isEmpty'
import { parseCookies } from 'nookies'

const baseUrl = process.env.REACT_APP_BASE_URL

const apiPost = async (query, formData) => {
  const { accessToken, workspaceID } = parseCookies()

  const isFormData = formData instanceof FormData

  const config = {
    method: 'POST',
    body: isFormData ? formData : JSON.stringify(formData),
  }

  if (!isFormData) {
    config.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  }

  if (!isEmpty(accessToken)) {
    config.headers = {
      Authorization: accessToken,
      workspaceID,
      ...config.headers,
    }
  }

  return fetch(`${baseUrl}/admin/${query}`, config)
    .then(response => {
      return response.json()
    })
    .then(response => {
      // if (response.status !== 200) throw new Error('Some error occurred')
      if (!response.success) {
        notify.error(response.message, null, response.err)
      }
      return response
    })
}

export default apiPost
