/* eslint-disable lodash/prefer-lodash-method */
import Layout from 'containers/Layout'
import { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import apiPost from 'lib/network/apiPost'
import notify from 'components/atoms/Notify'
import Switch from 'components/atoms/Switch'
import draftToHtml from 'draftjs-to-html'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const EmailSender = () => {
  const [value, setValue] = useState('')
  const [err, setErr] = useState('')

  const currentWorkspace = useSelector(state => get(state, 'user.workspace'))

  const [data, setData] = useState({
    footer: `You have received this update or an announcement from ${currentWorkspace?.name}`,
    emails: [],
    from: 'id.club <support@id.club>',
    subject: '',
    body: EditorState.createEmpty(),
  })
  const [isSending, setIsSending] = useState(false)

  const handleKeyDown = evt => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault()

      const value1 = value.trim()

      if (value1 && isValid(value1)) {
        setValue('')
        setData({ ...data, emails: [...data.emails, value1] })
      }
    }
  }

  const handleChange = evt => {
    setValue(evt.target.value)
    setErr('')
  }

  const handleDelete = item => {
    setData({ ...data, emails: data?.emails.filter(i => i !== item) })
  }

  const sendEmail = async () => {
    setIsSending(true)
    const response = await apiPost('tools/send-email', { ...data, body: draftToHtml(convertToRaw(data?.body.getCurrentContent())) })
    if (response.success) {
      setData({ emails: [], subject: '', body: EditorState.createEmpty() })
      setValue('')
      setIsSending(false)
      notify.success('Emails sent successfully')
    }
  }

  const handlePaste = evt => {
    evt.preventDefault()

    const paste = evt.clipboardData.getData('text')
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g)

    if (emails) {
      const toBeAdded = emails.filter(email => !isInList(email))
      setData({ ...data, emails: [...data.emails, ...toBeAdded] })
    }
  }

  const isValid = email => {
    let error = null

    if (isInList(email)) {
      error = `${email} has already been added.`
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`
    }

    if (error) {
      setErr(error)
      return false
    }

    return true
  }

  const isInList = email => {
    return data?.emails.includes(email)
  }

  const isEmail = email => {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email)
  }

  return (
    <Layout title="Send emails">
      <div className="p-4">
        <div className="border p-8">
          <div className="sm:grid sm:grid-cols-3 pb-4 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
            <label htmlFor="about" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              Emails <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <>
                <div className="email-flex-wrap mb-4 flex flex-wrap gap-2 ">
                  {data.emails.map(item => (
                    <div className="email-item" key={item}>
                      {item}
                      <button type="button" className="button" onClick={() => handleDelete(item)}>
                        &times;
                      </button>
                    </div>
                  ))}
                </div>

                <textarea
                  rows={6}
                  className={'input w-full max-w-lg focus:ring-dark-500 focus:border-dark-500 border-gray-300' + (err && ' has-error')}
                  value={value}
                  placeholder="Type or paste email addresses and press `Enter`..."
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  onPaste={handlePaste}
                />

                <div className="font-medium">Email count: {data?.emails?.length}</div>

                {err && <p className="error">{err}</p>}
              </>
            </div>
          </div>

          <div className="sm:grid pb-4 sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              From <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                onChange={e => setData({ ...data, from: e.target.value })}
                value={data?.from}
                type="text"
                name="slug"
                id="slug"
                placeholder="eg: Club3 <support@id.club>"
                className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
              />
              <div className="text-xs text-gray-400 mt-1">Note: email address needs to verified on sendgrid account</div>
            </div>
          </div>

          <div className="sm:grid pb-4 sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              Subject <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                onChange={e => setData({ ...data, subject: e.target.value })}
                value={data?.subject}
                type="text"
                name="slug"
                id="slug"
                placeholder="Email subject"
                className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:grid pb-4 sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              Body <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-lg">
              <Editor
                editorState={data?.body}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="body-editor"
                onEditorStateChange={editorState => setData({ ...data, body: editorState })}
              />
            </div>
          </div>

          <div className="border-t mt-5 pb-6 pt-4">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Show CTA
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Switch
                    value={data?.showCta}
                    onChange={checked => {
                      setData({ ...data, showCta: checked })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {data?.showCta ? (
            <>
              <div className="sm:grid pb-4 sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  CTA Text <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    onChange={e => setData({ ...data, ctaText: e.target.value })}
                    value={data?.ctaText}
                    type="text"
                    name="slug"
                    id="slug"
                    placeholder="eg: View your dashboard"
                    className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:grid pb-4 sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  CTA Link <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    onChange={e => setData({ ...data, ctaLink: e.target.value })}
                    value={data?.ctaLink}
                    type="text"
                    name="slug"
                    id="slug"
                    placeholder="eg: https://...."
                    className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </>
          ) : null}

          <div className="sm:grid pb-4 sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              Footer <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                onChange={e => setData({ ...data, footer: e.target.value })}
                value={data?.footer}
                type="text"
                name="slug"
                id="slug"
                placeholder="Email footer text"
                className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="pt-5 border-t border-gray-200">
            <div className="flex justify-between">
              <button
                disabled={isSending || !data?.emails?.length}
                onClick={() => sendEmail()}
                className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
              >
                {isSending ? (
                  <span className="flex items-start">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.project/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span>Please wait...</span>
                  </span>
                ) : (
                  <span>Send emails</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EmailSender
