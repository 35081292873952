import classNames from 'classnames'
import { CameraIcon } from '@heroicons/react/solid'
import { size, isEmpty, filter, map } from 'lodash'
import Input from 'components/atoms/Input'
import { PlusIcon, TrashIcon } from '@heroicons/react/outline'
import notify from 'components/atoms/Notify'
import { Field } from 'formik'

const SetupWorkspace = ({ workspaceSquareRef, showPreview, setFiles, files, previews, workspaceRef, props }) => {
  return (
    <div className="space-y-6 sm:space-y-5">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:pt-5">
        <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
          Logo
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="flex pb-4 items-center">
            <div className="bg-gray relative h-[120px] w-[120px] border mr-4 border-dashed border-gray-300 rounded-md">
              <input
                id="squareLogo"
                ref={workspaceSquareRef}
                name="squareLogo"
                className="hidden"
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={event => {
                  showPreview('squareLogo', event.target.files[0])
                  setFiles({ ...files, squareLogo: event.target.files[0] })
                }}
              />
              {previews.squareLogo ? (
                <span onClick={() => workspaceSquareRef.current.click()}>
                  <img src={previews.squareLogo} alt="" className="cursor-pointer object-contain h-[120px] w-[120px] rounded" />
                </span>
              ) : (
                <div
                  onClick={() => workspaceSquareRef.current.click()}
                  className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-gray-300 rounded-md  h-full w-full cursor-pointer"
                >
                  {previews.squareLogo ? null : <CameraIcon className="text-gray-400 h-8 w-8" />}
                </div>
              )}
              <span className="absolute -bottom-5 whitespace-nowrap text-[11px] text-gray-400 -left-0">Square ~(500px x 500px)</span>
            </div>
            <div className="bg-gray relative flex items-center justify-center h-[120px] w-[300px] border border-dashed border-gray-300 rounded-md">
              <input
                id="logo"
                ref={workspaceRef}
                name="logo"
                className="hidden"
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={event => {
                  showPreview('logo', event.target.files[0])
                  setFiles({ ...files, logo: event.target.files[0] })
                }}
              />
              {previews.logo ? (
                <span onClick={() => workspaceRef.current.click()}>
                  <img src={previews.logo} alt="" className="cursor-pointer object-contain rounded h-[120px] w-[300px]" />
                </span>
              ) : (
                <div
                  onClick={() => workspaceRef.current.click()}
                  className="bg-white flex p-2 items-center justify-center bg-contain h-full w-full bg-no-repeat bg-cente rounded-md cursor-pointer"
                >
                  {previews.logo ? null : <CameraIcon className="text-gray-400 h-8 w-8" />}
                </div>
              )}
              <span className="absolute -bottom-5 text-[11px] text-gray-400 -left-0">Rectangle ~(240px x 300px)</span>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label htmlFor="first-name" className="block  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Name of the Workspace <span className="text-red-500">*</span>
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <Input
            type="text"
            name="name"
            placeholder="eg. My Workspace"
            id="name"
            autoComplete="name"
            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label htmlFor="first-name" className="block  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Workspace domain <span className="text-red-500">*</span>
        </label>
        <div>
          <div className="mt-1 flex sm:mt-0 sm:col-span-2">
            <div className="relative w-[350px]" aria-describedby="Text input with .id.club appended to the text value.">
              <div
                className="c-input_text_overlay c-input_text_overlay--large"
                data-overlay-prefix=""
                data-overlay-suffix=".id.club"
                aria-hidden="true"
                data-qa="text_overlay_suffix"
              >
                {props.values.subdomain || 'your-app'}
              </div>
              <input
                onChange={e => props.setValues({ ...props.values, subdomain: e.target.value })}
                value={props.values.subdomain}
                data-qa="signin_subdomain_input"
                spellCheck="false"
                min="0"
                max="0"
                width="0"
                aria-describedby="subdomain_hint"
                aria-invalid="false"
                aria-labelledby="subdomain_label"
                aria-required="false"
                aria-label=""
                style={{ marginBottom: 0 }}
                autoComplete="off"
                className={classNames('c-input_text c-input_text--large full_width margin_bottom_100')}
                id="subdomain"
                name="subdomain"
                placeholder="your-app"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label htmlFor="first-name" className="block  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Frontend Domain(s) <span className="text-red-500">*</span>
        </label>
        <div>
          {map(props.values?.domain, (domain, index) => {
            return (
              <div key={index} className="mt-1 mb-3 flex sm:mt-0 sm:col-span-2">
                <span className="inline-flex font-mono h-[38px]  relative  items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                  https://
                </span>
                <input
                  onChange={e => {
                    const domain = props.values.domain
                    domain[index] = e.target.value
                    props.setFieldValue('domain', domain)
                  }}
                  value={domain}
                  placeholder="eg: google.com"
                  type="text"
                  className="rounded-r-md  border-gray-300 h-[38px] font-mono rounded-l-none "
                />
                {index === size(props.values?.domain) - 1 && (
                  <button
                    onClick={() => {
                      const domain = props.values.domain
                      domain.push('')
                      props.setFieldValue('domain', domain)
                    }}
                    type="button"
                    className="ml-3 flex items-center justify-center h-[38px] border border-gray-300 shadow-sm rounded-md relative min-w-[38px]"
                  >
                    <PlusIcon className="h-4 w-4 text-gray-400" />
                  </button>
                )}
                {size(props.values?.domain) !== 1 && (
                  <button
                    onClick={() => {
                      let domain = props.values.domain
                      if (isEmpty(domain[index])) {
                        domain = filter(domain, function (d, i) {
                          return i !== index
                        })
                        props.setFieldValue('domain', domain)
                      } else {
                        notify.deleteConfirm({
                          onConfirm: () => {
                            domain = filter(domain, function (d, i) {
                              return i !== index
                            })
                            props.setFieldValue('domain', domain)
                          },
                          dangerText: 'Delete',
                          title: 'Delete Domain?',
                          message: 'Are you sure you realy want to delete this Domain?',
                        })
                      }
                    }}
                    type="button"
                    className="ml-3 flex items-center justify-center h-[38px] border border-gray-300 shadow-sm rounded-md relative min-w-[38px]"
                  >
                    <TrashIcon className="h-4 w-4 text-gray-400" />
                  </button>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Location
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <Input
            type="text"
            value={props.values?.location || ''}
            placeholder="Address of the workspace organization"
            name="location"
            id="location"
            autoComplete="location"
            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Description
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <Field
            as="textarea"
            id="description"
            value={props.values?.description || ''}
            placeholder="About the workspace"
            name="description"
            rows={6}
            className="max-w-lg shadow-sm block w-full focus:ring-dark-500 focus:border-dark-500 sm:text-sm border border-gray-200 rounded-md"
          />
          <p className="mt-2 text-sm text-gray-500">Write a few sentences about workspace</p>
        </div>
      </div>
    </div>
  )
}

export default SetupWorkspace
