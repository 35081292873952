import { Col, Row } from 'antd'

const NotFound = () => {
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <div>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="clearfix">
              <h1 className="float-left display-3 mr-4">404</h1>
              <h4 className="pt-3">Oops! You are lost.</h4>
              <p className="text-muted float-left">The page you are looking for was not found.</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default NotFound
