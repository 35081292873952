/* eslint-disable */
import classNames from 'classnames'
import { split } from 'lodash'

const PreviewModal = ({ preview }) => {
  const type = split(preview.mimetype, '/')[0]

  return (
    <div className="preview-modal">
      {type === 'image' && <img loading="lazy" src={preview.url} alt="" />}
      {type === 'video' && !preview.isKey && <video controls src={preview.url} alt="" /> /* eslint-disable-line */}
      {type === 'video' && preview.isKey && (
        <iframe
          preload="false"
          title="video"
          controls={true}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen="true"
          poster={preview.thumbnail}
          src={preview.url}
          alt=""
          controlsList="nodownload nofullscreen noremoteplayback"
          className={classNames('object-cover h-[400px] w-full')}
        />
      )}
    </div>
  )
}

export default PreviewModal
