import moment from 'moment'
import { forwardRef, useState } from 'react'
import WithReports from './WithReports'
import { formateDate } from 'utils'
import { toString } from 'lodash'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import useModal from 'hooks/useModal'

const IRLPhoto = ({ user }) => {
  return (
    <div className="shadow-md rounded-lg flex items-center flex-col grid-item h-full w-full relative bg-white" key={user.id}>
      <img className="w-[200px] h-[200px] mt-3" src={user.photo} alt={user.displayName || 'IRL picture'} />
    </div>
  )
}

// eslint-disable-next-line
const EventsLog = forwardRef(({ ...props }, ref) => {
  const history = useHistory()
  const [user, setUser] = useState({})

  const [IRLModal, openIrlModal] = useModal({
    content: <IRLPhoto closeable={true} user={user} />,
    title: 'IRL Picture',
    dimensions: {
      height: '300px',
      width: '300px',
    },
  })

  const columns = [
    {
      name: 'Name',
      width: '10%',
      key: 'name',
      sortable: false,
      rendered: item => {
        return <div className="text-xs font-mono">{item?.event?.name ? <span>{item?.event?.name}</span> : ''}</div>
      },
    },
    {
      name: 'Station',
      width: '10%',
      key: 'station',
      sortable: false,
      rendered: item => {
        return <div className="text-xs font-mono">{item?.event?.name ? <span>{item?.station}</span> : ''}</div>
      },
    },
    {
      name: 'User Id',
      width: '10%',
      key: 'user',
      sortable: false,
      rendered: item => {
        return (
          <div className="text-xs font-mono">
            {' '}
            <span>{item?.user?.id}</span>{' '}
          </div>
        )
      },
    },
    {
      name: 'IRL Photo',
      width: '10%',
      align: 'left',
      key: 'user',
      rendered: item => (
        <img
          role="presentation"
          className={`h-10 w-10 rounded-full ${item?.user?.userPhoto && 'cursor-pointer'}`}
          onClick={() => {
            if (item?.user?.userPhoto) {
              item.user.photo = item?.user?.userPhoto
              setUser(item?.user)
              openIrlModal(true)
            }
          }}
          src={item?.user?.userPhoto || 'https://cdnd.reel8.com/1/profile/3.png'}
          alt=""
        />
      ),
    },
    {
      name: 'Name/Username',
      width: '10%',
      key: 'user',
      sortable: false,
      rendered: item => (
        <span className={`text-xs font-medium text-gray-500 py-1 rounded-md cursor-pointer`} onClick={() => history.push(`/users/edit/${item?.user?.id}/user-profile?t=2`)}>
          {item.user?.displayName || item.user?.username || item.user?.phone || item.user?.email}
        </span>
      ),
    },
    {
      name: 'Email',
      width: '10%',
      key: 'user',
      sortable: false,
      rendered: item => {
        return (
          <div className={`text-xs font-medium text-gray-500 py-1 rounded-md cursor-pointer`} onClick={() => history.push(`/users/edit/${item?.user?.id}/user-profile?t=2`)}>
            {' '}
            <span>{item?.user?.email}</span>{' '}
          </div>
        )
      },
    },
    {
      name: 'Scanner',
      width: '10%',
      key: 'scanner',
      sortable: false,
      rendered: item => <span className={`text-xs font-medium text-gray-500 rounded-md`}>{item.scanner?.displayName}</span>,
    },
    {
      name: 'Scanner Status',
      width: '10%',
      key: 'scannerStatus',
      sortable: false,
      rendered: item => (
        <span
          className={classNames(
            item.entryAllowed === null ? 'bg-yellow-100' : item.scanStatus === 'GRANTED' ? 'bg-green-100' : 'bg-red-100',
            'h-4 w-4 rounded-full flex items-center justify-center'
          )}
          aria-hidden="true"
        >
          <span className={classNames(item.entryAllowed === null ? 'bg-yellow-500' : item.scanStatus === 'GRANTED' ? 'bg-green-400' : 'bg-red-400', 'h-2 w-2 rounded-full')} />
        </span>
      ),
    },
    {
      name: 'Entry Allowed',
      width: '10%',
      key: 'entryAllowed',
      sortable: false,
      rendered: item => {
        return <span className={`text-xs font-medium text-gray-500 py-1 rounded-md`}>{toString(item.entryAllowed) || '-'}</span>
      },
    },
    {
      name: 'Event Date',
      width: '5%',
      key: 'createdAt',
      align: 'right',
      sortable: false,
      rendered: item => (
        <div className="text-sm text-gray-500">
          ({moment(item.createdAt).fromNow()}) {formateDate(moment(item.createdAt))}
        </div>
      ),
    },
  ]

  return (
    <>
      <WithReports {...props} apiURL="events/logs" defaultFileName="EventsLog" forwardedRef={ref} columns={columns} activeFilterName="past_7_days" />
      <IRLModal />
    </>
  )
})

export default EventsLog
