import { size } from 'lodash'

export const cartesian = arraysToCombine => {
  // eslint-disable-next-line
  let totalCounter = {}

  const divisors = []
  for (let i = arraysToCombine.length - 1; i >= 0; i--) {
    divisors[i] = divisors[i + 1] ? divisors[i + 1] * arraysToCombine[i + 1].length : 1
  }

  function getPermutation(n, arraysToCombine, cb) {
    const result = []
    let curArray

    for (let i = 0; i < arraysToCombine.length; i++) {
      curArray = arraysToCombine[i]
      const item = curArray[Math.floor(n / divisors[i]) % curArray.length]
      result.push(item)
    }

    cb(result)
  }

  let numPerms = arraysToCombine[0].length
  for (let i = 1; i < arraysToCombine.length; i++) {
    numPerms *= arraysToCombine[i].length
  }

  const combinations = []
  for (let i = 0; i < numPerms; i++) {
    getPermutation(i, arraysToCombine, result => {
      // eslint-disable-next-line
      result.forEach(i => {
        const c = totalCounter[i] || 0
        totalCounter[i] = c + 1
      })
    })
  }
  for (let i = 0; i < numPerms; i++) {
    getPermutation(i, arraysToCombine, result => {
      combinations.push(result)
    })
  }

  return combinations
}

export const startEndTruncate = (str, length = 5) => {
  if (size(str) > 35) {
    return str.substr(0, length) + '...' + str.substr(str.length - length, str.length)
  }
  return str
}

export const formateDate = momentDate => momentDate.format('yyyy-MM-DD HH:mm:ss Z')

export const getAttributeTotal = selected => {
  switch (selected) {
    case 'Private':
      return 2891
    case 'Sergeant':
      return 1734
    case 'Lieutenant':
      return 1156
    case 'Colonel':
      return 1156
    case 'General':
      return 578
    case 'Joker':
      return 261
    default:
      return 500
  }
}

export const walletLink = walletAddress => {
  return walletAddress ? (
    <a target="_blank" href={`https://${process.env.REACT_APP_ENV === 'production' ? '' : 'goerli.'}etherscan.io/address/${walletAddress}`} rel="noreferrer">
      ({startEndTruncate(walletAddress)})
    </a>
  ) : null
}

export function filterKeyEnter(onClickHandler) {
  return e => {
    if (e?.keyCode === 13) {
      onClickHandler(e)
    }
  }
}

export function accessibleOnClick(onClickHandler) {
  return {
    role: 'button',
    tabIndex: 0,
    onKeyDown: filterKeyEnter(onClickHandler),
    onClick: onClickHandler,
  }
}

export const convertBase64ToBlob = base64Image => {
  // Split into two parts
  // eslint-disable-next-line
  const parts = base64Image.split(';base64,')

  // Hold the content type
  // eslint-disable-next-line
  const imageType = parts[0].split(':')[1]

  // Decode Base64 string
  const decodedData = window.atob(parts[1])

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length)

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i)
  }

  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: imageType })
}
