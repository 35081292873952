import { lazy } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AuthRoute from 'containers/AuthRoute'
import AuthContainer from 'containers/AuthContainer'
import NotFound from 'pages/common/NotFound'
import ScannerEvents from 'pages/wp-scanner/Events'

const LoginPage = lazy(() => import('pages/auth/Login'))

const ScannerRouter = ({ loading, isUser, user, openChangePasswordModal }) => {
  return (
    <Router>
      <AuthContainer loading={loading}>
        <Switch>
          <AuthRoute isUser={isUser} component={<LoginPage />} showSideBar={false} isPublic exact path="/login" />

          {user.scanner && <AuthRoute component={<ScannerEvents openChangePasswordModal={openChangePasswordModal} />} isUser={isUser} path="/events" />}

          <AuthRoute component={<Redirect to="/events" />} isUser={isUser} path="/" />

          {!isUser && <AuthRoute component={<Redirect to="/login" />} isUser={isUser} path="/" />}

          {/* Error Page */}
          <Route component={NotFound} />
        </Switch>
      </AuthContainer>
    </Router>
  )
}

export default ScannerRouter
