export default function ShowFormResponse({ close, formResponse, setFormResponse }) {
  const closeModel = () => {
    setFormResponse(null)
    close()
  }
  return (
    <div>
      {formResponse?.responseData?.map((response, index) => {
        return (
          <div key={index} className="mb-8 space-y-4">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-0">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                {response?.field?.label}:
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">{response?.value}</div>
            </div>
          </div>
        )
      })}

      <div className="mt-4 flex justify-end">
        <button
          onClick={closeModel}
          className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
        >
          Close
        </button>
      </div>
    </div>
  )
}
