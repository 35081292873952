import { forwardRef } from 'react'
import WithReports from './WithReports'
// eslint-disable-next-line
const Campaign = forwardRef(({ ...props }, ref) => {
  const columns = [
    {
      name: 'Source',
      width: '20%',
      key: 'source',
      sortable: true,
      rendered: item => <div className="text-sm text-gray-500">{item.source}</div>,
    },
    {
      name: 'Campaign',
      width: '20%',
      key: 'name',
      sortable: true,
      rendered: item => <div className="text-sm text-gray-500">{item.name}</div>,
    },
    {
      name: 'Medium',
      width: '20%',
      key: 'medium',
      sortable: true,
      rendered: item => <div className="text-sm text-gray-500">{item.medium}</div>,
    },
    {
      name: 'User count',
      width: '20%',
      key: 'count',
      sortable: true,
      rendered: item => {
        return <div className="text-sm text-gray-500">{item.count}</div>
      },
    },
  ]

  return <WithReports {...props} apiURL="campaign-logs/list" defaultFileName="Campaign" forwardedRef={ref} columns={columns} activeFilterName="past_7_days" />
})

export default Campaign
