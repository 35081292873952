import { Transition, Popover as CPopover } from '@headlessui/react'
import { isFunction } from 'lodash'
import { Fragment, useRef } from 'react'

const Popover = ({ children, ref, content, width, custom, triggerOnHover }) => {
  const hoverRef = useRef(null)
  const timeoutDuration = 200
  let timeout

  const closePopover = () => {
    return hoverRef?.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true,
      })
    )
  }

  const onMouseEnter = open => {
    clearTimeout(timeout)
    if (open) return
    return hoverRef?.current?.click()
  }

  const onMouseLeave = open => {
    if (!open) return
    timeout = setTimeout(() => closePopover(), timeoutDuration)
  }

  return (
    <CPopover ref={ref} property="" className="relative outline-none" onMouseLeave={triggerOnHover && onMouseLeave.bind(null, open)}>
      {({ open, placement, close }) => (
        <>
          <CPopover.Button
            className="outline-none"
            ref={hoverRef}
            onMouseEnter={triggerOnHover && onMouseEnter.bind(null, open)}
            onMouseLeave={triggerOnHover && onMouseLeave.bind(null, open)}
          >
            {children}
          </CPopover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <CPopover.Panel
              style={
                custom
                  ? {
                      transform: 'translate(0px, 0px)',
                      width: '590px',
                    }
                  : { width }
              }
              placement="left"
              className={`absolute border rounded border-gray-200 z-10 w-screen shadow-md p-4 bg-white mt-3 transform -translate-y-full ${
                placement === 'right' ? 'left' : 'right'
              }-0 min-w-[200px]`}
            >
              {isFunction(content) ? content(close) : content}
            </CPopover.Panel>
          </Transition>
        </>
      )}
    </CPopover>
  )
}

export default Popover
