import CustomModal from 'components/molecules/Modal'
import React, { useState } from 'react'

export default function useModal({ callback, responsive, content, padding, title, closeable, height, width, customClass, dimensions, description, headerRight }) {
  const [state, setState] = useState(false)

  const open = () => {
    setState(true)
  }

  const close = () => {
    setState(false)
  }

  const childrenWithProps = React.Children.map(content, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { close })
    }
    return child
  })

  const Modal = props => {
    return (
      <CustomModal
        responsive={responsive}
        callback={callback}
        dimensions={dimensions}
        customClass={customClass}
        padding={padding}
        height={height}
        width={width}
        closeable={closeable}
        open={state}
        title={title}
        onClose={close}
        description={description}
        headerRight={headerRight}
        {...props}
      >
        {childrenWithProps}
      </CustomModal>
    )
  }

  return [Modal, open, close]
}
