import notify from 'components/atoms/Notify'
import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import Button from 'components/atoms/Button'
import { startCase } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import useModal from 'hooks/useModal'
import AddEditUserField from 'pages/users/modals/AddEditUserField'
import apiDelete from 'lib/network/apiDelete'

const CustomFields = () => {
  const [fields, setFields] = useState([])
  const [fieldId, setFieldId] = useState(null)
  useEffect(() => {
    fetchFields()
  }, [])

  const fetchFields = async () => {
    const response = await apiGet('user-fields')
    if (response.success) {
      setFields(response.data)
    }
  }

  const deleteConfirm = async id => {
    const response = await apiDelete(`user-field/${id}`)
    if (response.success) {
      notify.success('Field deleted successfully')
      fetchFields()
    }
  }

  const [FieldModal, openModal] = useModal({
    content: <AddEditUserField fetchData={fetchFields} id={fieldId} setId={setFieldId} />,
    title: fieldId ? `Edit User Field` : `Add New User Field`,
    dimensions: {
      height: 'auto',
      width: '800px',
      minHeight: 'auto',
      minWidth: 'auto',
    },
  })

  return (
    <Layout
      headerRight={
        <div>
          <Button
            onClick={() => {
              setFieldId(null)
              openModal()
            }}
          >
            Add New Field
          </Button>
        </div>
      }
      title="User Custom Fields"
      className=""
    >
      <FieldModal />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="overflow-hidden border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th style={{ width: '20%' }} scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Label
                    </th>
                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Placeholder
                    </th>
                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type
                    </th>
                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Updated At
                    </th>
                    <th scope="col" className="relative px-6 py-4">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields?.map(field => {
                    return (
                      <tr key={field.id} className="bg-white border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{startCase(field.label)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{startCase(field.placeholder)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {field.type === 'property' ? <span>{field.propertyType?.name}</span> : startCase(field.type)}
                        </td>
                        <td className="px-6 py-0 whitespace-nowrap text-sm text-gray-500">{moment(field.updatedAt).fromNow()} </td>
                        <td className="relative px-6 py-3 text-right">
                          <span
                            onClick={() => {
                              notify.deleteConfirm({
                                onConfirm: () => deleteConfirm(field.id),
                                dangerText: 'Delete',
                                title: 'Delete Field?',
                                message: 'Are you sure you want to delete this Field?',
                              })
                            }}
                            className="px-4 mr-2 py-1 rounded whitespace-nowrap text-xs font-medium cursor-pointer text-white bg-red-500"
                          >
                            Delete
                          </span>
                          <span
                            onClick={() => {
                              setFieldId(field.id)
                              openModal()
                            }}
                            className="px-4 py-1 rounded whitespace-nowrap text-xs font-medium cursor-pointer text-white bg-dark-400"
                          >
                            Edit
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CustomFields
