import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import EventForm from './EventForm'

const AddEditEvent = ({ readOnly }) => {
  const history = useHistory()
  const [isEdit, setIsEdit] = useState(null)

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      setIsEdit(true)
    }
  }, [id])

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <button
            onClick={() => {
              history.go(-1)
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Go Back
          </button>
        </div>
      }
      title={isEdit ? 'Edit Event' : 'Add New Event'}
      breadcrumbs={[
        { name: 'Event List', pathname: '/events' },
        { name: isEdit ? 'Event Details' : 'Add New Event', pathname: '/event/add', current: true },
      ]}
    >
      <div className="flex p-0  overflow-auto">
        <div className="bg-white flex w-full">
          <div className="w-full">
            <Switch>
              {!readOnly && <Route path="/events/add" component={() => <EventForm id={id} />} />}
              <Route path="/events/edit/:id" component={() => <EventForm readOnly={readOnly} id={id} isEdit={isEdit} />} />
            </Switch>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AddEditEvent
