import { Menu } from '@headlessui/react'
import { IoArrowDown, IoArrowUp } from 'react-icons/io5'
import { Fragment, useState } from 'react'
import { size } from 'lodash'

export default function MultiLevelDropDown({ children, onClick, menu, id }) {
  const [submenuVisible, setSubmenuVisible] = useState(false)
  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        {({ close, open }) => (
          <Fragment>
            <div>
              <Menu.Button onClick={() => setSubmenuVisible(false)} className="z-0 justify-center w-full rounded-md py-4 text-sm text-normal text-gray-700 hover:bg-gray-50">
                {children}
              </Menu.Button>
            </div>
            <Menu.Items className="absolute z-[999999] right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {menu?.map((item, index) => {
                return (
                  <div className="px-1 py-1" key={index}>
                    {size(item?.children) > 0 ? (
                      <>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${active ? 'bg-dark-600 text-white' : 'text-gray-900'} group w-full items-center rounded-md px-2 py-2 text-sm flex justify-between`}
                              onMouseEnter={e => {
                                e.preventDefault()
                                setSubmenuVisible(true)
                              }}
                              onMouseLeave={() => setSubmenuVisible(false)}
                            >
                              {item?.name}
                              {!submenuVisible ? <IoArrowDown className="h-5 w-5" /> : <IoArrowUp className="h-5 w-5" />}
                            </button>
                          )}
                        </Menu.Item>
                        {submenuVisible && (
                          <div
                            onMouseEnter={e => {
                              e.preventDefault()
                              setSubmenuVisible(true)
                            }}
                          >
                            {item?.description && <span className="text-[11px] px-4 text-gray-400">{item?.description}</span>}
                            <Fragment>
                              {item?.children?.map((child, childIndex) => {
                                return (
                                  <Menu.Item key={childIndex} onClick={() => onClick(child, id)}>
                                    {({ active }) => (
                                      <button className={`${active ? 'bg-dark-600 text-white' : 'text-gray-900'} group flex w-full items-center rounded-md px-4 py-2 text-sm`}>
                                        {child.name}
                                      </button>
                                    )}
                                  </Menu.Item>
                                )
                              })}
                            </Fragment>
                          </div>
                        )}
                      </>
                    ) : (
                      <Menu.Item onClick={() => onClick(item, id)}>
                        {({ active }) => (
                          <button className={`${active ? 'bg-dark-600 text-white' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                )
              })}
            </Menu.Items>
          </Fragment>
        )}
      </Menu>
    </div>
  )
}
