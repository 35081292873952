import { CameraIcon } from '@heroicons/react/solid'
import Input from 'components/atoms/Input'
import LoadingIcon from 'components/atoms/LoadingIcon'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import EmptyState from 'components/pageComponents/EmptyState'
import { Form, Formik } from 'formik'
import useParams from 'hooks/useGetParams'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import apiPut from 'lib/network/apiPut'
import { isEmpty, keys, forEach, isArray, isObject, includes, filter, split, xor, some, find } from 'lodash'
import { typeList } from 'pages/smart-contract/Contract'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

export const assetTypes = [
  { name: 'USD $', id: 'usd' },
  { name: 'ERC - 20', id: 'erc20' },
  { name: 'ERC - 721', id: 'erc721' },
  { name: 'ERC - 1155', id: 'erc1155' },
]

const Tier = ({ isEdit, loading, id, tierData, fetchTier }) => {
  const [previews, setPreviews] = useState({})
  const [files, setFiles] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const history = useHistory()
  // const [projectList, setProjectList] = useState(null)

  const [commId, setCommId] = useState(null)

  const [params] = useParams()

  useEffect(() => {
    if (params.id) {
      setCommId(parseInt(params.id, 10))
    }
  }, [params])

  const [contracts, setContracts] = useState([])

  const fetchContracts = async () => {
    const response = await apiGet('contracts')
    setContracts(response?.data)
  }

  useEffect(() => {
    fetchContracts()
  }, [])

  useEffect(() => {
    setPreviews({ logo: tierData?.logo })
  }, [tierData])

  const addTier = async data => {
    setIsSubmitting(true)
    const formData = new FormData()

    if (isEdit) {
      formData.append('id', parseInt(id, 10))
    }

    delete data.id

    forEach(keys(data), key => {
      if (data[key] === 'null' || data[key] === undefined || data[key] === null) delete data[key]
      // eslint-disable-next-line
      if (typeof data[key] === 'string' || typeof data[key] === 'number' || typeof data[key] === 'boolean' || Array.isArray(data[key])) {
        formData.append(key, data[key])
      }
      if (isObject(data[key]) && !isArray(data[key])) formData.append(key, JSON.stringify(data[key]))
    })

    if (files.logo) formData.append('logo', files.logo)

    if (isEdit) {
      const response = await apiPut(`tier/${parseInt(id, 10)}`, formData)
      if (response.success) {
        setIsSubmitting(false)
        notify.success('Tier updated successfully!')
      }
    } else {
      const response = await apiPost('tier', formData)
      if (response.success) {
        setIsSubmitting(false)
        notify.success('Tier added successfully!')
        history.push(`/tiers`)
      }
    }
  }

  const showPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews({ ...previews, [key]: preview })
  }

  const form = useRef(null)
  const tierRef = useRef(null)

  const list = [{ name: 'USD', id: 'usd' }, ...filter(typeList, { type: 'smart-contract' })]

  return (
    <Formik
      ref={form}
      validateOnChange={false}
      initialValues={
        isEdit
          ? {
              name: tierData?.name,
              tierQty: tierData?.tierQty,
              contracts: tierData?.contracts?.map(d => d.id),
              assetType: split(tierData?.assetType, ','),
              notification: tierData?.notification,
            }
          : { community: commId, assetType: [] }
      }
      enableReinitialize
      onSubmit={addTier}
    >
      {props =>
        (!loading && tierData && isEdit) || (!tierData && !isEdit) ? (
          <Form className="space-y-8 mx-auto p-4 rounded-md w-full">
            <div>
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">{!isEdit ? 'Setup Tier' : 'Tier details'}</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Create tier with name, description and logo</p>
                </div>
              </div>
              <div className="mt-6 sm:mt-5">
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                      Icon
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="flex items-center">
                        <input
                          id="logo"
                          ref={tierRef}
                          name="logo"
                          className="hidden"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          onChange={event => {
                            showPreview('logo', event.target.files[0])
                            setFiles({ ...files, logo: event.target.files[0] })
                          }}
                        />
                        {previews.logo ? (
                          <span onClick={() => tierRef.current.click()}>
                            <img src={previews.logo} alt="" className="cursor-pointer h-[80px] rounded" />
                          </span>
                        ) : (
                          <div
                            onClick={() => tierRef.current.click()}
                            className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-2 border-dashed border-gray-300 rounded-md cursor-pointer w-24 h-24"
                          >
                            {previews.logo ? null : <CameraIcon className="text-gray-400 h-8 w-8" />}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="first-name" className="block  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Name of the Tier <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Input
                        type="text"
                        name="name"
                        placeholder="eg. Gold, Silver, Platinum etc"
                        id="name"
                        autoComplete="name"
                        className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                    <label htmlFor="email" className="block text-sm font-medium text-normal text-gray-700 sm:mt-px sm:pt-2">
                      Asset Type
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Selection
                        className="w-[600px]"
                        multiple
                        title="Select Asset Type"
                        onChange={value => {
                          props.setFieldValue('assetType', xor([value.id], props.values?.assetType || []))
                        }}
                        list={list}
                        value={props.values?.assetType}
                      />
                    </div>
                  </div>

                  {some(
                    typeList.map(d => d.id),
                    d => includes(props.values.assetType, d)
                  ) && (
                    <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                      <label htmlFor="email" className="block text-sm font-medium text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Select Token Contract
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Selection
                          className="w-[600px]"
                          multiple
                          title="Select Smart Contract"
                          onChange={value => {
                            props.setFieldValue('contracts', xor([value.id], props.values?.contracts || []))
                          }}
                          list={contracts.filter(d => includes(props.values.assetType, d.contractType))}
                          value={props.values?.contracts}
                        />
                      </div>
                    </div>
                  )}

                  {props.values?.contracts?.length > 0 ? (
                    <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                      <label htmlFor="email" className="block text-sm font-medium text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Min Asset Qty
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        {props.values?.contracts?.length === 0 ? (
                          <span>Select Smart contract from above list</span>
                        ) : (
                          props.values?.contracts?.map((d, i) => {
                            return (
                              <div className="max-w-md mb-4" key={i}>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                    {find(contracts, { id: d })?.name} ({find(contracts, { id: d })?.contractType})
                                  </span>
                                  <input
                                    onChange={e => {
                                      const obj = {
                                        ...props.values?.tierQty,
                                        [d]: e.target.value,
                                      }
                                      props.setFieldValue('tierQty', obj)
                                    }}
                                    value={props.values?.tierQty?.[d] || ''}
                                    type="text"
                                    name="company-website"
                                    id="company-website"
                                    className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="eg. 1.50, 10, 1000"
                                  />
                                </div>
                              </div>
                            )
                          })
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                    <label htmlFor="email" className="block text-sm font-medium text-normal text-gray-700 sm:mt-px sm:pt-2">
                      Setup Notifications
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <fieldset className="space-y-5">
                        <legend className="sr-only">Notifications</legend>
                        <div className="relative flex items-start">
                          <div className="flex h-5 relative top-0.5 items-center">
                            <input
                              id="comments"
                              aria-describedby="comments-description"
                              name="comments"
                              checked={props.values?.notification?.allowed}
                              onChange={e => {
                                props.setFieldValue('notification.allowed', e.target.checked)
                              }}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="comments" className="font-medium text-gray-700">
                              Entry
                            </label>
                            <p id="comments-description" className="text-gray-500">
                              Get notified when a {props.values?.name} user checks in.
                            </p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex h-5 relative top-0.5 items-center">
                            <input
                              checked={props.values?.notification?.denied}
                              onChange={e => {
                                props.setFieldValue('notification.denied', e.target.checked)
                              }}
                              id="candidates"
                              aria-describedby="candidates-description"
                              name="candidates"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="candidates" className="font-medium text-gray-700">
                              Denied
                            </label>
                            <p id="candidates-description" className="text-gray-500">
                              Get notified when a {props.values?.name} user is denied entry.
                            </p>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-start">
                <button
                  type="submit"
                  className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                >
                  {isSubmitting ? (
                    <span className="flex items-start">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.tier/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span>Please wait...</span>
                    </span>
                  ) : (
                    <span>{!isEdit ? 'Add' : 'Update'}</span>
                  )}
                </button>
              </div>
            </div>
          </Form>
        ) : isEmpty(tierData) && !loading ? (
          <EmptyState v2 description="Tier not found" />
        ) : (
          <div className="w-full h-[500px] flex items-center justify-center">
            <LoadingIcon />
          </div>
        )
      }
    </Formik>
  )
}

export default Tier
