import classNames from 'classnames'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import ContentSelection from 'pages/nft/modals/ContentSelection'
import Popover from 'components/molecules/Popover'
import { useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useHistory } from 'react-router-dom'
import useModal from 'hooks/useModal'
import { IoClose, IoLink } from 'react-icons/io5'
import { forEach, groupBy, isArray, isBoolean, isNumber, isString, keys, xor, isObject, split, includes } from 'lodash'
import { CameraIcon, LockClosedIcon, LockOpenIcon } from '@heroicons/react/outline'
import { bytesToSize, getDefaultProject } from 'lib/utils'

const SingleNFTSeries = ({ id }) => {
  const [data, setData] = useState({ currency: 'ETH', price: 0.0, qty: 1 })
  const [projectList, setProjectList] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [content, setContent] = useState(null)
  const [previews, setPreviews] = useState({})
  const [properties, setProperties] = useState([])
  const [nftCreators, setNftCreators] = useState([])

  const [files, setFiles] = useState([])

  useEffect(() => {
    if (id) {
      apiGet(`series/${id}`).then(response => {
        const data = response.data
        setData({
          name: data?.name,
          price: data?.price,
          currency: data?.currency,
          qty: data?.qty,
          serviceFee: data?.serviceFee,
          nftCreator: data?.nftCreator?.id,
          content: data?.nftContent?.map(d => d?.media?.id),
          hidden: data?.nftContent?.filter(d => d?.hidden).map(d => d.id),
          links: data?.nftContent?.map(d => {
            return {
              id: d?.id,
              link: d?.link,
              url: d?.url,
              type: d?.type,
            }
          }),
          project: data?.project?.id,
          properties: data?.meta?.properties?.map(item => {
            return item.id
          }),
        })

        // setContent(response.data.content)
        setPreviews({ image: response.data?.meta?.image?.url })
        // setProperties(response.data.properties)
      })
    }
  }, [id, nftCreators, content, projectList])

  const history = useHistory()

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    const projects = response.data

    setProjectList(projects)
    setData(f => ({ ...f, project: getDefaultProject(projects) }))
  }

  const onDragEnd = (result, props) => {
    if (!result.destination) {
      return
    }

    const items = reorder(data.content || [], result.source.index, result.destination.index)
    setData(f => ({ ...f, content: items }))
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  const fetchContent = async project => {
    const response = await apiGet(`media`, { project })
    setContent(response.data?.media)
  }

  const [PopPrimaryContentModal, openPrimaryModal] = useModal({
    title: 'Select Content',
    closeable: true,
    dimensions: { height: 'calc(80%)', width: 'calc(80vw)' },
    content: <ContentSelection />,
  })

  const contentMapper = (list, props) => {
    // eslint-disable-next-line
    return list?.map(id => content?.find(item => item.id === id))
  }

  const fetchProperties = async () => {
    const response = await apiGet(`properties`)
    setProperties(response?.data)
  }

  const fetchNFTCreators = async () => {
    const response = await apiGet(`users`, { minimal: true, nftCreator: true })
    setNftCreators(response?.data.map(d => ({ ...d, name: d.displayName })))
  }

  useEffect(() => {
    if (data.project) {
      fetchContent(data.project)
    }
  }, [data.project])

  useEffect(() => {
    fetchProjects()
    fetchNFTCreators()
    fetchProperties()
  }, [])

  const showPreviews = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews(previews => ({ ...previews, [key]: preview }))
  }

  const addUpdateNFT = async () => {
    setIsSubmitting(true)

    const formData = new FormData()

    if (files.image) formData.append(`image`, files.image)

    forEach(keys(data), key => {
      if (data[key] === 'null' || data[key] === undefined || data[key] === null) delete data[key]
      if (isString(data[key]) || isNumber(data[key]) || isBoolean(data[key]) || isArray(data[key])) {
        formData.append(key, data[key])
      }
      if (isObject(data[key]) && !isArray(data[key])) formData.append(key, JSON.stringify(data[key]))
    })

    const path = id ? `series/${parseInt(id, 10)}` : 'series'
    const dataToSave = id ? { nftCreator: data?.nftCreator } : formData

    const response = await apiPost(path, dataToSave)
    if (response.success) {
      notify.success(`NFT ${id ? 'updated' : 'added'} successfully!`)
      if (!id) history.push('/series')
    }

    setIsSubmitting(false)
  }

  const imageRef = useRef(null)
  const propertyByGroup = groupBy(properties, 'type.name')

  return (
    <div className="p-4">
      <div className="w-full mb-[120px] flex flex-col">
        <div className="bg-white p-8 rounded-sm w-full border">
          <div className="flex items-center border-gray-200 pb-4 justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Create NFT Series</h3>
              {id ? (
                <p className="mt-1 text-red-600 max-w-2xl text-sm">Edit is not available. All fields are ready only</p>
              ) : (
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Select Project, Name, Price, Content and NFT Creator</p>
              )}
            </div>
          </div>

          <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
            <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              Select Project
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <Selection
                disabled={id}
                className="w-[250px]"
                title="Select Project"
                onChange={value => {
                  setData({ ...data, project: value.id })
                }}
                list={projectList}
                value={data?.project}
              />
            </div>
          </div>

          <div className="sm:grid mt-5 pb-6 sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
            <div>
              <label htmlFor="photo" className="flex items-center text-sm text-gray-700">
                NFT Image
              </label>
            </div>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="flex pb-4">
                <div className="items-center">
                  <input
                    ref={imageRef}
                    className="hidden"
                    type="file"
                    readOnly={id}
                    accept="image/*"
                    onChange={event => {
                      showPreviews('image', event.target.files[0])
                      setFiles({ ...files, image: event.target.files[0] })
                    }}
                  />
                  <div
                    onClick={() => (id ? null : imageRef.current.click())}
                    className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-2 border-dashed border-gray-300 rounded-md cursor-pointer w-[250px] h-[250px]"
                  >
                    {previews.image ? <img alt="" className="object-contain h-full" src={previews.image} /> : <CameraIcon className="text-gray-400 h-8 w-8" />}
                  </div>
                  <span className="text-xs mt-3 block text-gray-500">Use High Resolution Image only</span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
            <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              Name Of the Series
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                placeholder="Name of the Series"
                name="name"
                value={data.name || ''}
                readOnly={id}
                onChange={e => setData({ ...data, name: e.target.value })}
                id="name"
                className="max-w-xs block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
            <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              Price
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="mt-1 max-w-xs relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">
                    {data.currency === 'USD' ? '$' : <img className="h-3 w-3" src="https://cdn3.emoji.gg/emojis/5819-eth.png" alt="" />}
                  </span>
                </div>
                <input
                  type="decimal"
                  name="price"
                  readOnly={id}
                  id="price"
                  step={0.01}
                  onChange={e => setData({ ...data, price: e.target.value })}
                  value={data.price}
                  className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0.00"
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                  <label htmlFor="currency" className="sr-only">
                    Currency
                  </label>
                  <select
                    onChange={e => setData({ ...data, currency: e.target.value })}
                    value={data.currency || ''}
                    id="currency"
                    name="currency"
                    readOnly={id}
                    className="focus:ring-primary-500 focus:border-primary-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                  >
                    <option>USD</option>
                    <option>ETH</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
            <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              Service Fee
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="mt-1 max-w-xs relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">
                    {data.currency === 'USD' ? '$' : <img className="h-3 w-3" src="https://cdn3.emoji.gg/emojis/5819-eth.png" alt="" />}
                  </span>
                </div>
                <input
                  type="decimal"
                  name="serviceFee"
                  readOnly={id}
                  id="serviceFee"
                  step={0.01}
                  onChange={e => setData({ ...data, serviceFee: e.target.value })}
                  value={data.serviceFee || ''}
                  className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Service Fee"
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                  <label htmlFor="currency" className="sr-only">
                    Currency
                  </label>
                  <select
                    onChange={e => setData({ ...data, currency: e.target.value })}
                    value={data.currency || ''}
                    id="currency"
                    name="currency"
                    readOnly={id}
                    disabled={true}
                    className="focus:ring-primary-500 focus:border-primary-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                  >
                    <option>USD</option>
                    <option>ETH</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
            <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              Quantity
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                readOnly={id}
                type="number"
                placeholder="Quantity"
                value={data.qty || ''}
                onChange={e => setData({ ...data, qty: e.target.value })}
                className="max-w-xs block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <PopPrimaryContentModal
            content={content}
            multiple
            values={data?.content}
            onChange={values => {
              setData({ ...data, content: values })
            }}
          />

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid mt-5 pb-8 sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="last-name" className="block text-sm font-regular text-gray-700 sm:mt-px sm:pt-2">
                Media Content
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                {!(data?.content?.length && data?.content.length > 0 && content.length > 0) ? (
                  <button
                    disabled={!data?.project || id}
                    onClick={openPrimaryModal}
                    type="button"
                    className="mr-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                  >
                    Select Files from Library
                  </button>
                ) : (
                  <>
                    <DragDropContext onDragEnd={items => onDragEnd(items)}>
                      <div className="max-w-md select-none space-y-4">
                        <Droppable droppableId="droppable">
                          {provided => (
                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                              {contentMapper(data?.content)?.map((item, index) => {
                                const type = split(item?.mimetype, '/')[0]

                                const links = data?.links || {}
                                const link = links[item?.id] || {}

                                return (
                                  <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                                    {provided2 => (
                                      <li
                                        ref={provided2.innerRef}
                                        {...provided2.draggableProps}
                                        {...provided2.dragHandleProps}
                                        style={{ ...provided2.draggableProps.style }}
                                        className="mb-4 rounded-lg shadow relative items-center max-w-lg justify-between cursor-pointer flex"
                                        key={item.id.toString()}
                                      >
                                        <div className="flex">
                                          <div key={index} className="grid-item media-item rounded-lg items-center cursor-pointer w-[80px] h-[80px] flex justify-center">
                                            <div className="relative flex items-center">
                                              {type === 'image' && (
                                                <img
                                                  style={{ maxHeight: 130, objectFit: 'cover', border: `4px solid white` }}
                                                  className={classNames('bg-gray-200 w-[80px] h-[80px] rounded')}
                                                  src={item.thumbnail}
                                                  alt=""
                                                />
                                              )}
                                              {type === 'video' && (
                                                // eslint-disable-next-line
                                                <video
                                                  style={{ objectFit: 'cover', border: `4px solid white}` }}
                                                  className={classNames('bg-black w-[80px] h-[80px] rounded')}
                                                  src={item.url}
                                                  poster={item.thumbnail}
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <div className="ml-3 flex justify-center flex-col">
                                            <p className="text-sm max-w-[300px] flex flex-col font-medium text-gray-900 truncate">
                                              <span>{item?.filename}</span>
                                              <span className="text-xs text-gray-500">{bytesToSize(item?.size)}</span>
                                              {includes(data.hidden, item.id) && (
                                                <span className="text-[11px] w-[200px] font-normal text-gray-400 whitespace-normal">Available only for token owners</span>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="flex absolute justify-end items-end right-2">
                                          {type === 'image' && includes(data.hidden, item.id) ? (
                                            <Popover
                                              width={300}
                                              content={close => {
                                                return (
                                                  <div className="flex flex-col">
                                                    <div className="mt-[2px] mb-4 width-[200px]">
                                                      <Selection
                                                        title="Link Type"
                                                        onChange={value => {
                                                          setData({ ...data, links: { ...data.links, [item?.id]: { ...link, type: value.id } } })
                                                        }}
                                                        list={[
                                                          { name: 'Generic Link', id: 'generic' },
                                                          { name: 'MetaVerse Link', id: 'metaverse' },
                                                        ]}
                                                        value={link.type}
                                                      />
                                                    </div>
                                                    <div className="mt-[2px] mb-4 width-[200px]">
                                                      <input
                                                        onChange={e => {
                                                          setData({ ...data, links: { ...data.links, [item?.id]: { ...link, text: e.target.value } } })
                                                        }}
                                                        value={link.text}
                                                        type="text"
                                                        placeholder="Link button text"
                                                        autoComplete="new-password"
                                                        className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                                      />
                                                    </div>
                                                    <div className="mt-1 sm:mt-0 width-[200px]">
                                                      <input
                                                        onChange={e => {
                                                          setData({ ...data, links: { ...data.links, [item?.id]: { ...link, url: e.target.value } } })
                                                        }}
                                                        value={link.url}
                                                        type="text"
                                                        placeholder="Link Url"
                                                        autoComplete="new-password"
                                                        className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                                      />
                                                    </div>

                                                    <button
                                                      className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                                                      onClick={() => close()}
                                                    >
                                                      Okay
                                                    </button>
                                                  </div>
                                                )
                                              }}
                                            >
                                              <button className="shadow mr-2 p-2 border rounded-full">
                                                <IoLink className="h-4 text-gray-700 w-4" />
                                              </button>
                                            </Popover>
                                          ) : null}
                                          <button
                                            className={classNames(
                                              'text-sm shadow mr-2 border p-2 bg-white select-none rounded-full',
                                              includes(data.hidden, item.id) ? 'bg-green-600' : ''
                                            )}
                                            onClick={() => {
                                              setData({ ...data, hidden: xor(data.hidden || [], [item.id]) })
                                            }}
                                          >
                                            <span>
                                              {includes(data.hidden, item.id) ? (
                                                <LockClosedIcon className="h-4 w-4 text-white" />
                                              ) : (
                                                <LockOpenIcon className="h-4 w-4 text-gray-700" />
                                              )}
                                            </span>
                                          </button>
                                          <button
                                            className="shadow p-2 border rounded-full"
                                            onClick={() => setData({ ...data, content: data.content?.filter(d => d !== item.id) })}
                                          >
                                            <IoClose className="h-4 text-gray-700 w-4" />
                                          </button>
                                        </div>
                                      </li>
                                    )}
                                  </Draggable>
                                )
                              })}
                            </ul>
                          )}
                        </Droppable>
                        <span onClick={openPrimaryModal} className="text-xs text-primary-500 font-semibold mt-0 pt-2 block cursor-pointer">
                          Add More
                        </span>
                      </div>
                    </DragDropContext>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="last-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              Properties (Metadata) <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              {keys(propertyByGroup).map(group => {
                return (
                  <div className="bg-gray-50 select-none max-w-lg p-3 border rounded-md mb-4" key={group}>
                    <span className="font-semibold">{group}</span>
                    <div className="flex mt-2">
                      {propertyByGroup[group].map(property => {
                        return (
                          <div
                            key={property.name}
                            onClick={() => {
                              setData({ ...data, properties: xor([property.id], data.properties) })
                            }}
                            className="relative flex mr-4 items-start"
                          >
                            <div className="flex items-center h-5">
                              <input
                                disabled={id}
                                aria-describedby="comments-description"
                                checked={includes(data.properties, property.id)}
                                type="checkbox"
                                onChange={() => {
                                  setData({ ...data, properties: xor([property.id], data.properties) })
                                }}
                                className="focus:ring-dark-500 h-4 w-4 text-dark-600 border-gray-300 rounded"
                              />
                            </div>
                            <div className="ml-2 text-sm">
                              <label htmlFor="comments" className="font-regular text-gray-700">
                                {property.name}
                              </label>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
            <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
              NFT Creator
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  className="max-w-xs"
                  title="Select Creator"
                  onChange={value => {
                    setData({ ...data, nftCreator: value.id })
                  }}
                  list={nftCreators}
                  value={data?.nftCreator}
                />
              </div>
            </div>
          </div>

          <div className="pt-2">
            <div className="flex justify-between">
              <button
                onClick={() => history.goBack()}
                className={classNames(
                  'mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500'
                )}
              >
                <span>Back</span>
              </button>
              <button
                onClick={() => addUpdateNFT()}
                className={classNames(
                  'mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500'
                )}
              >
                {isSubmitting ? (
                  <span className="flex items-start">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span>Processing</span>
                  </span>
                ) : (
                  <span>{id ? 'Update' : 'Add'}</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleNFTSeries
