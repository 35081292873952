import Layout from 'containers/Layout'
import { useHistory } from 'react-router-dom'
import useModal from 'hooks/useModal'
import { useEffect, useState } from 'react'
import apiGet from 'lib/network/apiGet'
import AddEditProperty from 'pages/properties/modals/AddEditProperty'
import Property from './Property'
import { find, get, split, startCase, toLower } from 'lodash'
import Button from 'components/atoms/Button'
import { useSelector } from 'react-redux'

const Properties = () => {
  const history = useHistory()
  const [properties, setProperties] = useState(null)
  const [propertyId, setPropertyId] = useState(null)

  const [path, setPath] = useState(split(history.location.pathname, '/').pop())

  const types = useSelector(state => get(state, 'user.types', {}))
  const type = find(types?.properties, function (property) {
    return path === toLower(property.name)
  })

  useEffect(() => {
    history.listen(location => {
      setPath(split(location.pathname, '/').pop())
    })
  }, [])

  const fetchProperties = async () => {
    const response = await apiGet(`properties/${path}`)
    setProperties(response?.data)
  }

  useEffect(() => {
    fetchProperties()
  }, [path])

  const [PropertyModal, openPropertyModal] = useModal({
    content: <AddEditProperty type={type} fetchData={fetchProperties} id={propertyId} setPropertyId={setPropertyId} />,
    title: propertyId ? `Edit ${path}` : `Add New ${path}`,
    dimensions: {
      height: 'auto',
      width: '600px',
      maxHeight: 'auto',
      maxWidth: '600px',
    },
  })

  const openModal = () => {
    setPropertyId(null)
    openPropertyModal()
  }

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Button onClick={openModal}>Add {startCase(path)}</Button>
        </div>
      }
      title={`List of ${startCase(path)}`}
    >
      <Property type={type} data={properties} setId={setPropertyId} openModal={openPropertyModal} fetch={fetchProperties} />
      <PropertyModal width={600} height={400} />
    </Layout>
  )
}

export default Properties
