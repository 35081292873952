import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import moment from 'moment'
import { find, get } from 'lodash'
import apiGet from 'lib/network/apiGet'
import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
// import notify from 'components/atoms/Notify'
// import apiPost from 'lib/network/apiPost'
import Button from 'components/atoms/Button'
import { useHistory } from 'react-router-dom'
import PopupMenu from 'components/molecules/PopupMenu'
import { IoEllipsisVertical } from 'react-icons/io5'

export const typeList = [
  {
    name: 'ERC-20 - Currency Tokens',
    id: 'erc20',
    type: 'smart-contract',
  },
  {
    name: 'ERC-721 - NFT Tokens',
    id: 'erc721',
    type: 'smart-contract',
  },
  {
    name: 'ERC-1155 - NFT Tokens',
    id: 'erc1155',
    type: 'smart-contract',
  },
  {
    name: 'Payment Contract',
    id: 'payment',
  },
]

const Contract = () => {
  const [loading, setLoading] = useState(false)
  const [contracts, setContracts] = useState([])
  const history = useHistory()

  // const reFetch = async () => {
  //   setLoading(true)
  //   const response = await apiGet('contracts')
  //   setContracts([...response.data])

  //   setLoading(false)
  // }

  useEffect(() => {
    fetchContracts()
  }, [])

  const fetchContracts = async () => {
    const response = await apiGet('contracts')
    setContracts(response?.data)
    setLoading(false)
  }

  const addNewContract = () => {
    history.push('/smart-contracts/add')
  }

  const menuOptions = [[{ name: 'Edit', key: 'edit' }]]

  const menuClickHandler = (option, id, index) => {
    if (option.key === 'edit') {
      history.push(`/smart-contracts/edit/${id}`)
    }
  }

  const columns = [
    {
      name: 'Name',
      width: '20%',
      key: 'name',
      sortable: true,
      rendered: item => {
        return (
          <div
            onClick={() => {
              history.push(`/smart-contracts/edit/${item.id}`)
            }}
            className="cursor-pointer"
          >
            {item.name}
          </div>
        )
      },
    },
    {
      width: '10%',
      name: 'Address',
      sortable: true,
      key: 'campaign',
      rendered: (item, index) => (
        <a
          target="_blank"
          href={`https://${process.env.REACT_APP_ENV === 'production' ? '' : 'goerli.'}etherscan.io/address/${item.address}`}
          rel="noreferrer"
          className="flex items-center"
        >
          {item.address}
        </a>
      ),
    },
    {
      width: '10%',
      name: 'Type',
      sortable: true,
      key: 'locale',
      rendered: (item, index) => <div className="flex items-center">{find(typeList, { id: item.contractType })?.name || 'Default'}</div>,
    },
    {
      width: '10%',
      name: 'Tokens',
      key: 'tokens',
      sortable: true,
      rendered: item => {
        return item.contractType === 'erc721' ? (
          <span className="cursor-pointer text-indigo-600" onClick={() => history.push(`/smart-contracts/${item.id}/tokens`)}>
            Synced Tokens ({item.tokenCount})
          </span>
        ) : null
      },
    },
    {
      width: '10%',
      name: 'Created',
      align: 'right',

      key: 'created',
      sortable: true,
      rendered: item => {
        const createdTime = get(item, 'createdAt', '')
        const createdAt = createdTime ? moment(createdTime).fromNow() : 'Registered'
        return createdAt
      },
    },
    {
      width: '10%',
      name: 'Actions',
      align: 'right',
      sortable: false,
      rendered: (item, index) => (
        <PopupMenu onClick={option => menuClickHandler(option, item.id, index)} menu={menuOptions}>
          <IoEllipsisVertical className="h-5 w-5" aria-hidden="true" />
        </PopupMenu>
      ),
    },
  ]

  return (
    <Layout
      breadcrumbs={[{ name: 'Smart Contract List', pathname: '/contracts' }]}
      toolbar={<div className="mr-6 w-full justify-end flex"></div>}
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Button onClick={addNewContract} type="button">
            Add Contract
          </Button>
        </div>
      }
      title=""
    >
      <div className="flex flex-col">
        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                {!loading ? (
                  <>{contracts?.length === 0 ? <EmptyState v2 description="No contracts found" /> : <Table hasMore={false} data={contracts} columns={columns} />}</>
                ) : (
                  <div className="w-full h-[200px] border-0 flex justify-center items-center">
                    <LoadingIcon />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default Contract
