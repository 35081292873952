import Input from 'components/atoms/Input'
import { Form, Formik } from 'formik'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useState } from 'react'
import notify from 'components/atoms/Notify'
import { encrypt, decrypt } from 'lib/utils'
import Selection from 'components/molecules/Selection'

const AddEditCredential = ({ credentials, close, id, setId, fetchData, isWizard, workspaceId }) => {
  const [credentialData, setCredentialData] = useState(null)

  useEffect(() => {
    if (id) {
      fetchCredential()
    }
  }, [id])

  const fetchCredential = async () => {
    const data = {}
    if (isWizard && workspaceId) data.workspaceId = workspaceId
    const response = await apiGet(`creds/${id}`, data)
    setCredentialData(response.data)
  }

  const saveTypeData = async credData => {
    const EncryptedFormData = {
      ...credData,
      value: encrypt(credData?.value),
    }

    if (isWizard && workspaceId && !id) EncryptedFormData.workspaceId = workspaceId

    const data = await apiPost(id ? `creds/${id}` : 'creds', EncryptedFormData)
    if (data.success) {
      notify.success('Credentials saved successfully')
      fetchData()
      setId(null)
      close()
    }
  }

  const initialValues = {
    name: credentialData?.name,
    group: credentialData?.group,
    value: decrypt(credentialData?.value),
  }

  const creds = [
    { name: 'Google Client ID', group: 'social', id: 'google-client-id' },
    { name: 'Google Client Secret', group: 'social', id: 'google-client-secret' },
    { name: 'Discord Client ID', group: 'social', id: 'discord-client-id' },
    { name: 'Discord Client Secret', group: 'social', id: 'discord-client-secret' },
    { name: 'Twitter Consumer Key', group: 'social', id: 'twitter-consumer-key' },
    { name: 'Twitter Consumer Secret', group: 'social', id: 'twitter-consumer-secret' },
    { name: 'SendGrid API Key', group: 'email', id: 'sendgrid-api-key' },
    { name: 'Mail From', group: 'email', id: 'mail-from' },
    { name: 'Report Issue To', group: 'email', id: 'report-issue-to' },
    { name: 'Bucket Name', group: 'bucket', id: 'bucket-name' },
    { name: 'Bucket Access Key', group: 'bucket', id: 'bucket-access-key' },
    { name: 'Bucket Secret Key', group: 'bucket', id: 'bucket-secret-key' },
    { name: 'Bucket Endpoint', group: 'bucket', id: 'bucket-endpoint' },
    { name: 'Bucket URL', group: 'bucket', id: 'bucket-url' },
    { name: 'Web URL', group: 'application', id: 'web-url' },
    { name: 'Admin Wallet', group: 'web3', id: 'adminWallet' },
    { name: 'Admin Mnemonic', group: 'web3', id: 'adminMnemonic' },
    { name: 'Moonpay Publishable Key', group: 'moonpay', id: 'moonpay-publishable-key' },
    { name: 'Moonpay Secret Key', group: 'moonpay', id: 'moonpay-secret-key' },
    { name: 'Twilio Account ID', group: 'twilio', id: 'twilio-account-id' },
    { name: 'Twilio Auth Token', group: 'twilio', id: 'twilio-auth-token' },
    { name: 'Twilio Service ID', group: 'twilio', id: 'twilio-service-id' },
  ]

  const groups = [
    { name: 'Social', id: 'social' },
    { name: 'Email', id: 'email' },
    { name: 'Bucket', id: 'bucket' },
    { name: 'Web3', id: 'web3' },
    { name: 'Application', id: 'application' },
    { name: 'Moonpay', id: 'moonpay' },
    { name: 'Twilio', id: 'twilio' },
    { name: 'Others', id: 'others' },
  ]

  return (
    <Formik initialValues={id ? initialValues : { group: groups[0].id }} enableReinitialize={true}>
      {props => (
        <Form>
          <div className="mb-8 space-y-4">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-0">
              <label htmlFor="group" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Group
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection title="Select group" onChange={e => props.setFieldValue('group', e.id)} value={props.values.group} list={groups} />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-0">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  height="140px"
                  disabled={Boolean(id)}
                  title="Select credential type"
                  onChange={e => {
                    props.setFieldValue('name', e.id)
                    props.setFieldValue('group', e.group)
                  }}
                  value={props.values.name}
                  list={creds?.filter(d => d.group === props.values.group)}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-0">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Value
              </label>
              <div className="mt-1 font-mono sm:mt-0 sm:col-span-2">
                <Input
                  type="text"
                  name="value"
                  placeholder="*****************"
                  id="value"
                  autoComplete="new-value"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 flex justify-end">
            <button
              onClick={() => close()}
              className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
            >
              Close
            </button>
            <button
              onClick={() => saveTypeData(props.values)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddEditCredential
