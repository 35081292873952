import Layout from 'containers/Layout'
import useModal from 'hooks/useModal'
import { useEffect, useState } from 'react'
import apiGet from 'lib/network/apiGet'
import AddEditForm from './modals/AddEditForm'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import { setForms } from 'state/redux/actions/user.actions'
import FormList from './FormList'

const Forms = () => {
  const dispatch = useDispatch()
  const [formId, setFormId] = useState(null)

  const [formsData, setFormsData] = useState(null)

  const fetchForms = async () => {
    const response = await apiGet('forms')
    dispatch(setForms(response?.data))
    setFormsData(response?.data)
  }

  useEffect(() => {
    fetchForms()
  }, [])

  const [FormModel, openFormModel] = useModal({
    content: <AddEditForm fetchData={fetchForms} id={formId} setId={setFormId} />,
    title: formId ? `Edit Form` : `Add New Form`,
  })

  const openModal = () => {
    setFormId(null)
    openFormModel()
  }

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Button onClick={openModal} type="button">
            Add Form
          </Button>
        </div>
      }
      title={`Add Form`}
    >
      <FormList data={formsData} setId={setFormId} openModal={openFormModel} fetch={fetchForms} />
      <FormModel />
    </Layout>
  )
}

export default Forms
