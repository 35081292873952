import Header from 'containers/Header'

export default function Layout({ background, toolbar, children, showLogo = true, title, headerRight, breadcrumbs }) {
  const height = '54px'

  return (
    <div className="min-h-full">
      <main className="flex-1">
        {/* Page title & actions */}
        <Header showLogo={showLogo} toolbar={toolbar} pages={breadcrumbs} headerRight={headerRight} title={title} />
        <div
          id="rootScrollable"
          style={{ maxHeight: `calc(100vh - ${height})`, minHeight: `calc(100vh - ${height})`, background: background || 'white' }}
          className="overflow-y-auto"
        >
          {children}
        </div>
      </main>
    </div>
  )
}
