import Input from 'components/atoms/Input'
import { Field, Form, Formik } from 'formik'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useState } from 'react'
import notify from 'components/atoms/Notify'
import { get, startCase } from 'lodash'
import Selection from 'components/molecules/Selection'
import { useSelector } from 'react-redux'

const AddEditFormField = ({ form, close, id, setId, fetchData }) => {
  const [FieldData, setFieldData] = useState(null)

  const forms = useSelector(state => get(state, 'user.forms', []))

  useEffect(() => {
    if (id) {
      fetchField()
    }
  }, [id])

  const fetchField = async () => {
    const response = await apiGet(`field/${id}`)
    setFieldData(response.data)
  }

  const saveFieldData = async FieldData => {
    const data = await apiPost(id ? `fields/${id}` : 'fields/create', FieldData)
    if (data.success) {
      notify.success('Field saved successfully')
      fetchData()
      setId(null)
      close()
    }
  }

  const initialValues = {
    label: FieldData?.label,
    position: FieldData?.position,
    placeholder: FieldData?.placeholder,
    type: FieldData?.type,
    required: FieldData?.required,
    officeUse: FieldData?.officeUse,
    form: FieldData?.form?.id,
  }
  return (
    <Formik initialValues={id ? initialValues : { form: form.id }} enableReinitialize={true}>
      {props => (
        <Form>
          <div className="mb-8 space-y-4">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-0">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Field Label
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  type="text"
                  name="label"
                  placeholder="eg: name, contact no, etc."
                  id="label"
                  autoComplete="new-password"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-0">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Field Position
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  type="number"
                  name="position"
                  placeholder="eg: 1,2,3 etc."
                  id="position"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Field Placeholder
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  type="text"
                  name="placeholder"
                  placeholder="eg: Field Placeholder"
                  id="placeholder"
                  autoComplete="new-password"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Field Type
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  height="200px"
                  list={[
                    { name: 'Text', id: 'text' },
                    { name: 'Textarea', id: 'textarea' },
                    { name: 'Number', id: 'number' },
                    { name: 'Date', id: 'date' },
                    { name: 'Select', id: 'select' },
                    { name: 'Radio', id: 'radio' },
                    { name: 'Checkbox', id: 'checkbox' },
                  ]}
                  title="Select Field Type"
                  onChange={value => {
                    props.setFieldValue('type', value.id)
                  }}
                  value={props.values.type}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Required
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Field type="checkbox" name="required" id="required" autoComplete="required" />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Office Use
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Field type="checkbox" name="officeUse" id="officeUse" autoComplete="officeUse" />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Form
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  disabled
                  onChange={value => props.setFieldValue('form', value.id)}
                  title="Select Form"
                  value={props.values.form}
                  list={forms?.map(item => ({ id: item.id, name: startCase(item.name) }))}
                />
              </div>
            </div>
          </div>

          <div className="mt-4 flex justify-end">
            <button
              onClick={() => close()}
              className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
            >
              Close
            </button>
            <button
              onClick={() => saveFieldData(props.values)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddEditFormField
