import { QRCode } from 'react-qrcode-logo'
import { SketchPicker } from 'react-color'
import { Popover } from 'react-tiny-popover'
import Selection from 'components/molecules/Selection'
import { DownloadIcon } from '@heroicons/react/outline'
import { CameraIcon } from '@heroicons/react/solid'

const QrConfig = ({ qrLogoRef, showPreview, setFiles, files, previews, setQRConfig, qrConfig, setPopup, popup, getFeUrl, downloading, setDownloading, handleDownloadClick }) => {
  return (
    <div className="flex">
      <div className="w-[40%] space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label htmlFor="country" className="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2">
            QR Image
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="bg-gray relative h-[120px] w-[120px] border mr-4 border-dashed border-gray-300 rounded-md">
              <input
                id="qrLogo"
                ref={qrLogoRef}
                name="qrLogo"
                className="hidden"
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={event => {
                  showPreview('qrLogo', event.target.files[0])
                  setFiles({ ...files, qrLogo: event.target.files[0] })
                }}
              />
              {previews.qrLogo ? (
                <span onClick={() => qrLogoRef.current.click()}>
                  <img src={previews.qrLogo} alt="" className="cursor-pointer rounded" />
                </span>
              ) : (
                <div
                  onClick={() => qrLogoRef.current.click()}
                  className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-gray-300 rounded-md cursor-pointer w-full h-full"
                >
                  {previews.qrLogo ? null : <CameraIcon className="text-gray-400 h-8 w-8" />}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label htmlFor="country" className="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2">
            QR Style
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="bg-gray relative max-w-lg border mr-4 300 rounded-md">
              <Selection
                onChange={value => setQRConfig(old => ({ ...old, style: value.id }))}
                value={qrConfig?.style ?? 'dots'}
                list={[
                  { name: 'Round', id: 'dots' },
                  { name: 'Square', id: 'square' },
                ]}
              />
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 pt-2 sm:gap-4 border-t sm:items-start">
          <label htmlFor="country" className="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2">
            QR Color Config
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Popover
              width={100}
              onClickOutside={() => setPopup(b => ({ ...b, backgroundColor: false }))}
              isOpen={popup.backgroundColor}
              positions={['right']} // preferred positions by priority
              content={<SketchPicker picker color={qrConfig.backgroundColor} onChangeComplete={color => setQRConfig(old => ({ ...old, backgroundColor: color.hex }))} />}
            >
              <div className="mt-1 flex rounded-md w-[240px]">
                <span className="inline-flex w-[180px]  items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Background Color</span>

                <div
                  className="border border-gray-300 rounded-r-md bg-gray-50 px-3 text-gray-500 sm:text-sm"
                  style={{ backgroundColor: qrConfig.backgroundColor, width: 60, height: 36 }}
                  onClick={() => setPopup(b => ({ ...b, backgroundColor: !popup.backgroundColor }))}
                ></div>
              </div>
            </Popover>

            <Popover
              width={100}
              onClickOutside={() => setPopup(b => ({ ...b, foregroundColor: false }))}
              isOpen={popup.foregroundColor}
              positions={['right']} // preferred positions by priority
              content={<SketchPicker color={qrConfig.foregroundColor} onChangeComplete={color => setQRConfig(old => ({ ...old, foregroundColor: color.hex }))} />}
            >
              <div className="mt-4 flex rounded-md w-[240px]">
                <span className="inline-flex w-[180px] items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Foreground Color</span>

                <div
                  className="border border-gray-300 rounded-r-md bg-gray-50 px-3 text-gray-500 sm:text-sm"
                  style={{ backgroundColor: qrConfig.foregroundColor, width: 60, height: 36 }}
                  onClick={() => setPopup(b => ({ ...b, foregroundColor: !popup.foregroundColor }))}
                ></div>
              </div>
            </Popover>

            <Popover
              width={100}
              onClickOutside={() => setPopup(b => ({ ...b, topLeftColor: false }))}
              isOpen={popup.topLeftColor}
              positions={['right']} // preferred positions by priority
              content={<SketchPicker color={qrConfig.topLeftColor} onChangeComplete={color => setQRConfig(old => ({ ...old, topLeftColor: color.hex }))} />}
            >
              <div className="mt-4 flex rounded-md w-[240px]">
                <span className="inline-flex w-[180px] items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Top Left Color Outer</span>

                <div
                  className="border border-gray-300 rounded-r-md bg-gray-50 px-3 text-gray-500 sm:text-sm"
                  style={{ backgroundColor: qrConfig.topLeftColor, width: 60, height: 36 }}
                  onClick={() => setPopup(b => ({ ...b, topLeftColor: !popup.topLeftColor }))}
                ></div>
              </div>
            </Popover>

            <Popover
              width={100}
              onClickOutside={() => setPopup(b => ({ ...b, topLeftInnerColor: false }))}
              isOpen={popup.topLeftInnerColor}
              positions={['right']} // preferred positions by priority
              content={<SketchPicker color={qrConfig.topLeftInnerColor} onChangeComplete={color => setQRConfig(old => ({ ...old, topLeftInnerColor: color.hex }))} />}
            >
              <div className="mt-4 flex rounded-md w-[240px]">
                <span className="inline-flex w-[180px] items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Top Left Color Inner</span>

                <div
                  className="border border-gray-300 rounded-r-md bg-gray-50 px-3 text-gray-500 sm:text-sm"
                  style={{ backgroundColor: qrConfig.topLeftInnerColor, width: 60, height: 36 }}
                  onClick={() => setPopup(b => ({ ...b, topLeftInnerColor: !popup.topLeftInnerColor }))}
                ></div>
              </div>
            </Popover>

            <Popover
              width={100}
              onClickOutside={() => setPopup(b => ({ ...b, topRightColor: false }))}
              isOpen={popup.topRightColor}
              positions={['right']} // preferred positions by priority
              content={<SketchPicker color={qrConfig.topRightColor} onChangeComplete={color => setQRConfig(old => ({ ...old, topRightColor: color.hex }))} />}
            >
              <div className="mt-4 flex rounded-md w-[240px]">
                <span className="inline-flex w-[180px] items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Top Right Color</span>

                <div
                  className="border border-gray-300 rounded-r-md bg-gray-50 px-3 text-gray-500 sm:text-sm"
                  style={{ backgroundColor: qrConfig.topRightColor, width: 60, height: 36 }}
                  onClick={() => setPopup(b => ({ ...b, topRightColor: !popup.topRightColor }))}
                ></div>
              </div>
            </Popover>

            <Popover
              width={100}
              onClickOutside={() => setPopup(b => ({ ...b, topRightInnerColor: false }))}
              isOpen={popup.topRightInnerColor}
              positions={['right']} // preferred positions by priority
              content={<SketchPicker color={qrConfig.topRightInnerColor} onChangeComplete={color => setQRConfig(old => ({ ...old, topRightInnerColor: color.hex }))} />}
            >
              <div className="mt-4 flex rounded-md w-[240px]">
                <span className="inline-flex w-[180px] items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Top Right Color</span>

                <div
                  className="border border-gray-300 rounded-r-md bg-gray-50 px-3 text-gray-500 sm:text-sm"
                  style={{ backgroundColor: qrConfig.topRightInnerColor, width: 60, height: 36 }}
                  onClick={() => setPopup(b => ({ ...b, topRightInnerColor: !popup.topRightInnerColor }))}
                ></div>
              </div>
            </Popover>

            <Popover
              width={100}
              onClickOutside={() => setPopup(b => ({ ...b, bottomLeftColor: false }))}
              isOpen={popup.bottomLeftColor}
              positions={['right']} // preferred positions by priority
              content={<SketchPicker color={qrConfig.bottomLeftColor} onChangeComplete={color => setQRConfig(old => ({ ...old, bottomLeftColor: color.hex }))} />}
            >
              <div className="mt-4 flex rounded-md w-[240px]">
                <span className="inline-flex w-[180px] items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Bottom Left Color</span>

                <div
                  className="border border-gray-300 rounded-r-md bg-gray-50 px-3 text-gray-500 sm:text-sm"
                  style={{ backgroundColor: qrConfig.bottomLeftColor, width: 60, height: 36 }}
                  onClick={() => setPopup(b => ({ ...b, bottomLeftColor: !popup.bottomLeftColor }))}
                ></div>
              </div>
            </Popover>

            <Popover
              width={100}
              onClickOutside={() => setPopup(b => ({ ...b, bottomLeftInnerColor: false }))}
              isOpen={popup.bottomLeftInnerColor}
              positions={['right']} // preferred positions by priority
              content={<SketchPicker color={qrConfig.bottomLeftInnerColor} onChangeComplete={color => setQRConfig(old => ({ ...old, bottomLeftInnerColor: color.hex }))} />}
            >
              <div className="mt-4 flex rounded-md w-[240px]">
                <span className="inline-flex w-[180px] items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Top Left Color</span>

                <div
                  className="border border-gray-300 rounded-r-md bg-gray-50 px-3 text-gray-500 sm:text-sm"
                  style={{ backgroundColor: qrConfig.bottomLeftInnerColor, width: 60, height: 36 }}
                  onClick={() => setPopup(b => ({ ...b, bottomLeftInnerColor: !popup.bottomLeftInnerColor }))}
                ></div>
              </div>
            </Popover>

            <span className="mt-4 block font-semibold text-indigo-700" onClick={() => setQRConfig({ backgroundColor: 'white', foregroundColor: 'black' })}>
              Reset All
            </span>
          </div>
        </div>
      </div>

      <div className="w-[30%] ml-4 flex justify-start border-l pl-4">
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="flex pb-4 qr-canvas items-center">
            <QRCode
              logoImage={previews.qrLogo}
              eyeColor={[
                { outer: qrConfig.topLeftColor || qrConfig.foregroundColor || '#000000', inner: qrConfig.topLeftInnerColor || qrConfig.foregroundColor || '#000000' }, // top/left eye
                {
                  outer: qrConfig.topRightColor || qrConfig.foregroundColor || '#000000',
                  inner: qrConfig.topRightInnerColor || qrConfig.foregroundColor || '#000000',
                }, // top/right eye
                {
                  outer: qrConfig.bottomLeftColor || qrConfig.foregroundColor || '#000000',
                  inner: qrConfig.bottomLeftInnerColor || qrConfig.foregroundColor || '#000000',
                }, // bottom/left eye
              ]}
              // enableCORS={true}
              quietZone={10}
              ecLevel="Q"
              // logoOpacity={0.2}
              // logoHeight={250}
              // logoWidth={250}

              removeQrCodeBehindLogo={previews.qrLogo !== null}
              qrStyle={qrConfig.style ?? 'dots'}
              style={{ borderRadius: '20px' }}
              eyeRadius={
                qrConfig?.style === 'dots'
                  ? [
                      [10, 10, 10, 10], // top/left eye
                      [10, 10, 10, 10], // top/right eye
                      [10, 10, 10, 10], // bottom/left
                    ]
                  : undefined
              }
              bgColor={qrConfig.backgroundColor}
              fgColor={qrConfig.foregroundColor || '#000000'}
              size={250}
              enableCORS={true}
              value={getFeUrl()}
              id={'react-qrcode-logo'}
            />
            <button
              onClick={e => {
                setDownloading(true)
                e.preventDefault()
                if (!downloading) {
                  handleDownloadClick()
                }
              }}
              className="border px-2 ml-1 flex justify-center items-center mr-4 h-8 shadow-sm text-sm rounded-md  bg-white border-gray-300"
            >
              {downloading ? (
                <svg className="animate-spin h-5 w-5 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <DownloadIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QrConfig
