import MediaList from 'pages/media/MediaList'
import { useEffect, useState } from 'react'

const ContentSelection = ({ content, multiple, values = [], close, onChange }) => {
  const [items, setItems] = useState(values)

  useEffect(() => {
    setItems(values)
  }, [])

  return (
    <div className="-m-6">
      <MediaList items={items} setItems={setItems} isSelector mediaList={content} />

      {multiple ? (
        <div className="px-6 py-4 border-t flex justify-between items-center border-gray-100">
          <span>Selected: {items.length}</span>
          <button
            onClick={() => {
              onChange(items)
              close()
            }}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
          >
            Continue
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default ContentSelection
