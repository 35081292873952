import Layout from 'containers/Layout'
import { useHistory, useParams } from 'react-router-dom'
import useModal from 'hooks/useModal'
import { useEffect, useState } from 'react'
import apiGet from 'lib/network/apiGet'
import AddEditFormField from './modals/AddEditFormField'
import FormField from './FormField'
import { get, startCase } from 'lodash'
import { useSelector } from 'react-redux'

const FormFields = () => {
  const history = useHistory()
  const [formFields, setFormFields] = useState(null)
  const [fieldId, setFieldId] = useState(null)

  const { id } = useParams()

  const forms = useSelector(state => get(state, 'user.forms', []))
  const form = forms?.find(form => parseInt(id, 10) === form.id) /* eslint-disable-line */

  const fetchFormFields = async () => {
    const response = await apiGet(`fields/${id}`)
    setFormFields(response?.data)
  }

  useEffect(() => {
    fetchFormFields()
    if (!form) {
      return history.push('/forms')
    }
  }, [id])

  const [PropertyModal, openPropertyModal] = useModal({
    content: <AddEditFormField form={form} fetchData={fetchFormFields} id={fieldId} setId={setFieldId} />,
    title: fieldId ? `Edit ${form?.name} field` : `Add New ${form?.name} field`,
  })

  const openModal = () => {
    setFieldId(null)
    openPropertyModal()
  }

  return (
    <Layout
      headerRight={
        <div>
          <span
            onClick={() => {
              history.push(`/forms/${form.id}/response`)
            }}
            className="px-4 py-2 mr-3 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-dark-400"
          >
            Responses
          </span>
          <span
            onClick={() => {
              openModal()
            }}
            className="px-4 py-2 mr-3 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-dark-400"
          >
            Add Field
          </span>
        </div>
      }
      title={`${startCase(form?.name)} Fields`}
    >
      <FormField form={form} data={formFields} setId={setFieldId} openModal={openPropertyModal} fetch={fetchFormFields} />
      <PropertyModal />
    </Layout>
  )
}

export default FormFields
