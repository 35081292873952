import notify from 'components/atoms/Notify'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useState } from 'react'
import LoadingIcon from 'components/atoms/LoadingIcon'
import Layout from 'containers/Layout'
import { XMarkIcon } from '@heroiconsv2/react/24/outline'
import Selection from 'components/molecules/Selection'
import { startCase, reverse, last } from 'lodash'
import EmptyState from 'components/pageComponents/EmptyState'
import Button from 'components/atoms/Button'

const ClubAccess = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [mainData, setMainData] = useState(null)
  const [event, setEvent] = useState(null)

  const [eventData, setEventData] = useState([])

  useEffect(() => {
    fetchEvent()
  }, [])

  const fetchEvent = async () => {
    const response = await apiGet(`events`, { size: 100, page: 1 })
    setEventData(response?.data)

    setEvent(reverse(response?.data)?.[0]?.id)
  }

  useEffect(() => {
    if (event) {
      fetchData()
    }
  }, [event])

  const fetchData = async () => {
    setLoading(true)
    const response = await apiGet(`data/package`, { event })
    const data = response.data

    if (data?.data?.length) {
      setData(data?.data)
      setMainData({ ...data, project: data?.project?.id })
      setLoading(false)
    } else {
      setData([])
      setMainData({ project: data?.project?.id })
      setLoading(false)
    }
  }

  const saveData = () => {
    setIsSubmitting(true)

    const formData = new FormData()
    formData.append('event', event)

    if (data) {
      formData.append('data', JSON.stringify(data))
    }

    if (mainData.id) {
      formData.append('value', mainData?.value || mainData?.slug)
      formData.append('description', mainData?.description || mainData?.slug)
      formData.append('project', mainData?.project)

      if (mainData.type) {
        formData.append('type', mainData.type)
      }

      if (mainData.slug) {
        formData.append('slug', mainData.slug)
      }

      apiPost(`datalist/${mainData.id}`, formData).then(response => {
        if (response.success) {
          notify.success('Data saved successfully')
        }
        setIsSubmitting(false)
      })
    } else {
      formData.append('value', 'package')
      formData.append('description', 'package')
      formData.append('slug', 'package')
      formData.append('type', 'json')

      apiPost(`datalist`, formData).then(response => {
        if (response.success) {
          notify.success('Data saved successfully')
        }
        setIsSubmitting(false)
      })
    }
  }

  return (
    <Layout>
      <div className="p-8">
        <div className="p-8 border bg-white overflow-visible">
          <div>
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Access/Status and Validations</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Name, CLUB Price, Access Name</p>
              </div>
              <div className="flex">
                <Button
                  onClick={() => {
                    setData([...data, { id: last(data)?.id + 1, name: '', price: '', package: '' }])
                  }}
                >
                  Add Tier
                </Button>
                <Selection
                  onChange={value => {
                    setEvent(value.id)
                  }}
                  className="w-64"
                  title="Select event"
                  list={eventData?.map(item => ({
                    id: item.id,
                    name: startCase(item.name),
                  }))}
                  value={event}
                />
              </div>
            </div>

            {!loading && event ? (
              <div className="mt-6 sm:mt-5">
                <div className="mt-4 grid grid-cols-2 gap-4">
                  {[...data].map((item, index) => (
                    <div className="bg-white relative rounded-md border pr-[64px] p-8" key={index}>
                      <span
                        onClick={() => {
                          setData([...data.filter(i => i.id !== item.id)])
                        }}
                        className="absolute top-3 cursor-pointer right-3"
                      >
                        <XMarkIcon className="h-5 w-5" />
                      </span>
                      <div key={item.id} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
                        <label htmlFor="first-name" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Package Name <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            value={item?.name}
                            onChange={e => {
                              const updated = { ...item, name: e.target.value }
                              const items = data.map(i => (i.id === item.id ? updated : i))
                              setData(items)
                            }}
                            type="text"
                            name="name"
                            placeholder="Name of the Package"
                            id="name"
                            autoComplete="name"
                            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div key={item.id} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                        <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Package Access <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            value={item?.package}
                            onChange={e => {
                              const updated = { ...item, package: e.target.value }
                              const items = data.map(i => (i.id === item.id ? updated : i))
                              setData(items)
                            }}
                            type="text"
                            name="name"
                            placeholder="ie: Gold, Silver, Bronze"
                            id="name"
                            autoComplete="name"
                            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div key={item.id} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                        <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          CLUB/FIAT <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 flex gap-2 sm:col-span-2">
                          <input
                            value={item?.price}
                            onChange={e => {
                              const updated = { ...item, price: e.target.value }
                              const items = data.map(i => (i.id === item.id ? updated : i))
                              setData(items)
                            }}
                            type="number"
                            name="price"
                            placeholder="CLUB"
                            id="price"
                            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                          <input
                            value={item?.fiat}
                            onChange={e => {
                              const updated = { ...item, fiat: e.target.value }
                              const items = data.map(i => (i.id === item.id ? updated : i))
                              setData(items)
                            }}
                            type="number"
                            name="fiat"
                            placeholder="FIAT ($)"
                            id="fiat"
                            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div key={item.id} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                        <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          NFT Count <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            value={item?.nftCount}
                            onChange={e => {
                              const updated = { ...item, nftCount: e.target.value }
                              const items = data.map(i => (i.id === item.id ? updated : i))
                              setData(items)
                            }}
                            type="number"
                            name="nftCount"
                            placeholder="ie: 1, 2, 3"
                            id="nftCount"
                            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="pt-5 border-gray-300">
                  <div className="flex justify-between">
                    <button
                      type="button"
                      onClick={() => saveData()}
                      className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                    >
                      {isSubmitting ? (
                        <span className="flex items-start">
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.post/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          <span>Please wait...</span>
                        </span>
                      ) : (
                        <span>Save</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : !event ? (
              <EmptyState v2 description="Please select an event" />
            ) : (
              <div className="w-full flex justify-center items-center h-[200px]">
                <LoadingIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ClubAccess
