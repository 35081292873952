import moment from 'moment'
import { CameraIcon } from '@heroicons/react/outline'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useRef, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import apiDelete from 'lib/network/apiDelete'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { getDefaultProject } from 'lib/utils'

const AddEditDrop = () => {
  const history = useHistory()

  const [projectList, setProjectList] = useState(null)
  const [data, setData] = useState({ name: '', status: 'closed', project: null, contract: null })
  const [contracts, setContracts] = useState([])
  const [previews, setPreviews] = useState({})
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [stageList, setStageList] = useState([])

  const { id } = useParams()

  useEffect(() => {
    fetchContracts()
  }, [])

  useEffect(() => {
    if (id) {
      fetchStages(id)
    } else {
      setStageList([
        { name: 'Closed', id: 'closed' },
        {
          name: 'Paused',
          id: 'paused',
        },
      ])
    }
  }, [id])

  const fetchStages = drop => {
    apiGet('stages', { drop }).then(response => {
      const stages = response.data

      // if (!find(stages, { stage: 'paused' })) {
      //   stages.push({ stage: 'paused', validationMessage: 'Minting Paused' })
      // }

      // if (!find(stages, { stage: 'closed' })) {
      //   stages.push({ stage: 'closed', validationMessage: 'Minting Closed' })
      // }

      // setStages(stages.map(d=>({ id: drop, stages }))
      setStageList(stages.map(d => ({ id: d.stage, name: d.stage })))
    })
  }

  const fetchContracts = async () => {
    const response = await apiGet('contracts')
    setContracts(response?.data)
  }

  useEffect(() => {
    if (id) {
      fetchDrop()
    }
  }, [id])

  const fetchDrop = async () => {
    const response = await apiGet(`drops/${id}`)
    const data = response.data
    setPreviews({ image: data.image?.thumbnail })
    setData({
      price: data?.ETH,
      model: data.model,
      start: data.start,
      end: data.end,
      name: data.name,
      contract: data.contract?.id,
      project: data.project?.id,
      status: data.status,
      reducingIntervalInMins: data.reducingIntervalInMins,
      reducingPercentage: data.reducingPercentage,
      minEthPrice: data.minEthPrice,
      currentEth: data.currentEth,
      slug: data.slug,
    })
  }

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    const projects = response.data

    setProjectList(projects)
    setData({ ...data, project: getDefaultProject(projects) })
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  const saveDrop = async () => {
    setLoading(true)
    const formData = new FormData()

    formData.append('name', data?.name)
    formData.append('status', data?.status)
    formData.append('project', data?.project)
    formData.append('contract', data?.contract)
    formData.append('start', moment(data.start).utc().toISOString())
    formData.append('end', moment(data.end).utc().toISOString())
    formData.append('price', data?.price)
    formData.append('model', data?.model || 'flat')
    formData.append('slug', data?.slug)

    if (data?.model === 'dutch') {
      formData.append('reducingIntervalInMins', data?.reducingIntervalInMins)
      formData.append('reducingPercentage', data?.reducingPercentage)
      formData.append('minEthPrice', data?.minEthPrice)
    }

    if (file) {
      formData.append('image', file)
    }

    const response = await apiPost(id ? `drops/${id}` : 'drops', formData)

    if (response.success) {
      notify.success('NFT Drop saved successfully')
      id ? fetchDrop() : history.push('/drops')
    }
    setLoading(false)
  }

  const showPreviews = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews(previews => ({ ...previews, [key]: preview }))
  }

  const imageRef = useRef(null)

  const deleteDrop = () => {
    notify.deleteConfirm({
      title: 'Delete Drop',
      message: 'Are you sure you want to delete this Drop?',
      onConfirm: async () => {
        const response = await apiDelete(`/drops/${id}`)
        if (response?.success) {
          notify.success('Drop deleted successfully')
        }
      },
    })
  }

  const user = useSelector(state => get(state, 'user.user'))

  return (
    <Layout title="Add New Drop">
      <div className="p-4">
        <div className="w-full mb-[120px] flex flex-col">
          <div className="bg-white p-8 rounded-sm w-full border">
            <div className="flex items-center border-gray-200 pb-4 justify-between">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">NFT Drop</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Select Project, Name, and create nft drop</p>
              </div>
            </div>

            <div className="sm:grid mt-5 pb-6 sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
              <div>
                <label htmlFor="photo" className="flex items-center text-sm text-gray-700">
                  NFT Placeholder Image
                </label>
              </div>

              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex pb-4">
                  <div className="items-center">
                    <input
                      ref={imageRef}
                      className="hidden"
                      type="file"
                      accept="image/*"
                      onChange={event => {
                        showPreviews('image', event.target.files[0])
                        setFile(event.target.files[0])
                      }}
                    />
                    <div
                      onClick={() => imageRef.current.click()}
                      className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-2 border-dashed border-gray-300 rounded-md cursor-pointer w-[250px] h-[250px]"
                    >
                      {previews.image ? <img alt="" className="object-contain h-full" src={previews.image} /> : <CameraIcon className="text-gray-400 h-8 w-8" />}
                    </div>
                    <span className="text-xs mt-3 block text-gray-500">Use High Resolution Image only</span>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Select Project
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  className="w-[250px]"
                  title="Select Project"
                  onChange={value => {
                    setData({ ...data, project: value.id })
                  }}
                  list={projectList}
                  value={data?.project}
                />
              </div>
            </div>
            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Drop Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  placeholder="eg: Kharma Drop"
                  name="name"
                  value={data.name}
                  onChange={e => setData({ ...data, name: e.target.value })}
                  id="name"
                  className="max-w-sm block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Drop Slug
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  placeholder="eg: kharma"
                  name="slug"
                  value={data.slug}
                  onChange={e => setData({ ...data, slug: e.target.value })}
                  id="slug"
                  className="max-w-sm block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Start Date time
              </label>
              <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                <input
                  type="datetime-local"
                  placeholder="Start Date and Time"
                  name="start"
                  value={moment(data.start).format('YYYY-MM-DDTHH:mm')}
                  onChange={e => setData({ ...data, start: e.target.value })}
                  className="max-w-[300px] mr-5 block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                />
                <span className="text-xs block ml-2 mt-2">Local Time: {moment(data.start).format('ddd MMM DD, yyyy, HH:mm A')}</span>
                <span className="text-xs block ml-2 mt-2">UTC Time: {moment(data.start).utc().format('ddd MMM DD, yyyy, HH:mm A')}</span>
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                End Date time
              </label>
              <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                <input
                  type="datetime-local"
                  placeholder="End Date and Time"
                  name="end"
                  value={moment(data.end).format('YYYY-MM-DDTHH:mm')}
                  onChange={e => setData({ ...data, end: e.target.value })}
                  className="max-w-[300px] mr-5 block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                />
                <span className="text-xs block ml-2 mt-2">Local Time: {moment(data.end).format('ddd MMM DD, yyyy, HH:mm A')}</span>
                <span className="text-xs block ml-2 mt-2">UTC Time: {moment(data.end).utc().format('ddd MMM DD, yyyy, HH:mm A')}</span>
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Select Smart Contract
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  className="w-[250px]"
                  title="Select Smart Contract"
                  onChange={value => {
                    setData({ ...data, contract: value.id })
                  }}
                  list={contracts.map(d => ({ id: d.id, name: d.name }))}
                  value={data?.contract}
                />
              </div>
            </div>
            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Pricing Model
              </label>
              <div className="mt-1 gap-3 max-w-2xl sm:mt-0 sm:col-span-1">
                <Selection
                  disabled={!user.isPlatformAdmin}
                  className="w-[250px] h-[40px]"
                  title="Select Pricing Model"
                  onChange={e => {
                    setData({ ...data, model: e.id })
                  }}
                  list={[
                    { name: 'Flat', id: 'flat' },
                    { name: 'Dutch Auction', id: 'dutch' },
                  ]}
                  value={data.model || 'flat'}
                />
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Flat Price Per NFT
              </label>
              <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                <input
                  type="number"
                  placeholder="Price per token"
                  name="price"
                  step="0.001"
                  value={data.price}
                  onChange={e => setData({ ...data, price: e.target.value })}
                  id="price"
                  className="max-w-sm block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                />

                <span className="text-xs ml-2 mt-2 block">
                  {<span>ETH per Token{data.model === 'dutch' && <span>(Starting price for dutch auction, current : {data.currentEth?.toFixed(3)})</span>}</span> ??
                    'Smart Contract not selected'}
                </span>
              </div>
            </div>

            {data?.model === 'dutch' && (
              <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Dutch Pricing (Reduce By)
                </label>
                <div className="mt-1 sm:mt-0 flex sm:col-span-2">
                  <div className="mt-1 mb-0 flex sm:mt-0 mr-4 sm:col-span-2">
                    <input
                      onChange={e => (user.isPlatformAdmin ? setData({ ...data, reducingPercentage: e.target.value }) : null)}
                      value={data.reducingPercentage}
                      placeholder="1"
                      type="number"
                      disabled={!user.isPlatformAdmin}
                      step={1}
                      max={100}
                      min={0}
                      className="rounded-l-md  border-gray-300 h-[38px] font-mono rounded-r-none "
                    />
                    <span className="inline-flex font-mono h-[38px]  relative  items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      %
                    </span>
                  </div>
                  <div className="mt-1 mb-0 flex sm:mt-0 sm:col-span-2">
                    <span className="inline-flex font-mono h-[38px]  relative  items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      PER
                    </span>
                    <input
                      value={data.reducingIntervalInMins}
                      onChange={e => (user.isPlatformAdmin ? setData({ ...data, reducingIntervalInMins: e.target.value }) : null)}
                      placeholder="10"
                      type="number"
                      step={1}
                      disabled={!user.isPlatformAdmin}
                      max={280}
                      min={0}
                      className="border-gray-300 h-[38px] font-mono rounded-none "
                    />
                    <span className="inline-flex font-mono h-[38px]  relative  items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      MIN
                    </span>
                  </div>
                  <div className="mt-1 ml-4 mb-0 flex sm:mt-0 sm:col-span-2">
                    <span className="inline-flex font-mono h-[38px]  relative  items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      UNTIL
                    </span>
                    <input
                      value={data.minEthPrice}
                      onChange={e => (user.isPlatformAdmin ? setData({ ...data, minEthPrice: e.target.value }) : null)}
                      placeholder="10"
                      disabled={!user.isPlatformAdmin}
                      type="number"
                      step="0.001"
                      className="border-gray-300 max-w-[100px] h-[38px] font-mono rounded-none "
                    />
                    <span className="inline-flex font-mono h-[38px]  relative  items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      ETH
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Status
              </label>
              <div className="mt-1 flex gap-3 max-w-md sm:mt-0 sm:col-span-1">
                <Selection
                  disabled={!user.isPlatformAdmin}
                  className="w-[250px] h-[40px]"
                  title="Select Status"
                  onChange={e => {
                    setData({ ...data, status: e.id })
                  }}
                  list={stageList}
                  value={data.status}
                />
              </div>
            </div>
            <div className="pt-2">
              <div className="mt-4 flex space-x-4 justify-end">
                {id && (
                  <button
                    onClick={deleteDrop}
                    type="button"
                    className="inline-flex text-red-500 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                  >
                    Delete Drop
                  </button>
                )}
                <button
                  onClick={() => {
                    history.go(-1)
                  }}
                  className="inline-flex items-center  px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
                >
                  Go Back
                </button>
                <button
                  disabled={loading}
                  onClick={() => saveDrop()}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
                >
                  {loading ? 'Saving...' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AddEditDrop
