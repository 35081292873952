import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { IoClose } from 'react-icons/io5'

import { accessibleOnClick } from 'utils'
import classNames from 'classnames'

const Modal = ({
  height = 300,
  width = 600,
  callback,
  open,
  closeable,
  onClose,
  children,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  responsive,
  onConfirm,
  title,
  confirmDisabled,
  confirmLoading,
  customClass,
  dimensions,
  backDrop = false,
  padding = 'p-6',
  description,
  headerRight,
  ...rest
}) => {
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...rest })
    }
    return child
  })

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className={`fixed  z-10 inset-0 overflow-y-auto`} onClose={() => !backDrop && onClose()}>
        <div className={classNames(responsive && 'responsive-modal', 'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0')}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                responsive && 'responsive-modal-container',
                'modal-container inline-block overflow-hidden align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle'
              )}
            >
              {title && (
                <div className="border-b border-gray-100 py-4 px-6">
                  <div className="flex justify-between items-center">
                    <h3 className="font-bold  text-gray-900">{title}</h3>
                    {headerRight && <div className="flex items-center">{headerRight}</div>}
                    {closeable && (
                      <div className="cursor-pointer w-6 flex items-center justify-center h-6" {...accessibleOnClick(onClose)}>
                        <IoClose className="text-gray-700 h-5 w-5" />
                      </div>
                    )}
                  </div>
                  {description && <span className="text-[12px] text-gray-500">{description}</span>}
                </div>
              )}
              <div style={{ width, height, ...dimensions }} className={`${customClass} overflow-auto ${padding}`}>
                {childrenWithProps}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

Modal.propTypes = {
  cancelText: PropTypes.string,
  children: PropTypes.any,
  confirmDisabled: PropTypes.any,
  confirmLoading: PropTypes.any,
  confirmText: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.any,
  title: PropTypes.any,
}

export default Modal
