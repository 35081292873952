import { Form, Formik } from 'formik'
import Input from 'components/atoms/Input'
import LoadingIcon from 'components/atoms/LoadingIcon'
import apiPost from 'lib/network/apiPost'
import notify from 'components/atoms/Notify'
import changePasswordSchema from 'validations/passwordChange.validation'
import { useState } from 'react'

const ChangePassword = ({ close }) => {
  const [loading, setLoading] = useState(false)
  const setPassword = values => {
    setLoading(true)
    apiPost('user/change-password', values).then(res => {
      if (res.success) {
        notify.success('Password has been changed successfully.')
        close()
      }
      setLoading(false)
    })
  }

  return (
    <>
      <Formik
        validationSchema={changePasswordSchema}
        enableReinitialize
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        onSubmit={setPassword}
      >
        {props => (
          <div className="w-full mb-[60px] flex flex-col">
            <div className="bg-white flex w-full">
              <Form className="space-y-8 w-full mx-auto px-8">
                <div className="space-y-6">
                  <>
                    <div>
                      <label htmlFor="password" className="block text-sm text-normal text-gray-700">
                        Old Password
                      </label>
                      <div className="mt-1">
                        <Input
                          id="oldPassword"
                          name="oldPassword"
                          type="password"
                          autoComplete="current-password"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-dark-500 focus:border-dark-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="password" className="block text-sm text-normal text-gray-700">
                        New Password
                      </label>
                      <div className="mt-1">
                        <Input
                          id="newPassword"
                          name="newPassword"
                          type="password"
                          autoComplete="current-password"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-dark-500 focus:border-dark-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="password" className="block text-sm text-normal text-gray-700">
                        Confirm Password
                      </label>
                      <div className="mt-1">
                        <Input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          autoComplete="current-password"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-dark-500 focus:border-dark-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </>
                  <div>
                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-normal text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                    >
                      {loading && <LoadingIcon className="text-white" />}
                      Set Password
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  )
}

export default ChangePassword
