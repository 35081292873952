import WorkspaceWizard from './WorkspaceWizard'
import WizardWrapper from './wizard/WizardWrapper'

const AddWorkspace = ({
  colors,
  splashRef,
  files,
  tabs,
  isEdit,
  setCurrentTab,
  readOnly,
  currentTab,
  props,
  workspaceSquareRef,
  showPreview,
  previews,
  setFiles,
  workspaceRef,
  setPCustomColor,
  primaryColorRef,
  pCustomColor,
  setSCustomColor,
  secondaryColorRef,
  sCustomColor,
  fontColorRef,
  fCustomColor,
  setFCustomColor,
  qrLogoRef,
  setQRConfig,
  qrConfig,
  setPopup,
  popup,
  setWorkspaceData,
  workspaceData,
  eventConfig,
  setEventConfig,
  getFeUrl,
  downloading,
  setDownloading,
  handleDownloadClick,
  events,
  steps,
  currentStep,
  setCurrentStep,
  isSubmitting,
  setIsWorkspaceCredError,
  isWorkspaceCredError,
}) => {
  return (
    <WorkspaceWizard steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} loading={isSubmitting} isWorkspaceCredError={isWorkspaceCredError}>
      <WizardWrapper
        colors={colors}
        splashRef={splashRef}
        files={files}
        tabs={tabs}
        isEdit={isEdit}
        setCurrentTab={setCurrentTab}
        readOnly={readOnly}
        currentTab={currentTab}
        props={props}
        workspaceSquareRef={workspaceSquareRef}
        showPreview={showPreview}
        previews={previews}
        setFiles={setFiles}
        workspaceRef={workspaceRef}
        setPCustomColor={setPCustomColor}
        primaryColorRef={primaryColorRef}
        pCustomColor={pCustomColor}
        setSCustomColor={setSCustomColor}
        secondaryColorRef={secondaryColorRef}
        sCustomColor={sCustomColor}
        fontColorRef={fontColorRef}
        fCustomColor={fCustomColor}
        setFCustomColor={setFCustomColor}
        qrLogoRef={qrLogoRef}
        setQRConfig={setQRConfig}
        qrConfig={qrConfig}
        setPopup={setPopup}
        popup={popup}
        setWorkspaceData={setWorkspaceData}
        workspaceData={workspaceData}
        eventConfig={eventConfig}
        setEventConfig={setEventConfig}
        getFeUrl={getFeUrl}
        downloading={downloading}
        setDownloading={setDownloading}
        handleDownloadClick={handleDownloadClick}
        events={events}
        steps={steps}
        currentStep={currentStep}
        setIsWorkspaceCredError={setIsWorkspaceCredError}
        isWorkspaceCredError={isWorkspaceCredError}
      />
    </WorkspaceWizard>
  )
}

export default AddWorkspace
