import { Form, Formik, Field } from 'formik'
import apiPost from 'lib/network/apiPost'
import notify from 'components/atoms/Notify'

const ReportIssue = ({ close }) => {
  const reportIssue = async formData => {
    const data = await apiPost('report-issue', formData)
    if (data.success) {
      notify.success(`Response has been sent successfully`)
      close()
    }
  }

  return (
    <Formik initialValues={{ description: '' }} enableReinitialize={true} onSubmit={reportIssue}>
      {props => (
        <Form>
          <div className="mb-8">
            <div className="sm:grid sm:items-start sm:border-gray-200 sm:pt-0">
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-700">
                Description <span className="text-red-500">*</span>
              </label>
              <div className="mt-2 sm:mt-0">
                <Field
                  as="textarea"
                  id="description"
                  placeholder="Description"
                  name="description"
                  rows={7.5}
                  className="shadow-sm block w-full focus:ring-dark-500 focus:border-dark-500 sm:text-sm border border-gray-200 rounded-md"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 flex justify-end">
            <button
              onClick={() => close()}
              className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
            >
              Close
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Send
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ReportIssue
