import CustomInputField from 'components/atoms/CustomInputField'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import { Field, Form, Formik } from 'formik'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import validationSchema from 'validations/globalSlug.validation'

const AddEditRole = ({ close, id, fetchData, setId }) => {
  const [projectList, setProjectList] = useState(null)
  const [record, setRecord] = useState(null)

  const workspace = useSelector(state => get(state, 'user.workspace', null))

  useEffect(() => {
    fetchProjects()
  }, [])

  useEffect(() => {
    if (id) {
      fetchRole()
    }
  }, [id])

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    setProjectList(response?.data)
  }
  const fetchRole = async () => {
    const temp = await apiGet(`slugs/${id}`)
    setRecord({ ...temp?.data, project: temp?.data?.project?.id })
  }

  const initialValues = {
    path: record?.path,
    description: record?.description,
    project: record?.project,
    componentName: record?.componentName,
    redirectToUrl: record?.redirectToUrl,
    type: record?.type,
  }

  const handleSubmit = async record => {
    const data = await apiPost(id ? `slugs/${id}` : 'slugs/create', record)

    if (data.success) {
      notify.success('Slug Saved successfully')
      fetchData()
      setId(null)
      close()
    }
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {props => (
        <Form>
          <div className="mb-6 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm border-gray-300 rounded-md">
            <div className="mb-6">
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">{workspace.domain}/</span>
                  <Field
                    type="text"
                    name="path"
                    placeholder="Add global slug i.e root-path"
                    id="path"
                    component={CustomInputField}
                    autoComplete="_off"
                    className="flex-1 block w-full focus:outline-none focus:shadow-none focus:border-dark-300 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                  />
                </div>
              </div>
            </div>
            <div className="sm:mt-0 sm:col-span-2">
              <Selection
                className="w-100"
                title="Slug type"
                error={props.errors.type}
                onChange={value => props.setFieldValue('type', value?.id)}
                list={[
                  { id: 'redirect', name: 'Redirect' },
                  { id: 'lander', name: 'Lander Component' },
                  { id: 'collection', name: 'Project Collection' },
                ]}
                value={props.values?.type}
              />
            </div>
          </div>

          {props.values.type === 'collection' && (
            <div className="mb-6 max-w-full block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm border-gray-300 rounded-md">
              <div className="sm:mt-0 sm:col-span-2">
                <Selection
                  className="w-100"
                  title="Select Project"
                  onChange={value => props.setFieldValue('project', value?.id)}
                  list={projectList}
                  value={props.values?.project}
                />
              </div>
            </div>
          )}

          {props.values.type === 'lander' && (
            <div className="mb-6">
              <Field
                name="componentName"
                placeholder="Component name i.e Linsanity"
                id="componentName"
                className="max-w-full block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm border-gray-300 rounded-md"
                component={CustomInputField}
              />
            </div>
          )}

          {props.values.type === 'redirect' && (
            <div className="mb-6">
              <Field
                name="redirectToUrl"
                placeholder="Redirect Url i.e https://google.com"
                id="redirectToUrl"
                className="max-w-full block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm border-gray-300 rounded-md"
                component={CustomInputField}
              />
            </div>
          )}

          <div className="mb-6">
            <div className="mt-1">
              <Field
                as="textarea"
                name="description"
                id="description"
                rows={6}
                placeholder="Description"
                className="max-w-full block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={() => {
                setId(null)
                close()
              }}
              className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
            >
              Close
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddEditRole
