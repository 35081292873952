import moment from 'moment'
import { forwardRef } from 'react'
import WithReports from './WithReports'
import toUpper from 'lodash/toUpper'
import { formateDate } from 'utils'
import { Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
// eslint-disable-next-line
const ActivityLogs = forwardRef(({ ...props }, ref) => {
  const history = useHistory()

  const getLogType = type => {
    switch (type) {
      case 'direct':
        return 'ETH Payment'
      case 'moonpay':
        return 'MoonPay Payment'
      case 'moonpay_asset_info':
        return 'Requested Moonpay Asset Info'
      case 'moonpay_mint':
        return 'Moonpay Requested Mint'
      case 'admin':
        return 'Admin Action'
      case 'user':
        return 'User Action'
      case null:
        return 'Admin Action'
      default:
        return type
    }
  }

  const columns = [
    {
      name: 'Type',
      width: '100%',
      key: 'logType',
      sortable: false,
      rendered: item => (
        <div className="text-sm h-[50px] items-center bg-white flex whitespace-normal text-gray-500">
          <div className="flex justify-center h-full w-[380px] bg-[#f3f3f3] items-center">
            <span className="text-[12px] py-2 px-2 font-mono rounded-lg text-gray-500">
              {toUpper(getLogType(item.logType))}{' '}
              <span className="">
                {formateDate(moment(item.createdAt))} {moment(item.createdAt).fromNow()}
              </span>
            </span>
          </div>
          <div className="w-full flex px-3 items-center max-w-[70%]">
            <Tooltip title={item?.userId?.displayName}>
              <span className="cursor-pointer" onClick={item?.userId ? () => history.push(`/users/edit/${item?.userId?.id}/user-profile?t=2`) : null}>
                {!item?.userId?.userProfiles[0]?.profile ? (
                  <span className="h-4 w-4 bg-gray-600 mr-2 flex justify-center items-center rounded-full text-white">{item?.userId?.displayName?.charAt(0)}</span>
                ) : (
                  <img className="h-4 w-4 rounded-full mr-2" src={item?.userId?.userProfiles[0]?.profile} alt="" />
                )}
              </span>
            </Tooltip>

            <span style={{ borderBottom: '1px dashed #dedede' }} className="font-regular text-[12px] block font-mono">
              {item.description ? item.description : item.shortDescription}
            </span>
          </div>
        </div>
      ),
    },
  ]

  const headers = [
    { label: 'Type', key: 'logType' },
    { label: 'Description', key: 'shortDescription' },
    { label: 'Date', key: 'createdAt' },
  ]

  return (
    <WithReports
      filters={false}
      search={false}
      {...props}
      apiURL="activity-logs/list"
      defaultFileName="Activity"
      forwardedRef={ref}
      noTable
      headers={headers}
      columns={columns}
      activeFilterName="past_7_days"
    />
  )
})

export default ActivityLogs
