import { CalendarIcon, DownloadIcon, FilterIcon, SearchIcon } from '@heroicons/react/outline'
// import { Tooltip } from "antd";
import 'antd/lib/tooltip/style/css'
import 'antd/lib/skeleton/style/css'
import moment from 'moment'
import Popover from 'components/molecules/Popover'
import Layout from 'containers/Layout'
import { debounce, get, isEmpty, last, split } from 'lodash'
import { useEffect, useState, useRef } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import ActivityLogs from './ActivityLogs'
import EventsLog from './EventsLog'
import Campaigns from './Campaigns'
import Registrations from './Registrations'
import Transactions from './Transactions'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import useModal from 'hooks/useModal'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import Selection from 'components/molecules/Selection'
import { IoInformationCircleSharp } from 'react-icons/io5'
import ToolTip from 'components/atoms/Tooltip'
import ExternalUsers from './ExternalUsers'
import ExternalTransactions from './ExternalTransactions'

// import Button from "components/atoms/Button";

const Reports = () => {
  const history = useHistory()
  const downloadCSV = useRef()

  const [dateRange, setDateRange] = useState({})
  const [search, setSearch] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [q, setQueryData] = useState({})
  const [downloading, setDownloading] = useState(false)

  const [workspace, setWorkspace] = useState(null)
  const [scanStatus, setScanStatus] = useState(null)
  const currentWorkspace = useSelector(state => get(state, 'user.workspace'))

  const [date, setDate] = useState(null)

  const type = last(split(history.location.pathname, '/'))

  const [path, setPath] = useState(type)
  const user = useSelector(state => get(state, 'user.user'))

  useEffect(() => {
    setPath(path)
  }, [type])

  useEffect(() => {
    if (currentWorkspace?.id !== workspace && currentWorkspace) {
      setWorkspace(currentWorkspace.id)
    }
  }, [currentWorkspace.id])

  const searchFn = debounce(e => {
    setSearch(e.target.value)
  }, 1000)

  function handleChange(event) {
    setSearchKey(event.target.value)
    searchFn(event)
  }

  const formatDate = momentDate => momentDate.format('yyyy-MM-DD HH:mm')

  const handleSelect = range => {
    const sd = formatDate(moment(new Date(range.range1.startDate)))
    const ed = formatDate(moment(new Date(range.range1.endDate)))

    setDate({ ...range.range1 })
    setDateRange({ sd, ed })
  }

  useEffect(() => {
    const qs = queryString.parse(window.location.search)
    if (qs.ed && qs.sd) {
      setDate({ startDate: new Date(qs.sd), endDate: new Date(qs.ed) })
      setDateRange({ sd: qs.sd, ed: qs.ed })
    }

    if (qs.q) {
      setQueryData(JSON.parse(qs.q))
    }
  }, [])

  const regHeaders = [
    { label: 'Id', key: 'id' },
    { label: 'First name', key: 'firstName' },
    { label: 'Last name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'CF IP', key: 'campaign.meta.cfIp' },
    { label: 'CF Country', key: 'campaign.meta.cfCountry' },
    { label: 'walletAddress', key: 'walletAddress' },
    { label: 'name', key: 'campaign.name' },
    { label: 'source', key: 'campaign.source' },
    { label: 'locale', key: 'campaign.locale' },
    { label: 'medium', key: 'campaign.medium' },
    { label: 'Date', key: 'createdAt' },
  ]

  const getConditionalText = type => {
    switch (type) {
      case 'email':
        return 'someone@gmail.com'
      case 'walletAddress':
        return '0x29D7.....D24c3'
      case 'campaign':
        return 'someCampaign'
      case 'source':
        return 'someSource'
      case 'locale':
        return 'someLocale'
      case 'medium':
        return 'someMedium'
      default:
        return 'placeholder'
    }
  }

  const getToolTipContent = type => {
    const placeholder = getConditionalText(type)
    return (
      <div className="w-[300px] py-2 px-2">
        <ul style={{ listStyleType: 'number' }} className="text-sm text-normal text-gray-700 ml-2">
          <li>
            <q className="bg-gray-200 px-1 rounded font-bold">=</q> allows you to match exact ones.
          </li>

          <ul style={{ listStyleType: 'circle' }} className="text-sm text-normal text-gray-700 ml-2 mt-1 mb-3">
            <li>
              <div>
                <span className="px-1 bg-gray-200 rounded text-dark">= &apos;{placeholder}&apos;</span>
              </div>
            </li>
          </ul>
          <li>
            <q className="bg-gray-200 px-1 rounded font-bold">Like</q> allows you to match on similar values
          </li>
          <ul style={{ listStyleType: 'circle' }} className="text-sm text-normal text-gray-700 ml-2 mt-1 mb-3">
            <li>
              <span className="px-1 bg-gray-200 rounded text-dark">LIKE &apos;%{placeholder}%&apos;</span>
            </li>
          </ul>
          <li>
            <q className="bg-gray-200 px-1 rounded font-bold">NOT</q> can put before any conditional statement to select rows for which that statement is false.
          </li>
          <ul style={{ listStyleType: 'circle' }} className="text-sm text-normal text-gray-700 ml-2 mt-1">
            <li>
              <span className="px-1 bg-gray-200 rounded text-dark">NOT LIKE &apos;{placeholder}&apos;</span>
            </li>
          </ul>
        </ul>
      </div>
    )
  }

  const regFilterHeader = [
    { label: 'Email', key: 'email', tableKey: 'user.email', toolTipContent: getToolTipContent('email') },
    { label: 'Wallet', key: 'walletAddress', tableKey: 'user.walletAddress', toolTipContent: getToolTipContent('walletAddress') },
    { label: 'Campaign', key: 'campaign.name', tableKey: 'campaign.name', toolTipContent: getToolTipContent('campaign') },
    { label: 'Source', key: 'campaign.source', tableKey: 'campaign.source', toolTipContent: getToolTipContent('source') },
    { label: 'Locale', key: 'campaign.locale', tableKey: 'campaign.locale', toolTipContent: getToolTipContent('locale') },
    { label: 'Medium', key: 'campaign.medium', tableKey: 'campaign.medium', toolTipContent: getToolTipContent('medium') },
  ]

  const CustomFilters = ({ q }) => {
    const [queries, setQueries] = useState(q)
    return (
      <div>
        <div className="grid grid-cols-6 gap-6">
          {regFilterHeader?.map((item, index) => {
            return (
              <div key={index} className="col-span-6 sm:col-span-3">
                <label htmlFor="first-name" className="block flex text-sm font-medium text-gray-700">
                  {item.label}{' '}
                  <ToolTip content={item?.toolTipContent}>
                    <IoInformationCircleSharp className="h-5 ml-1 w-4 text-gray-400" />
                  </ToolTip>
                </label>
                <input
                  value={queries[item.tableKey]}
                  onChange={e => setQueries({ ...queries, [item.tableKey]: e.target.value })}
                  placeholder="Raw Queries"
                  type="text"
                  className="mt-1 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            )
          })}
        </div>
        <div className="flex border-t border-gray-100 mt-8 pt-4 justify-end">
          <button
            onClick={() => closeModal()}
            type="button"
            className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              closeModal()
              setQueryData(queries)
            }}
            type="button"
            className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          >
            Apply
          </button>
          <button
            disabled={isEmpty(q)}
            onClick={() => {
              // setQueries({});
              setQueryData({})
              closeModal()
            }}
            type="button"
            className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          >
            Clear
          </button>
        </div>
      </div>
    )
  }
  const scannerStatus = [
    { id: 'DENIED', name: 'Denied' },
    { id: 'GRANTED', name: 'Granted' },
  ]
  const popover = useRef()
  const workspaces = useSelector(state => get(state, 'user.workspaces'))

  const [Modal, openModal, closeModal] = useModal({
    dimensions: { minHeight: 'auto', height: 'auto' },
    title: 'Filters',
    width: 'auto',
    height: 'auto',
    description: (
      <span>
        Each field must have a comparison operator, if no operator is added, then <span className="px-1 bg-gray-200 rounded text-dark font-bold">=</span> will be used as the
        default comparison operator
      </span>
    ),
    content: <CustomFilters q={q} />,
  })

  return (
    <Layout
      background="#f4f5f9"
      title={''}
      headerRight={
        <div className="items-center flex sm:mt-0 sm:ml-4">
          {(type === 'registrations' || history.location.pathname === '/reports/external/transactions' || history.location.pathname === '/reports/external/users') && !user.export && (
            <button
              onClick={(e, done) => {
                if (!downloading) {
                  downloadCSV.current.download(setDownloading, response => {})
                }
              }}
              type="button"
              className="border px-2 flex justify-center items-center mr-4 h-8 shadow-sm text-sm rounded-md  bg-white border-gray-300"
            >
              {downloading ? (
                <svg className="animate-spin h-5 w-5 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <DownloadIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              )}
            </button>
          )}

          {type === 'registrations' && (
            <button
              onClick={() => {
                openModal()
              }}
              type="button"
              className="border px-2 flex justify-center items-center mr-4 h-8 shadow-sm text-sm rounded-md text-gray-400 bg-white border-gray-300"
            >
              <FilterIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </button>
          )}
          {
            <Popover
              custom
              placement="left"
              ref={popover}
              content={close => (
                <DateRangePicker
                  weekStartsOn={0}
                  footerContent={
                    <>
                      <button
                        onClick={close}
                        className="w-[100px] mt-4 py-2 mr-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none"
                      >
                        Close
                      </button>
                      <button
                        disabled={!dateRange?.sb && !dateRange?.ed}
                        onClick={() => {
                          setDateRange({ sd: '', ed: '' })
                          setDate({ startDate: '', endDate: '' })
                        }}
                        className="w-[100px] mt-4 py-2 mr-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none"
                      >
                        Clear
                      </button>
                    </>
                  }
                  ranges={[{ ...date }]}
                  onChange={(date, a, b, c) => handleSelect(date, close, a, b, c)}
                />
              )}
            >
              <button
                type="button"
                className="inline-flex w-[250px] h-[30px] justify-center items-center border border-gray-300 shadow-sm text-sm leading-4 font-regular rounded-md text-gray-700 bg-white hover:bg-gray-50 outline-none"
              >
                <CalendarIcon className="h-4 w-4 mr-4 text-gray-500" />
                {dateRange.sd && dateRange.ed ? `${moment(dateRange.sd).format('MMM Do, YYYY')}  -  ${moment(dateRange.ed).format('MMM Do, YYYY')}` : 'Select Date Range'}
              </button>
            </Popover>
          }
          <Modal></Modal>
          {
            <div className="max-w-[200px] ml-4 w-full lg:max-w-[200px]">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  onChange={handleChange}
                  id="search"
                  value={searchKey}
                  name="search"
                  className="block lg:w-[200px] md:w-[200] pl-10 pr-3 py-1 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1  sm:text-sm"
                  placeholder="Search"
                  type="search"
                />
              </div>
            </div>
          }
          {user.isPlatformAdmin ? (
            <div className="ml-4">
              <Selection
                onChange={value => {
                  setWorkspace(value.id)
                }}
                className="min-w-[200px] ml-15"
                value={workspace}
                list={workspaces}
                title="Workspace Filter"
              />
            </div>
          ) : null}
          {type === 'event-logs' ? (
            <div className="ml-4">
              <Selection
                onChange={value => {
                  setScanStatus(value.id)
                }}
                className="min-w-[100px] ml-15"
                value={scanStatus}
                list={scannerStatus}
                title="Scan Status"
              />
            </div>
          ) : null}
        </div>
      }
    >
      <div className="">
        <Switch>
          <Route
            path="/reports/registrations"
            render={() => (
              <Registrations
                isReg
                setSearchKey={setSearchKey}
                workspace={workspace}
                q={q}
                headers={regHeaders}
                search={search}
                dateRange={dateRange}
                ref={downloadCSV}
                setDateRange={setDateRange}
                setScanStatus={setScanStatus}
              />
            )}
          />
          <Route
            path="/reports/campaigns"
            render={() => (
              <Campaigns
                isCampaign
                workspace={workspace}
                search={search}
                dateRange={dateRange}
                setSearchKey={setSearchKey}
                ref={downloadCSV}
                setDateRange={setDateRange}
                setScanStatus={setScanStatus}
              />
            )}
          />
          <Route
            path="/reports/transactions"
            render={() => (
              <Transactions
                isTransaction
                ref={downloadCSV}
                workspace={workspace}
                search={search}
                dateRange={dateRange}
                setSearchKey={setSearchKey}
                setDateRange={setDateRange}
                setScanStatus={setScanStatus}
              />
            )}
          />
          <Route
            path="/reports/activity-logs"
            render={() => (
              <ActivityLogs
                ref={downloadCSV}
                workspace={workspace}
                search={search}
                dateRange={dateRange}
                setSearchKey={setSearchKey}
                setDateRange={setDateRange}
                setScanStatus={setScanStatus}
              />
            )}
          />
          <Route
            path="/reports/event-logs"
            render={() => (
              <EventsLog
                ref={downloadCSV}
                workspace={workspace}
                search={search}
                dateRange={dateRange}
                setSearchKey={setSearchKey}
                setDateRange={setDateRange}
                sStatus={scanStatus}
                setScanStatus={setScanStatus}
              />
            )}
          />
          <Route
            path="/reports/external/users"
            render={() => (
              <ExternalUsers
                ref={downloadCSV}
                workspace={workspace}
                search={search}
                dateRange={dateRange}
                setSearchKey={setSearchKey}
                setDateRange={setDateRange}
                sStatus={scanStatus}
                setScanStatus={setScanStatus}
              />
            )}
          />
          <Route
            path="/reports/external/transactions"
            render={() => (
              <ExternalTransactions
                ref={downloadCSV}
                workspace={workspace}
                search={search}
                dateRange={dateRange}
                setSearchKey={setSearchKey}
                setDateRange={setDateRange}
                sStatus={scanStatus}
                setScanStatus={setScanStatus}
              />
            )}
          />
        </Switch>
      </div>
    </Layout>
  )
}

export default Reports
