import { Form, Formik } from 'formik'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useState } from 'react'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'

const AddEditConfigFlag = ({ close, id, setTypeId, fetchData }) => {
  const [configFlagData, setConfigFlagData] = useState(null)

  useEffect(() => {
    if (id) {
      fetchConfigFlag()
    }
  }, [id])

  const fetchConfigFlag = async () => {
    const response = await apiGet(`/config/${id}`)
    setConfigFlagData(response.data)
  }

  const saveConfigFlagData = async configFlagData => {
    const data = await apiPost(id ? `config/${id}` : 'config/create', configFlagData)
    if (data.success) {
      notify.success(`config falg saved successfully`)
      fetchData()
      setTypeId(null)
      close()
    }
  }
  const initialValues = {
    table: configFlagData?.table,
    value: configFlagData?.value,
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize={true}>
      {props => (
        <Form>
          <div className="mb-8 space-y-4">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-0">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Table
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  list={[
                    { name: 'User', id: 'users' },
                    { name: 'Media', id: 'media' },
                    { name: 'Nft Series', id: 'series' },
                  ]}
                  title="Select table"
                  name="table"
                  onChange={value => {
                    props.setFieldValue('table', value?.id)
                  }}
                  value={props.values?.table}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Value
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  list={[
                    { name: 'Homepage', id: 1 },
                    { name: 'Featured', id: 2 },
                  ]}
                  title="Select value"
                  name="value"
                  onChange={value => {
                    props.setFieldValue('value', value?.id)
                  }}
                  value={props.values?.value}
                />
              </div>
            </div>
          </div>

          <div className="mt-4 flex justify-end">
            <button
              onClick={() => close()}
              className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
            >
              Close
            </button>
            <button
              onClick={() => saveConfigFlagData(props.values)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddEditConfigFlag
