import Layout from 'containers/Layout'
import { useParams, useHistory } from 'react-router-dom'
import useModal from 'hooks/useModal'
import { useEffect, useState } from 'react'
import apiGet from 'lib/network/apiGet'
import ShowFormResponse from './modals/ShowFormResponse'
import FormResponseList from './FormResponseList'
import { useSelector } from 'react-redux'
import { get, startCase } from 'lodash'

const FormResponses = () => {
  const history = useHistory()
  const { id } = useParams()

  const [formResponses, setFormResponses] = useState(null)
  const [formResponse, setFormResponse] = useState(null)

  const forms = useSelector(state => get(state, 'user.forms', []))
  const form = forms?.find(form => parseInt(id, 10) === form.id) /* eslint-disable-line */

  const fetchFormResponses = async () => {
    const response = await apiGet(`form_responses/${id}`)
    setFormResponses(response?.data)
  }

  useEffect(() => {
    fetchFormResponses()
  }, [id])

  const [ResponseModal, openResponseModal] = useModal({
    content: <ShowFormResponse formResponse={formResponse} setFormResponse={setFormResponse} />,
    title: `${formResponse?.name || ''} Response`,
  })

  return (
    <Layout
      title={<span className="whitespace-nowrap">{startCase(form?.name)} Form Responses</span>}
      headerRight={
        <span
          onClick={() => {
            history.go(-1)
          }}
          className="px-4 py-2 mr-3 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-dark-400"
        >
          Go Back
        </span>
      }
    >
      <FormResponseList data={formResponses} setFormResponse={setFormResponse} openModal={openResponseModal} />
      <ResponseModal />
    </Layout>
  )
}

export default FormResponses
