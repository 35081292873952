import moment from 'moment'
import { forwardRef } from 'react'
// import { useHistory } from "react-router-dom";
import WithReports from './WithReports'
import { formateDate, startEndTruncate } from 'utils'
import { Tooltip } from 'antd'
import { IoCopyOutline } from 'react-icons/io5'
import notify from 'components/atoms/Notify'
import { truncate } from 'lodash'
// eslint-disable-next-line
const Registrations = forwardRef(({ ...props }, ref) => {
  // const history = useHistory();

  const copyText = text => {
    navigator.clipboard.writeText(text)
    notify.success('Copied to clipboard', truncate(text, { length: 35 }))
  }

  const onColumnClick = item => {
    window.open(`/users/edit/${item.id}/user-profile`)
  }

  const columns = [
    {
      name: 'Id',
      width: '10%',
      key: 'id',
      sortable: true,
      rendered: item => (
        <div className="text-[13px] text-gray-500" onClick={() => onColumnClick(item)}>
          {item.id}
        </div>
      ),
    },
    {
      name: 'Name',
      key: 'displayName',
      width: '25%',
      sortable: true,
      rendered: item => {
        return (
          <span onClick={() => onColumnClick(item)} className="truncate whitespace-normal text-[13px] cursor-pointer">
            {item.displayName || startEndTruncate(item.walletAddress)}
          </span>
        )
      },
    },
    {
      name: 'Email/Phone',
      key: 'email',
      width: '25%',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              {' '}
              <span className="text-[13px]" onClick={() => onColumnClick(item)}>
                {item?.email}
              </span>
              {item?.email ? (
                <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item?.email)}>
                  <IoCopyOutline className="ml-2" />
                </button>
              ) : null}
            </div>
            <div>
              {' '}
              <span className="text-[13px]" onClick={() => onColumnClick(item)}>
                {item?.phone}
              </span>
              {item.phone ? (
                <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item?.phone)}>
                  <IoCopyOutline className="ml-2" />
                </button>
              ) : null}
            </div>
          </>
        )
      },
    },
    {
      name: 'Wallet',
      key: 'displayName',
      width: '25%',
      sortable: true,
      rendered: item => (
        <Tooltip title={`${item.walletAddress}`}>
          <span onClick={() => onColumnClick(item)} className="text-[13px]">
            {startEndTruncate(item?.walletAddress)}
          </span>
          {item.walletAddress && (
            <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item?.walletAddress)}>
              <IoCopyOutline className="ml-2" />
            </button>
          )}
        </Tooltip>
      ),
    },
    {
      name: 'IP',
      width: '25%',
      key: 'cfCountry',
      sortable: true,
      rendered: item => (
        <span className="text-[13px]  text-gray-700 whitespace-normal" onClick={() => onColumnClick(item)}>
          <div className="whitespace-normal">{item?.campaign?.meta?.cfIp}</div>
        </span>
      ),
    },
    {
      name: 'City',
      width: '25%',
      key: 'cfCountry',
      sortable: true,
      rendered: item => (
        <span className="text-[13px]  text-gray-700 whitespace-normal" onClick={() => onColumnClick(item)}>
          <div className="whitespace-normal">{item?.campaign?.meta?.IPcity && <div className="pr-1 whitespace-nowrap">{item?.campaign?.meta?.IPcity}</div>}</div>
        </span>
      ),
    },
    {
      name: 'State',
      width: '25%',
      key: 'cfCountry',
      sortable: true,
      rendered: item => (
        <span className="text-[13px]  text-gray-700 whitespace-normal" onClick={() => onColumnClick(item)}>
          <div className="whitespace-normal"> {item?.campaign?.meta?.IPSubdivisions && <div className="pr-1 whitespace-nowrap">{item?.campaign?.meta?.IPSubdivisions}</div>}</div>
        </span>
      ),
    },
    {
      name: 'Country',
      width: '25%',
      key: 'cfCountry',
      sortable: true,
      rendered: item => (
        <span className="text-[13px]  text-gray-700 whitespace-normal" onClick={() => onColumnClick(item)}>
          <div className="whitespace-normal">{item?.campaign?.meta?.cfCountry && <div className="pr-1 whitespace-nowrap">{item?.campaign?.meta?.cfCountry}</div>}</div>
        </span>
      ),
    },

    {
      name: 'Campaign',
      width: '10%',
      key: 'cName',
      sortable: true,
      rendered: item => (
        <span className="text-[13px]" onClick={() => onColumnClick(item)}>
          {item?.campaign?.name}
        </span>
      ),
    },
    {
      name: 'Source',
      width: '10%',
      key: 'cSource',
      sortable: true,
      rendered: item => (
        <span className="text-[13px]" onClick={() => onColumnClick(item)}>
          {item?.campaign?.source}
        </span>
      ),
    },
    {
      name: 'Medium',
      width: '10%',
      key: 'cMedium',
      sortable: true,
      rendered: item => (
        <span className="text-[13px]" onClick={() => onColumnClick(item)}>
          {item?.campaign?.medium}
        </span>
      ),
    },
    {
      name: 'Registered',
      width: 'auto',
      key: 'createdAt',
      sortable: true,
      rendered: item => (
        <div className="text-[13px] text-gray-500 overflow-scroll " onClick={() => onColumnClick(item)}>
          {formateDate(moment(item.createdAt))}
        </div>
      ),
    },
  ]

  return (
    <WithReports
      // onRowClick={(item) => window.open(`/users/edit/${item.id}/user-profile`)}
      {...props}
      apiDownloadURL="registrations/csv"
      apiURL="registrations/list"
      defaultFileName="Registrations"
      forwardedRef={ref}
      columns={columns}
    />
  )
})

export default Registrations
