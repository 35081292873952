export const useReadonly = readOnly => {
  if (readOnly) {
    // set all fields to readonly in the entire page using document

    const inputs = document.getElementsByTagName('input')
    const labels = document.getElementsByTagName('label')
    const files = document.querySelectorAll('input[type=file]')
    const clickable = document.querySelectorAll('#clickable')
    const buttons = document.querySelectorAll('#rootScrollable button')

    // loop through each input element and set the readonly attribute
    for (let i = 0; i < files?.length; i++) {
      files[i].disabled = true
    }

    // loop through each input element and set the readonly attribute
    for (let i = 0; i < inputs?.length; i++) {
      inputs[i].readOnly = true
    }

    for (let i = 0; i < labels?.length; i++) {
      labels[i].style.pointerEvents = 'none'
    }

    for (let i = 0; i < clickable?.length; i++) {
      clickable[i].style.pointerEvents = 'none'
    }

    for (let i = 0; i < buttons?.length; i++) {
      buttons[i].disabled = true
    }

    // find tds and disable pointer events
    const tds = document.querySelectorAll('td')
    for (let i = 0; i < tds?.length; i++) {
      tds[i].style.pointerEvents = 'none'
    }
  }
}
