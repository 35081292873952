import { Menu, Transition } from '@headlessui/react'
import { PlusIcon, SelectorIcon } from '@heroicons/react/outline'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { includes, startCase } from 'lodash'
import { Fragment } from 'react'
import 'antd/es/tooltip/style/css'
import { useHistory } from 'react-router-dom'
import { setCookie } from 'nookies'

const BottomMenu = ({ workspaces, user, handleLogout, currentWorkspace, openChangePasswordModal }) => {
  const allAdmins = ['platform_admin', 'workspace_admin', 'concierge', 'event_manager', 'campaign_manager']
  const history = useHistory()

  const switchWorkspace = workspace => {
    const config = {
      path: '/',
      maxAge: 604800,
      sameSite: true,
    }
    setCookie(null, 'workspaceID', workspace.id, config)
    window.location.href = '/reports/registrations'
  }

  const access = user.access
  const accessItems = access?.map(d => d.menu?.name) || []

  return (
    <Menu as="div" className="absolute mt-1 bottom-0 w-full inline-block text-left">
      <div>
        <Menu.Button className="group w-full bg-white border-t border-gray-100 px-3.5 py-2 text-sm text-left font-medium text-gray-700 focus:outline-none">
          <span className="flex w-full justify-between items-center">
            <span className="flex min-w-0 items-center justify-between space-x-3">
              <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src={user.userProfile?.profile} alt="" />
              <span className="flex-1 flex flex-col min-w-0">
                <span className="text-gray-600 text-xs font-normal truncate">{startCase(user.roles.filter(d => includes(allAdmins, d)).join(' | '))}</span>
                <span className="text-gray-700 text-sm truncate">{user.displayName}</span>
              </span>
            </span>
            <SelectorIcon className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{ boxShadow: '0px -5px 4px rgb(0 0 0 / 2%)' }}
          className="z-10 -translate-y-full border-t border-gray-100 -top-2 origin-bottom absolute right-0 left-0 mt-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="px-4 py-3 border-b border-gray-100">
            <span className="text-xs mb-4 block">My Workspace</span>
            <div className="flex px-1 flex-wrap">
              {workspaces?.map(workspace => {
                if (!workspace.primaryColor) {
                  workspace.primaryColor = '#1344ce'
                }

                const selected = currentWorkspace.id === workspace.id

                return (
                  <Tooltip key={workspace.id} className="mr-4 flex flex-col items-center" title={workspace.name}>
                    <div
                      onClick={() => switchWorkspace(workspace)}
                      key={workspace.id}
                      style={{ background: selected ? currentWorkspace.primaryColor ?? '#858585' : workspace.primaryColor ?? '#858585' }}
                      className="flex cursor-pointer text-white justify-center items-center h-7 rounded-full w-7 mt-1"
                    >
                      {selected ? <div style={{ border: `2px solid ${workspace.primaryColor}` }} className="rounded-full absolute h-9 w-9"></div> : null}
                      {workspace?.name?.charAt(0)}
                    </div>
                    <span className="text-[8px] mt-1 text-ellipsis truncate  w-7">{workspace?.name}</span>
                  </Tooltip>
                )
              })}
              {user.isPlatformAdmin && (
                <Tooltip className="mr-4 flex flex-col items-center" title="Create workspace">
                  <div
                    onClick={() => history.push('/workspaces/add')}
                    style={{ color: currentWorkspace.primaryColor, background: '#858585' }}
                    className="flex cursor-pointer text-white justify-center items-center h-7 rounded-full w-7 mt-1"
                  >
                    <div style={{ border: `2px dashed #858585` }} className="rounded-full absolute h-9 w-9"></div>
                    <PlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  </div>
                  <span className="text-[8px] mt-1">Create</span>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="py-1">
            {includes(accessItems, 'Workspace Settings') && (
              <Menu.Item onClick={() => history.push(`/workspaces/edit/${currentWorkspace.id}`)}>
                {({ active }) => (
                  <span className={classNames(active ? 'bg-gray-100 cursor-pointer text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Workspace settings</span>
                )}
              </Menu.Item>
            )}

            {includes(accessItems, 'Workspace Settings') && (
              <Menu.Item onClick={() => history.push(`/workspaces/credentials`)}>
                {({ active }) => (
                  <span className={classNames(active ? 'bg-gray-100 cursor-pointer text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Workspace credentials</span>
                )}
              </Menu.Item>
            )}
            <Menu.Item onClick={() => history.push('/profile')}>
              {({ active }) => (
                <span className={classNames(active ? 'bg-gray-100 cursor-pointer text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Profile settings</span>
              )}
            </Menu.Item>
            <Menu.Item onClick={() => openChangePasswordModal()}>
              {({ active }) => (
                <span className={classNames(active ? 'bg-gray-100 cursor-pointer text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Change Password</span>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item onClick={handleLogout}>
              {({ active }) => <span className={classNames(active ? 'bg-gray-100 cursor-pointer text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Logout</span>}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default BottomMenu
