import { Field, Form, Formik } from 'formik'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useState, useRef } from 'react'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import Layout from 'containers/Layout'
import { useParams, useHistory } from 'react-router-dom'
import { get, keys, forEach, isArray, isObject, includes, xor, filter } from 'lodash'
import { typeList } from 'pages/smart-contract/Contract'
import Switch from 'components/atoms/Switch'
import Button from 'components/atoms/Button'
import { useSelector } from 'react-redux'
import { cleanObject } from 'lib/utils'
import { CameraIcon } from '@heroicons/react/solid'

const AddEditContract = () => {
  const contractLogoRef = useRef(null)
  const [contractData, setContractData] = useState(null)
  const [events, setEvents] = useState([])
  const [saving, setSaving] = useState(false)
  const [previews, setPreviews] = useState({})
  const [files, setFiles] = useState({})

  const history = useHistory()
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      fetchContract()
    }
    return () => {
      return setContractData({})
    }
  }, [id])

  const workspaces = useSelector(state => get(state, 'user.workspaces'))
  const user = useSelector(state => get(state, 'user.user'))

  const fetchContract = async () => {
    const response = await apiGet(`contracts/${id}`)

    const data = response?.data?.abi
    // eslint-disable-next-line
    const events = filter(data, function (item) {
      return item.type === 'event'
    })

    setPreviews({ logo: response?.data?.logo })

    setContractData({ ...response?.data, abi: JSON.stringify(response?.data?.abi), proxyAbi: JSON.stringify(response?.data?.proxyAbi, {}) })
    setEvents(events)
  }

  const showPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews({ ...previews, [key]: preview })
  }

  const saveContractData = async contractData => {
    setSaving(true)
    const data = {
      ...cleanObject(contractData),
    }
    const formData = new FormData()
    forEach(keys(data), key => {
      if (data[key] === 'null' || data[key] === undefined || data[key] === null) delete data[key]
      // eslint-disable-next-line
      if (typeof data[key] === 'string' || typeof data[key] === 'number' || typeof data[key] === 'boolean' || Array.isArray(data[key])) {
        formData.append(key, data[key])
      }
      if (isObject(data[key]) && !isArray(data[key])) formData.append(key, JSON.stringify(data[key]))
    })

    if (files.logo) formData.append('logo', files.logo)

    const response = await apiPost(id ? `contracts/${id}` : 'contracts', formData)
    if (response.success) {
      setSaving(false)
      notify.success('Contract saved successfully')
      history.push('/smart-contracts')
    }
  }

  const initialValues = {
    name: contractData?.name,
    abi: contractData?.abi,
    workspaces: contractData?.workspaces?.map(d => d.id),
    address: contractData?.address,
    price: contractData?.price ?? 0,
    currency: contractData?.currency ?? 'ETH',
    mintLimit: contractData?.mintLimit ?? 0,
    explorer: contractData?.explorer ?? '',
    active: contractData?.active ?? false,
    listenEvents: contractData?.listenEvents ?? true,
    events: contractData?.events || [],
    qty: contractData?.qty ?? 0,
    isExternal: contractData?.isExternal ?? false,
    contractType: contractData?.contractType ?? 'erc721',
    maxSupply: contractData?.maxSupply ?? 0,
    proxy: contractData?.proxy ?? false,
    proxyAddress: contractData?.proxyAddress ?? '',
    proxyAbi: contractData?.proxyAbi ?? '{}',
    baseURI: contractData?.baseURI ?? '',
  }

  const autoFetchMaxSupply = async props => {
    const response = await apiPost(`max-supply`, {
      address: props.values.address,
      abi: JSON.parse(props.values.abi),
    })
    const maxSupply = response?.data
    if (maxSupply) {
      props.setFieldValue('maxSupply', parseInt(maxSupply, 10))
    }
  }

  const deleteContract = () => {
    notify.deleteConfirm({
      title: 'Delete contract',
      message: 'Are you sure you want to delete this contract?',
      onConfirm: async () => {
        const response = await apiPost(`contracts/${id}/delete`)
        if (response?.success) {
          notify.success('Contract deleted successfully')
          history.push('/smart-contracts')
        }
      },
    })
  }
  const syncTokens = async () => {
    const response = await apiGet(`sync-tokens/${id}`)

    if (response?.success) {
      notify.success('Tokens synced successfully')
    }
  }

  const handleJsonAbi = (json, type) => {
    try {
      if (type === 'proxy') {
        return
      }
      const data = JSON.parse(json)
      const events = data?.filter(item => item.type === 'event')
      setEvents(events)
    } catch (e) {}
  }

  return (
    <Layout
      headerRight={
        id && (
          <Button
            onClick={() => {
              history.go(-1)
            }}
            type="button"
          >
            Go Back
          </Button>
        )
      }
    >
      <div className="bg-white m-4 p-8 border rounded-sm">
        <Formik
          initialValues={id ? initialValues : { workspaces: [], events: [], currency: 'ETH', mintLimit: 0, maxSupply: 0, proxy: false, proxyAddress: '', proxyAbi: '{}' }}
          enableReinitialize={true}
        >
          {props => (
            <Form>
              <div>
                <div className="flex items-center mb-4 border-gray-200 pb-4 justify-between border-b">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Basic Details</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Name, Contract Address, Contract ABI, Contract Type</p>
                  </div>
                </div>

                <div className="border-b mt-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label htmlFor="country" className="block text-sm  text-gray-700 sm:mt-px sm:pt-2">
                        Contract Logo
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="bg-gray relative h-[120px] mb-3 w-[120px] border mr-4 border-dashed border-gray-300 rounded-md">
                          <input
                            id="qrLogo"
                            ref={contractLogoRef}
                            name="qrLogo"
                            className="hidden"
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            onChange={event => {
                              showPreview('logo', event.target.files[0])
                              setFiles({ ...files, logo: event.target.files[0] })
                            }}
                          />
                          {previews.logo ? (
                            <span onClick={() => contractLogoRef.current.click()}>
                              <img src={previews.logo} alt="" className="cursor-pointer rounded" />
                            </span>
                          ) : (
                            <div
                              onClick={() => contractLogoRef.current.click()}
                              className="bg-white flex p-2 items-center justify-center bg-contain bg-no-repeat bg-center border-gray-300 rounded-md cursor-pointer w-full h-full"
                            >
                              {previews.qrLogo ? null : <CameraIcon className="text-gray-400 h-8 w-8" />}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {user.isPlatformAdmin ? (
                  <div className="border-b mt-5">
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Workspaces Access
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          {workspaces?.length > 0 && (
                            <Selection
                              multiple
                              title="Select Workspaces"
                              className="block mb-6 w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                              onChange={value => props.setFieldValue('workspaces', xor([value.id], props.values?.workspaces))}
                              list={workspaces}
                              value={props.values?.workspaces}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="border-b mt-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Name
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Field
                          name="name"
                          placeholder="Name"
                          id="name"
                          type="text"
                          spellCheck={false}
                          className="block mb-6 w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-b mt-5 pb-6">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        This is Proxy Contract
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Switch
                          value={props.values?.proxy}
                          onChange={checked => {
                            props.setFieldValue('proxy', checked)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-b mt-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        {props.values?.proxy ? 'Implementation Contract Address' : 'Contract Address'}
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Field
                          name="address"
                          placeholder="Address"
                          id="address"
                          type="text"
                          spellCheck={false}
                          className="block font-mono mb-6 w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-b mt-5 pb-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        {props.values?.proxy ? 'Implementation ABI' : 'ABI'}
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="border border-gray-300 rounded-md overflow-hidden">
                          <textarea
                            spellCheck={false}
                            className="w-full border-0 outline-none font-mono text-sm p-3"
                            rows={20}
                            value={props.values?.abi}
                            onChange={e => {
                              handleJsonAbi(e.target.value)
                              props.setFieldValue('abi', e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {props.values?.proxy ? (
                  <div className="border-b mt-5">
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Proxy Contract Address
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            name="proxyAddress"
                            placeholder="Address"
                            id="proxyAddress"
                            type="text"
                            spellCheck={false}
                            className="block font-mono mb-6 w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {props.values?.proxy ? (
                  <div className="border-b mt-5 pb-5">
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Proxy ABI JSON
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="border border-gray-300 rounded-md overflow-hidden">
                            <textarea
                              spellCheck={false}
                              className="w-full border-0 outline-none font-mono text-sm p-3"
                              rows={20}
                              value={props.values?.proxyAbi}
                              onChange={e => {
                                handleJsonAbi(e.target.value, 'proxy')
                                props.setFieldValue('proxyAbi', e.target.value)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="border-b mt-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Contract Type
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Selection
                          title="Select Contract Type"
                          className="block mb-6 w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          onChange={value => props.setFieldValue('contractType', value?.id)}
                          list={typeList}
                          value={props.values?.contractType}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-b mt-5 pb-6">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        External
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Switch
                          value={props.values?.isExternal}
                          onChange={checked => {
                            props.setFieldValue('isExternal', checked)
                            if (checked && props.values?.contractType === 'erc721' && props.values?.abi && props.values?.address) {
                              autoFetchMaxSupply(props)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {props.values.isExternal ? (
                  <div className="border-b mt-5">
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Max Supply
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            name="maxSupply"
                            placeholder="Max Supply Count"
                            type="number"
                            spellCheck={false}
                            className="block font-mono mb-6 w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {props.values.isExternal ? (
                  <div className="border-b mt-5">
                    <div className="space-y-6 sm:space-y-5 pb-2">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Base token URI
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            name="baseURI"
                            placeholder="Default base URI"
                            type="text"
                            default=""
                            spellCheck={false}
                            className="block font-mono mb-6 w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {props.values.isExternal ? (
                  <div className="border-b mt-5">
                    <div className="space-y-6 sm:space-y-5 pb-4">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Sync/Cache tokens
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <button className="h-[30px] text-white rounded px-4 bg-dark-500" onClick={syncTokens} disabled={!id}>
                            Sync Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {events?.length > 0 && (
                  <div className="border-b mt-5 pb-5">
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="abc" className="font-regular text-gray-700">
                          Listen to events
                        </label>
                        <div className="mt-1 select-none max-w-xs sm:mt-0 sm:col-span-2">
                          <fieldset className="">
                            {events.map((item, index) => (
                              <div key={index} className="relative flex items-start">
                                <div className="flex h-5 items-center">
                                  <input
                                    onChange={e => {
                                      if (e.target.checked) {
                                        props.setFieldValue('events', [...props.values.events, item.name])
                                      } else {
                                        props.setFieldValue(
                                          'events',
                                          props.values?.events.filter(event => event !== item.name)
                                        )
                                      }
                                    }}
                                    checked={includes(props.values.events, item.name)}
                                    id={`label-${index}`}
                                    aria-describedby="comments-description"
                                    name="comments"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label htmlFor={`label-${index}`} className="font-medium text-gray-700">
                                    {item.name}
                                  </label>
                                  <span id="comments-description" className="text-gray-500">
                                    <span className="sr-only">{item.name}</span>
                                  </span>
                                </div>
                              </div>
                            ))}
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="border-b mt-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Explorer URL
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Field
                          name="explorer"
                          placeholder="Address"
                          id="explorer"
                          type="text"
                          spellCheck={false}
                          className="block font-mono mb-6 w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {props.values.contractType === 'erc721' || props.values.contractType === 'erc1155' ? (
                  <div className="border-b mt-5">
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                          Total Quantity
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            name="qty"
                            placeholder="Total Qty"
                            id="qty"
                            type="number"
                            spellCheck={false}
                            className="block font-mono mb-6 w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {(props.values?.contractType === 'erc721' || props.values?.contractType === 'erc115') && !props.values?.isExternal ? (
                  <>
                    <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pt-5 pb-5 mb-6">
                      <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                        Token Price
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="mt-1 max-w-xs relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">
                              {props.values.currency === 'USD' ? '$' : <img className="h-3 w-3" src="https://cdn3.emoji.gg/emojis/5819-eth.png" alt="" />}
                            </span>
                          </div>
                          <input
                            type="decimal"
                            name="price"
                            id="price"
                            step={0.01}
                            onChange={e => {
                              props.setFieldValue('price', e.target.value)
                            }}
                            value={props.values?.price}
                            className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                            placeholder="0.00"
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center">
                            <label htmlFor="currency" className="sr-only">
                              Currency
                            </label>
                            <select
                              onChange={e => {
                                props.setFieldValue('currency', e.target.value)
                              }}
                              value={props.values?.currency}
                              id="currency"
                              name="currency"
                              className="focus:ring-primary-500 focus:border-primary-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                            >
                              <option>ETH</option>
                              <option>USD</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!props.values?.isExternal && (
                      <div className="border-b mt-5">
                        <div className="space-y-6 sm:space-y-5">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                            <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                              Limit per user
                            </label>
                            <div className="mt-1 max-w-xs sm:mt-0 sm:col-span-2">
                              <Field
                                name="mintLimit"
                                placeholder="Max number of tokens to sell per user"
                                id="mintLimit"
                                type="number"
                                spellCheck={false}
                                className="block mb-6 w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
              </div>

              <div className="mt-4 flex space-x-4 justify-end">
                {id && (
                  <button
                    onClick={deleteContract}
                    type="button"
                    className="inline-flex text-red-500 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                  >
                    Delete Contract
                  </button>
                )}
                <button
                  onClick={() => {
                    history.go(-1)
                  }}
                  className="inline-flex items-center  px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
                >
                  Go Back
                </button>
                <button
                  onClick={() => {
                    if (!saving) saveContractData(props.values)
                  }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
                >
                  {saving ? 'Saving...' : 'Save'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  )
}

export default AddEditContract
