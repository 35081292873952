import { Form, Formik } from 'formik'
import Input from 'components/atoms/Input'

import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useState } from 'react'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'

const AddRsvp = ({ close, id, fetchData }) => {
  const [searchUsers, setSearchUsers] = useState([])

  const saveTypeData = async values => {
    const data = await apiPost(`events/rsvp/${id}`, values)
    if (data.success) {
      notify.success('RSVP created saved successfully')
      await fetchData()
      close()
    }
  }

  const searchList = async value => {
    if (value.length >= 2) {
      const list = await apiGet('users', { size: 10, page: 1, search: value, includeProfile: true })

      setSearchUsers(
        list?.data?.users.map(d => ({
          description: (d.username ? `@${d.username}` : null) ?? d.email ?? d.phone ?? (d.twitterId ? `@${d.twitterId}` : null) ?? (d.discordId ? `@${d.discordId}` : null),
          name: d.displayName || d.phone || d.walletAddress || d.username || d.email,
          id: d.id,
        })) || []
      )
    }
  }

  const initialValues = {
    userId: '',
    count: 0,
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize={true}>
      {props => (
        <Form>
          <div className="mb-8 space-y-4">
            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                User
              </label>
              <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                <Selection
                  height={300}
                  onChange={value => {
                    props.setFieldValue('userId', value.id)
                  }}
                  showSearch
                  onSearch={value => searchList(value)}
                  title="Select User"
                  list={[...searchUsers.flat()]}
                  value={props.values.userId}
                />
              </div>
            </div>
            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                RSVP Count
              </label>
              <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                <Input
                  type="number"
                  name="count"
                  placeholder="RSVP count"
                  id="count"
                  autoComplete="count"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 flex justify-end">
            <button
              onClick={() => close()}
              className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
            >
              Close
            </button>
            <button
              onClick={() => saveTypeData(props.values)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddRsvp
