const Button = ({ className, full, children, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="order-0 whitespace-nowrap h-[30px] inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-xs text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 sm:order-1 sm:ml-3"
    >
      {children}
    </button>
  )
}

export default Button
