import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useState } from 'react'
import { groupBy, startCase, map, keys, forEach, xor, every, includes, find } from 'lodash'
import notify from 'components/atoms/Notify'
import { IoChevronDown } from 'react-icons/io5'

const AddEditRole = ({ close, id, fetchData, setId, menuItems }) => {
  const [roleData, setRoleData] = useState(null)
  const [toggles, setToggles] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [readonlyItems, setReadonlyItems] = useState([])

  const [main, setMain] = useState(null)

  useEffect(() => {
    if (menuItems.length > 0) {
      // eslint-disable-next-line lodash/prefer-filter
      forEach(menuItems, d => {
        if (d.parent === 'extra') d.parent = 'More options'
        if (d.parent === null) {
          d.parent = 'Main'
        }
      })

      const main = groupBy(menuItems, 'parent')
      setMain(main)
    }
  }, [menuItems])

  useEffect(() => {
    if (id) {
      fetchRole()
    }
  }, [id])

  const fetchRole = async () => {
    const roleData = await apiGet(`/roles/${id}`)
    setRoleData({ name: startCase(roleData?.data?.name), allowExport: roleData?.data?.allowExport, showScanner: roleData?.data?.showScanner })

    const items = roleData?.data?.items.map(d => d?.menu?.id) || []
    setToggles(roleData?.data?.items.map(d => d?.menu?.parent))

    setCheckedItems(items)

    const readonlyItems = roleData?.data?.items.filter(d => d?.readOnly).map(d => d?.menu?.id) || []
    setReadonlyItems(readonlyItems)
  }

  const saveRoleData = async () => {
    const items = checkedItems?.filter(item => {
      return item !== null
    })
    const data = await apiPost(id ? `roles/${id}` : 'roles', { ...roleData, items, readonlyItems })
    if (data.success) {
      notify.success('Role saved successfully')
      fetchData()
      setId(null)
      close()
    }
  }

  const handleCheck = (e, items, key) => {
    // avoid triggering the parent checkbox

    const checked = every(
      items.map(d => d.id),
      d => includes(checkedItems, d)
    )

    if (checked) {
      const updatedChecked = xor(
        items.map(d => d.id),
        checkedItems || []
      )
      setCheckedItems(updatedChecked)
    } else {
      setCheckedItems([...checkedItems, ...items.map(d => d.id)])
    }
    setToggles([...toggles, key])
  }

  const rename = name => {
    if (name === 'Tier Notifications') return 'Tiers & Notification Settings'
    if (name === 'RSVP List') return 'RSVPs'

    return name
  }

  return (
    <div>
      <div className="mb-1">
        <div className="mt-1">
          <input
            value={roleData?.name}
            onChange={e => setRoleData({ ...roleData, name: e.target.value })}
            type="name"
            name="name"
            extraClassName="max-w-full"
            id="name"
            className="shadow-sm focus:ring-dark-500 focus:border-dark-500 block w-full max-w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="eg: Org Admin"
          />
        </div>

        <div className="relative flex items-start pt-4">
          <div className="min-w-0 flex-1 text-sm">
            <label htmlFor="candidates" className="font-medium flex text-gray-700">
              Scanner{' '}
              <p id="candidates-description" className="text-gray-500 ml-2 font-normal">
                (Allow this role to access the scanner interface)
              </p>
            </label>
          </div>
          <div className="ml-3 flex h-5 items-center">
            <input
              checked={roleData?.showScanner}
              onChange={e => {
                setRoleData({ ...roleData, showScanner: e.target.checked })
                // disabled scanner menu option as well
                if (!e.target.checked) {
                  setCheckedItems(checkedItems?.filter(d => d !== find(menuItems, { name: 'Switch to Scanner' })?.id))
                }
              }}
              id="candidates"
              aria-describedby="candidates-description"
              name="candidates"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
        </div>
        <div className="relative flex items-start pt-2 pb-4">
          <div className="min-w-0 flex-1 text-sm">
            <label htmlFor="candidates" className="font-medium flex text-gray-700">
              Disable copying{' '}
              <p id="candidates-description" className="text-gray-500 ml-2 font-normal">
                (copying of data from the dashboard, export reports etc.)
              </p>
            </label>
          </div>
          <div className="ml-3 flex h-5 items-center">
            <input
              checked={roleData?.allowExport}
              onChange={e => setRoleData({ ...roleData, allowExport: e.target.checked })}
              id="candidates"
              aria-describedby="candidates-description"
              name="candidates"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50 p-4 border border-gray-300 rounded-lg">
        {map(keys(main), (d, index) => {
          const items = main[d]

          // checked if all items are checked
          const checked = every(
            items.map(d => d.id),
            d => includes(checkedItems, d)
          )

          return (
            <div className="mb-2" key={index}>
              <span className="flex items-center mb-2">
                <IoChevronDown onClick={() => setToggles(xor([d], toggles || []))} className="-rotate-90 cursor-pointer" />
                <input checked={checked} onChange={e => handleCheck(e, main[d], d)} className="ml-3 mr-2" type="checkbox" />
                {d === 'Manage NFTs' ? 'Manage NFTs' : startCase(d)}
              </span>
              {includes(toggles, d) && items?.filter(d => d.name !== d).length > 0 && (
                <div className="ml-6 mb-2">
                  {items
                    ?.filter(d => d.name !== 'Roles')
                    .map((subitem, index) => {
                      const disabled = subitem.name === 'Switch to Scanner' && !roleData?.showScanner
                      return (
                        <span key={index} className="flex ml-3 border-b border-dashed items-center mb-2">
                          <div className="w-full justify-between flex items-center">
                            <span>
                              <input
                                disabled={disabled}
                                checked={includes(checkedItems, subitem.id)}
                                onChange={() => {
                                  setCheckedItems(xor([subitem.id], checkedItems))
                                }}
                                className="ml-0 mr-2"
                                type="checkbox"
                              />
                              {rename(startCase(subitem.name))}
                            </span>
                            <span className="block">
                              <input
                                disabled={disabled}
                                checked={includes(readonlyItems, subitem.id)}
                                onChange={() => {
                                  setReadonlyItems(xor([subitem.id], readonlyItems))
                                }}
                                className="ml-3 mr-2"
                                type="checkbox"
                              />{' '}
                              <span>Read only</span>
                            </span>
                          </div>
                        </span>
                      )
                    })}
                </div>
              )}
            </div>
          )
        })}
      </div>
      <div className="mt-4 flex justify-end">
        <button
          onClick={() => close()}
          className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
        >
          Close
        </button>
        <button
          onClick={() => saveRoleData()}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default AddEditRole
