import * as Yup from 'yup'

export const smsTwoFaAuth = Yup.object().shape({
  code: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(4, 'Must be exactly 4 digits')
    .max(4, 'Must be exactly 4 digits')
    .required('Please enter OTP code'),
})

export const googleTwoFaAuth = Yup.object().shape({
  code: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits')
    .required('Please enter OTP code'),
})
