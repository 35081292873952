import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import { uniqBy, size, filter, find, get, debounce, truncate, capitalize, isEmpty, startCase } from 'lodash'
import { useEffect, useState, useRef, useImperativeHandle } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { walletLink } from 'utils'
import EmptyState from 'components/pageComponents/EmptyState'
import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import moment from 'moment'
import AddRsvp from './modals/AddRsvp'
import useModal from 'hooks/useModal'
import apiPost from 'lib/network/apiPost'
import notify from 'components/atoms/Notify'
import { PencilIcon, TrashIcon, DownloadIcon } from '@heroicons/react/outline'
import apiDelete from 'lib/network/apiDelete'
import { SearchIcon } from '@heroicons/react/solid'
import { IoCopyOutline } from 'react-icons/io5'
import Blockies from 'react-blockies'

const IRLPhoto = ({ user }) => {
  return (
    <div className="shadow-md rounded-lg flex items-center flex-col grid-item h-full w-full relative bg-white" key={user.id}>
      <img className="w-[200px] h-[200px] mt-3" src={user.photo} alt={user.displayName || 'IRL picture'} />
      <span className="block mt-3">{user.displayName || user.email || user.phone}</span>
    </div>
  )
}

const EventRSVP = () => {
  const downloadCSV = useRef()
  const [filters, setFilters] = useState({})
  const [user, setUser] = useState({})
  const [eventStats, setEventStats] = useState({})
  const [isEditFields, setIsEditFields] = useState([{ id: null, isEdit: false, count: null }])
  const [data, setData] = useState([])
  const [eventDetails, setEventDetails] = useState(null)
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const history = useHistory()

  const { id } = useParams()

  useEffect(() => {
    fetchEventData(true, true)
  }, [id, filters])

  const reFetch = () => {
    fetchEventData(true, true)
  }

  const copyText = text => {
    navigator.clipboard.writeText(text)
    notify.success('Copied to clipboard', truncate(text, { length: 35 }))
  }

  useImperativeHandle(downloadCSV, () => ({
    download: async (setDownloading, cb) => {
      setDownloading(true)
      const response = await apiGet(`events/rsvp/${id}/csv`, { size: 9999999, page: 0, ...filter })
      if (response.data?.content) {
        const csvContent = 'data:text/csv;charset=utf-8,' + response?.data?.content

        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', response?.data?.name)
        document.body.appendChild(link)

        link.click() // This will download the data file named "my_data.csv".
      } else {
        window.open(`${process.env.REACT_APP_BASE_URL}/admin/registrations/download/${response?.data?.name}`, '_blank')
      }

      setDownloading(false)

      cb()
    },
  }))

  const fetchEventData = async (noLoading = false, reset = false) => {
    if (!noLoading) setLoading(true)
    const response = await apiGet(`events/rsvp/${id}`, { size: 30, page: (reset ? 0 : pagination?.currentPage || 0) + 1, ...filters })
    const eventRsvp = response.data?.list
    reset ? setData(eventRsvp) : setData(uniqBy([...data, ...eventRsvp], 'id'))
    setPagination(response?.data?.pagination)
    if (!eventDetails) setEventDetails(response.data?.event)
    const { stats, totalRsvpCount, eventEntries } = response.data
    if (stats || totalRsvpCount || eventEntries) {
      setEventStats({
        ...eventStats,
        stats,
        totalRsvpCount,
        eventEntries,
      })
    }

    setLoading(false)
  }

  const [CreateRsvpModel, openRsvpModal] = useModal({
    content: <AddRsvp id={id} fetchData={reFetch} closeable={true} />,
    title: 'Create RSVP',
  })

  const [IRLModal, openIrlModal] = useModal({
    content: <IRLPhoto closeable={true} user={user} />,
    title: user.irl ? 'IRL Picture' : 'Avatar',
    dimensions: {
      height: '300px',
      width: '300px',
    },
  })

  const deleteRsvp = id => {
    notify.deleteConfirm({
      title: 'Delete RSVP',
      message: 'Are you sure you want to delete this RSVP?',
      onConfirm: async () => {
        const response = await apiDelete(`/events/rsvp/${id}/delete`)
        if (response?.success) {
          notify.success('RSVP deleted successfully')
          reFetch()
        }
      },
    })
  }

  const editRsvpCount = async id => {
    notify.deleteConfirm({
      title: 'Edit RSVP Count',
      message: 'Are you sure you want to update this RSVP count?',
      onConfirm: async () => {
        const value = find(isEditFields, { id, isEdit: true })
        const data = await apiPost(`events/rsvp/${id}/update`, { count: value.count })
        if (data.success) {
          notify.success('RSVP count updated successfully')
          const obj = [
            // eslint-disable-next-line
            ...filter(isEditFields, function (o) {
              return o.id !== id
            }),
            {
              ...value,
              isEdit: false,
            },
          ]
          setIsEditFields(obj)
        }
      },
    })
  }

  const approveDenyRSVP = async (id, status_) => {
    notify.alertConfirm({
      title: `${capitalize(status)} RSVP for this user?`,
      message: 'Are you sure you want to update this RSVP status?',
      onConfirm: async () => {
        const status = status_ === 'approve' ? 'approved' : 'denied'
        const response = await apiPost(`events/rsvp/${id}/update`, { status })
        if (response.success) {
          notify.success('RSVP status updated successfully')
          // update the item in the list
          const newArr = data.map(function (a) {
            return a.id === id ? { ...a, status } : a
          })
          setData([...newArr])
        }
      },
    })
  }

  const columns = [
    {
      name: 'NAME/USERNAME',
      width: '20%',
      align: 'left',
      key: 'user',
      rendered: item => (
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10 relative">
            {item.user?.profile ? (
              <img
                role="presentation"
                className="h-10 w-10 rounded-full cursor-pointer"
                src={item?.user?.profile}
                alt=""
                onClick={() => {
                  item.user.photo = item?.user?.profile
                  item.user.irl = false
                  setUser(item.user)
                  openIrlModal(true)
                }}
              />
            ) : (
              <Blockies className="rounded-full" seed={item.user?.walletAddress || item.user?.email} size={6.5} scale={6} />
            )}
          </div>
          <div className="ml-4 cursor-pointer" onClick={() => history.push(`/users/edit/${item?.user?.id}/user-profile?t=2`)}>
            <div className="text-sm text-normal text-gray-900">{item.user?.displayName || item.user?.username}</div>
            <span className=" py-3 text-sm text-gray-800 font-regular truncate">
              {walletLink(item?.user?.walletAddress)}{' '}
              {item?.user?.walletAddress && (
                <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item?.user?.walletAddress)}>
                  <IoCopyOutline className="ml-1" />
                </button>
              )}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: 'EMAIL/PHONE',
      width: '10%',
      align: 'left',
      key: 'email',
      rendered: item => <span className="py-3 text-sm text-gray-800 font-regular truncate">{item?.user?.email || item?.user?.phone}</span>,
    },
    {
      name: 'IRL Photo',
      width: '10%',
      align: 'left',
      key: 'user',
      rendered: item => (
        <img
          role="presentation"
          className={`h-10 w-10 rounded-full ${item?.user?.userPhoto && 'cursor-pointer'}`}
          onClick={() => {
            if (item?.user?.userPhoto) {
              item.user.photo = item?.user?.userPhoto
              item.user.irl = true
              setUser(item?.user)
              openIrlModal(true)
            }
          }}
          src={item?.user?.userPhoto || 'https://cdnd.reel8.com/1/profile/3.png'}
          alt=""
        />
      ),
    },
    {
      name: 'TOKENS',
      width: '10%',
      align: 'left',
      key: 'tokens',
      rendered: item => (
        <span className=" py-3 text-sm text-gray-800 font-regular truncate">
          <div className="space-x-2">
            {item?.tokens?.map(token => (
              <span className="bg-gray-200 px-2 py-1 rounded-md" key={token.id}>{`${token.tokenID?.tokenID} ${token?.tokenID.contract?.name}`}</span>
            ))}
          </div>
        </span>
      ),
    },
    {
      name: 'COUNT',
      width: '20%',
      align: 'left',
      key: 'count',
      rendered: item => (
        <div className="text-[13px] w-[140px] text-gray-500 overflow-scroll ">
          {size(filter(isEditFields, { id: item.id, isEdit: true })) > 0 ? (
            <>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  onChange={e => {
                    const obj = [
                      // eslint-disable-next-line
                      ...filter(isEditFields, function (o) {
                        return o.id !== item.id
                      }),
                      {
                        id: item.id,
                        isEdit: true,
                        count: e.target.value,
                      },
                    ]
                    setIsEditFields(obj)
                  }}
                  // eslint-disable-next-line
                  value={size(filter(isEditFields, { id: item.id, isEdit: true })) > 0 ? filter(isEditFields, { id: item.id, isEdit: true })[0].count : 0}
                  type="text"
                  name="company-website"
                  id="company-website"
                  className="block w-[80px] flex-1 rounded-none rounded-l-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="eg. 1,2,3"
                />
                <span
                  className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 text-white bg-dark-600 px-2 cursor-pointer hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:text-sm"
                  onClick={() => editRsvpCount(item.id)}
                >
                  Save
                </span>
              </div>
            </>
          ) : (
            <>
              {find(isEditFields, { id: item.id, isEdit: false })?.count || item.count}
              <PencilIcon
                className="h-3 w-4 cursor-pointer text-dark-700 inline"
                onClick={() => {
                  setIsEditFields(prev => [...prev, { id: item.id, isEdit: true, count: item.count }])
                }}
              />
            </>
          )}
        </div>
      ),
    },
    {
      width: '5%',
      name: 'Approve',
      key: 'approve',
      align: 'left',
      rendered: item => {
        return !isEmpty(item.status) ? (
          item.status === 'approved' ? (
            <span>
              <span className="text-green-800 bg-green-300 p-1 px-2 rounded">Approved</span>
              <span
                onClick={() => {
                  approveDenyRSVP(item.id, 'deny')
                }}
                className="bg-red-500  cursor-pointer p-0.5 ml-2 text-white px-2 rounded text-xs"
              >
                Deny
              </span>
            </span>
          ) : (
            <span>
              <span className="text-red-800 bg-red-300 p-1 px-2 rounded">Denied</span>
              <span
                onClick={() => {
                  approveDenyRSVP(item.id, 'approve')
                }}
                className="bg-green-500  cursor-pointer p-0.5 ml-2 text-white px-2 rounded text-xs"
              >
                Approve
              </span>
            </span>
          )
        ) : (
          <div className="flex space-x-2">
            <button
              className="px-2 py-1 text-xs rounded-md cursor-pointer bg-green-700 text-white inline"
              onClick={() => {
                approveDenyRSVP(item.id, 'approve')
              }}
            >
              Approve
            </button>
            <button
              className="px-2 py-1 cursor-pointer rounded-md text-white bg-red-500 inline"
              onClick={() => {
                approveDenyRSVP(item.id, 'deny')
              }}
            >
              Reject
            </button>
          </div>
        )
      },
    },

    {
      width: '5%',
      name: 'Created',
      key: 'created',
      align: 'left',
      rendered: item => {
        const createdTime = get(item, 'createdAt', '')
        const createdAt = createdTime ? moment(createdTime).fromNow() : 'Registered'
        return createdAt
      },
    },
    {
      width: '5%',
      name: 'Action',
      key: 'action',
      align: 'left',
      rendered: item => {
        return (
          <TrashIcon
            className="h-5 w-5 cursor-pointer text-dark-700 inline"
            onClick={() => {
              deleteRsvp(item.id)
            }}
          />
        )
      },
    },
  ]

  const searchList = async value => {
    if (value.length >= 2) {
      setPagination({ currentPage: 0 })
      setData([])
      setFilters({ ...filters, search: value })
    } else {
      setPagination({ currentPage: 0 })
      setData([])
      setFilters({ ...filters, search: null })
    }
  }

  const searchFn = debounce(e => {
    searchList(e.target.value)
  }, 1000)

  function handleChange(event) {
    searchFn(event)
  }

  const getStatusClasses = status => {
    switch (status) {
      case 'denied':
        return 'bg-red-100 text-red-800'
      case 'approved':
        return 'bg-green-100 text-green-800'
      case 'pending':
        return 'bg-yellow-100 text-yellow-800'
      default:
        return 'bg-blue-100 text-blue-800'
    }
  }

  return (
    <Layout
      background="#f4f5f9"
      title={eventDetails?.name}
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <button
            onClick={(e, done) => {
              if (!downloading) {
                downloadCSV.current.download(setDownloading, response => {})
              }
            }}
            type="button"
            className="border px-2 flex justify-center items-center mr-4 h-8 shadow-sm text-sm rounded-md  bg-white border-gray-300"
          >
            {downloading ? (
              <svg className="animate-spin h-5 w-5 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <DownloadIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            )}
          </button>
          <div className="max-w-xs w-full lg:max-w-xs">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                onChange={handleChange}
                id="search"
                name="search"
                className="block w-full h-[30px] pl-10 pr-3 py-2 rounded-md leading-5 bg-gray-100  border-0 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
                placeholder="Search"
                type="search"
              />
            </div>
          </div>
          <button
            onClick={() => {
              openRsvpModal()
            }}
            type="button"
            className="order-0 whitespace-nowrap h-[30px] inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-xs text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 sm:order-1 ml-3"
          >
            Add New
          </button>
        </div>
      }
    >
      <div className="bg-[#f4f5f9]">
        <div className="align-middle inline-block min-w-full">
          <div className="px-4 py-2">
            <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
              Responses : {eventStats?.totalRsvpCount || 0}
            </span>
            <span className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">Signed In: {eventStats?.eventEntries || 0}</span>
            {eventDetails?.requiresApproval &&
              eventStats?.stats?.map((item, idx) => {
                return (
                  <span key={idx} className={`${getStatusClasses(item.status)} text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}>
                    {startCase(item.status)} : {item.count}
                  </span>
                )
              })}
          </div>
          <div className="px-4 py-2 absolute bottom-4 left-[50%]">
            {data?.length !== pagination?.count && (
              <span className="tag">
                Showing {data?.length} of {pagination?.count}
              </span>
            )}
          </div>
          <div className="min-w-full">
            {!loading ? (
              <>
                {data?.length === 0 ? (
                  <EmptyState v2 description="No data found" />
                ) : (
                  <Table
                    noTable={false}
                    hasMore={pagination?.currentPage !== pagination?.totalPage}
                    fetchData={() => fetchEventData(true)}
                    data={data}
                    columns={columns.filter(d => {
                      if (d.key === 'approve') {
                        return eventDetails?.requiresApproval
                      }
                      return true
                    })}
                  />
                )}
              </>
            ) : (
              <div className="w-full h-[200px] border-0 flex justify-center items-center">
                <LoadingIcon />
              </div>
            )}
          </div>
        </div>
      </div>
      <CreateRsvpModel />
      <IRLModal />
    </Layout>
  )
}

export default EventRSVP
