import { useEffect, useState } from 'react'
import startCase from 'lodash/startCase'
import Layout from 'containers/Layout'
import moment from 'moment'
import { debounce, get, includes, orderBy, size, toLower, truncate, uniqBy } from 'lodash'
import apiGet from 'lib/network/apiGet'
import classNames from 'classnames'
// import PopupMenu from "components/molecules/PopupMenu";
import { useHistory, useParams } from 'react-router-dom'
// import { IoEllipsisVertical } from "react-icons/io5";
// import notify from "components/atoms/Notify";
import Blockies from 'react-blockies'
// import apiPost from "lib/network/apiPost";
import { SearchIcon, UploadIcon, DocumentDownloadIcon } from '@heroicons/react/solid'
import Selection from 'components/molecules/Selection'
import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import Button from 'components/atoms/Button'
import { startEndTruncate, accessibleOnClick } from 'utils'
import { useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import { IoCopyOutline, IoEllipsisVertical } from 'react-icons/io5'
import notify from 'components/atoms/Notify'
import { setCookie } from 'nookies'
import MultiLevelDropDown from 'components/atoms/MultiLevelDropDown'
import ImportUsers from './modals/ImportUsers'
import useModal from 'hooks/useModal'
import importUserCsv from 'assets/files/import_users.csv'

const Users = () => {
  const history = useHistory()
  const [roleData, setRoleData] = useState(null)
  const [filter, setFilter] = useState({ workspace: -1 })
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [users, setUsers] = useState([])

  const onlyAdmins = includes(history.location?.pathname, 'admins')

  const currentWorkspace = useSelector(state => get(state, 'user.workspace'))
  const workspaces = useSelector(state => get(state, 'user.workspaces'))

  const { id } = useParams()

  const fetchRoles = async () => {
    const response = await apiGet('roles', { size: 100, page: 1 })
    setRoleData(response?.data)
  }

  const impersonateUser = async (id, key) => {
    const response = await apiGet(`/user/impersonate/${id}`, { role: key })
    if (response.success) {
      if (key === 'user') {
        return response?.data?.frontendUrl ? window.open(response?.data?.frontendUrl, '_blank').focus() : null
      }
      const config = {
        path: '/',
        maxAge: 604800,
        sameSite: true,
      }
      setCookie(null, 'impersonateToken', get(response?.data, 'accessToken', ''), config)
      setCookie(null, 'impersonateRole', key, config)
      setCookie(null, 'isImpersonated', true, config)
      window.location.href = '/'
    }
  }

  useEffect(() => {
    fetchRoles()
  }, [])

  useEffect(() => {
    if (filter.workspace !== -1) {
      fetchUsers(false, true)
    }
  }, [filter, id, onlyAdmins])

  useEffect(() => {
    if (currentWorkspace.id) {
      setFilter({ ...filter, workspace: currentWorkspace.id })
    }
  }, [currentWorkspace])

  const fetchUsers = async (noLoading = false, reset = false) => {
    if (!noLoading) setLoading(true)
    const response = await apiGet('users', { onlyAdmins, workspaceId: id, size: 20, page: ((reset ? 0 : pagination?.currentPage) || 0) + 1, ...filter })

    setPagination(response?.data?.pagination)

    if (reset) setUsers(response?.data?.users)
    else setUsers([...users, ...response.data.users])

    setLoading(false)
  }

  const searchList = async value => {
    if (value.length >= 2) {
      setPagination({ currentPage: 0 })
      setUsers([])
      setFilter({ ...filter, search: value })
    } else {
      setPagination({ currentPage: 0 })
      setUsers([])
      setFilter({ ...filter, search: null })
    }
  }

  const searchFn = debounce(e => {
    searchList(e.target.value)
  }, 1000)

  function handleChange(event) {
    searchFn(event)
  }

  const user = useSelector(state => get(state, 'user.user'))

  const copyText = text => {
    navigator.clipboard.writeText(text).then(() => {
      notify.success('Copied to clipboard', truncate(text, { length: 35 }))
    })
  }

  const getImpersonationMenu = item => {
    const items = [{ name: 'Impersonate on App', key: 'user' }]
    const adminRoles = item.roles?.filter(role => {
      return !includes(['user', 'creator'], toLower(startCase(role.name)))
    })
    const impersonateAble = size(adminRoles) > 0

    if (!impersonateAble) return items

    const nestedRolesItem = []
    // eslint-disable-next-line
    adminRoles?.forEach(role => {
      nestedRolesItem.push({ name: startCase(role.name), key: role.name })
    })

    return [...items, { name: 'Impersonate on Dashboard', description: 'Please select a role', children: [...nestedRolesItem] }]
  }

  const menuClickHandler = (option, id) => {
    impersonateUser(id, option.key)
  }

  const columns = [
    {
      name: 'Name',
      width: '10%',
      key: 'name',
      sortable: true,
      rendered: item => {
        return (
          <div className="flex items-center cursor-pointer" {...accessibleOnClick(() => window.open(`/users/edit/${item.id}/user-profile`))}>
            <div className="flex-shrink-0 h-10 w-10 relative">
              {item.profile?.profile ? (
                <img className="h-10 w-10 rounded-full" src={item?.profile?.profile} alt="" />
              ) : (
                <Blockies className="rounded-full" seed={item.walletAddress || item.email || ''} size={6.5} scale={6} />
              )}
              <div
                className={classNames(
                  item.status === 1 ? 'bg-green-500' : item.status === 5 ? 'bg-blue-500' : 'bg-gray-300',
                  'top-0 right-0 inline-flex h-[12px] w-[12px] absolute text-xs leading-5 rounded-full'
                )}
              ></div>
            </div>
            <div className="ml-4">
              <div className="text-sm text-normal text-gray-900">{item.displayName || 'User'}</div>
              <div
                // eslint-disable-next-line
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
                className="text-[13px] text-gray-500 flex"
              >
                {item.walletAddress ? (
                  <span className="flex items-center">
                    <Tooltip title={`${item.walletAddress}`}>
                      <span
                        // eslint-disable-next-line
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          copyText(item.walletAddress)
                        }}
                        className="text-[13px] hover:text-indigo-500 cursor-pointer"
                      >
                        {startEndTruncate(item?.walletAddress, 6)}
                      </span>
                    </Tooltip>
                    <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item?.walletAddress)}>
                      <IoCopyOutline className="ml-2" />
                    </button>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      width: '10%',
      name: 'Email/Phone',
      key: 'email',
      sortable: true,
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]">{item.email}</span>
              {item?.email && (
                <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item?.email)}>
                  <IoCopyOutline className="ml-2" />
                </button>
              )}
            </div>
            <div>
              <span className="text-[13px]">{item.phone}</span>
              {item?.phone && (
                <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item?.phone)}>
                  <IoCopyOutline className="ml-2" />
                </button>
              )}
            </div>
          </>
        )
      },
    },
    {
      width: '10%',
      name: 'Type',
      key: 'type_pa',
      sortable: true,
      rendered: item => (
        <span className="text-[13px]">
          {
            // eslint-disable-next-line
            orderBy(item?.roles?.map(r => startCase(r.name))).map((d, index) => {
              return (
                <span key={index} className="border mr-3 shadow-sm rounded-2xl px-2 py-0.5">
                  {d}
                </span>
              )
            })
          }
        </span>
      ),
    },
    {
      width: '10%',
      name: 'Type',
      key: 'type_wa',
      sortable: true,
      rendered: item => (
        <span className="text-[13px]">
          {
            // eslint-disable-next-line
            orderBy(item?.roles?.filter(d => d.name !== 'platform_admin')?.map(r => startCase(r.name)))?.map(d => (
              <span className="border mr-3 shadow-sm rounded-2xl px-2 py-0.5" key={d}>
                {d}
              </span>
            ))
          }
        </span>
      ),
    },
    {
      width: '5%',
      name: 'Source',
      key: 'source',
      sortable: true,
      rendered: item => (
        <div>
          {item.campaign?.source ? <span className="bg-white mr-1 py-1 px-2 rounded-full border text-[11px]">{item?.campaign?.source}</span> : null}
          {item.discordId ? <span className="bg-white mr-1 py-1 px-2 rounded-full border text-[11px]">Discord</span> : null}
          {item.twitterId ? <span className="bg-white py-1 px-2 rounded-full border text-[11px]">Twitter</span> : null}
        </div>
      ),
    },
    {
      width: '5%',
      name: 'Created',
      key: 'created',
      align: user.isWorkspaceAdmin ? 'right' : 'left',
      sortable: true,
      rendered: item => {
        const createdTime = get(item, 'createdAt', '')
        const createdAt = createdTime ? moment(createdTime).fromNow() : 'Registered'
        return createdAt
      },
    },
    {
      width: '10%',
      name: 'Workspace',
      key: 'workspace',
      align: user.isWorkspaceAdmin ? 'right' : 'left',
      sortable: true,
      rendered: item => (
        <div className="flex -space-x-1 overflow-hidden">
          {item?.accountsWith?.map((workspace, index) => (
            <Tooltip key={index} title={workspace.name}>
              <div className="inline-block h-7 w-7 rounded-full ring-2 ring-white">
                <div
                  key={workspace.id}
                  style={{ background: workspace.primaryColor, color: workspace?.textColor }}
                  className="flex cursor-pointer font-medium justify-center items-center h-7 rounded-full w-7"
                >
                  {workspace.name.charAt(0)}
                </div>
              </div>
            </Tooltip>
          ))}
        </div>
      ),
    },
    {
      width: '10%',
      name: 'Last login',
      key: 'lastLogin',
      align: user.isWorkspaceAdmin ? 'right' : 'left',
      sortable: false,
      rendered: item =>
        item.accountsWith[0]?.session ? (
          <div className="flex flex-col">
            <span>{moment(item.accountsWith[0]?.session?.createdAt).fromNow()}</span>
            <span className="text-xs text-gray-500">with {startCase(item.accountsWith[0]?.session?.loginWith)}</span>
          </div>
        ) : null,
    },
    {
      width: '5%',
      name: 'Impersonate',
      key: 'impersonate',
      align: user.isWorkspaceAdmin ? 'right' : 'left',
      sortable: false,
      rendered: item => {
        return (
          <MultiLevelDropDown menu={getImpersonationMenu(item)} onClick={menuClickHandler} id={item.id}>
            <IoEllipsisVertical className="h-5 w-5" aria-hidden="true" />
          </MultiLevelDropDown>
        )
      },
    },
  ]

  const filterColumns = columnsData => {
    return columnsData.filter(item => {
      if (user.isWorkspaceAdmin && item.key === 'type_pa') return false
      if (user.isPlatformAdmin && item.key === 'type_wa') return false

      if (!user.isPlatformAdmin && item.key === 'impersonate') return false

      if (user.isWorkspaceAdmin && item.key === 'actions') return false
      if (user.isWorkspaceAdmin && item.key === 'workspace') return false

      if (id && item.key === 'workspace') return false

      if (id && item.key === 'lastLogin') return true
      if (!id && item.key === 'lastLogin') return false

      return true
    })
  }

  const downloadCsv = () => {
    const link = document.createElement('a')
    link.href = importUserCsv
    link.download = 'import users.csv'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const [ImportUsersModal, openImportUsersModal] = useModal({
    content: <ImportUsers refetch={fetchUsers} />,
    title: `Import users`,
    headerRight: (
      <button
        className="inline-flex items-center py-1 px-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
        onClick={downloadCsv}
      >
        <DocumentDownloadIcon className="h-5 w-5 mr-1" aria-hidden="true" /> CSV Template
      </button>
    ),
    dimensions: {
      height: 'auto',
      width: '500px',
      minHeight: 'auto',
      minWidth: '500px',
    },
  })

  return (
    <Layout
      showLogo={!!id}
      breadcrumbs={[{ name: 'Users List', pathname: '/users' }]}
      headerRight={
        <div className="flex items-center w-full justify-end sm:mt-0 sm:ml-4">
          <button
            onClick={() => {
              openImportUsersModal()
            }}
            type="button"
            className="border px-2 flex justify-center items-center mr-4 h-8 shadow-sm text-sm rounded-md  bg-white border-gray-300"
          >
            <UploadIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </button>
          {user.isPlatformAdmin ? (
            <Selection
              onChange={value => {
                setFilter({ ...filter, workspace: value.id === filter.workspace ? null : value.id })
                setPagination({ currentPage: 0 })
                setUsers([])
              }}
              className="min-w-[200px] mr-4"
              value={filter.workspace}
              list={workspaces}
              title="Workspace Filter"
            />
          ) : null}
          <Selection
            onChange={value => {
              setFilter({ ...filter, type: value.id === filter.type ? null : parseInt(value.id, 10) })
              setPagination({ currentPage: 0 })
              setUsers([])
            }}
            className="min-w-[200px] mr-4"
            value={filter.type}
            list={roleData?.roles?.map(item => ({ id: item.id, name: startCase(item.name) }))}
            title="User Type Filter"
          />
          <div className="max-w-xs w-full lg:max-w-xs">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                onChange={handleChange}
                id="search"
                name="search"
                className="block w-full min-w-[200px] h-[30px] pl-10 pr-3 py-2 rounded-md leading-5 bg-gray-100  border-0 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
                placeholder="Search"
                type="search"
              />
            </div>
          </div>
          {user.isWorkspaceAdmin ? <Button onClick={() => history.push('/users/add')}>Invite User</Button> : <Button onClick={() => history.push('/users/add')}>Add User</Button>}
        </div>
      }
      title="Users List"
    >
      <div className="flex flex-col">
        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                {!loading ? (
                  <>
                    {users?.length === 0 ? (
                      <EmptyState v2 description="No users found" />
                    ) : (
                      <Table
                        hasMore={pagination?.currentPage !== pagination?.totalPage}
                        fetchData={() => fetchUsers(true)}
                        sortBy={filter.sortBy}
                        sortOrder={filter.sortOrder}
                        handleSort={(sortBy, sortOrder) => {
                          setFilter({ ...filter, sortBy, sortOrder })
                          setPagination({ currentPage: 0 })
                          setUsers([])
                        }}
                        data={uniqBy(users, 'id')}
                        columns={filterColumns(columns)}
                      />
                    )}
                  </>
                ) : (
                  <div className="w-full h-[200px] border-0 flex justify-center items-center">
                    <LoadingIcon />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      <ImportUsersModal />
    </Layout>
  )
}

export default Users
