import Layout from 'containers/Layout'
import { useEffect, useState } from 'react'
import Selection from 'components/molecules/Selection'
import apiGet from 'lib/network/apiGet'
import { PlusIcon, MinusIcon } from '@heroicons/react/outline'
import { find, replace, toLower, xor, forEach, split, uniqBy, isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import apiPost from 'lib/network/apiPost'
import notify from 'components/atoms/Notify'
import apiDelete from 'lib/network/apiDelete'
import { getDefaultProject } from 'lib/utils'

const Stages = () => {
  const [projectList, setProjectList] = useState(null)
  const [dataList, setDataList] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [data, setData] = useState({
    stages: [],
  })
  const [dropList, setDropList] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    const projects = response.data

    setProjectList(projects)
    setData({ ...data, project: getDefaultProject(projects) })
  }

  const fetchStages = drop => {
    apiGet('stages', { drop }).then(response => {
      const stages = response.data

      if (!find(stages, { stage: 'paused' })) {
        stages.push({ stage: 'paused', validationMessage: 'Minting Paused' })
      }

      if (!find(stages, { stage: 'closed' })) {
        stages.push({ stage: 'closed', validationMessage: 'Minting Closed' })
      }

      forEach(stages, stage => {
        stage.validationValue = split(stage.validationValue, ',')
      })

      setData({ ...data, stages })
    })
  }

  const fetchCampaigns = async () => {
    const response = await apiGet('campaign-logs/list')
    const campaigns = response.data?.list

    setCampaigns(campaigns)
  }

  const fetchDrops = async project => {
    setLoading(true)
    const response = await apiGet('drops', { project })
    setDropList(response.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchProjects()
    fetchCampaigns()
    fetchDataList()
  }, [])

  useEffect(() => {
    if (data.project) {
      fetchDrops(data.project)
    }
  }, [data.project])

  useEffect(() => {
    if (data.drop) {
      fetchStages(data.drop)
    }
  }, [data.drop])

  const saveStages = () => {
    const value = uniqBy(data.stages, 'stage')
    if (value.length < data.stages.length) {
      notify.error('Stage name must be Unique')
    } else {
      apiPost('stages', data).then(e => {
        notify.success('Success', e.message)
      })
    }
  }

  const fetchDataList = async project => {
    setLoading(true)
    const response = await apiGet('datalist', { project })
    setDataList(response.data)
    setLoading(false)
  }

  const history = useHistory()

  const deleteStage = id => {
    notify.deleteConfirm({
      title: 'Delete Stage',
      message: 'Are you sure you want to delete this Stage?. It will delete all the related data',
      onConfirm: async () => {
        if (id) {
          const response = await apiDelete(`/stage/${id}`)
          if (response?.success) {
            history.push('/stages')
            notify.success('Success', response.message)
            fetchStages(data.drop)
          }
        } else {
          data?.stages.pop()
          setData({ ...data })
        }
      },
    })
  }

  return (
    <Layout title="NFT Minting Controlled Stages">
      <div className="p-4">
        <div className="w-full mb-[120px] flex flex-col">
          <div className="bg-white p-8 rounded-sm w-full border">
            <div className="flex items-center border-gray-200 pb-4 justify-between">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Manage Minting Stages</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Select Project, Drop and stages</p>
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Select Project
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  className="w-[250px]"
                  title="Select Project"
                  onChange={value => {
                    setData({ ...data, project: value.id })
                  }}
                  list={projectList}
                  value={data?.project}
                />
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Select Drop
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  className="w-[250px]"
                  title="Select Drop"
                  onChange={value => {
                    setData({ ...data, drop: value.id })
                  }}
                  list={dropList}
                  value={data?.drop}
                />
              </div>
            </div>

            {data.drop && (
              <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Select stages
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  {data?.stages?.map((d, index) => {
                    const defaultStage = d.stage === 'paused' || d.stage === 'closed'
                    const showCampaign = d.validationType === 'campaignSource' || d.validationType === 'campaignName'
                    const showDataList = d.validationType === 'datalist'

                    let list = []

                    if (d.validationType === 'campaignSource') list = campaigns.map(d => ({ id: d.source, name: d.source }))
                    if (d.validationType === 'campaignName') list = campaigns.map(d => ({ id: d.name, name: d.name }))

                    list = uniqBy(list, 'id')

                    return (
                      <div key={index} className="flex border rounded-md mb-4 p-4 bg-gray-50">
                        <input
                          type="text"
                          placeholder="Stage Name"
                          value={d.stage}
                          onChange={e => {
                            const stages = data.stages.map(s => {
                              if (s.stage === d.stage && s.id === d.id) {
                                return { ...s, stage: toLower(replace(e.target.value, ' ', '-')) }
                              }
                              return s
                            })
                            setData({ ...data, stages })
                          }}
                          className="max-w-[100px] h-[32px] mr-3 block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                        />

                        <input
                          type="text"
                          placeholder="Validation Message"
                          value={d.validationMessage}
                          onChange={e => {
                            const stages = data.stages.map(s => {
                              if (s.stage === d.stage && s.id === d.id) {
                                return { ...s, validationMessage: e.target.value }
                              }
                              return s
                            })
                            setData({ ...data, stages })
                          }}
                          className="max-w-[250px] mr-3 h-[32px] block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                        />

                        {!defaultStage && (
                          <div>
                            <Selection
                              className="w-[200px] mb-3"
                              title="Validation Type"
                              onChange={value => {
                                const stages = data.stages.map(s => {
                                  if (s.stage === d.stage && s.id === d.id) {
                                    return { ...s, validationType: value.id }
                                  }
                                  return s
                                })
                                setData({ ...data, stages })
                              }}
                              list={[
                                { name: 'Campaign Name', id: 'campaignName' },
                                { name: 'Campaign Source', id: 'campaignSource' },
                                { name: 'Drop Allowlist', id: 'dropAllowList' },
                                { name: 'Datalist', id: 'datalist' },
                                { name: 'None', id: '' },
                              ]}
                              value={d.validationType}
                            />
                            {showCampaign && (
                              <Selection
                                className="w-[200px]"
                                multiple
                                title={`Select Campaign ${d.validationType?.replace('campaign', '')}`}
                                onChange={value => {
                                  const stages = data.stages.map(s => {
                                    if (s.stage === d.stage && s.id === d.id) {
                                      return { ...s, validationValue: xor(d.validationValue, [value.id]) }
                                    }
                                    return s
                                  })
                                  setData({ ...data, stages })
                                }}
                                list={list}
                                value={d.validationValue}
                              />
                            )}
                            {showDataList && (
                              <Selection
                                className="w-[200px]"
                                multiple
                                title="Select Datalist"
                                onChange={value => {
                                  const stages = data.stages.map(s => {
                                    if (s.stage === d.stage && s.id === d.id) {
                                      return { ...s, validationValue: xor(d.validationValue, [value.id]) }
                                    }
                                    return s
                                  })
                                  setData({ ...data, stages })
                                }}
                                list={dataList.map(d => ({ id: d.slug, name: d.description }))}
                                value={d.validationValue?.filter(d => d !== '')}
                              />
                            )}
                          </div>
                        )}
                        {index > 1 && (
                          <button
                            onClick={() => {
                              if (isEmpty(data.stages[index].name) && isEmpty(data.stages[index].validationMessage)) {
                                data?.stages.pop()
                                setData({ ...data })
                              } else {
                                deleteStage(d.id)
                              }
                            }}
                            type="button"
                            className="ml-3 flex items-center justify-center h-[32px] border border-gray-300 shadow-sm rounded-md relative min-w-[32px]"
                          >
                            <MinusIcon className="h-4 w-4 text-gray-400" />
                          </button>
                        )}

                        {index === data?.stages?.length - 1 && (
                          <button
                            onClick={() => {
                              data?.stages.push({ name: '', validationMessage: '' })
                              setData({ ...data })
                            }}
                            type="button"
                            className="ml-3 flex items-center justify-center h-[32px] border border-gray-300 shadow-sm rounded-md relative min-w-[32px]"
                          >
                            <PlusIcon className="h-4 w-4 text-gray-400" />
                          </button>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            )}

            <div className="pt-2">
              <div className="mt-4 flex space-x-4 justify-end">
                <button
                  onClick={() => {
                    history.go(-1)
                  }}
                  className="inline-flex items-center  px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
                >
                  Go Back
                </button>
                <button
                  disabled={loading}
                  onClick={() => saveStages()}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
                >
                  {loading ? 'Saving...' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Stages
