import LayoutRouter from 'containers/Router'
import { destroyCookie, parseCookies } from 'nookies'
import { useState, useEffect } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useGet } from 'hooks/useApi'
import { setUser, setWorkspace, setWorkspaceList } from 'state/redux/actions/user.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import 'styles/index.less'
import ScannerRouter from 'containers/ScannerRouter'
import useModal from 'hooks/useModal'
import ChangePassword from 'containers/ChangePassword'

const App = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const { accessToken, workspaceID, scanner } = parseCookies()

  const { response, refetch, isFetched } = useGet('auth', {
    retry: false,
    enabled: false,
  })

  const user = useSelector(state => get(state, 'user.user'))

  const scannerInterface = Boolean(scanner === 'true')

  const [ChangePasswordModal, openChangePasswordModal] = useModal({
    content: <ChangePassword />,
    title: `Change Password`,
    dimensions: {
      height: 'auto',
      width: '500px',
      minHeight: 'auto',
      minWidth: '500px',
    },
  })

  useEffect(() => {
    if (response && !response?.success) {
      dispatch(setUser({}))
      dispatch(setWorkspace({}))
      destroyCookie(null, 'accessToken')
      destroyCookie(null, 'workspaceID')

      history?.push('/login')
      return
    }

    if (response?.success) {
      dispatch(setUser(response?.data?.user))
      dispatch(setWorkspace(response?.data?.workspace))
      dispatch(setWorkspaceList(response?.data?.user?.workspaces))

      setLoading(false)
    }
  }, [response])

  useEffect(() => {
    if (!isEmpty(accessToken)) {
      try {
        refetch()
      } catch (err) {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [accessToken, workspaceID])

  return (
    <>
      {user?.scanner && scannerInterface ? (
        <ScannerRouter user={user} isUser={!isEmpty(user) && !loading && isFetched} loading={loading} openChangePasswordModal={openChangePasswordModal} />
      ) : (
        <LayoutRouter user={user} isUser={!isEmpty(user) && !loading && isFetched} loading={loading} openChangePasswordModal={openChangePasswordModal} />
      )}
      <ChangePasswordModal />
    </>
  )
}

export default App
