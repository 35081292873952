import classNames from 'classnames'
import Input from 'components/atoms/Input'
import SetupWorkspace from './wizard/SetupWorkspace'
import Customization from './wizard/Customization'
import AuthMethods from './wizard/AuthMethods'
import QrConfig from './wizard/QrConfig'

const EditWorkspace = ({
  colors,
  splashRef,
  files,
  tabs,
  isEdit,
  setCurrentTab,
  readOnly,
  currentTab,
  props,
  workspaceSquareRef,
  showPreview,
  previews,
  setFiles,
  workspaceRef,
  setPCustomColor,
  primaryColorRef,
  pCustomColor,
  setSCustomColor,
  secondaryColorRef,
  sCustomColor,
  fontColorRef,
  fCustomColor,
  setFCustomColor,
  qrLogoRef,
  setQRConfig,
  qrConfig,
  setPopup,
  popup,
  setWorkspaceData,
  workspaceData,
  eventConfig,
  setEventConfig,
  getFeUrl,
  downloading,
  setDownloading,
  handleDownloadClick,
  events,
}) => {
  return (
    <div>
      <div className="border-b border-gray-200 pb-5 sm:pb-0">
        <h3 className="text-xl font-medium leading-6 text-gray-900">{isEdit ? `Workspace Settings ${readOnly ? '(Read-only)' : ''}` : 'Create new workspace'}</h3>

        <div className="mt-3 sm:mt-4">
          <div className="hidden sm:block">
            {isEdit && (
              <nav className="-mb-px flex space-x-8">
                {tabs
                  ?.filter(d => {
                    if (!isEdit && d !== 1) return false
                    return true
                  })
                  .map(tab => (
                    <span
                      key={tab.name}
                      onClick={() => setCurrentTab(tab.id)}
                      className={classNames(
                        tab.id === currentTab ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap pb-4 border-b-2 font-medium text-sm cursor-pointer'
                      )}
                      aria-current={currentTab === tab.id ? 'page' : undefined}
                    >
                      {tab.name}
                    </span>
                  ))}
              </nav>
            )}
          </div>
        </div>
      </div>
      <div className="mt-6 sm:mt-5">
        {!isEdit && (
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-2">
              <label htmlFor="first-name" className="block  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name of the Workspace <span className="text-red-500">*</span>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  type="text"
                  name="name"
                  placeholder="eg. My Workspace"
                  id="name"
                  autoComplete="name"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first-name" className="block  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Workspace domain <span className="text-red-500">*</span>
              </label>
              <div>
                <div className="mt-1 flex sm:mt-0 sm:col-span-2">
                  <div className="relative w-[350px]" aria-describedby="Text input with .id.club appended to the text value.">
                    <div
                      className="c-input_text_overlay c-input_text_overlay--large"
                      data-overlay-prefix=""
                      data-overlay-suffix=".id.club"
                      aria-hidden="true"
                      data-qa="text_overlay_suffix"
                    >
                      {props.values.subdomain || 'your-app'}
                    </div>
                    <input
                      onChange={e => props.setValues({ ...props.values, subdomain: e.target.value })}
                      value={props.values.subdomain}
                      data-qa="signin_subdomain_input"
                      spellCheck="false"
                      min="0"
                      max="0"
                      width="0"
                      aria-describedby="subdomain_hint"
                      aria-invalid="false"
                      aria-labelledby="subdomain_label"
                      aria-required="false"
                      aria-label=""
                      style={{ marginBottom: 0 }}
                      autoComplete="off"
                      className={classNames('c-input_text c-input_text--large full_width margin_bottom_100')}
                      id="subdomain"
                      name="subdomain"
                      placeholder="your-app"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isEdit && currentTab === 1 && (
          <SetupWorkspace
            workspaceSquareRef={workspaceSquareRef}
            showPreview={showPreview}
            setFiles={setFiles}
            files={files}
            previews={previews}
            workspaceRef={workspaceRef}
            props={props}
          />
        )}

        {isEdit && currentTab === 2 && (
          <Customization
            splashRef={splashRef}
            showPreview={showPreview}
            setFiles={setFiles}
            files={files}
            previews={previews}
            colors={colors}
            props={props}
            setPCustomColor={setPCustomColor}
            primaryColorRef={primaryColorRef}
            pCustomColor={pCustomColor}
            setSCustomColor={setSCustomColor}
            secondaryColorRef={secondaryColorRef}
            sCustomColor={sCustomColor}
            setFCustomColor={setFCustomColor}
            fontColorRef={fontColorRef}
            fCustomColor={fCustomColor}
          />
        )}

        {isEdit && currentTab === 3 && (
          <QrConfig
            qrLogoRef={qrLogoRef}
            showPreview={showPreview}
            setFiles={setFiles}
            files={files}
            previews={previews}
            setQRConfig={setQRConfig}
            qrConfig={qrConfig}
            setPopup={setPopup}
            popup={popup}
            getFeUrl={getFeUrl}
            downloading={downloading}
            setDownloading={setDownloading}
            handleDownloadClick={handleDownloadClick}
          />
        )}

        {currentTab === 4 && (
          <AuthMethods eventConfig={eventConfig} setEventConfig={setEventConfig} events={events} setWorkspaceData={setWorkspaceData} workspaceData={workspaceData} />
        )}
      </div>
    </div>
  )
}

export default EditWorkspace
