import notify from 'components/atoms/Notify'
import apiPost from 'lib/network/apiPost'
import { startCase } from 'lodash'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const FormList = ({ setId, data, openModal, fetch }) => {
  const history = useHistory()
  const deleteConfirm = async id => {
    const response = await apiPost(`forms/${id}/delete`)
    if (response.success) {
      notify.success('Form deleted successfully')
      fetch()
    }
  }

  return (
    <div className="">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th style={{ width: '20%' }} scope="col" className="px-6 py-4 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-4 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">
                      Last updated
                    </th>
                    <th scope="col" className="relative px-6 py-4">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map(form => {
                    return (
                      <tr key={form.id} className="bg-white border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{startCase(form.name)}</td>
                        <td className="px-6 py-0 whitespace-nowrap text-sm text-gray-500">{moment(form.updatedAt).fromNow()} </td>
                        <td className="relative px-6 py-3 text-right">
                          <span
                            onClick={() => {
                              history.push(`/forms/${form.id}/response`)
                            }}
                            className="px-4 py-1 mr-3 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-dark-400"
                          >
                            Responses
                          </span>
                          <span
                            onClick={() => {
                              history.push(`/forms/${form.id}`)
                            }}
                            className="px-4 py-1 mr-3 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-dark-400"
                          >
                            Manage Fields
                          </span>

                          <span
                            onClick={() => {
                              setId(form.id)
                              openModal()
                            }}
                            className="px-4 py-1 mr-3 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-dark-400"
                          >
                            Edit
                          </span>

                          <span
                            onClick={() => {
                              notify.deleteConfirm({
                                onConfirm: () => deleteConfirm(form.id),
                                dangerText: 'Delete',
                                title: 'Delete form?',
                                message: 'Are you sure you want to delete this form',
                              })
                            }}
                            className="px-4 mr-2 py-1 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-red-500"
                          >
                            Delete
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormList
