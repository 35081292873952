import { useEffect, useState } from 'react'

/*
 * Same as "useState" but saves the value to local storage each time it changes
 */

export const usePersistState = (initialValue, localStorageKey) => {
  const [value, setValue] = useState(() => {
    try {
      const valueFromLS = localStorage.getItem(localStorageKey)
      return valueFromLS ? JSON.parse(valueFromLS) : initialValue
    } catch (error) {
      return initialValue
    }
  })

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value))
  }, [value, localStorageKey])

  return [value, setValue]
}
