import * as types from 'state/redux/constants'

export const setUser = user => async dispatch => {
  try {
    dispatch({ type: types.SET_USER_DATA, data: user })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}

export const setWorkspace = workspace => async dispatch => {
  try {
    dispatch({ type: types.SET_WORKSPACE, data: workspace })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}

export const setTiers = tiers => async dispatch => {
  try {
    dispatch({ type: types.SET_TIERS, data: tiers })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}

export const setWorkspaceList = workspaces => async dispatch => {
  try {
    dispatch({ type: types.SET_WORKSPACE_LIST, data: workspaces })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}

export const setCommunityList = communities => async dispatch => {
  try {
    dispatch({ type: types.SET_COMMUNITY_LIST, data: communities })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}

export const setUserProject = project => async dispatch => {
  try {
    dispatch({ type: types.SET_USER_PROJECTS, data: project })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}

export const setTypes = typesData => async dispatch => {
  try {
    dispatch({ type: types.SET_TYPES, data: typesData })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}

export const setForms = formsData => async dispatch => {
  try {
    dispatch({ type: types.SET_FORMS, data: formsData })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}

export const setNotificationList = notifications => async dispatch => {
  try {
    dispatch({ type: types.SET_NOTIFICATION_LIST, data: notifications })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}

export const setEventList = events => async dispatch => {
  try {
    dispatch({ type: types.SET_EVENT_LIST, data: events })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}
