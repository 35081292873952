import classNames from 'classnames'
import { CheckIcon } from '@heroicons/react/outline'
import { CameraIcon } from '@heroicons/react/solid'

const Customization = ({
  splashRef,
  showPreview,
  setFiles,
  files,
  previews,
  colors,
  props,
  setPCustomColor,
  primaryColorRef,
  pCustomColor,
  setSCustomColor,
  secondaryColorRef,
  sCustomColor,
  setFCustomColor,
  fontColorRef,
  fCustomColor,
}) => {
  return (
    <div className="space-y-6 sm:space-y-5">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:pt-5">
        <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
          Splash Image
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="flex pb-4 items-center">
            <div className="bg-gray relative  h-[220px] w-[640px]  border border-dashed border-gray-300 rounded-md">
              <input
                id="splash"
                ref={splashRef}
                name="splash"
                className="hidden"
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={event => {
                  showPreview('splash', event.target.files[0])
                  setFiles({ ...files, splash: event.target.files[0] })
                }}
              />
              {previews.splash ? (
                <span onClick={() => splashRef.current.click()}>
                  <img src={previews.splash} alt="" className="cursor-pointer w-full h-full rounded" />
                </span>
              ) : (
                <div
                  onClick={() => splashRef.current.click()}
                  className="bg-white flex  items-center justify-center bg-contain bg-no-repeat bg-cente rounded-md cursor-pointer h-full w-full"
                >
                  {previews.splash ? null : <CameraIcon className="text-gray-400 h-8 w-8" />}
                </div>
              )}
              <span className="absolute -bottom-5 text-[11px] text-gray-400 -left-0">Cover/Splash Image ~(1280px x 440px)</span>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 sm:pt-5">
        <label htmlFor="first-name" className="block  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Primary Color
        </label>
        <div className="mt-1 flex sm:mt-0 sm:col-span-2">
          {colors?.map(color => {
            return (
              <div
                id="clickable"
                key={color}
                onClick={() => {
                  props.setFieldValue('primaryColor', color)
                  setPCustomColor(false)
                }}
                style={{ backgroundColor: color }}
                className={classNames(
                  color === props.values.primaryColor ? 'scale-150 mr-4 ml-2' : '',
                  'h-6 w-6 mr-2 flex justify-center items-center rounded-full cursor-pointer transition-all border border-gray-400'
                )}
              >
                {color === props.values.primaryColor ? <CheckIcon className="text-white h-4 w-4" /> : null}
              </div>
            )
          })}
          <div
            id="clickable"
            onClick={() => primaryColorRef.current.click()}
            style={
              !pCustomColor
                ? { backgroundImage: "url('https://a.rgbimg.com/users/x/xy/xymonau/600/n2UtdJe.jpg')", backgroundSize: 'cover', backgroundPosition: 'center' }
                : { backgroundColor: props.values.primaryColor }
            }
            className={classNames(pCustomColor ? 'scale-150 mr-4 ml-2' : '', 'h-6 w-6 mr-2 flex justify-center items-center rounded-full cursor-pointer')}
          >
            {pCustomColor ? <CheckIcon className="text-white  relative left-1  h-4 w-4" /> : null}
            <input
              value={props.values.primaryColor}
              defaultValue={props.values.primaryColor}
              onChange={e => {
                props.setFieldValue('primaryColor', e.target.value)
                setPCustomColor(true)
              }}
              ref={primaryColorRef}
              className="h-0 opacity-0 w-0 p-0 m-0 border-0 mr-2 justify-center items-center rounded-full cursor-pointer"
              type="color"
            />
          </div>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label htmlFor="first-name" className="block  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Secondary Color
        </label>
        <div className="mt-1 flex sm:mt-0 sm:col-span-2">
          {colors?.map(color => {
            return (
              <div
                id="clickable"
                key={color}
                onClick={() => {
                  props.setFieldValue('secondaryColor', color)
                  setSCustomColor(false)
                }}
                style={{ backgroundColor: color }}
                className={classNames(
                  color === props.values.secondaryColor ? 'scale-150 mr-4 ml-2' : '',
                  'h-6 w-6 mr-2 flex justify-center items-center rounded-full cursor-pointer transition-all border border-gray-400'
                )}
              >
                {color === props.values.secondaryColor ? <CheckIcon className="text-white h-4 w-4" /> : null}
              </div>
            )
          })}
          <div
            id="clickable"
            onClick={() => secondaryColorRef.current.click()}
            style={
              !sCustomColor
                ? { backgroundImage: "url('https://a.rgbimg.com/users/x/xy/xymonau/600/n2UtdJe.jpg')", backgroundSize: 'cover', backgroundPosition: 'center' }
                : { backgroundColor: props.values.secondaryColor }
            }
            className={classNames(sCustomColor ? 'scale-150 mr-4 ml-2' : '', 'h-6 w-6 mr-2 flex justify-center items-center rounded-full cursor-pointer')}
          >
            {sCustomColor ? <CheckIcon className="text-white relative left-1 h-4 w-4" /> : null}
            <input
              value={props.values.secondaryColor}
              defaultValue={props.values.secondaryColor}
              onChange={e => {
                props.setFieldValue('secondaryColor', e.target.value)
                setSCustomColor(true)
              }}
              ref={secondaryColorRef}
              className="h-0 opacity-0 w-0 p-0 m-0 border-0 mr-2 justify-center items-center rounded-full cursor-pointer"
              type="color"
            />
          </div>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label htmlFor="first-name" className="block  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Font Color
        </label>
        <div className="mt-1 flex sm:mt-0 sm:col-span-2">
          {colors?.map(color => {
            return (
              <div
                id="clickable"
                key={color}
                onClick={() => {
                  props.setFieldValue('fontColor', color)
                  setFCustomColor(false)
                }}
                style={{ backgroundColor: color }}
                className={classNames(
                  color === props.values.fontColor ? 'scale-150 mr-4 ml-2' : '',
                  'h-6 w-6 mr-2 flex justify-center items-center rounded-full cursor-pointer transition-all border border-gray-400'
                )}
              >
                {color === props.values.fontColor ? <CheckIcon className="text-white h-4 w-4" /> : null}
              </div>
            )
          })}
          <div
            id="clickable"
            onClick={() => fontColorRef.current.click()}
            style={
              !fCustomColor
                ? { backgroundImage: "url('https://a.rgbimg.com/users/x/xy/xymonau/600/n2UtdJe.jpg')", backgroundSize: 'cover', backgroundPosition: 'center' }
                : { backgroundColor: props.values.fontColor }
            }
            className={classNames(fCustomColor ? 'scale-150 mr-4 ml-2' : '', 'h-6 w-6 mr-2 flex justify-center items-center rounded-full cursor-pointer')}
          >
            {fCustomColor ? <CheckIcon className="text-white relative left-1 h-4 w-4" /> : null}
            <input
              value={props.values.fontColor}
              defaultValue={props.values.fontColor}
              onChange={e => {
                props.setFieldValue('fontColor', e.target.value)
                setFCustomColor(true)
              }}
              ref={fontColorRef}
              className="h-0 opacity-0 w-0 p-0 m-0 border-0 mr-2 justify-center items-center rounded-full cursor-pointer"
              type="color"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Customization
