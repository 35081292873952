import * as projectValidation from './project.validation'
import * as postSchema from './post.validation.js'
import { AddUserSchema } from './user.validation'
import * as twoFaAuthSchema from './twoFaAuth.validation'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  projectValidation,
  postSchema,
  AddUserSchema,
  twoFaAuthSchema,
}
