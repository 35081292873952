import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import apiDelete from 'lib/network/apiDelete'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { getDefaultProject } from 'lib/utils'

const AddEditDataList = () => {
  const history = useHistory()

  const [projectList, setProjectList] = useState(null)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      fetchDataList()
    }
  }, [id])

  const fetchDataList = async () => {
    const response = await apiGet(`datalist/${id}`)
    const item = response.data
    setData({ ...item, slug: item.slug, description: item.description, value: item.value, project: item.project?.id, type: item.type })

    if (item.type === 'simple-array') {
      setData(d => ({
        ...d,
        data: item.data?.join(','),
      }))
    }
  }

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    const projects = response.data

    setProjectList(projects)
    setData(d => ({ ...d, project: getDefaultProject(projects) }))
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  const saveDatalist = async () => {
    setLoading(true)
    const formData = new FormData()

    if (data.description) {
      formData.append('description', data?.description)
    }

    if (data.value) {
      formData.append('value', data?.value)
    }

    if (data.project) {
      formData.append('project', data?.project)
    }

    if (data.type) {
      formData.append('type', data.type)
    }

    if (data.slug) {
      formData.append('slug', data.slug)
    }

    if (data.data) {
      formData.append('data', data?.data)
    }

    const response = await apiPost(id ? `datalist/${id}` : 'datalist', formData)

    if (response.success) {
      notify.success('Datalist saved successfully')
      history.push('/datalist/drop')
    }
    setLoading(false)
  }

  const deleteDatalist = () => {
    notify.deleteConfirm({
      title: 'Delete Datalist',
      message: 'Are you sure you want to delete this Datalist?',
      onConfirm: async () => {
        const response = await apiDelete(`/datalist/${id}`)
        if (response?.success) {
          notify.success('Datalist deleted successfully')
        }
      },
    })
  }

  const user = useSelector(state => get(state, 'user.user'))

  return (
    <Layout title="Add New Datalist">
      <div className="p-4">
        <div className="w-full mb-[120px] flex flex-col">
          <div className="bg-white p-8 rounded-sm w-full border">
            <div className="flex items-center border-gray-200 pb-4 justify-between">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Datalist</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Select Project, Description, and Data / Values</p>
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Select Project
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  className="w-[250px]"
                  title="Select Project"
                  onChange={value => {
                    setData({ ...data, project: value.id })
                  }}
                  list={projectList}
                  value={data?.project}
                />
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  placeholder="eg: Private Sale Phase 1"
                  name="description"
                  value={data.description}
                  onChange={e => setData({ ...data, description: e.target.value })}
                  id="name"
                  className="max-w-sm block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Type
              </label>
              <div className="mt-1 gap-3 max-w-2xl sm:mt-0 sm:col-span-1">
                <Selection
                  disabled={!user.isPlatformAdmin}
                  className="w-[250px] h-[40px]"
                  title="Select Data Type"
                  onChange={e => {
                    setData({ ...data, type: e.id })
                  }}
                  list={[
                    { name: 'Simple Array', id: 'simple-array' },
                    { name: 'Array', id: 'array' },
                    { name: 'Object', id: 'object' },
                    { name: 'String', id: 'string' },
                    { name: 'Number', id: 'number' },
                  ]}
                  value={data.type}
                />
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Slug
              </label>
              <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                <input
                  placeholder="Hyphen separated slug"
                  name="slug"
                  value={data.slug}
                  onChange={e => setData({ ...data, slug: e.target.value })}
                  id="price"
                  className="max-w-sm block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Value
              </label>
              <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                <input
                  placeholder="eg: Special Price"
                  name="value"
                  value={data.value}
                  onChange={e => setData({ ...data, value: e.target.value })}
                  id="price"
                  className="max-w-sm block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
              <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                Data
              </label>
              <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                <textarea
                  rows={10}
                  placeholder="eg: JSON, comma separated values, etc"
                  name="data"
                  value={data.data}
                  onChange={e => setData({ ...data, data: e.target.value })}
                  className="max-w-sm block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="pt-2">
              <div className="mt-4 flex space-x-4 justify-end">
                {id && (
                  <button
                    onClick={deleteDatalist}
                    type="button"
                    className="inline-flex text-red-500 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                  >
                    Delete Datalist
                  </button>
                )}
                <button
                  onClick={() => {
                    history.go(-1)
                  }}
                  className="inline-flex items-center  px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
                >
                  Go Back
                </button>
                <button
                  disabled={loading}
                  onClick={() => saveDatalist()}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
                >
                  {loading ? 'Saving...' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AddEditDataList
