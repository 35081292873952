import { Cropper } from 'react-advanced-cropper'
import 'react-advanced-cropper/dist/style.css'
import { useRef, useState } from 'react'
import { split } from 'lodash'

export const ImageCropper = ({ currentFile, imageType, setPreview, setFiles, close }) => {
  const cropperRef = useRef()
  const [cropping, setCropping] = useState(false)

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = split(dataUrl, ',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const onCrop = () => {
    if (cropperRef.current) {
      setCropping(true)
      const base64String = cropperRef.current.getCanvas()?.toDataURL('image/jpeg')
      setPreview(preview => ({ ...preview, [imageType]: base64String }))
      const CreatedFile = dataURLtoFile(base64String, imageType)
      setFiles(files => ({ ...files, [imageType]: CreatedFile }))
      setCropping(false)
      close()
    }
  }

  return (
    <div>
      <Cropper
        stencilProps={{
          grid: true,
        }}
        src={currentFile}
        className={'cropper'}
        ref={cropperRef}
      />
      <div className="flex justify-end mt-2">
        <button
          onClick={close}
          type="button"
          className="mr-2 inline-flex text-red-500 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={cropping}
          onClick={onCrop}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
        >
          {cropping && (
            <svg className="animate-spin h-5 w-5 text-white mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Apply
        </button>
      </div>
    </div>
  )
}
