import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import moment from 'moment'
import apiGet from 'lib/network/apiGet'
import PopupMenu from 'components/molecules/PopupMenu'
import { useHistory } from 'react-router-dom'
import { IoEllipsisVertical } from 'react-icons/io5'
import Button from 'components/atoms/Button'
import notify from 'components/atoms/Notify'
import apiDelete from 'lib/network/apiDelete'
import { getDefaultProject, setDefaultProject } from 'lib/utils'

const Projects = () => {
  const [projectData, setProjectData] = useState([])
  const [defProject, setDefProject] = useState()
  const history = useHistory()

  useEffect(() => {
    fetchProjects()
  }, [])

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    setProjectData(response?.data)
    setDefProject(getDefaultProject(response?.data))
  }

  const menuOptions = [
    [
      { name: 'Edit', key: 'edit' },
      { name: 'Mark as default', key: 'make-default' },
    ],
    [{ name: 'Delete', key: 'delete' }],
  ]

  const menuClickHandler = (option, id) => {
    if (option.key === 'edit') {
      history.push(`/projects/edit/${id}`)
    }
    if (option.key === 'make-default') {
      setDefaultProject(id)
      setDefProject(id)
    }
    if (option.key === 'delete') {
      notify.deleteConfirm({
        title: 'Delete Project',
        message: 'Are you sure you want to delete this Project?. It will delete all the related data',
        onConfirm: async () => {
          const response = await apiDelete(`/project/${id}`)
          if (response?.success) {
            fetchProjects()
            history.push('/projects')
            notify.success('Success', 'Project deleted successfully')
          }
        },
      })
    }
  }

  return (
    <Layout
      breadcrumbs={[{ name: 'Projects', link: '/projects' }]}
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Button
            onClick={() => {
              history.push('/projects/add')
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Add new project
          </Button>
        </div>
      }
      title="Projects"
    >
      <div className="hidden sm:block">
        <div className="align-middle inline-block min-w-full">
          <table className="min-w-full">
            <thead>
              <tr className="border-gray-200">
                <th style={{ width: '20%' }} className="px-6 py-4 border-b border-gray-200 bg-gray-50 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">
                  <span className="lg:pl-2">Name</span>
                </th>

                <th className="hidden whitespace-nowrap md:table-cell px-6 py-4 border-b border-gray-200 bg-gray-50 text-right text-xs text-normal text-gray-500 uppercase tracking-wider">
                  Last updated
                </th>
                <th className="pr-6 py-4 border-b border-gray-200 bg-gray-50 text-right text-xs text-normal text-gray-500 uppercase tracking-wider" />
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {projectData?.map(project => {
                return (
                  <tr key={project.id}>
                    <td className="px-6 py-0 whitespace-nowrap text-sm text-normal text-gray-900">
                      <div onClick={() => history.push(`/projects/edit/${project.id}`)} className="flex cursor-pointer items-center space-x-3 lg:pl-2">
                        <div className="flex items-center">
                          <div className="ml-0">
                            <div className="text-sm text-normal flex items-center text-gray-900">
                              {project.name} {project.id === defProject ? <span className="bg-green-500 block ml-2 text-white px-1 py-0.5 rounded-md text-xs">Default</span> : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="hidden md:table-cell px-6 py-0 whitespace-nowrap text-sm text-gray-500 text-right">{moment(project.updatedAt).fromNow()}</td>
                    <td className="px-6 py-0 whitespace-nowrap text-right text-sm text-normal">
                      <PopupMenu onClick={option => menuClickHandler(option, project.id)} menu={menuOptions}>
                        <IoEllipsisVertical className="h-5 w-5" aria-hidden="true" />
                      </PopupMenu>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default Projects
