import notify from 'components/atoms/Notify'
import isEmpty from 'lodash/isEmpty'
import { parseCookies } from 'nookies'

const baseUrl = process.env.REACT_APP_BASE_URL

const apiPut = async (query, formData) => {
  const { accessToken, workspaceID } = parseCookies()

  const config = {
    method: 'PUT',
    body: formData,
  }

  if (!isEmpty(accessToken)) {
    config.headers = {
      Authorization: accessToken,
      workspaceID,
      ...config.headers,
    }
  }

  return fetch(`${baseUrl}/admin/${query}`, config)
    .then(response => {
      return response.json()
    })
    .then(response => {
      if (!response.success) {
        notify.error(response.message, null, response.err)
      }
      return response
    })
}

export default apiPut
