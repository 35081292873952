import { Route, Redirect } from 'react-router-dom'
import { cloneElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import Sidebar from 'containers/Sidebar'
import MobileHeader from 'containers/MobileHeader'
import classNames from 'classnames'
import { parseCookies } from 'nookies'

/**
 * @isPublic - can public see this page without login?
 * @isUser - is user logged in?
 * @WhatItDoes - This component will return user to login page if they are not logged in.
 * and if they are logged in then it will just return the passed component
 */

const AddSlider = ({ child, showSideBar, readOnly, openChangePasswordModal }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const drawer = useSelector(state => get(state, 'settings.drawer'))
  const user = useSelector(state => get(state, 'user.user'))

  const { scanner } = parseCookies()

  const scannerInterface = Boolean(scanner === 'true')
  const showSidebar = !scannerInterface

  return (
    <div>
      {showSideBar && showSidebar ? <Sidebar user={user} setVisible={setSidebarOpen} visible={sidebarOpen} openChangePasswordModal={openChangePasswordModal} /> : null}
      <div className={showSideBar ? classNames(drawer && showSidebar && 'lg:pl-64', 'flex cubic-transition flex-col') : classNames()}>
        {showSideBar && showSidebar ? <MobileHeader user={user} openChangePasswordModal={openChangePasswordModal} setSidebarOpen={setSidebarOpen} /> : null}
        {child}
      </div>
    </div>
  )
}

const AuthRoute = ({
  customRedirect = '/reports/registrations',
  readOnly,
  isPublic = false,
  showSideBar = true,
  children,
  isUser,
  component: Component = null,
  exact,
  path,
  openChangePasswordModal,
  ...rest
}) => {
  const onPublic = () => {
    if (isUser) {
      return <Redirect to={customRedirect} />
    } else {
      return Component ? (
        <AddSlider child={cloneElement(Component, { readOnly })} showSideBar={showSideBar} openChangePasswordModal={openChangePasswordModal} />
      ) : (
        <AddSlider child={children} showSideBar={showSideBar} openChangePasswordModal={openChangePasswordModal} />
      )
    }
  }
  const onPrivate = () => {
    if (isUser) {
      return Component ? (
        <AddSlider child={cloneElement(Component, { readOnly })} showSideBar={showSideBar} openChangePasswordModal={openChangePasswordModal} />
      ) : (
        <AddSlider child={children} showSideBar={showSideBar} openChangePasswordModal={openChangePasswordModal} />
      )
    } else {
      return <Redirect to="/login" />
    }
  }

  if (isPublic) {
    return <Route {...rest} exact={exact} path={path} render={() => onPublic()} />
  } else {
    return <Route {...rest} exact={exact} path={path} render={() => onPrivate()} />
  }
}

export default AuthRoute
