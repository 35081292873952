import { lazy, useEffect, useState } from 'react'
import { parseCookies } from 'nookies'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AuthRoute from 'containers/AuthRoute'
import AuthContainer from 'containers/AuthContainer'
import NotFound from 'pages/common/NotFound'
import Users from 'pages/users/Users'
import AddEditUser from 'pages/users/AddEditUser'
import AddEditProject from 'pages/projects/AddEditProject'
import Projects from 'pages/projects/Projects'
import SlugsPage from 'pages/slugs/GlobalSlugsList'
import AddEditSlug from 'pages/slugs/AddEditSlug'
import Properties from 'pages/properties/Properties'
import AddEditPost from 'pages/posts/AddEditPost'
import Posts from 'pages/posts/Posts'
import Types from 'pages/properties/Types'
import Roles from 'pages/properties/Roles'
import ConfigFlag from 'pages/config-flags/ConfigFlag'
import Events from 'pages/events/Events'
import AddEditEvent from 'pages/events/AddEditEvent'
import AddEditWorkspace from 'pages/workspaces/AddEditWorkspace'
import Workspaces from 'pages/workspaces/WorkspaceList'
import Contract from 'pages/smart-contract/Contract'
import Forms from 'pages/forms/Forms'
import FormFields from 'pages/forms/FormFields'
import Media from 'pages/media/Media'
import CustomFields from 'pages/users/CustomFields'
import FormResponses from 'pages/forms/FormResponses'
import Reports from 'pages/reports/Reports'
import Credentials from 'pages/workspaces/Credentials'
import NFTSeries from 'pages/nft/NFTSeries'
import AddNFTSeries from 'pages/nft/AddNFTSeries'
import Drops from 'pages/nft/Drops'
import AddDrop from 'pages/nft/AddEditDrop'
import AddEditContract from 'pages/smart-contract/AddEditContact'
import MintedNFTs from 'pages/nft/MintedNFTs'
import Randomization from 'pages/nft/Randomization'
import Stages from 'pages/nft/Stages'
import RandomizationList from 'pages/nft/RandomizationList'
import NGenAssets from 'pages/ngen/NGenAssets'
import DataList from 'pages/events/DataList'
import AddEditDataList from 'pages/events/AddEditDataList'
import ClubAccess from 'pages/events/ClubAccess'
import Tools from 'pages/tools/Tools'
import Tiers from 'pages/tiers/Tiers'
import AddEditTier from 'pages/tiers/AddEditTier'
import TierNotification from 'pages/tiers/TierNotification'
import EventRSVP from 'pages/events/RSVP'
import ContractTokens from 'pages/smart-contract/ContractTokens'
import { find, get, includes, isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { useReadonly } from 'hooks/useReadonly'
import ForgotPassword from 'pages/auth/ForgotPassword'
import Profile from 'pages/profile/Profile'
import EmailSender from 'pages/tools/EmailSender'
const DashboardPage = lazy(() => import('pages/dashboard'))
const LoginPage = lazy(() => import('pages/auth/Login'))

const LayoutRouter = ({ loading, isUser, user, openChangePasswordModal }) => {
  const [workspaceModal, setWorkspaceModal] = useState(false)
  const routes = [
    { path: '/login', exact: true, isPublic: true, isUser, component: LoginPage, showSideBar: false },
    { path: '/forgot', exact: true, isPublic: true, isUser, component: <ForgotPassword />, showSideBar: false },
    { path: '/dashboard', isUser, component: <DashboardPage /> },
    { path: '/users/add', isUser, component: <AddEditUser /> },
    { path: '/users/edit/:id', isUser, component: <AddEditUser /> },
    { path: '/users/edit/:id/admins', isUser, component: <Users />, key: 'id' },
    { path: '/users/:id', isUser, component: <Users /> },
    { path: '/users', isUser, component: <Users /> },
    { path: '/posts/add', isUser, component: <AddEditPost /> },
    { path: '/posts/edit/:id', isUser, component: <AddEditPost /> },
    { path: '/posts/:id', isUser, component: <Posts /> },
    { path: '/events/add', isUser, component: <AddEditEvent /> },
    { path: '/events/edit/:id', isUser, component: <AddEditEvent /> },
    { path: '/events/rsvp/:id', isUser, component: <EventRSVP /> },
    { path: '/events', isUser, component: <Events /> },
    { path: '/workspaces/credentials', isUser, component: <Credentials /> },
    { path: '/config-flag', isUser, component: <ConfigFlag /> },
    { path: '/email-sender', isUser, component: <EmailSender /> },
    { path: '/workspaces/add', isUser, component: <AddEditWorkspace /> },
    { path: '/workspaces/edit/:id', isUser, component: <AddEditWorkspace />, key: 'id' },
    { path: '/workspaces', isUser, component: <Workspaces /> },
    { path: '/tiers/notification', isUser, component: <TierNotification /> },
    { path: '/tiers/edit/:id', isUser, component: <AddEditTier />, key: 'id' },
    { path: '/tiers/add', isUser, component: <AddEditTier /> },
    { path: '/tiers', isUser, component: <Tiers /> },
    { path: '/projects/add', isUser, component: <AddEditProject /> },
    { path: '/projects/edit/:id', isUser, component: <AddEditProject /> },
    { path: '/projects/edit/:id/:type', isUser, component: <AddEditProject /> },
    { path: '/projects', isUser, component: <Projects /> },
    { path: '/series/add', isUser, component: <AddNFTSeries /> },
    { path: '/series/edit/:id', isUser, component: <AddNFTSeries /> },
    { path: '/series', isUser, component: <NFTSeries /> },
    { path: '/nft/tokens', isUser, component: <MintedNFTs />, exact: true },
    { path: '/randomization', isUser, component: <Randomization />, exact: true },
    { path: '/randomization-list', isUser, component: <RandomizationList />, exact: true },
    { path: '/stages', isUser, component: <Stages />, exact: true },
    { path: '/profile', isUser, exact: true, component: <Profile /> },
    {
      component: <AddDrop />,
      isUser,
      path: '/drops/add',
    },
    {
      component: <AddDrop />,
      isUser,
      path: '/drops/edit/:id',
    },
    {
      component: <Drops />,
      isUser,
      path: '/drops',
    },
    {
      component: <ClubAccess />,
      isUser,
      path: '/club-access',
    },
    {
      component: <AddEditDataList />,
      isUser,
      path: '/datalist/drop/add',
    },
    {
      component: <AddEditDataList />,
      isUser,
      path: '/datalist/drop/edit/:id',
    },

    {
      component: <DataList />,
      isUser,
      path: '/datalist/drop',
    },

    {
      component: <Media />,
      isUser,
      exact: true,
      path: '/media',
    },
    {
      component: <Tools />,
      isUser,
      exact: true,
      path: '/tools',
    },
    {
      component: <Properties />,
      isUser,
      exact: true,
      path: '/properties/:id',
    },
    {
      component: <Roles />,
      isUser,
      exact: true,
      path: '/roles',
    },
    {
      component: <NGenAssets />,
      isUser,
      exact: true,
      path: '/ngen',
    },
    {
      component: <Types />,
      isUser,
      exact: true,
      path: '/types/:identifier',
    },
    {
      component: <Forms />,
      isUser,
      exact: true,
      path: '/forms',
    },
    {
      component: <FormFields />,
      isUser,
      exact: true,
      path: '/forms/:id',
    },
    {
      component: <CustomFields />,
      isUser,
      exact: true,
      path: '/custom-fields',
    },
    {
      component: <FormResponses />,
      isUser,
      exact: true,
      path: '/forms/:id/response',
    },
    {
      component: <SlugsPage />,
      isUser,
      path: '/global-slugs',
    },
    {
      component: <AddEditSlug />,
      isUser,
      path: '/global-slugs/add',
    },
    {
      component: <AddEditContract />,
      isUser,
      exact: true,
      path: '/smart-contracts/add',
    },
    {
      component: <ContractTokens />,
      isUser,
      exact: true,
      path: '/smart-contracts/:id/tokens',
    },
    {
      component: <AddEditContract />,
      isUser,
      exact: true,
      path: '/smart-contracts/edit/:id',
    },
    {
      component: <Contract />,
      isUser,
      exact: true,
      path: '/smart-contracts',
    },
    {
      component: <Reports />,
      isUser,
      exact: true,
      path: '/reports/registrations',
    },
    {
      component: <Reports />,
      isUser,
      exact: true,
      path: '/reports/external/users',
    },
    {
      component: <Reports />,
      isUser,
      exact: true,
      path: '/reports/external/transactions',
    },
    {
      component: <Reports />,
      isUser,
      exact: true,
      path: '/reports/campaigns',
    },
    {
      component: <Reports />,
      isUser,
      exact: true,
      path: '/reports/transactions',
    },
    {
      component: <Reports />,
      isUser,
      exact: true,
      path: '/reports/activity-logs',
    },
    {
      component: <Reports />,
      isUser,
      exact: true,
      path: '/reports/event-logs',
    },
  ]

  const access = user.access?.map(d => d.menu?.pathname)
  const hasWorkspaceSettings = find(user.access, d => d.menu?.name === 'Workspace Settings')
  const readOnlyWorkspaceSettings = find(user.access, d => d.menu?.name === 'Workspace Settings')?.readOnly

  const currentWorkspace = useSelector(state => get(state, 'user.workspace'))
  const { isImpersonated } = parseCookies()

  useEffect(() => {
    const creds = currentWorkspace?.creds
    // Check if the workspace has creds that is required and not yet set

    const emptyValues = creds?.filter(d => d.required && !d.value)

    if (!isEmpty(user) && !isEmpty(currentWorkspace) && (emptyValues?.length || !creds?.length)) {
      setWorkspaceModal(true)
      if (hasWorkspaceSettings && !includes(window?.location?.pathname, '/workspaces')) {
        window?.location?.replace('/workspaces/credentials?require-creds=true')
      }
    }
  }, [currentWorkspace, loading, user])

  const disableCopyData = () => {
    // eslint-disable-next-line prefer-promise-reject-errors
    navigator.clipboard.writeText = () => Promise.reject('Copying is not allowed')
    // eslint-disable-next-line prefer-promise-reject-errors
    navigator.clipboard.write = () => Promise.reject('Copying is not allowed')

    const buttons = document.querySelectorAll('#disable-export')
    for (let i = 0; i < buttons?.length; i++) {
      buttons[i].disabled = true
      buttons[i].style.visibility = 'hidden'
    }

    // add class in body to disable copy
    document.body.classList.add('unselectable')

    document.addEventListener('contextmenu', function (event) {
      event.preventDefault()
    })
  }

  useEffect(() => {
    document.addEventListener(
      'DOMNodeInserted',
      function (ev) {
        const location = window?.location?.pathname
        const accessItem = find(user.access, d => includes(location, d?.menu?.pathname))

        if (location === '/users') accessItem.readOnly = false // This change required because user are unable to edit profile

        if (includes(location, '/workspaces')) {
          useReadonly((hasWorkspaceSettings && readOnlyWorkspaceSettings) || isImpersonated)
        }

        useReadonly(accessItem?.readOnly || isImpersonated)

        if (user.export || isImpersonated) {
          disableCopyData()
        }
      },
      false
    )
  }, [isImpersonated])

  return (
    <Router>
      <AuthContainer loading={loading}>
        {!loading && user ? (
          <>
            {workspaceModal ? (
              <>
                <Switch>
                  <AuthRoute isUser={isUser} component={<LoginPage />} showSideBar={false} isPublic exact path="/login" />
                  <AuthRoute customRedirect="/workspaces/credentials" isUser={isUser} component={<Credentials />} showSideBar={false} exact path="/workspaces/credentials" />
                  <AuthRoute customRedirect="/workspaces/credentials" component={<Redirect to="/workspaces/credentials" />} isUser={isUser} path="/" />
                </Switch>
              </>
            ) : (
              <Switch>
                <AuthRoute isUser={isUser} component={<LoginPage />} showSideBar={false} isPublic exact path="/login" />
                <AuthRoute isUser={isUser} component={<ForgotPassword />} showSideBar={false} isPublic exact path="/forgot" />

                {routes
                  ?.filter(d => {
                    if (d.path === '/reports/registrations' || d.path === '/profile') {
                      return true
                    }

                    if (includes(d.path, '/workspaces') && hasWorkspaceSettings) {
                      return true
                    }

                    // check if access has the path that starts with the route path
                    if (find(access, a => includes(d.path, a))) {
                      return true
                    }

                    return false
                  })
                  .map((route, index) => {
                    let readOnly = false

                    if (includes(route.path, '/workspaces')) {
                      readOnly = hasWorkspaceSettings && readOnlyWorkspaceSettings
                    }

                    const accessItem = find(user.access, d => includes(route.path, d?.menu?.pathname))
                    if (accessItem) {
                      readOnly = accessItem?.readOnly
                    }

                    return <AuthRoute readOnly={readOnly} key={route.key || index} openChangePasswordModal={openChangePasswordModal} {...route} />
                  })}

                {!isUser && <AuthRoute component={<Redirect to="/login" />} isUser={isUser} path="/" />}

                {isUser && <AuthRoute component={<Redirect to="/reports/registrations" />} isUser={isUser} path="/" />}
                {isUser && <AuthRoute component={<Redirect to="/dashboard" />} isUser={isUser} path="/" />}

                <Route component={NotFound} />
              </Switch>
            )}
          </>
        ) : (
          <Switch>
            <AuthRoute isUser={isUser} component={<LoginPage />} showSideBar={false} isPublic exact path="/login" />
            <AuthRoute component={<Redirect to="/login" />} isUser={isUser} path="/" />
            <AuthRoute isUser={isUser} component={<ForgotPassword />} showSideBar={false} isPublic exact path="/forgot" />
          </Switch>
        )}
      </AuthContainer>
    </Router>
  )
}

export default LayoutRouter
