import useUrlState from '@ahooksjs/use-url-state'
import LoadingIcon from 'components/atoms/LoadingIcon'
import notify from 'components/atoms/Notify'
import EmptyState from 'components/pageComponents/EmptyState'
import { Form, Formik } from 'formik'
import apiPost from 'lib/network/apiPost'
import apiPut from 'lib/network/apiPut'
import { size, isEmpty, keys, forEach, isArray, isObject, split, filter, includes, find } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import apiGet from 'lib/network/apiGet'
import { decrypt } from 'lib/utils'
import EditWorkspace from './EditWorkspace'
import AddWorkspace from './AddWorkspace'

const Workspace = ({ isEdit, loading, id, workspaceData: data, fetchWorkspace, readOnly, isWizard, setId }) => {
  const [previews, setPreviews] = useState({})
  const [files, setFiles] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [isWorkspaceCredError, setIsWorkspaceCredError] = useState(false)

  const [pCustomColor, setPCustomColor] = useState(false)
  const [sCustomColor, setSCustomColor] = useState(false)
  const [fCustomColor, setFCustomColor] = useState(false)

  const [workspaceData, setWorkspaceData] = useState(null)
  const [domains, setDomains] = useState([])
  const [eventConfig, setEventConfig] = useState({})
  const [events, setEvents] = useState([])

  const [qrConfig, setQRConfig] = useState({})
  const [popup, setPopup] = useState({})
  const frontEndUrl = find(workspaceData?.creds, { name: 'web-url' })

  const getFeUrl = () => {
    if (!frontEndUrl) return null
    const decryptedUrl = decrypt(frontEndUrl?.value)
    return `${decryptedUrl}/profile/qr`
  }

  function handleDownloadClick(item) {
    setDownloading(true)
    const canvas = document.getElementById('react-qrcode-logo')
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      const downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = `${workspaceData?.name} Qr code.png`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
    setDownloading(false)
  }

  useEffect(() => {
    fetchEvent()
  }, [])

  const convertImageUrlToBase64String = (key, url) => {
    if (key && url) {
      const imageUrl = url
      const img = document.createElement('img')
      img.setAttribute('crossorigin', 'anonymous')

      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const context = canvas.getContext('2d')
        context.drawImage(img, 0, 0, img.width, img.height)
        setPreviews({ ...previews, [key]: canvas.toDataURL('image/png') })
      }
      img.src = imageUrl
    }
  }

  useEffect(() => {
    if (data && isEmpty(workspaceData)) {
      setPreviews({ logo: data?.logo, squareLogo: data?.squareLogo, splash: data?.splash, qrLogo: data?.qrLogo })

      if (currentTab === 3) convertImageUrlToBase64String('qrLogo', data?.qrLogo)

      if (!includes(colors, data?.primaryColor)) setPCustomColor(true)
      if (!includes(colors, data?.secondaryColor)) setSCustomColor(true)
      if (!includes(colors, data?.fontColor)) setFCustomColor(true)

      setEventConfig(d => ({ ...d, id: data?.eventConfig?.id }))

      const { loginConfig } = data

      if (loginConfig) {
        const { mintType } = loginConfig
        if (mintType === 'pre-mint') {
          data.loginConfig.preMint = true
        }
        if (mintType === 'post-mint') {
          data.loginConfig.postMint = true
        }
        if (mintType === 'both') {
          data.loginConfig.postMint = true
          data.loginConfig.preMint = true
        }
        delete data.loginConfig.mintType
      }

      setTimeout(() => {
        delete data?.eventConfig
        setWorkspaceData(data)
        setDomains(data?.domain)
      }, 300)
    }
    return () => {
      setEventConfig({})
    }
  }, [data])

  useEffect(() => {
    setQRConfig(JSON.parse(JSON.stringify(workspaceData?.qrConfig || '{}')))
  }, [workspaceData])

  useEffect(() => {
    if (eventConfig.id && events?.length > 0) {
      const event = find(events, { id: eventConfig.id })
      setEventConfig(d => ({ ...d, ...event.stepConfig }))
    }
    return () => {
      setEventConfig({})
    }
  }, [eventConfig.id, events])

  const addWorkspace = async data => {
    delete data.qrConfig
    if (currentTab === 4) {
      const { loginConfig } = data
      if (loginConfig) {
        const mintType = loginConfig?.postMint && loginConfig?.preMint ? 'both' : loginConfig?.postMint ? 'post-mint' : loginConfig?.preMint ? 'pre-mint' : null
        if (!mintType) return notify.error('Validation Error', 'Please select the mint type')

        if ((mintType === 'both' || mintType === 'post-mint') && size(loginConfig.postMintMethods) === 0)
          return notify.error('Validation Error', 'Please select the post mint methods')
        if ((mintType === 'both' || mintType === 'pre-mint') && size(loginConfig.preMintMethods) === 0)
          return notify.error('Validation Error', 'Please select the pre mint methods')

        delete data.loginConfig.postMint
        delete data.loginConfig.preMint
        data.loginConfig.mintType = mintType
      } else return notify.error('Validation Error', 'Please select the mint type')
    }

    setIsSubmitting(true)
    const formData = new FormData()
    if (isEdit) {
      formData.append('id', parseInt(id, 10))
    }
    delete data.id
    forEach(keys(data), key => {
      data.domain = filter(data.domain, function (d) {
        return !isEmpty(d)
      })
      setDomains(data.domain)
      if (data[key] === 'null' || data[key] === undefined || data[key] === null) delete data[key]
      // eslint-disable-next-line
      if (typeof data[key] === 'string' || typeof data[key] === 'number' || typeof data[key] === 'boolean' || Array.isArray(data[key])) {
        formData.append(key, data[key])
      }
      if (isObject(data[key]) && !isArray(data[key])) formData.append(key, JSON.stringify(data[key]))
    })
    formData.append('qrConfig', JSON.stringify(qrConfig))
    formData.append('eventConfig', JSON.stringify(eventConfig))
    if (files.logo) formData.append('logo', files.logo)
    if (files.squareLogo) formData.append('squareLogo', files.squareLogo)
    if (files.splash) formData.append('splash', files.splash)
    if (files.qrLogo) formData.append('qrLogo', files.qrLogo)
    if (isEdit) {
      const response = await apiPut(`workspace/${parseInt(id, 10)}`, formData)
      if (response.success) {
        notify.success('workspace updated successfully!')
        if (isWizard) {
          if (currentStep === 6) window.location.href = `/workspaces/edit/${id}?t=1`
          setCurrentStep(currentStep + 1, id)
        }

        fetchWorkspace()
      }
      setIsSubmitting(false)
    } else {
      if (!data?.name || !data?.subdomain) {
        setIsSubmitting(false)
        return notify.error('Please fill out the required fields')
      }
      const response = await apiPost('workspace', formData)
      if (response.success) {
        notify.success('Workspace added successfully!')
        setWorkspaceData(response.data)
        setCurrentStep(currentStep + 1, response?.data?.id)
        setWorkspaceData(response?.data)
      }
      setIsSubmitting(false)
    }
  }

  const fetchEvent = async () => {
    const response = await apiGet(`events`)
    setEvents(response?.data)
  }

  const colors = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#9e9e9e',
    '#607d8b',
    '#ffffff',
    '#000000',
  ]

  const showPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreviews({ ...previews, [key]: preview })
  }

  const workspaceRef = useRef(null)
  const workspaceSquareRef = useRef(null)
  const splashRef = useRef(null)

  const qrLogoRef = useRef(null)

  const primaryColorRef = useRef(null)
  const secondaryColorRef = useRef(null)
  const fontColorRef = useRef(null)

  const tabs = [
    { id: 1, name: 'Overview', href: '#', current: false },
    { id: 2, name: 'Customization', href: '#', current: false },
    { id: 3, name: 'QR Profile Settings', href: '#', current: false },
    { id: 4, name: 'Sign-up and Login', href: '#', current: true },
  ]

  const [params, setParams] = useUrlState({ t: tabs[0]?.name })

  const currentTab = parseInt(params.t, 10)
  const setCurrentTab = currentTab => setParams({ ...params, t: currentTab })

  useEffect(() => {
    if (!currentTab && isEdit) {
      setCurrentTab(tabs[0]?.id)
    }
  }, [currentTab, isEdit])

  const steps = [
    { id: 1, name: 'Create Workspace' },
    { id: 2, name: 'Setup Credentials' },
    { id: 3, name: 'Setup Workspace' },
    { id: 4, name: 'Sign-up and Login' },
    { id: 5, name: 'Customization' },
    { id: 6, name: 'QR Profile Settings' },
  ]

  const [stepsParams, setStepsParams] = useUrlState({ t: steps[0]?.name })

  const currentStep = parseInt(stepsParams.t, 10)
  const setCurrentStep = (currentStep, workspaceId = null) => setStepsParams({ ...stepsParams, t: currentStep, workspaceId })

  useEffect(() => {
    if (!currentStep) {
      setCurrentStep(steps[0]?.id)
    }
  }, [currentStep])

  return (
    <Formik
      validateOnChange={false}
      initialValues={
        isEdit ? { ...workspaceData, logo: null, domain: split(domains, ','), qrType: workspaceData?.qrType || 'simple' } : { qrType: 'simple', domain: [''], color: colors[0] }
      }
      enableReinitialize
      onSubmit={addWorkspace}
    >
      {props =>
        (!loading && workspaceData && isEdit) || (!workspaceData && !isEdit) ? (
          <Form className="space-y-8 mx-auto px-8 py-8 rounded-md w-full">
            {isWizard ? (
              <AddWorkspace
                colors={colors}
                splashRef={splashRef}
                files={files}
                tabs={tabs}
                isEdit={isEdit}
                setCurrentTab={setCurrentTab}
                readOnly={readOnly}
                currentTab={currentTab}
                props={props}
                workspaceSquareRef={workspaceSquareRef}
                showPreview={showPreview}
                previews={previews}
                setFiles={setFiles}
                workspaceRef={workspaceRef}
                setPCustomColor={setPCustomColor}
                primaryColorRef={primaryColorRef}
                pCustomColor={pCustomColor}
                setSCustomColor={setSCustomColor}
                secondaryColorRef={secondaryColorRef}
                sCustomColor={sCustomColor}
                fontColorRef={fontColorRef}
                fCustomColor={fCustomColor}
                setFCustomColor={setFCustomColor}
                qrLogoRef={qrLogoRef}
                setQRConfig={setQRConfig}
                qrConfig={qrConfig}
                setPopup={setPopup}
                popup={popup}
                setWorkspaceData={setWorkspaceData}
                workspaceData={workspaceData}
                eventConfig={eventConfig}
                setEventConfig={setEventConfig}
                getFeUrl={getFeUrl}
                downloading={downloading}
                setDownloading={setDownloading}
                handleDownloadClick={handleDownloadClick}
                events={events}
                steps={steps}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                isSubmitting={isSubmitting}
                setIsWorkspaceCredError={setIsWorkspaceCredError}
                isWorkspaceCredError={isWorkspaceCredError}
              />
            ) : (
              <EditWorkspace
                colors={colors}
                splashRef={splashRef}
                files={files}
                tabs={tabs}
                isEdit={isEdit}
                setCurrentTab={setCurrentTab}
                readOnly={readOnly}
                currentTab={currentTab}
                props={props}
                workspaceSquareRef={workspaceSquareRef}
                showPreview={showPreview}
                previews={previews}
                setFiles={setFiles}
                workspaceRef={workspaceRef}
                setPCustomColor={setPCustomColor}
                primaryColorRef={primaryColorRef}
                pCustomColor={pCustomColor}
                setSCustomColor={setSCustomColor}
                secondaryColorRef={secondaryColorRef}
                sCustomColor={sCustomColor}
                fontColorRef={fontColorRef}
                fCustomColor={fCustomColor}
                setFCustomColor={setFCustomColor}
                qrLogoRef={qrLogoRef}
                setQRConfig={setQRConfig}
                qrConfig={qrConfig}
                setPopup={setPopup}
                popup={popup}
                setWorkspaceData={setWorkspaceData}
                workspaceData={workspaceData}
                eventConfig={eventConfig}
                setEventConfig={setEventConfig}
                getFeUrl={getFeUrl}
                downloading={downloading}
                setDownloading={setDownloading}
                handleDownloadClick={handleDownloadClick}
                events={events}
                isSubmitting={isSubmitting}
              />
            )}
            {!readOnly && !isWizard && (
              <div className="pt-5">
                <div className="flex justify-start">
                  <button
                    type="submit"
                    className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                  >
                    {isSubmitting ? (
                      <span className="flex items-start">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.workspace/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Please wait...</span>
                      </span>
                    ) : (
                      <span>{!isEdit ? 'Create' : 'Update'}</span>
                    )}
                  </button>
                </div>
              </div>
            )}
          </Form>
        ) : isEmpty(workspaceData) && !loading ? (
          <EmptyState v2 description="Workspace not found" />
        ) : (
          <div className="w-full h-[500px] flex items-center justify-center">
            <LoadingIcon />
          </div>
        )
      }
    </Formik>
  )
}

export default Workspace
