import notify from 'components/atoms/Notify'
import apiPost from 'lib/network/apiPost'
import { keys } from 'lodash'
import moment from 'moment'

const ConfigFlagForm = ({ setId, data, openModal, fetch }) => {
  const deleteConfirm = async id => {
    const response = await apiPost(`config/${id}/delete`)
    if (response.success) {
      notify.success('Config Flag deleted successfully')
      fetch()
    }
  }
  const values = {
    media: 'Media',
    users: 'User',
    series: 'Nft Series',
    1: 'Homepage',
    2: 'Featured',
  }

  const data1 = keys(data)
  return (
    <div className="">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th style={{ width: '20%' }} scope="col" className="px-6 py-4 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">
                      Table
                    </th>
                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Value
                    </th>
                    <th scope="col" className="px-6 py-4 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">
                      Last updated
                    </th>
                    <th scope="col" className="relative px-6 py-4">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data1?.map(type => {
                    return (
                      <tr key={data[type].id} className="bg-white border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{values?.[data[type].table]}</td>
                        <td className="px-6 py-0 whitespace-nowrap text-sm text-gray-500 ">
                          <span className="block text-gray-900 max-w-[400px] truncate">{values?.[data[type].value]}</span>
                        </td>
                        <td className="px-6 py-0 whitespace-nowrap text-sm text-gray-500">{moment(data[type].updatedAt).fromNow()} </td>
                        <td className="relative px-6 py-3 text-right">
                          <span
                            onClick={() => {
                              notify.deleteConfirm({
                                onConfirm: () => deleteConfirm(data[type].id),
                                dangerText: 'Delete',
                                title: 'Delete Config Flag?',
                                message: 'Are you sure you want to delete this Config Flag',
                              })
                            }}
                            className="px-4 mr-2 py-1 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-red-500"
                          >
                            Delete
                          </span>
                          <span
                            onClick={() => {
                              setId(data[type].id)
                              openModal()
                            }}
                            className="px-4 py-1 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-dark-400"
                          >
                            Edit
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfigFlagForm
