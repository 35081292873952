import { EyeIcon, EyeOffIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { decrypt } from 'lib/utils'
import { startCase, includes, keys } from 'lodash'
import notify from 'components/atoms/Notify'
import apiPost from 'lib/network/apiPost'
import React, { Fragment } from 'react'
import { IoAlertCircle } from 'react-icons/io5'
import classNames from 'classnames'
import { Tooltip } from 'antd'

const CredentialsList = ({
  credentials = {},
  apiKey,
  currentWorkspace,
  sections,
  toggleSection,
  showValues,
  toggleValue,
  setCredentialId,
  openCredentialModal,
  refetch,
  isWizard,
  workspaceId,
}) => {
  const [showApiKey, setShowApiKey] = React.useState(false)

  const Icon = ({ value, className = 'h-5 w-6 text-dark-700 inline' }) => {
    return !includes(showValues, value) ? <EyeOffIcon className={className} /> : <EyeIcon className={className} />
  }
  const deleteConfirm = async id => {
    const data = {}
    if (isWizard && workspaceId) data.workspaceId = workspaceId
    const response = await apiPost(`creds/${id}/delete`, data)
    if (response.success) {
      notify.success('Cred deleted successfully')
      refetch()
    }
  }

  return (
    <div>
      {apiKey && (
        <>
          <div className="px-6 border bg-white select-none border-b cursor-pointer py-4 flex sm:border-gray-200 items-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{currentWorkspace?.name || 'Workspace'}</h3>
          </div>
          <div className="py-4 mb-4 bg-white border-t-0 border">
            <div className="px-6 flex items-center sm:py-3">
              <label htmlFor="location" className={classNames('w-[20%] items-center text-[13px] flex font-medium text-gray-700')}>
                {currentWorkspace?.name || 'workspace'} API Key
              </label>
              <div className="content-start ml-4 flex w-[70%]">
                <span className="h-5 font-mono flex items-center">{showApiKey ? apiKey : '**********************************************'}</span>
                <span className="h-5 w-6 text-dark-700 inline ml-2" onClick={() => setShowApiKey(!showApiKey)}>
                  {showApiKey ? <EyeOffIcon className="h-5 w-6 text-dark-700 inline" /> : <EyeIcon className="h-5 w-6 text-dark-700 inline" />}
                </span>
              </div>
            </div>
          </div>
        </>
      )}

      {credentials &&
        keys(credentials)?.map(key => {
          return (
            <Fragment key={key}>
              <div
                onClick={() => toggleSection(key)}
                className="px-6 border bg-white select-none border-b cursor-pointer py-4 flex sm:border-gray-200 items-center justify-between"
              >
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">{startCase(key)}</h3>
                </div>
              </div>
              <div className="py-4 mb-4 bg-white border-t-0 border">
                {credentials[key]?.map(cred => {
                  const isRequired = cred.required && !cred.value
                  return (
                    <div key={cred.id} className="px-6 flex items-center justify-between sm:py-3">
                      <label htmlFor="location" className={classNames('w-[20%] items-center text-[13px] flex font-medium text-gray-700', isRequired && 'text-red-500')}>
                        {isRequired && (
                          <Tooltip title="Required">
                            <IoAlertCircle className="h-4 w-4 mr-2 text-red-500" />
                          </Tooltip>
                        )}{' '}
                        {startCase(cred.name)}:
                      </label>
                      <div className="content-between flex w-[70%]">
                        <span className="h-5 font-mono flex items-center">
                          {includes(showValues, cred?.id) || !cred.value ? decrypt(cred.value) : '**********************************************'}
                        </span>
                        <span className="h-5 w-6 text-dark-700 inline ml-2" onClick={() => toggleValue(cred?.id)}>
                          <Icon value={cred?.id} />
                        </span>
                      </div>
                      <div className="sm:mt-0 sm:col-span-1 text-end">
                        <PencilIcon
                          className="h-5 w-6 cursor-pointer text-dark-700 inline mr-1"
                          onClick={() => {
                            setCredentialId(cred?.id)
                            openCredentialModal()
                          }}
                        />
                        <TrashIcon
                          className="h-5 w-6 ml-4 cursor-pointer text-dark-700 inline"
                          onClick={() => {
                            notify.deleteConfirm({
                              onConfirm: () => deleteConfirm(cred?.id),
                              dangerText: 'Delete',
                              title: `Delete ${cred?.name}?`,
                              message: `Are you sure you want to delete this?`,
                            })
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </Fragment>
          )
        })}
    </div>
  )
}

export default CredentialsList
