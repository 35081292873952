import Loading from 'pages/common/Loading'

const AuthContainer = ({ children, loading }) => {
  if (!loading) {
    return <div className="app">{children}</div>
  } else {
    return <Loading />
  }
}

export default AuthContainer
