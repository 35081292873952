import notify from 'components/atoms/Notify'
import apiDelete from 'lib/network/apiDelete'
import { startCase } from 'lodash'
import moment from 'moment'

const Role = ({ setId, data, openModal, fetch }) => {
  const deleteConfirm = async roleId => {
    const response = await apiDelete(`roles/${roleId}/delete`)
    if (response.success) {
      notify.success('Role deleted successfully')
      fetch()
    }
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto lg:-mx-0">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th style={{ width: '20%' }} scope="col" className="px-6 py-4 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">
                    Name
                  </th>

                  <th scope="col" className="px-6 py-4 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">
                    Last updated
                  </th>
                  <th scope="col" className="relative px-6 py-4">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.roles?.map(role => {
                  // const permissions = keys(role).filter(key => role[key] === true)
                  return (
                    <tr key={role.id} className="border-b bg-white">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-normal text-gray-900">{startCase(role?.name)}</td>

                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{moment(role?.updatedAt).fromNow()}</td>
                      <td className="relative px-6 py-1 text-right">
                        <span
                          onClick={() => {
                            notify.deleteConfirm({
                              onConfirm: () => deleteConfirm(role?.id),
                              dangerText: 'Delete',
                              title: 'Delete role?',
                              message: 'Are you sure you want to delete this role',
                            })
                          }}
                          className="px-4 mr-2 py-1 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-red-500"
                        >
                          Delete
                        </span>
                        <span
                          onClick={() => {
                            setId(role?.id)
                            openModal()
                          }}
                          className="px-4 py-1 rounded whitespace-nowrap text-xs text-normal cursor-pointer text-white bg-dark-400"
                        >
                          Edit
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Role
