import CryptoJS from 'crypto-js'
import { find, keys } from 'lodash'

const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'Size not available'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

const encrypt = text => {
  if (text) {
    const encrypted = CryptoJS.AES.encrypt(text, process.env.REACT_APP_ENCYRPTION_KEY).toString()
    return encrypted
  } else return text
}

const decrypt = encrypted => {
  if (encrypted) {
    const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_ENCYRPTION_KEY)
    const originalText = decrypted.toString(CryptoJS.enc.Utf8)
    return originalText
  } else return encrypted
}

const objectToBase64url = payload => arrayBufferToBase64Url(new TextEncoder().encode(JSON.stringify(payload)))

const arrayBufferToBase64Url = buffer =>
  btoa(String.fromCharCode(...new Uint8Array(buffer)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')

export async function streamSignedUrl(videoID) {
  const expiresTimeInS = 3600
  const keyID = process.env.REACT_APP_CF_KEY
  const jwkKey = process.env.REACT_APP_JWK_KEY

  const encoder = new TextEncoder()
  const expiresIn = Math.floor(Date.now() / 1000) + expiresTimeInS
  const headers = {
    alg: 'RS256',
    kid: keyID,
  }
  const data = {
    sub: videoID,
    kid: keyID,
    exp: expiresIn,
  }

  const token = `${objectToBase64url(headers)}.${objectToBase64url(data)}`

  const jwk = JSON.parse(atob(jwkKey))

  const key = await crypto.subtle.importKey(
    'jwk',
    jwk,
    {
      name: 'RSASSA-PKCS1-v1_5',
      hash: 'SHA-256',
    },
    false,
    ['sign']
  )

  const signature = await crypto.subtle.sign({ name: 'RSASSA-PKCS1-v1_5' }, key, encoder.encode(token))

  const signedToken = `${token}.${arrayBufferToBase64Url(signature)}`

  return signedToken
}

const getDefaultProject = (projects = []) => {
  const defaultProjectId = localStorage.getItem('default_project')

  if (projects.length > 0 && defaultProjectId) {
    return find(projects, { id: parseInt(defaultProjectId, 10) })?.id
  }

  return projects[0]?.id
}

const setDefaultProject = id => {
  return localStorage.setItem('default_project', id)
}

const cleanObject = obj => {
  // eslint-disable-next-line
  if (obj && typeof obj === 'object') {
    // eslint-disable-next-line no-restricted-syntax
    for (const k of keys(obj)) {
      if (obj[k] === null) {
        delete obj[k]
        // eslint-disable-next-line
      } else if (typeof obj[k] === 'object') {
        cleanObject(obj[k])
      }
    }
  }
  return obj
}

// eslint-disable-next-line lodash/prefer-lodash-method
const isDev = window.location.hostname?.includes('localhost') || window.location.hostname?.includes('-dev')

export { bytesToSize, encrypt, decrypt, getDefaultProject, setDefaultProject, isDev, cleanObject }
