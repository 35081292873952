import Input from 'components/atoms/Input'
import { Field, Form, Formik } from 'formik'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useState } from 'react'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import apiPut from 'lib/network/apiPut'
import { useSelector } from 'react-redux'
import { forEach, get, isArray, isBoolean, isString, keys, isNumber, isObject } from 'lodash'

const AddEditUserField = ({ fetchData, close, id }) => {
  const [fieldData, setFieldData] = useState(null)
  const types = useSelector(state => get(state, 'user.types', {}))

  const properties = types?.properties

  useEffect(() => {
    if (id) {
      fetchField()
    }
  }, [id])

  const fetchField = async () => {
    const response = await apiGet(`user-field/${id}`)
    setFieldData(response.data)
  }

  const saveFieldData = async data => {
    const formData = new FormData()

    if (id) {
      formData.append('id', parseInt(id, 10))
    }

    delete data.id

    forEach(keys(data), key => {
      if (data[key] === 'null' || data[key] === undefined || data[key] === null) delete data[key]
      if (isString(data[key]) || isNumber(data[key]) || isBoolean(data[key]) || isArray(data[key])) {
        formData.append(key, data[key])
      }
      if (isObject(data[key]) && !isArray(data[key])) formData.append(key, JSON.stringify(data[key]))
    })

    if (id) {
      const response = await apiPut(`user-field/${parseInt(id, 10)}`, formData)
      if (response.success) {
        notify.success('User field updated successfully!')
        fetchData()
        close()
      }
    } else {
      const response = await apiPost('user-field', formData)
      if (response.success) {
        notify.success('User Fields added successfully!')
        fetchData()
        close()
      }
    }
  }

  const initialValues = {
    label: fieldData?.label,
    placeholder: fieldData?.placeholder,
    type: fieldData?.type,
    required: fieldData?.required || false,
    forEvent: fieldData?.forEvent || false,
    description: fieldData?.description,
  }

  return (
    <Formik initialValues={id ? initialValues : {}} enableReinitialize={true}>
      {props => (
        <Form>
          <div className="mb-8 space-y-4">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-0">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Field Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  type="text"
                  name="label"
                  placeholder="eg: name, contact no, etc."
                  id="label"
                  autoComplete="new-password"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Field Placeholder
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  type="text"
                  name="placeholder"
                  placeholder="eg: Field Placeholder"
                  id="placeholder"
                  autoComplete="new-password"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Field Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  type="text"
                  name="description"
                  placeholder="eg: Field Description"
                  id="placeholder"
                  autoComplete="new-password"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Field Type
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  list={[
                    { name: 'Text', id: 'text' },
                    { name: 'Textarea', id: 'textarea' },
                    { name: 'Number', id: 'number' },
                    { name: 'Property', id: 'property' },
                    { name: 'Date', id: 'date' },
                    { name: 'Select', id: 'select' },
                    { name: 'Radio', id: 'radio' },
                    { name: 'Checkbox', id: 'checkbox' },
                  ]}
                  title="Select Field Type"
                  onChange={value => {
                    props.setFieldValue('type', value.id)
                  }}
                  value={props.values.type}
                />
              </div>
            </div>

            {props.values.type === 'property' && (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Property Type
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Selection
                    list={properties?.map(d => ({ name: d.name, id: d.id }))}
                    title="Select Property Type"
                    onChange={value => {
                      props.setFieldValue('propertyType', value.id)
                    }}
                    value={props.values.propertyType}
                  />
                </div>
              </div>
            )}

            <div className="sm:grid items-center sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px">
                Required
              </label>
              <div className="mt-1 sm:mt-0 flex h-[20px] items-center sm:col-span-2">
                <Field type="checkbox" name="required" id="required" autoComplete="required" />
              </div>
            </div>

            <div className="sm:grid items-center sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px">
                Event Onboarding field
              </label>
              <div className="mt-1 sm:mt-0 flex h-[20px] items-center sm:col-span-2">
                <Field type="checkbox" name="forEvent" id="forEvent" autoComplete="required" />
              </div>
            </div>
          </div>

          <div className="mt-4 flex justify-end">
            <button
              onClick={() => close()}
              className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
            >
              Close
            </button>
            <button
              onClick={() => saveFieldData(props.values)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddEditUserField
