import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import apiGet from 'lib/network/apiGet'
import apiPut from 'lib/network/apiPost'
import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { forEach, isEmpty, keys, map, xor, join, get, includes, find, isObject, isArray, truncate, uniqBy, toLower } from 'lodash'
import apiDelete from 'lib/network/apiDelete'
import moment from 'moment-timezone'
import LoadingIcon from 'components/atoms/LoadingIcon'
import { DownloadIcon } from '@heroicons/react/outline'
import { useSelector } from 'react-redux'
import GoogleMapComponent from 'components/maps/GoogleMaps'
import useModal from 'hooks/useModal'
import { CameraIcon } from '@heroicons/react/solid'
import { convertBase64ToBlob } from 'utils'
import Popover from 'components/molecules/Popover'
import { IoInformationCircleSharp, IoCopyOutline } from 'react-icons/io5'
import { decrypt } from 'lib/utils'
import Switch from 'components/atoms/Switch'
import { QRCode } from 'react-qrcode-logo'
import ImageResizeModal from './modals/ImageResizeModal'

const EventForm = ({ isEdit, id, readOnly }) => {
  const allTimeZones = moment.tz?.names()?.map(timeZone => {
    return { id: timeZone, name: timeZone }
  })

  const [timeZones, setTimeZones] = useState({
    allTimeZones,
    startDateSearchTimeZones: allTimeZones,
    endDateSearchTimeZones: allTimeZones,
  })
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({ concierge: [], hosts: [] })
  const [searchHosts, setSearchHosts] = useState([])
  const [searchConcierge, setSearchConcierge] = useState([])
  const [hosts, setHosts] = useState([])
  const [concierge, setConcierge] = useState([])
  const [downloading, setDownloading] = useState(false)
  const [qrConfig, setQRConfig] = useState({})

  const [customEventField, setCustomEventField] = useState([])

  const [preview, setPreview] = useState({})
  const [tempPreview, setTempPreview] = useState({})
  const [imageType, setImageType] = useState()
  const [files, setFiles] = useState({})
  const [properties, setProperties] = useState([])

  const tiers = useSelector(state => get(state, 'user.tiers', [])) || []
  const currentWorkspace = useSelector(state => get(state, 'user.workspace'))

  const frontEndUrl = find(currentWorkspace?.creds, { name: 'web-url' })

  const getFeEventUrlWithSlug = slug => {
    if (!id || !slug || !frontEndUrl?.value) return null
    const decryptedUrl = decrypt(frontEndUrl?.value)
    return `${decryptedUrl}/events/${slug}`
  }

  function handleDownloadClick() {
    setDownloading(true)
    const canvas = document.getElementById('react-qrcode-logo')
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      const downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = `${data?.name} Qr code.png`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
    setDownloading(false)
  }

  useEffect(() => {
    if (id) {
      fetchEvent(id)
    }
    return () => {
      setData({})
    }
  }, [id])

  useEffect(() => {
    fetchCustomEventFields()
    fetchProperties()
    return () => {
      setProperties([])
    }
  }, [])

  const fetchProperties = async () => {
    const response = await apiGet(`properties/Event Categories`)
    setProperties(response?.data)
  }

  const fetchCustomEventFields = async () => {
    const response = await apiGet(`user-fields`, { forEvent: true })
    setCustomEventField(response?.data)
  }

  const convertGoogleMapsToBlob = (lat, lng) => {
    const width = 1200
    const height = 1200

    const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=18&size=${height}x${width}&format=png32&maptype=roadmap&markers=color:red%7C${lat},${lng}&scale=2&key=AIzaSyCq3ejXvl92LD0TgWQVzvK8CuuaJ90Vjj8`

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const imageObj = new Image()
    imageObj.width = 1200
    imageObj.height = 1200
    imageObj.crossOrigin = 'crossOrigin' // This enables CORS
    imageObj.onload = function () {
      canvas.width = imageObj.width
      canvas.height = imageObj.height
      context.drawImage(imageObj, 0, 0, imageObj.width, imageObj.height)
      const dataURL = canvas.toDataURL('image/png')
      const imageBlob = convertBase64ToBlob(dataURL)
      setFiles({ ...files, locationImage: new File([imageBlob], `${lat},${lng}`) })
      showPreview('locationImage', imageBlob)
    }
    imageObj.src = staticMapUrl
  }

  const convertImageUrlToBase64String = url => {
    if (url) {
      const imageUrl = url
      const img = document.createElement('img')
      img.setAttribute('crossorigin', 'anonymous')

      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const context = canvas.getContext('2d')
        context.drawImage(img, 0, 0, img.width, img.height)
        setPreview(p => ({ ...p, qrLogo: canvas.toDataURL('image/png') }))
      }
      img.src = imageUrl
    }
  }

  const showPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setPreview(p => ({ ...p, [key]: preview }))
  }

  const showTempPreview = (key, file) => {
    const preview = URL.createObjectURL(file)
    setTempPreview(p => ({ ...p, [key]: preview }))
    setImageType(key)
    openImageModal()
  }

  const fetchEvent = async () => {
    setQRConfig(JSON.parse(JSON.stringify(currentWorkspace?.qrConfig || '{}')))
    setLoading(true)
    const response = await apiGet(`event/${id}`, { minimal: true })
    const data = response.data

    delete response?.data?.image?.thumbnail

    forEach(Object.entries(response.data), ([key, value]) => {
      if (value === 'null') {
        delete response.data[key]
      }
    })

    setData({
      name: data.name,
      location: data.location,
      description: data.description,
      tiers: data.tiers?.map(d => d.id),
      blacklist: join(data.blacklistWallets || '', ','),
      start: data.start,
      end: data.end,
      stations: data.stations || {},
      validations: data.validations,
      hosts: map(data?.hosts, 'id'),
      concierge: map(data?.concierge, 'id'),
      lat: data?.lat,
      lng: data?.lng,
      maxAllowed: data?.maxAllowed,
      maxAttendees: data?.maxAttendees,
      properties: data?.properties?.map(d => d.id),
      slug: data?.slug,
      stepConfig: data?.stepConfig,
      requiresApproval: data?.requiresApproval,
      endDateTimeZone: data?.endDateTimeZone,
      startDateTimeZone: data?.startDateTimeZone,
    })

    convertImageUrlToBase64String(currentWorkspace.qrLogo)

    const lHosts =
      data?.hosts?.map(d => ({
        description: (d.username ? `@${d.username}` : null) ?? d.email ?? d.phone ?? (d.twitterId ? `@${d.twitterId}` : null) ?? (d.discordId ? `@${d.discordId}` : null),
        name: d.displayName ?? get(d, 'userProfiles[0].username'),
        id: d.id,
      })) || []

    const lConcierge =
      data?.concierge?.map(d => ({
        description: (d.username ? `@${d.username}` : null) ?? d.email ?? d.phone ?? (d.twitterId ? `@${d.twitterId}` : null) ?? (d.discordId ? `@${d.discordId}` : null),
        name: d.displayName ?? get(d, 'userProfiles[0].username'),
        id: d.id,
      })) || []

    setSearchHosts(lHosts)
    setSearchConcierge(lConcierge)

    setHosts(lHosts)
    setConcierge(lConcierge)

    setPreview(p => ({ ...p, splash: data?.splash, previewSplash: data?.previewSplash, locationImage: data?.locationImage }))

    setLoading(false)
  }

  const fetchSeries = () => {
    apiGet('series', { project: data.project }).then(response => {
      // setSeriesList(response?.data?.map(item => ({ name: item.name, id: item.id })))
    })
  }

  useEffect(() => {
    fetchSeries()
  }, [])

  const copyText = text => {
    const input = document.createElement('input')
    input.style.position = 'absolute'
    input.style.left = '-9999px'
    input.style.top = '0'
    input.value = text
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    notify.success('Copied to clipboard', truncate(text, { length: 35 }))
  }
  const addEvent = () => {
    setIsSubmitting(true)
    const formData = new FormData()

    if (isEdit) {
      formData.append('id', parseInt(id, 10))
      delete data.id
    }

    forEach(keys(data), key => {
      if (data[key] === 'null' || data[key] === undefined || data[key] === null) delete data[key]
      // eslint-disable-next-line
      if (typeof data[key] === 'string' || typeof data[key] === 'number' || typeof data[key] === 'boolean' || Array.isArray(data[key])) {
        formData.append(key, data[key])
      }
      if (isObject(data[key]) && !isArray(data[key])) formData.append(key, JSON.stringify(data[key]))
    })

    formData.delete('start')
    formData.delete('end')
    formData.delete('validations')

    formData.append('start', moment(data.start).utc().toISOString())
    formData.append('end', moment(data.end).utc().toISOString())

    if (!isEmpty(files)) {
      forEach(files, (file, key) => {
        formData.append(key, file)
      })
    }

    apiPut(isEdit ? `event/${id}` : 'event', formData)
      .then(response => {
        setIsSubmitting(false)
        if (response?.success) {
          if (isEdit) {
            notify.success('Success', 'Event updated successfully')
          } else {
            notify.success('Success', 'Event added successfully')
            history.push(`/events`)
          }
        }
      })
      .catch(error => {
        setIsSubmitting(false)
        console.log(error) // eslint-disable-line no-console
      })
  }

  const deletePost = data => {
    notify.deleteConfirm({
      title: 'Delete event',
      message: 'Are you sure you want to delete this event?',
      onConfirm: async () => {
        const response = await apiDelete(`event/${id}/delete`)
        if (response?.success) {
          history.push('/events')
          notify.success('Event deleted successfully')
        }
      },
    })
  }

  const [MapModel, openMapModel] = useModal({
    content: <GoogleMapComponent data={data} setData={setData} convertGoogleMapsToBlob={convertGoogleMapsToBlob} />,
    title: 'Select Location',
    dimensions: {
      height: 'auto',
      width: '750px',
      minHeight: 'auto',
      minWidth: '750px',
    },
  })

  const [ImageModal, openImageModal] = useModal({
    content: <ImageResizeModal currentFile={get(tempPreview, imageType, '')} imageType={imageType} setPreview={setPreview} setFiles={setFiles} />,
    title: 'Adjust Your Image',
    dimensions: {
      height: 'auto',
      width: '750px',
      minHeight: 'auto',
      minWidth: '750px',
    },
    responsive: true,
  })

  const searchList = async (value, type) => {
    if (value.length >= 2) {
      const list = await apiGet('users', { size: 10, page: 1, search: value, includeProfile: true })

      if (type === 'hosts') {
        setSearchHosts(
          list?.data?.users.map(d => ({
            description: (d.username ? `@${d.username}` : null) ?? d.email ?? d.phone ?? (d.twitterId ? `@${d.twitterId}` : null) ?? (d.discordId ? `@${d.discordId}` : null),
            name: d.displayName || d.phone || d.walletAddress || d.username || d.email,
            id: d.id,
          })) || []
        )
      }

      if (type === 'concierge') {
        setSearchConcierge(
          list?.data?.users.map(d => ({
            description: (d.username ? `@${d.username}` : null) ?? d.email ?? d.phone ?? (d.twitterId ? `@${d.twitterId}` : null) ?? (d.discordId ? `@${d.discordId}` : null),
            name: d.displayName || d.phone || d.walletAddress || d.userProfile?.username || d.email,
            id: d.id,
          })) || []
        )
      }
    }
  }

  const splashRef = useRef(null)
  const previewSplashRef = useRef(null)
  const locationImageRef = useRef(null)

  const localTz = 'Asia/Kolkata' // new Intl.DateTimeFormat().resolvedOptions()?.timeZone()

  return (
    <div className="p-6 overflow-visible">
      <div>
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{!isEdit ? 'Create Event' : 'Event details'}</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Create a new Event</p>
          </div>
        </div>
        {!loading ? (
          <div>
            <div className="space-y-6 mt-4 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Event Name <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    value={data?.name}
                    onChange={e => setData({ ...data, name: e.target.value })}
                    type="text"
                    name="name"
                    placeholder="Name of the Event"
                    id="name"
                    autoComplete="name"
                    className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center border-t sm:pt-5">
                <label htmlFor="photo" className="block text-sm text-gray-700">
                  Splash Image
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex pb-4 items-center">
                    <div className="bg-gray relative  h-[220px] w-[640px]  border border-dashed border-gray-300 rounded-md">
                      <input
                        id="splash"
                        ref={splashRef}
                        name="splash"
                        className="hidden"
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={event => {
                          showTempPreview('splash', event.target.files[0])
                        }}
                      />
                      {preview.splash ? (
                        <span onClick={() => splashRef.current.click()}>
                          <img src={preview.splash} alt="" className="cursor-pointer w-full h-full rounded" />
                        </span>
                      ) : (
                        <div
                          onClick={() => splashRef.current.click()}
                          className="bg-white flex  items-center justify-center bg-contain bg-no-repeat bg-center rounded-md cursor-pointer h-full w-full"
                        >
                          {preview.splash ? null : <CameraIcon className="text-gray-400 h-8 w-8" />}
                        </div>
                      )}
                      <span className="absolute -bottom-5 text-[11px] text-gray-400 -left-0">Cover/Splash Image ~(1280px x 440px)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center border-t sm:pt-5">
                <label htmlFor="photo" className="block text-sm text-gray-700">
                  Preview Splash Image
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex pb-4 items-center">
                    <div className="bg-gray relative  h-[220px] w-[640px]  border border-dashed border-gray-300 rounded-md">
                      <input
                        id="previewSplash"
                        ref={previewSplashRef}
                        name="previewSplash"
                        className="hidden"
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={event => {
                          showTempPreview('previewSplash', event.target.files[0])
                        }}
                      />
                      {preview.previewSplash ? (
                        <span onClick={() => previewSplashRef.current.click()}>
                          <img src={preview.previewSplash} alt="" className="cursor-pointer w-full h-full rounded" />
                        </span>
                      ) : (
                        <div
                          onClick={() => previewSplashRef.current.click()}
                          className="bg-white flex  items-center justify-center bg-contain bg-no-repeat bg-center rounded-md cursor-pointer h-full w-full"
                        >
                          {preview.previewSplash ? null : <CameraIcon className="text-gray-400 h-8 w-8" />}
                        </div>
                      )}
                      <span className="absolute -bottom-5 text-[11px] text-gray-400 -left-0">Cover/Splash Image ~(1280px x 440px)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 border-t sm:gap-4 sm:items-start  sm:border-gray-200 sm:pt-5">
                <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Location <span className="text-red-500">*</span>
                </label>

                <div className="mt-1 mb-0 sm:mt-0 mr-4 sm:col-span-2">
                  <div>
                    <div
                      onClick={openMapModel}
                      value={data?.location}
                      type="text"
                      disabled
                      name="name"
                      placeholder="Type Location Name"
                      id="name"
                      autoComplete="name"
                      className="max-w-lg p-3 cursor-pointer shadow-sm block w-full focus:ring-dark-500 focus:border-dark-500 sm:text-sm border border-gray-200 rounded-md"
                    >
                      <p className="">{data?.location ?? 'Please select the location from Map'}</p>
                    </div>
                    <span className="text-[11px] space-x-2 text-indigo-500 font-medium">
                      <span>{data?.lat ? `Latitude: ${data?.lat}` : ''}</span>
                      <span> {data?.lng ? `Longitude: ${data.lng}` : ''}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center border-t sm:pt-5">
                <label htmlFor="photo" className="block text-sm text-gray-700">
                  Location Image
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex pb-4 items-center">
                    <div className="bg-gray relative  h-[400px] w-[640px]  border border-dashed border-gray-300 rounded-md">
                      <input
                        id="locationImage"
                        ref={locationImageRef}
                        name="locationImage"
                        className="hidden"
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={event => {
                          showPreview('locationImage', event.target.files[0])
                          setFiles({ ...files, locationImage: event.target.files[0] })
                        }}
                      />
                      {preview.locationImage ? (
                        <span onClick={() => locationImageRef.current.click()}>
                          <img src={preview.locationImage} alt="" className="cursor-pointer w-full h-full rounded" />
                        </span>
                      ) : (
                        <div
                          onClick={() => locationImageRef.current.click()}
                          className="bg-white flex  items-center justify-center bg-contain bg-no-repeat bg-center rounded-md cursor-pointer h-full w-full"
                        >
                          {preview.locationImage ? null : <CameraIcon className="text-gray-400 h-8 w-8" />}
                        </div>
                      )}
                      <span className="absolute -bottom-5 text-[11px] text-gray-400 -left-0">Event Map Image ~(926px x 524px)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Event Categories
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Selection
                    onChange={value => {
                      setData({ ...data, properties: xor([value.id], data.properties || []) })
                    }}
                    multiple
                    title="Select Event Categories"
                    list={properties?.map(d => ({ id: d.id, name: d.name }))}
                    value={data.properties}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Event Slug
                </label>
                <div className="mt-1  sm:mt-0 sm:col-span-2">
                  <div className="flex">
                    <input
                      value={data?.slug}
                      onChange={e => setData({ ...data, slug: e.target.value })}
                      type="text"
                      name="name"
                      placeholder="eg: 2021"
                      id="name"
                      autoComplete="name"
                      className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <Popover
                      triggerOnHover
                      width={250}
                      content={
                        <ul style={{ listStyleType: 'disc' }} className="text-sm text-normal text-gray-700 ml-2">
                          <li>Spaces are not allowed</li>
                          <li>Use Hyphens for Spaces</li>
                        </ul>
                      }
                    >
                      <button type="button" className="ml-3 top-[10px] outline-none flex items-center justify-center rounded-md relative">
                        <IoInformationCircleSharp className="h-4 w-4 text-gray-400" />
                      </button>
                    </Popover>
                  </div>
                  {getFeEventUrlWithSlug(data.slug) && (
                    <div className="mt-1 mr-5 sm:mt-0 sm:col-span-2">
                      <span className="text-xs block ml-2 mt-2 ">
                        <a href={getFeEventUrlWithSlug(data.slug)} rel="noreferrer" target="_blank" className="hover:underline text-blue-600 cursor-pointer">
                          {getFeEventUrlWithSlug(data.slug)}
                        </a>
                        <button className="hover:text-indigo-500" onClick={() => copyText(getFeEventUrlWithSlug(data.slug))}>
                          <IoCopyOutline className="ml-2" />
                        </button>
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {getFeEventUrlWithSlug(data.slug) && (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                  <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                    QR Code for Event
                  </label>
                  <div className="mt-1  sm:mt-0 sm:col-span-2">
                    <div className="flex">
                      <div className="flex pb-4 qr-canvas items-center">
                        <QRCode
                          logoImage={preview?.qrLogo}
                          eyeColor={[
                            {
                              outer: qrConfig?.topLeftColor || qrConfig?.foregroundColor || '#000000',
                              inner: qrConfig?.topLeftInnerColor || qrConfig?.foregroundColor || '#000000',
                            }, // top/left eye
                            {
                              outer: qrConfig?.topRightColor || qrConfig?.foregroundColor || '#000000',
                              inner: qrConfig?.topRightInnerColor || qrConfig?.foregroundColor || '#000000',
                            }, // top/right eye
                            {
                              outer: qrConfig?.bottomLeftColor || qrConfig?.foregroundColor || '#000000',
                              inner: qrConfig?.bottomLeftInnerColor || qrConfig?.foregroundColor || '#000000',
                            }, // bottom/left eye
                          ]}
                          quietZone={10}
                          ecLevel="Q"
                          // logoOpacity={0.2}
                          // logoHeight={250}
                          // logoWidth={250}

                          removeQrCodeBehindLogo={preview?.qrLogo !== null}
                          qrStyle={qrConfig?.style ?? 'dots'}
                          style={{ borderRadius: '20px' }}
                          eyeRadius={
                            qrConfig?.style === 'dots'
                              ? [
                                  [10, 10, 10, 10], // top/left eye
                                  [10, 10, 10, 10], // top/right eye
                                  [10, 10, 10, 10], // bottom/left
                                ]
                              : undefined
                          }
                          bgColor={qrConfig?.backgroundColor}
                          fgColor={qrConfig?.foregroundColor || '#000000'}
                          size={150}
                          enableCORS
                          value={getFeEventUrlWithSlug(data.slug)}
                          id={'react-qrcode-logo'}
                        />
                        <button
                          onClick={e => {
                            setDownloading(true)
                            e.preventDefault()
                            if (!downloading) {
                              handleDownloadClick(data)
                            }
                          }}
                          className="border px-2 ml-1 flex justify-center items-center mr-4 h-8 shadow-sm text-sm rounded-md  bg-white border-gray-300"
                        >
                          {downloading ? (
                            <svg className="animate-spin h-5 w-5 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : (
                            <DownloadIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Max Attendees <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    value={data?.maxAttendees}
                    onChange={e => setData({ ...data, maxAttendees: e.target.value })}
                    type="number"
                    name="name"
                    placeholder="Eg: 150"
                    id="name"
                    autoComplete="name"
                    className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first-name" className="block  text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Allowed per user <span className="text-red-500">*</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    value={data?.maxAllowed}
                    onChange={e => setData({ ...data, maxAllowed: e.target.value })}
                    type="number"
                    name="name"
                    placeholder="Eg: 2"
                    id="name"
                    autoComplete="name"
                    className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="border-t mt-5 pt-5">
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-0">
                      Requires Admin Approval <span className="text-red-500">*</span>
                    </label>
                    <div className="sm:mt-0 sm:col-span-2">
                      <Switch
                        value={data?.requiresApproval}
                        onChange={checked => {
                          setData({ ...data, requiresApproval: checked })
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="about" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Description
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <textarea
                    type="textarea"
                    id="description"
                    placeholder="Write special Instructions"
                    name="description"
                    rows={6}
                    value={data?.description}
                    onChange={e => setData({ ...data, description: e.target.value })}
                    className="max-w-lg shadow-sm block w-full focus:ring-dark-500 focus:border-dark-500 sm:text-sm border border-gray-200 rounded-md"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Steps Config
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="bg-gray-100 p-4 max-w-lg rounded-lg">
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="text-left">Steps</th>
                          <td className="py-2">Required</td>
                          <td className="py-2">Hidden</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="py-2" style={{ width: '70%' }}>
                            Sign-up: Tokens
                          </td>
                          <td className="py-2" style={{ width: '15%' }}>
                            <input
                              disabled={data?.stepConfig?.walletRequired}
                              type="checkbox"
                              checked={data?.stepConfig?.tokenRequired}
                              onChange={e => setData(old => ({ ...old, stepConfig: { ...old.stepConfig, tokenRequired: e.target.checked } }))}
                            />
                          </td>
                          <td className="py-2" style={{ width: '15%' }}>
                            <input
                              disabled={data?.stepConfig?.walletRequired || data?.stepConfig?.tokenRequired}
                              type="checkbox"
                              checked={data?.stepConfig?.tokenSkip}
                              onChange={e => setData(old => ({ ...old, stepConfig: { ...old.stepConfig, tokenSkip: e.target.checked } }))}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="py-2">Sign-up: Wallet</td>
                          <td className="py-2">
                            <input
                              type="checkbox"
                              disabled={data?.stepConfig?.walletSkip}
                              checked={data?.stepConfig?.walletRequired}
                              onChange={e => {
                                setData(old => ({ ...old, stepConfig: { ...old.stepConfig, walletRequired: e.target.checked } }))
                                if (e.target.checked) setData(old => ({ ...old, stepConfig: { ...old.stepConfig, tokenRequired: true, tokenSkip: false, walletSkip: false } }))
                              }}
                            />
                          </td>
                          <td className="py-2">
                            <input
                              disabled={data?.stepConfig?.walletRequired}
                              type="checkbox"
                              checked={data?.stepConfig?.walletSkip}
                              onChange={e => setData(old => ({ ...old, stepConfig: { ...old.stepConfig, walletSkip: e.target.checked } }))}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="py-2">Sign-up: IRL</td>
                          <td className="py-2">
                            <input
                              type="checkbox"
                              disabled={data?.stepConfig?.irlSkip}
                              checked={data?.stepConfig?.irlRequired}
                              onChange={e => setData(old => ({ ...old, stepConfig: { ...old.stepConfig, irlRequired: e.target.checked } }))}
                            />
                          </td>
                          <td className="py-2">
                            <input
                              disabled={data?.stepConfig?.irlRequired}
                              type="checkbox"
                              checked={data?.stepConfig?.irlSkip}
                              onChange={e => setData(old => ({ ...old, stepConfig: { ...old.stepConfig, irlSkip: e.target.checked } }))}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="py-2">Sign-up: Address</td>
                          <td className="py-2">
                            <input
                              disabled={data?.stepConfig?.addressSkip}
                              type="checkbox"
                              checked={data?.stepConfig?.addressRequired}
                              onChange={e => setData(old => ({ ...old, stepConfig: { ...old.stepConfig, addressRequired: e.target.checked } }))}
                            />
                          </td>
                          <td className="py-2">
                            <input
                              disabled={data?.stepConfig?.addressRequired}
                              type="checkbox"
                              checked={data?.stepConfig?.addressSkip}
                              onChange={e => setData(old => ({ ...old, stepConfig: { ...old.stepConfig, addressSkip: e.target.checked } }))}
                            />
                          </td>
                        </tr>

                        {customEventField?.map((field, index) => (
                          <tr key={index}>
                            <td className="py-2">{field.label}</td>
                            <td className="py-2">
                              <input
                                disabled={data?.stepConfig?.[`${field.label}Skip`]}
                                type="checkbox"
                                checked={data?.stepConfig?.[`${field.label}Required`]}
                                onChange={e => setData(old => ({ ...old, stepConfig: { ...old.stepConfig, [`${field.label}Required`]: e.target.checked } }))}
                              />
                            </td>
                            <td className="py-2">
                              <input
                                disabled={data?.stepConfig?.[`${field.label}Required`]}
                                type="checkbox"
                                checked={data?.stepConfig?.[`${field.label}Skip`]}
                                onChange={e => setData(old => ({ ...old, stepConfig: { ...old.stepConfig, [`${field.label}Skip`]: e.target.checked } }))}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-2 text-xs font-medium">
                    {data?.stepConfig?.walletRequired ? 'Tokens will be hard verified using wallet' : 'Tokens will be soft verified (No wallet required)'}
                  </div>
                </div>
              </div>
              {data?.stepConfig?.tokenRequired && (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                  <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                    QR Validation Tiers
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Selection
                      onChange={value => {
                        setData({ ...data, tiers: xor([value.id], data.tiers || []) })
                      }}
                      multiple
                      title="Select Tiers"
                      list={tiers.map(d => ({ id: d.id, name: d.name }))}
                      value={data.tiers}
                    />
                  </div>
                </div>
              )}
              <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Event Hosts
                </label>
                <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                  <Selection
                    height={300}
                    onChange={value => {
                      setData({ ...data, hosts: xor([value.id], data.hosts || []) })
                      setHosts(xor([value], hosts || []))
                    }}
                    showSearch
                    onSearch={value => searchList(value, 'hosts')}
                    multiple
                    title="Select Host Users"
                    list={uniqBy([...hosts, ...searchHosts.filter(d => !includes(hosts, d))].flat(), 'id')}
                    value={data.hosts}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="country" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Blacklist wallets
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <textarea
                    onChange={e => {
                      setData({ ...data, blacklist: e.target.value })
                    }}
                    placeholder="Enter wallet address separated by comma"
                    rows={10}
                    className="max-w-lg shadow-sm block w-full focus:ring-dark-500 focus:border-dark-500 sm:text-sm border border-gray-200 rounded-md"
                    title="Select block list contracts"
                    list={[]}
                    value={data.blacklist}
                  />
                </div>
              </div>
              <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Event Concierge
                </label>
                <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                  <Selection
                    height={300}
                    onChange={value => {
                      setData({ ...data, concierge: xor([value.id], data.concierge || []) })
                      setConcierge(xor([value], concierge || []))
                    }}
                    showSearch
                    onSearch={value => searchList(value, 'concierge')}
                    multiple
                    title="Select Concierge Users"
                    list={uniqBy([...concierge, ...searchConcierge.filter(d => !includes(concierge, d))].flat(), 'id')}
                    value={data.concierge}
                  />
                </div>
              </div>
              {data.concierge?.length > 0 ? (
                <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                  <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                    Assign Stations
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {concierge?.length === 0 ? (
                      <span>Select Concierge Users from the above list</span>
                    ) : (
                      data?.concierge?.map((d, i) => {
                        return (
                          <div className="max-w-md mb-4" key={i}>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                {find([...searchConcierge, ...concierge], { id: d })?.name}
                              </span>
                              <input
                                onChange={e => {
                                  const obj = {
                                    ...data?.stations,
                                    [d]: e.target.value,
                                  }
                                  setData({ ...data, stations: obj })
                                }}
                                value={get(data?.stations, d, '')}
                                type="text"
                                name="company-website"
                                id="company-website"
                                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="eg Gate 1, Gate 2, Gate 3 etc"
                              />
                            </div>
                          </div>
                        )
                      })
                    )}
                  </div>
                </div>
              ) : null}
              <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  Start Date
                </label>
                <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                  <div className="flex">
                    <input
                      type="datetime-local"
                      placeholder="Start Date"
                      name="start"
                      value={moment(data.start).format('YYYY-MM-DD HH:mm:ss')}
                      onChange={e => {
                        const { value } = e.target
                        const date = new Date(value)
                        const month = date.getMonth() + 1
                        const day = date.getDate()
                        if (!month || !day) {
                          return
                        }

                        setData({ ...data, start: e.target.value })
                      }}
                      className="max-w-[300px] mr-5 block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <Selection
                      onChange={value => {
                        setData({ ...data, startDateTimeZone: value.id })
                      }}
                      showSearch
                      title="Select Start Date Time Zone"
                      list={timeZones?.startDateSearchTimeZones}
                      onSearch={value => {
                        setTimeZones(timeZones => ({
                          ...timeZones,
                          startDateSearchTimeZones: timeZones?.allTimeZones?.filter(timeZone => {
                            return includes(toLower(timeZone?.name), toLower(value))
                          }),
                        }))
                      }}
                      value={data.startDateTimeZone}
                    />
                  </div>

                  <span className="text-xs block ml-2 mt-2">
                    Local Date:
                    {moment(data.start).tz(localTz).format('YYYY-MM-DD, HH:mm A z')}
                  </span>
                  <span className="text-xs block ml-2 mt-2">UTC Date: {moment(data.start).utc().format('YYYY-MM-DD, HH:mm A')}</span>
                </div>
              </div>
              <div style={{ marginBottom: 20 }} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-6">
                <label htmlFor="email" className="block text-sm text-normal text-gray-700 sm:mt-px sm:pt-2">
                  End Date
                </label>
                <div className="mt-1 items-center sm:mt-0 sm:col-span-2">
                  <div className="flex">
                    <input
                      type="datetime-local"
                      placeholder="End Date"
                      name="end"
                      value={moment(data.end).format('YYYY-MM-DD HH:mm:ss')}
                      onChange={e => {
                        const { value } = e.target
                        const date = new Date(value)
                        const month = date.getMonth() + 1
                        const day = date.getDate()
                        if (!month || !day) {
                          return
                        }
                        setData({ ...data, end: e.target.value })
                      }}
                      className="max-w-[300px] mr-5 block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <Selection
                      onChange={value => {
                        setData({ ...data, endDateTimeZone: value.id })
                      }}
                      showSearch
                      title="Select End Date Time Zone"
                      list={timeZones?.endDateSearchTimeZones}
                      onSearch={value => {
                        setTimeZones(timeZones => ({
                          ...timeZones,
                          endDateSearchTimeZones: timeZones?.allTimeZones?.filter(timeZone => {
                            return includes(toLower(timeZone?.name), toLower(value)) || includes(toLower(timeZone?.name), toLower(data.endDateTimeZone))
                          }),
                        }))
                      }}
                      value={data.endDateTimeZone}
                    />
                  </div>

                  <span className="text-xs block ml-2 mt-2">
                    Local Date:
                    {moment(data.end).tz(localTz).format('YYYY-MM-DD, HH:mm A z')}
                  </span>
                  <span className="text-xs block ml-2 mt-2">UTC Date: {moment(data.end).utc().format('YYYY-MM-DD, HH:mm A')}</span>
                </div>
              </div>
            </div>

            {!readOnly ? (
              <div className="pt-5 border-gray-300">
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={() => addEvent()}
                    className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                  >
                    {isSubmitting ? (
                      <span className="flex items-start">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.post/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Please wait...</span>
                      </span>
                    ) : (
                      <span>{!isEdit ? 'Add' : 'Save'}</span>
                    )}
                  </button>
                  {isEdit && (
                    <button
                      onClick={() => {
                        deletePost()
                      }}
                      type="button"
                      className="inline-flex text-red-500 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white"
                    >
                      Delete Event
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="w-full flex justify-center items-center h-[200px]">
            <LoadingIcon />
          </div>
        )}
      </div>
      <ImageModal />
      <MapModel />
    </div>
  )
}

export default EventForm
