import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
// import PopupMenu from "components/molecules/PopupMenu";
import { useParams } from 'react-router-dom'
import LoadingIcon from 'components/atoms/LoadingIcon'
import { SearchIcon } from '@heroicons/react/solid'
import InfiniteScroll from 'react-infinite-scroll-component'
import { debounce, size } from 'lodash'
import EmptyState from 'components/pageComponents/EmptyState'

const ContractTokens = () => {
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [tokens, setTokens] = useState([])
  const [filter, setFilter] = useState({})

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      fetchTokens(false, true)
    }
  }, [id, filter])

  const fetchTokens = async (noLoading = false, reset = false) => {
    if (!noLoading) setLoading(true)
    const response = await apiGet(`contracts/${id}/tokens`, { size: 36, page: ((reset ? 0 : pagination?.currentPage) || 0) + 1, ...filter })

    setPagination(response?.data?.pagination)

    if (reset) setTokens(response?.data?.list)
    else setTokens([...tokens, ...response.data.list])

    setLoading(false)
  }

  const searchList = async value => {
    if (value.length >= 2) {
      setPagination({ currentPage: 0 })
      setTokens([])
      setFilter({ ...filter, search: value })
    } else {
      setPagination({ currentPage: 0 })
      setTokens([])
      setFilter({ ...filter, search: null })
    }
  }

  const searchFn = debounce(e => {
    searchList(e.target.value)
  }, 1000)

  function handleChange(event) {
    searchFn(event)
  }

  return (
    <Layout
      showLogo={!!id}
      breadcrumbs={[{ name: 'Contract Token list', pathname: '/' }]}
      headerRight={
        <div className="max-w-xs w-full lg:max-w-xs">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              onChange={handleChange}
              id="search"
              name="search"
              className="block w-full h-[30px] pl-10 pr-3 py-2 rounded-md leading-5 bg-gray-100  border-0 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
              placeholder="Search"
              type="search"
            />
          </div>
        </div>
      }
      title="Contract Tokens List"
    >
      <div className="flex flex-col">
        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                <InfiniteScroll
                  className="w-full"
                  style={{ minHeight: 'calc(100vh - 54px)' }}
                  dataLength={tokens?.length} // This is important field to render the next data
                  next={() => fetchTokens(true, false)}
                  scrollableTarget="rootScrollable"
                  hasMore={pagination?.currentPage !== pagination?.totalPage}
                  loader={
                    loading ? (
                      <div style={{ width: 'calc(100vw - 256px)' }} className="h-[54px] flex justify-center items-center">
                        <LoadingIcon />
                      </div>
                    ) : null
                  }
                >
                  {size(tokens) === 0 && !loading && <EmptyState v2 description="No items in the list" />}
                  <div className="grid overflow-x-hidden p-6 gap-4 grid-cols-8">
                    {loading
                      ? null
                      : tokens?.length
                      ? tokens?.map(token => {
                          return (
                            <div className="shadow-sm rounded-lg flex items-center flex-col grid-item h-full w-full relative bg-white" key={token.id}>
                              <img className="w-full h-full" alt={token.tokenID} src={token.image} />
                              <span className="block">
                                #{token.tokenID} - {token.contract?.name}
                              </span>
                            </div>
                          )
                        })
                      : null}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default ContractTokens
