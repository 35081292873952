import { withRouter, useHistory } from 'react-router'
import { destroyCookie, setCookie, parseCookies } from 'nookies'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { setTypes, setUser, setForms, setEventList, setWorkspace, setUserProject, setTiers } from 'state/redux/actions/user.actions'
import {
  SearchIcon,
  StarIcon,
  UsersIcon,
  GlobeIcon,
  BellIcon,
  FlagIcon,
  MenuIcon,
  BeakerIcon,
  CogIcon,
  DocumentAddIcon,
  ViewListIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'
import { Fragment, useEffect, useState } from 'react'
import { XIcon } from '@heroicons/react/solid'
import { get, groupBy, xor, startCase, toLower, includes, isString, split, find } from 'lodash'
import classNames from 'classnames'
import logo from 'assets/images/opsjet.png'
import { setDrawerToggle, setToggles, setMainToggles } from 'state/redux/actions/settings.actions'
import apiGet from 'lib/network/apiGet'
import BottomMenu from 'containers/BottomMenu'
import { PlusIconSvg } from 'components/Icons/Icons'
import { IoFolderOutline, IoMail } from 'react-icons/io5'
import { Cog6ToothIcon, SwatchIcon } from '@heroiconsv2/react/24/outline'
import { useInterval } from 'hooks/useInterval'
import NotificationDrawer from 'containers/NotificationDrawer'

const Sidebar = ({ setVisible, visible, user, openChangePasswordModal }) => {
  const { isImpersonated } = parseCookies()
  const [search, setSearch] = useState('')
  const [toggleHold, setToggleHold] = useState([])
  const [notificationDrawer, setNotificationDrawer] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0)

  const dispatch = useDispatch()
  const history = useHistory()

  const fetchTypes = async () => {
    const response = await apiGet('types')
    dispatch(setTypes(groupBy(response.data, 'identifier')))
  }

  const fetchWorkspaceTiers = async () => {
    const response = await apiGet('tiers')
    dispatch(setTiers(response.data))
  }

  const fetchForm = async () => {
    const response = await apiGet('forms')
    dispatch(setForms(response.data))
  }

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    dispatch(setUserProject(response?.data))
  }

  const fetchNotifications = async () => {
    const response = await apiGet('notifications-count')
    setNotificationCount(response.data)
  }

  const fetchEvents = async () => {
    const response = await apiGet(`events`, { size: 100, page: 1 })
    dispatch(setEventList(response?.data))
  }

  const workspaces = useSelector(state => get(state, 'user.workspaces'))
  const drawer = useSelector(state => get(state, 'settings.drawer'))
  const toggles = useSelector(state => get(state, 'settings.toggles', [])) || []
  const mainToggles = useSelector(state => get(state, 'settings.mainToggles', [])) || []

  const types = useSelector(state => get(state, 'user.types', [])) || []
  const forms = useSelector(state => get(state, 'user.forms', [])) || []
  const projects = useSelector(state => get(state, 'user.projects', [])) || []
  const wpTiers = useSelector(state => get(state, 'user.tiers', [])) || []
  const events = useSelector(state => get(state, 'user.events', [])) || []

  const handleLogout = e => {
    dispatch(setUser({}))
    dispatch(setWorkspace({}))

    destroyCookie({}, 'impersonateToken', { path: '/' })
    destroyCookie({}, 'isImpersonated', { path: '/' })
    destroyCookie({}, 'impersonateRole', { path: '/' })
    destroyCookie({}, 'accessToken', { path: '/' })
    destroyCookie({}, 'role', { path: '/' })
    history.push('/login')
    return false
  }

  const fetchData = () => {
    fetchProjects()
    fetchTypes()
    fetchForm()
    fetchWorkspaceTiers()
    fetchNotifications()
    fetchEvents()
  }

  useInterval(() => {
    fetchNotifications()
  }, 1000 * 30)

  useEffect(() => {
    fetchData()
  }, [])

  const handleNotification = () => {
    setNotificationDrawer(!notificationDrawer)
    setNotificationCount(0)
  }

  const postTypes = types?.post || []
  const propertyTypes = types?.properties || []

  const platformAdminOnly = ['platform_admin']
  const allAdmins = ['platform_admin', 'workspace_admin', 'concierge']
  const managers = ['platform_admin', 'workspace_admin', 'event_manager']
  const ngenAdmins = ['ngen']

  const currentWorkspace = useSelector(state => get(state, 'user.workspace'))

  const access = user.access
  const accessItems = access?.map(d => d.menu?.name) || []

  const isOnlyWorkspaceManager = includes(user?.roles, 'campaign_manager')

  const tierMenu = {
    name: 'Tiers',
    icon: StarIcon,
    role: allAdmins,
    customHeader: () => (
      <div className="flex justify-center px-5 pb-4">
        <button
          onClick={() => history.push('/tiers/add')}
          className="bg-gray-100 w-full hover:bg-gray-200 flex py-1.5 text-[11px] uppercase font-medium text-[#7c828d] rounded-sm items-center justify-center"
        >
          <PlusIconSvg />
          <span className="ml-2">NEW TIER</span>
        </button>
      </div>
    ),
    items: [
      ...(wpTiers && wpTiers.map(tier => ({ dynamic: true, parent: 'Tiers', name: tier.name, pathname: `/tiers/edit/${tier.id}` }))),
      {
        icon: () => <Cog6ToothIcon className="h-3 w-3" />,
        role: allAdmins,
        name: 'tier notifications',
        span: <span style={{ borderBottom: '1px dashed currentColor' }}>Notification settings</span>,
        pathname: '/tiers/notification',
      },
    ],
  }

  const menuItems = [
    {
      name: 'Reports & Logs',
      icon: () => (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
      ),
      role: managers,
      items: [
        {
          name: 'Registrations',
          pathname: `/reports/registrations?q=&workspace=${currentWorkspace?.id}`,
        },
        {
          name: 'External Users',
          pathname: `/reports/external/users?q=&workspace=${currentWorkspace?.id}`,
        },
        {
          name: 'External Transactions',
          pathname: `/reports/external/transactions?q=&workspace=${currentWorkspace?.id}`,
        },
        {
          name: 'Campaigns',
          pathname: `/reports/campaigns?q=&workspace=${currentWorkspace?.id}`,
        },
        {
          name: 'Transactions',
          pathname: `/reports/transactions?q=&workspace=${currentWorkspace?.id}`,
        },
        {
          role: platformAdminOnly,
          name: 'Logs',
          pathname: `/reports/activity-logs?q=&workspace=${currentWorkspace?.id}`,
        },
        {
          name: 'Event Logs',
          pathname: `/reports/event-logs?q=&workspace=${currentWorkspace?.id}`,
        },
      ],
    },

    {
      name: 'Users',
      customHeader: () => (
        <div className="flex justify-center px-5 pb-4">
          <button
            onClick={() => history.push('/users/add')}
            className="bg-gray-100 w-full hover:bg-gray-200 flex py-1.5 text-[11px] uppercase font-medium text-[#7c828d] rounded-sm items-center justify-center"
          >
            <PlusIconSvg />
            <span className="ml-2">NEW USER</span>
          </button>
        </div>
      ),
      role: allAdmins,
      icon: UsersIcon,
      items: [
        { name: 'Users', role: allAdmins, pathname: '/users' },
        { dynamic: user.isPlatformAdmin, name: 'Roles', role: platformAdminOnly, pathname: '/roles' },
        { name: 'Custom Fields', role: platformAdminOnly, pathname: '/custom-fields' },
      ],
    },
    !isOnlyWorkspaceManager && { ...tierMenu },
    {
      name: 'Projects',
      icon: StarIcon,
      customHeader: () => (
        <div className="flex justify-center px-5 pb-4">
          <button
            onClick={() => history.push('/projects/add')}
            className="bg-gray-100 w-full hover:bg-gray-200 flex py-1.5 text-[11px] uppercase font-medium text-[#7c828d] rounded-sm items-center justify-center"
          >
            <PlusIconSvg />
            <span className="ml-2">NEW PROJECT</span>
          </button>
        </div>
      ),
      role: platformAdminOnly,
      items: [
        ...(projects &&
          projects.map(project => {
            return { dynamic: true, name: startCase(project.name), parent: 'manage projects', pathname: `/projects/edit/${project?.id}` }
          })),
        {
          icon: () => (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
            </svg>
          ),
          role: platformAdminOnly,
          name: 'manage projects',
          span: <span>Manage Projects</span>,
          pathname: '/projects',
        },
        {
          name: 'Project Properties',
          icon: FlagIcon,
          pathname: '',
          role: allAdmins,
          items: [
            ...(propertyTypes &&
              propertyTypes.map(menu => {
                return { dynamic: true, parent: 'Project Properties', name: startCase(menu.name), pathname: `/properties/${toLower(menu.name)}` }
              })),
            {
              icon: () => (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                </svg>
              ),
              name: 'manage property types',
              span: <span style={{ borderBottom: '1px dashed currentColor' }}>Manage Property Types</span>,
              pathname: '/types/properties',
            },
          ],
        },
      ],
    },
    {
      name: 'Manage Events',
      role: [...new Set(allAdmins.concat(managers))],
      icon: UserGroupIcon,
      customHeader: () => (
        <div className="flex justify-center px-5 mt-2 pb-4">
          <button
            onClick={() => history.push('/events/add')}
            className="bg-gray-100 w-full hover:bg-gray-200 flex py-1.5 text-[11px] uppercase font-medium text-[#7c828d] rounded-sm items-center justify-center"
          >
            <PlusIconSvg />
            <span className="ml-2">NEW EVENT</span>
          </button>
        </div>
      ),
      items: [
        ...(events &&
          events.map(event => ({
            dynamic: true,
            name: event.name,
            parent: 'Manage Events',
            pathname: `/events/edit/${event.id}`,
            items: [{ dynamic: true, parent: 'Manage Events', name: 'RSVP List', pathname: `/events/rsvp/${event.id}` }],
          }))),
      ],
    },
    { name: 'Manage Smart Contracts', role: platformAdminOnly, icon: BeakerIcon, pathname: '/smart-contracts' },
    {
      name: 'Manage NFTs',
      icon: () => (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
        </svg>
      ),
      role: platformAdminOnly,
      pathname: '',
      items: [
        { name: 'NFT Series', role: allAdmins, pathname: '/series' },
        { name: 'Minting Stages', role: platformAdminOnly, pathname: '/stages' },
        { name: 'Randomization', role: platformAdminOnly, pathname: '/randomization-list' },
        { name: 'NFT Drops', role: allAdmins, pathname: '/drops' },
        {
          name: 'Wallet Access',
          icon: () => <ViewListIcon className="h-3 w-3" />,
          pathname: '/datalist/drop',
          role: allAdmins,
        },
        { name: 'Minted Tokens', count: 10, role: allAdmins, pathname: '/nft/tokens' },
        {
          icon: () => (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
            </svg>
          ),
          name: 'create nft series',
          span: <span style={{ borderBottom: '1px dashed currentColor' }}>Create NFT Series</span>,
          pathname: '/series/add',
        },
        {
          icon: () => (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
            </svg>
          ),
          name: 'create randomization',
          span: <span style={{ borderBottom: '1px dashed currentColor' }}>Create Randomization</span>,
          pathname: '/randomization',
        },
      ],
    },
    {
      name: 'SHOW MORE',
      icon: IoFolderOutline,
      role: platformAdminOnly,
      items: [
        {
          name: 'Media Gallery',
          icon: () => (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          ),
          pathname: '/media',
          role: platformAdminOnly,
        },
        { name: 'Email Sender', role: platformAdminOnly, icon: IoMail, pathname: '/email-sender' },
        {
          name: 'Posts',
          icon: GlobeIcon,
          pathname: '/posts',
          role: platformAdminOnly,
          items: [
            ...(postTypes &&
              postTypes.map(menu => {
                return { name: startCase(menu.name), pathname: `/posts/${menu?.id}` }
              })),
            {
              icon: () => (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                </svg>
              ),
              name: 'manage post types',
              span: <span style={{ borderBottom: '1px dashed currentColor' }}>Manage Post Types</span>,
              pathname: '/types/post',
            },
          ],
        },
        {
          name: 'Forms',
          icon: DocumentAddIcon,
          role: platformAdminOnly,
          items: [
            ...(forms &&
              forms.map(menu => {
                return { name: menu.name, pathname: `/forms/${menu.id}` }
              })),
            {
              name: 'manage forms',
              icon: () => <PlusIconSvg />,
              span: <span style={{ borderBottom: '1px dashed currentColor' }}>Manage Forms</span>,
              pathname: '/forms',
            },
          ],
        },
        { name: 'Slug Manager', role: platformAdminOnly, icon: BellIcon, pathname: '/global-slugs' },
        {
          name: 'NGEN Assets',
          icon: () => (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z"
              />
            </svg>
          ),
          pathname: '/ngen',
          role: ngenAdmins,
        },

        { name: 'Config Flags', role: platformAdminOnly, icon: FlagIcon, pathname: '/config-flag' },
      ],
    },
    isOnlyWorkspaceManager && { ...tierMenu },
  ]

  const menu = menuItems?.filter(d => {
    const item = find(user.access, x => x?.menu?.name === d.name || x?.menu?.parent === d.name)

    if (d?.items?.length) {
      if (d.customHeader) {
        if (item?.readOnly) d.customHeader = false
      }

      d.items = d?.items?.filter(sub => {
        if (sub?.items?.length) {
          sub.items = sub?.items?.filter(subsub => {
            return (subsub.dynamic && includes(accessItems, d.name)) || includes(accessItems, subsub.name)
          })
        }

        return sub.items?.length || (sub?.dynamic && item) || includes(accessItems, sub.name)
      })
    }

    return d.items?.length || includes(accessItems, d.name)
  })

  const handleDrawer = () => dispatch(setDrawerToggle(!drawer))

  const color = '#1344ce'
  const lightColor = color + '1A'

  const Submenu = ({ items, item, level }) => {
    const open = level === 3 ? includes(toggles, item.name) : includes(mainToggles, item.name)

    return (
      <div className={classNames('space-y-1 drawer_menu', open ? 'drawer_submenu_open' : 'drawer_submenu_close')}>
        {items
          ?.filter(menu => {
            if (search === '') return true
            if (isString(menu.name)) return true
            if (toLower(item.name).search(toLower(search)) !== -1) return true
            return toLower(menu.name).search(toLower(search)) !== -1
          })
          ?.map((sub, index) => {
            const current = split(sub?.pathname, '?')[0] === history.location.pathname

            const hasSubmenu = sub.items?.length > 0

            return (
              <div key={index}>
                <span
                  onClick={hasSubmenu ? () => dispatch(setToggles(xor([sub.name], toggles))) : () => history.push(sub.pathname)}
                  style={{
                    borderLeftColor: current ? color : 'transparent',
                    background: current ? lightColor : '',
                    color: current ? color : '',
                  }}
                  className={classNames(
                    level === 3 ? 'pl-9' : 'pl-5',
                    current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-900 hover:bg-gray-50',
                    'group  flex h-[32px] items-center border-l-2 px-3 py-1.5 text-[12px] font-normal cursor-pointer'
                  )}
                  aria-current={current ? 'page' : undefined}
                >
                  {hasSubmenu ? (
                    <span className="absolute left-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={classNames('h-4 w-4 transition-all', includes(toggles, sub.name) ? 'rotate-90' : '')}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  ) : null}
                  <div style={{ color: current ? 'white' : '', background: current ? color : '#eeeeee' }} className={`h-4 w-4 rounded-md mr-3 flex items-center justify-center`}>
                    {sub.icon ? (
                      <sub.icon />
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-2 w-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                        />
                      </svg>
                    )}
                  </div>
                  <span
                    className={classNames(hasSubmenu && ' hover:text-indigo-600', 'text-ellipsis truncate')}
                    onClick={e => {
                      if (hasSubmenu) {
                        e.preventDefault()
                        e.stopPropagation()
                        history.push(sub.pathname)
                      }
                    }}
                  >
                    {sub.span ? sub.span : sub.name}
                  </span>
                </span>
                {hasSubmenu ? <Submenu level={3} items={sub.items} item={sub} /> : null}
              </div>
            )
          })}
      </div>
    )
  }

  const switchToScanner = () => {
    const config = {
      path: '/',
      maxAge: 604800,
      sameSite: true,
    }

    setCookie(null, 'scanner', true, config)
    window.location.href = '/events'
  }

  const switchToCurrentRole = () => {
    destroyCookie({}, 'impersonateToken', { path: '/' })
    destroyCookie({}, 'isImpersonated', { path: '/' })
    destroyCookie({}, 'impersonateRole', { path: '/' })

    window.location.href = '/'
  }

  const Menu = () => {
    return (
      <nav className="mt-6">
        <div className="pb-40">
          <div className="mb-0">
            {includes(accessItems, 'Workspace Settings') && (
              <div
                onClick={() => history.push(`/workspaces/edit/${currentWorkspace?.id}`)}
                style={{ background: includes(history.location.pathname, 'workspaces/edit') && lightColor }}
                className={classNames('px-4 hover:bg-gray-100 pl-6 py-1.5 flex items-center cursor-pointer')}
              >
                <CogIcon style={{ color: includes(history.location.pathname, 'workspaces/edit') && color }} className="h-4 w-4 mr-2 text-gray-500" />
                <span>Workspace Settings</span>
              </div>
            )}

            {isImpersonated && (
              <div onClick={switchToCurrentRole} className={classNames('px-4 hover:bg-gray-100 pl-6 py-1.5 flex items-center cursor-pointer')}>
                <SwatchIcon className="h-4 w-4 mr-2 text-gray-500" />
                <span>Back to Platform admin</span>
              </div>
            )}

            {user?.scanner && includes(accessItems, 'Switch to Scanner') && (
              <div onClick={switchToScanner} className={classNames('px-4 hover:bg-gray-100 pl-6 py-1.5 flex items-center cursor-pointer')}>
                <SwatchIcon className="h-4 w-4 mr-2 text-gray-500" />
                <span>Switch to Scanner</span>
              </div>
            )}
          </div>
          <div className="mt-4">
            {menu
              ?.filter(menu => {
                if (search === '') return true
                if (menu.items) {
                  return (
                    menu.items.filter(item => {
                      return toLower(item?.name).search(toLower(search)) !== -1
                    }).length > 0 || toLower(menu.name).search(toLower(search)) !== -1
                  )
                }
                return toLower(menu.name).search(toLower(search)) !== -1
              })
              .filter(menu => {
                return (
                  menu.items?.filter(item => {
                    return toLower(item.name).search(toLower(search)) !== -1
                  }).length > 0 || toLower(menu.name).search(toLower(search)) !== -1
                )
              })
              .map((item, index) => {
                const current = history.location.pathname === item.pathname

                return <MenuItem current={current} index={index} key={index} item={item} />
              })}
          </div>
        </div>
      </nav>
    )
  }

  const MenuItem = ({ index, current, item }) => {
    const hasSubmenu = item.items?.length > 0
    const selected = includes(mainToggles, item.name)
    const itemOnClick = () => {
      if (item.items) {
        dispatch(setMainToggles(xor([item.name], mainToggles)))
      }
      if (item.pathname) {
        history.push(item.pathname)
      }
    }

    return (
      <div key={index} className={classNames('border-gray-100 relative', selected && hasSubmenu && 'pb-4')}>
        <span
          onClick={itemOnClick}
          style={{
            borderLeftColor: current ? color : 'transparent',
            background: current ? lightColor : '',
            color: current ? color : '',
            letterSpacing: '0.28px',
          }}
          className={classNames(
            current ? 'text-[#53575e]' : 'text-[#979797] hover:bg-gray-50',
            'group flex relative items-center h-[48px] border-t border-[#e9ebf0] uppercase border-l-2 px-4 pl-6 py-1.5 text-[11px] font-bold cursor-pointer'
          )}
          aria-current={current ? 'page' : undefined}
        >
          {item.name}

          {hasSubmenu ? (
            <span className="absolute right-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={classNames('h-4 w-4 transition-all', includes(mainToggles, item.name) ? 'rotate-90' : '')}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          ) : null}
        </span>
        {selected && item.customHeader ? item.customHeader() : null}
        {hasSubmenu ? <Submenu items={item.items} item={item} /> : null}
      </div>
    )
  }

  return (
    <>
      <Transition.Root show={visible} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setVisible}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setVisible(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-8 w-auto" src={logo} alt="Workflow" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <Menu />
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
        </Dialog>
      </Transition.Root>
      <div
        style={{ borderRight: '1px solid #e9ebf0' }}
        className={classNames(
          drawer ? 'noscrollbar drawer-open' : 'drawer-close opacity-0',
          'hidden lg:flex lg:flex-col  lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-4 lg:pb-4 lg:bg-white'
        )}
      >
        <div className="flex items-center justify-between flex-shrink-0 px-4">
          <img className="h-6 w-auto" src={logo} alt="Workflow" />
          <div>
            <button className="relative" onClick={handleNotification}>
              {notificationCount > 0 ? <div className="notification-bell">{notificationCount}</div> : null}
              <BellIcon className="h-5 w-5 mr-3" aria-hidden="true" />
            </button>
            <button onClick={handleDrawer}>
              <MenuIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="mt-2 h-0 flex-1 noscrollbar flex flex-col overflow-y-auto">
          <div className="px-3 mt-2">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
                <SearchIcon className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
              </div>
              <input
                onChange={e => {
                  setSearch(e.target.value)
                }}
                value={search}
                onFocus={e => {
                  setToggleHold(toggles)
                  dispatch(setToggles(menu.map(item => item.name)))
                }}
                onBlur={() => {
                  dispatch(setToggles(toggleHold))
                }}
                type="text"
                name="search"
                id="search"
                className="block w-full pl-9 shadow-none sm:text-sm bg-gray-100 border-0 focus:outline-none rounded-sm"
                placeholder="Search options"
              />
            </div>
          </div>

          {notificationDrawer && <div onClick={() => setNotificationDrawer(false)} className="notification-drawer-overlay"></div>}
          <div className={classNames(notificationDrawer && 'notification-drawer-open', 'notification-drawer')}>
            <NotificationDrawer open={notificationDrawer} />
          </div>

          <Menu />

          <BottomMenu user={user} workspaces={workspaces} currentWorkspace={currentWorkspace} openChangePasswordModal={openChangePasswordModal} handleLogout={handleLogout} />
        </div>
      </div>
    </>
  )
}

export default withRouter(Sidebar)
