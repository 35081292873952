import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import PostForm from 'pages/posts/PostForm'

const AddEditPost = () => {
  const history = useHistory()
  const [isEdit, setIsEdit] = useState(null)

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      setIsEdit(true)
    }
  }, [id])

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <button
            onClick={() => {
              history.go(-1)
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Go Back
          </button>
        </div>
      }
      title={isEdit ? 'Edit Post' : 'Add New Post'}
      breadcrumbs={[
        { name: 'Post List', pathname: '/posts' },
        { name: isEdit ? 'Post Details' : 'Add New Post', pathname: '/posts/add', current: true },
      ]}
    >
      <div className="flex overflow-auto">
        <div className="bg-white flex w-full">
          {/* <SettingNav isEdit={isEdit} navigation={navigation} id={id} path="posts" /> */}
          <div className="w-full">
            <Switch>
              <Route path="/posts/add" component={() => <PostForm id={id} />} />
              <Route path="/posts/edit/:id" component={() => <PostForm id={id} isEdit={isEdit} />} />
            </Switch>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AddEditPost
