import styled from 'styled-components'
import logo from 'assets/images/opsjet.png'

const ErrorContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border: none;
    font-size: 1rem;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    padding: 12px;
  }

  > div {
    width: 100%;
    color: #771f1f;
    padding: 36px 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  #container {
    width: 100%;
    margin: 90px 160px;
  }

  #container * {
    margin-bottom: 50px;
    font-family: 'Courier New', Courier, monospace;
  }

  #container h1 {
    width: 100%;
    text-align: center;
    display: inline-block;
    font-size: 3em;
    line-height: 100%;
    font-weight: bold;
    letter-spacing: 0.05em;
    text-shadow: 8px -3px 0 rgba($red, 0.6), -10px 5px 0 rgba($blue, 0.6), 9px 7px 0 rgba($yellow, 0.6);
    transition: all 0.2s ease;

    &:hover {
      text-shadow: 4px -1px 0 rgba($red, 0.6), -5px 2px 0 rgba($blue, 0.6), 6px 3px 0 rgba($yellow, 0.6);
      transition: all 0.2s ease;
    }
  }

  img {
    width: 150px;
  }

  #container h3 {
    width: 100%;
    text-align: center;
    font-size: 1.2em;
  }

  #container a {
    padding: 15px 15px;
    color: white;
    font-weight: bold;
    font-size: 0.8em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    border-radius: 6px;
    background-color: var(--primary-color);
    background-color: rgba(#f05555, 0.9);
    box-shadow: -7px 7px 0 rgba(#f05555, 0.7), -12px 12px 0 rgba(#ffdb59, 0.8);
    transition: all 0.2s ease;

    &:hover {
      box-shadow: -3px 3px 0 rgba(#4868f4, 0.7), -6px 6px 0 rgba(#ffdb59, 0.8);
      transition: all 0.2s ease;
    }
  }
  .error_container {
    background: rgb(255 77 79 / 17%);
    width: 100%;
    padding: 12px;
    font-family: 'Courier New', Courier, monospace;
    border-radius: 4px;
    max-height: 400px;
    overflow-y: auto;
    white-space: pre-line;
  }
`

const Error = ({ error }) => {
  return (
    <ErrorContainer>
      <div id="container">
        <img src={logo} alt="opsjet" />
        <h1>We are facing some difficulties</h1>
        <h3>Technical error. Something went wrong. Its been reported. We are on it. Will fix this asap. We promise!</h3>
        <div className="error_container">{error.stack}</div>
        <button onClick={() => window.location.replace('/')}>Take me back home</button>
      </div>
    </ErrorContainer>
  )
}

export default Error
