import classNames from 'classnames'
import EmptyState from 'components/pageComponents/EmptyState'
import useModal from 'hooks/useModal'
import { bytesToSize } from 'lib/utils'
import { includes, split, xor } from 'lodash'
import PreviewModal from 'pages/media/modals/PreviewModal'
import { useState } from 'react'
import notify from 'components/atoms/Notify'
import apiDelete from 'lib/network/apiDelete'
import PopupMenu from 'components/molecules/PopupMenu'
import { IoEllipsisVertical, IoPlay } from 'react-icons/io5'

const MediaList = ({ mediaList, fetchMedia, project, items: selectedFiles, setItems: setSelectedFiles, openModal, isSelector = false }) => {
  const menuHandler = (option, id) => {
    if (option.key === 'delete') {
      deleteContent(id)
    }
  }

  const deleteContent = contentId => {
    notify.deleteConfirm({
      title: 'Delete Content?',
      message: 'Are you sure you want to delete this content?',
      dangerText: 'Delete',
      onConfirm: async () => {
        const response = await apiDelete(`media/${contentId}`)
        fetchMedia(project)
        if (response.success) {
          notify.success('Content deleted succesfully!')
        }
      },
    })
  }

  const [preview, setPreview] = useState(null)

  const [PreviewModalComponent, openPreviewModal] = useModal({
    content: <PreviewModal preview={preview} />,
    title: preview?.filename,
    height: '400px',
    closeable: true,
    width: '600px',
    customClass: 'preview-modal-container',
  })

  return (
    <section className="p-4" aria-labelledby="gallery-heading">
      <PreviewModalComponent />
      {mediaList?.length > 0 ? (
        <ul className="flex gap-x-2 flex-wrap gap-y-4 sm:grid-cols-4 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-4">
          {mediaList?.map((file, index) => {
            const type = split(file.mimetype, '/')[0]
            const selected = includes(selectedFiles, file.id)

            return (
              <div key={index} title={file.filename} className="flex flex-col items-center justify-start">
                <div
                  key={index}
                  style={{ background: selected ? '#f4f5f9' : '#fff' }}
                  className="grid-item media-item p-2 rounded-lg items-center cursor-pointer w-[140px] h-[140px] flex justify-center"
                >
                  <div
                    className="flex items-center"
                    onClick={() => {
                      if (!isSelector) {
                        setPreview(file)
                        openPreviewModal()
                      } else {
                        const newSelectedFiles = xor(selectedFiles, [file])
                        if (newSelectedFiles.length <= 20) {
                          setSelectedFiles(files => xor(files, [file.id]))
                        }
                      }
                    }}
                  >
                    {type === 'image' && (
                      <img
                        style={{ maxHeight: 130, objectFit: 'contain', border: `4px solid ${selected ? 'white' : 'white'}` }}
                        className={classNames('shadow-md bg-gray-200 w-auto rounded')}
                        src={file.thumbnail}
                        alt=""
                      />
                    )}
                    {type === 'video' && (
                      /* eslint-disable-next-line */
                      <video
                        style={{ objectFit: 'contain', border: `4px solid ${selected ? 'white' : 'white'}` }}
                        className={classNames('shadow-md bg-black w-auto rounded')}
                        src={file.url}
                        poster={file.thumbnail}
                        alt=""
                      />
                    )}
                    {type === 'video' && (
                      <span style={{ top: 'calc(50% - 16px)', left: 'calc(50% - 16px)' }} className="absolute h-8 w-8 top-[50%] left-[50%] play-button">
                        <IoPlay className="h-4 w-4 text-white shadow-xl" />
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex justify-between ">
                  <div className="">
                    <span
                      style={{ background: selected ? '#257AFD' : '', color: selected ? 'white' : '' }}
                      className="w-auto px-1 py-0.5 rounded-sm text-center text-[13px] giveMeEllipsis whitespace-normal"
                    >
                      {file.filename}
                    </span>
                    <span className="w-[140px] text-center text-xs giveMeEllipsis whitespace-normal">{bytesToSize(file.size)}</span>
                  </div>
                  <PopupMenu onClick={option => menuHandler(option, file.id)} menu={[[{ name: 'Delete content', key: 'delete' }]]}>
                    <IoEllipsisVertical />
                  </PopupMenu>
                </div>
              </div>
            )
          })}
        </ul>
      ) : (
        <EmptyState title={`No media files`} onButtonClick={openModal} buttonText={`Add Media`} description="No media found. Add new photos or video files" />
      )}
    </section>
  )
}

export default MediaList
