import classNames from 'classnames'

const PageContent = ({ children, center, title = '', actionButton }) => {
  return (
    <div style={{ minHeight: center ? 'calc(100vh - 56px)' : '' }} className={classNames('', center ? 'd-flex align-center justify-center' : '')}>
      {children}
    </div>
  )
}

export default PageContent
