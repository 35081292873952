import { useEffect, useState } from 'react'
import Layout from 'containers/Layout'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import apiGet from 'lib/network/apiGet'
import ProjectProfile from 'pages/projects/ProjectProfile'
import EmptyState from 'components/pageComponents/EmptyState'
import Loading from 'pages/common/Loading'
import { forEach } from 'lodash'

const AddEditProject = ({ readOnly }) => {
  const history = useHistory()
  const [isEdit, setIsEdit] = useState(null)
  const [properties, setProperties] = useState([])
  const [projectData, setProjectData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  useEffect(() => {
    fetchProperties()
  }, [])

  useEffect(() => {
    if (id) fetchProject()
  }, [id])

  const fetchProject = async () => {
    setLoading(true)
    const response = await apiGet(`project/${id}`)
    if (response.success) {
      forEach(Object.entries(response.data), ([key, value]) => {
        if (value === 'null') {
          delete response.data[key]
        }
      })
      setProjectData(response.data)
    }
    setLoading(false)
  }

  const fetchProperties = async () => {
    const response = await apiGet(`properties`)
    setProperties(response?.data)
  }

  useEffect(() => {
    if (id) {
      setIsEdit(true)
    }
  }, [id])

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <button
            onClick={() => {
              history.push('/projects')
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm text-normal rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Go Back
          </button>
        </div>
      }
      title={isEdit ? 'Edit Project' : 'Add New Project'}
      breadcrumbs={[
        { name: 'Project List', pathname: '/projects' },
        { name: isEdit ? 'Project Details' : 'Add New Project', pathname: '/projects/add', current: true },
      ]}
    >
      <div className="flex p-3 overflow-auto">
        <div className="bg-white flex w-full">
          <div className="w-full">
            <Switch>
              {!readOnly && <Route path="/projects/add" component={() => <ProjectProfile properties={properties} id={id} />} />}
              {projectData && !loading ? (
                <Route
                  path="/projects/edit/:id"
                  component={() => <ProjectProfile readOnly={readOnly} loading={loading} projectData={projectData} properties={properties} id={id} isEdit={isEdit} />}
                />
              ) : loading ? (
                <Loading />
              ) : (
                <EmptyState description="Project Not Found" v2 />
              )}
            </Switch>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AddEditProject
