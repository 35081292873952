import { getIn } from 'formik'

const getStyles = (errors, fieldName, errorMessageWrapper = false) => {
  if (getIn(errors, fieldName)) {
    if (errorMessageWrapper) {
      return {
        color: 'red',
        fontSize: '12px',
      }
    }
    return {
      border: '1px solid red',
    }
  }
}

const CustomInputField = formProps => {
  const {
    field,
    form: { errors },
    ...restFieldProps
  } = formProps

  return (
    <div className="w-full">
      <input className="w-full" {...field} style={getStyles(errors, field.name)} {...restFieldProps} />
    </div>
  )
}

export default CustomInputField
