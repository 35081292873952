import Input from 'components/atoms/Input'
import { Form, Formik } from 'formik'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useEffect, useState } from 'react'
import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'

const AddEditType = ({ identifier, close, id, setTypeId, fetchData }) => {
  const [typeData, setTypeData] = useState(null)

  useEffect(() => {
    if (id) {
      fetchProperty()
    }
  }, [id])

  const fetchProperty = async () => {
    const response = await apiGet(`/types/${id}`)
    setTypeData(response.data)
  }

  const saveTypeData = async typeData => {
    const data = await apiPost(id ? `types/${id}` : 'types', typeData)
    if (data.success) {
      notify.success(`${typeData?.identifier} saved successfully`)
      fetchData()
      setTypeId(null)
      close()
    }
  }

  const initialValues = {
    name: typeData?.name,
    identifier: typeData?.identifier,
  }

  return (
    <Formik initialValues={id ? initialValues : { identifier }} enableReinitialize={true}>
      {props => (
        <Form>
          <div className="mb-8 space-y-4">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-0">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  type="text"
                  name="name"
                  placeholder="eg: Tags, Categories, Genres etc."
                  id="name"
                  autoComplete="new-password"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Identifier
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Selection
                  list={[
                    { name: 'Properties', id: 'properties' },
                    { name: 'Post', id: 'post' },
                  ]}
                  title="Select Identifier"
                  onChange={value => {
                    props.setFieldValue('identifier', value.id)
                  }}
                  value={props.values.identifier}
                />

                {/* <Input
                  type="text"
                  name="identifier"
                  placeholder="eg: Pro or post"
                  id="identifier"
                  autoComplete="new-identifier"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                /> */}
              </div>
            </div>

            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                workspace
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Field
                  as="select"
                  id="workspace"
                  name="workspace"
                  autoComplete="role"
                  className="max-w-lg block pr-4 focus:ring-dark-500 focus:border-dark-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                >
                  <option disabled selected>
                    Select workspace
                  </option>
                  {workSpace?.map((item) => {
                    return (
                      <option key={item.id} value={parseInt(item.id)}>
                        {startCase(item.name)}
                      </option>
                    );
                  })}
                </Field>
              </div>
            </div> */}
          </div>

          <div className="mt-4 flex justify-end">
            <button
              onClick={() => close()}
              className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
            >
              Close
            </button>
            <button
              onClick={() => saveTypeData(props.values)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddEditType
