import Layout from 'containers/Layout'
import Button from 'components/atoms/Button'
import { useState, useEffect } from 'react'
import CredentialsList from './CredentialsList'
import AddEditCredential from './modals/AddEditCredential'
import useModal from 'hooks/useModal'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { useDispatch, useSelector } from 'react-redux'
import { IoAlertCircle } from 'react-icons/io5'
import Selection from 'components/molecules/Selection'
import { destroyCookie, setCookie } from 'nookies'
import { useHistory } from 'react-router-dom'
import { setUser } from 'state/redux/actions/user.actions'
import Popover from 'components/molecules/Popover'
import notify from 'components/atoms/Notify'
import { get, includes, isEmpty, flatten, values } from 'lodash'
import queryString from 'query-string'

const Credentials = ({ userData, roles, isWizard, setIsWorkspaceCredError }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [sections, setSections] = useState(['social'])
  const [showValues, setShowValue] = useState([])
  const [credentialId, setCredentialId] = useState(null)
  const [credentials, setCredentials] = useState([])
  const [apiKey, setApiKey] = useState('')
  const user = useSelector(state => get(state, 'user.user'))

  const qs = queryString.parse(window.location.search)
  const workspaceId = user?.isPlatformAdmin ? qs?.workspaceId : null

  const [credError, setCredError] = useState(false)
  const toggleSection = section => {
    if (includes(sections, section)) {
      setSections(sections.filter(s => s !== section))
    } else {
      setSections([...sections, section])
    }
  }

  const toggleValue = value => {
    if (includes(showValues, value)) {
      setShowValue(showValues.filter(s => s !== value))
    } else {
      setShowValue([...showValues, value])
    }
  }

  const fetchCredentials = async () => {
    const response = await apiGet('creds', { workspaceId })
    setCredentials(response?.data)
    if (isWizard && workspaceId) {
      const emptyValues = flatten(values(response?.data))?.filter(d => d.required && !d.value)
      setCredError(emptyValues?.length)
      setIsWorkspaceCredError(emptyValues?.length)
    }
  }

  useEffect(() => {
    fetchCredentials()
    if (!isWizard && !workspaceId) {
      const emptyValues = currentWorkspace?.creds?.filter(d => d.required && !d.value)
      setCredError(!isEmpty(user) && !isEmpty(currentWorkspace) && (emptyValues?.length || !currentWorkspace?.creds?.length))
    }
  }, [])

  const [CredentialModal, openCredentialModal] = useModal({
    content: <AddEditCredential credentials={credentials} isWizard={isWizard} workspaceId={workspaceId} id={credentialId} setId={setCredentialId} fetchData={fetchCredentials} />,
    title: credentialId ? `Edit Credential` : `Add Credential`,
  })

  const currentWorkspace = useSelector(state => get(state, 'user.workspace'))
  const workspaces = useSelector(state => get(state, 'user.workspaces'))

  useEffect(() => {
    if (!apiKey) {
      setApiKey(currentWorkspace?.apiKey)
    }
  }, [currentWorkspace])

  const switchWorkspace = workspace => {
    const config = {
      path: '/',
      maxAge: 604800,
      sameSite: true,
    }
    setCookie(null, 'workspaceID', workspace.id, config)
    window.location.href = '/reports/registrations'
  }

  const handleLogout = e => {
    dispatch(setUser({}))

    destroyCookie({}, 'impersonateToken', { path: '/' })
    destroyCookie({}, 'isImpersonated', { path: '/' })
    destroyCookie({}, 'impersonateRole', { path: '/' })

    destroyCookie({}, 'accessToken', { path: '/' })
    destroyCookie({}, 'role', { path: '/' })
    history.push('/login')
  }

  const cloneCredentials = workspace => {
    notify.alertConfirm({
      title: `Clone ${workspace.name} Credentials`,
      message: `Are you sure you want to clone ${workspace.name} Credentials?`,
      onConfirm: async () => {
        const data = {}
        if (isWizard && workspaceId) data.workspaceId = workspaceId
        const response = await apiPost(`clone-credentials/${parseInt(workspace.id, 10)}`, data)
        if (response?.success) {
          notify.success('Success', `Credentials Cloned successfully`)
          notify.warning('Warning', `Please update the Web Url according to requirements`)
          setCredError(false)
          !isWizard && window?.location?.replace('/workspaces/credentials?require-creds=false')
          fetchCredentials()
        }
      },
    })
  }
  const generateWorkspaceApi = async () => {
    notify.alertConfirm({
      title: `Generate '${currentWorkspace.name}' workspace API Key`,
      message: `Are you sure you want to generate the '${currentWorkspace.name}' workspace API Key, this action revokes the old API key`,
      onConfirm: async () => {
        const response = await apiPost(`workspace/generate-api-key`, {})
        if (response?.success) {
          setApiKey(response.data?.apiKey)
          notify.success('API key generated successfully')
        }
      },
    })
  }

  return isWizard ? (
    <div>
      <div className="flex justify-end mb-3">
        {user.isPlatformAdmin && (
          <Popover
            width={150}
            height={200}
            content={close => {
              return (
                <>
                  <p className="font-bold mb-3">Select a Workspace:</p>
                  {workspaces?.map(workspace => {
                    return (
                      <button
                        key={workspace.id}
                        onClick={e => {
                          e.preventDefault()
                          cloneCredentials(workspace)
                          close()
                        }}
                        className={`hover:bg-dark-600 hover:text-white border group flex w-full items-center rounded-md px-2 py-2 text-sm mb-2 ${
                          includes(userData?.workspaces, workspace.id) ? 'bg-dark-600 text-white' : ''
                        }`}
                      >
                        {workspace.name}
                      </button>
                    )
                  })}
                </>
              )
            }}
          >
            <Button
              type="button"
              className="ml-3 outline-none bottom-[-4px] flex items-center justify-center h-[38px] border border-gray-300 shadow-sm rounded-md relative min-w-[38px]"
            >
              Clone Credentials
            </Button>
          </Popover>
        )}
        <Button
          onClick={() => {
            setCredentialId(null)
            openCredentialModal()
          }}
          type="button"
          className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3 "
        >
          Add New
        </Button>
      </div>
      {credError ? (
        <div className="pt-3 mb-3">
          <div className="bg-red-500 p-2 rounded-sm flex items-center text-white">
            <IoAlertCircle className="h-4 w-4 text-white mr-2" />
            Fill in the required values to access your workspace
          </div>
        </div>
      ) : null}
      <CredentialsList
        apiKey={isWizard ? '' : apiKey}
        currentWorkspace={isWizard ? {} : currentWorkspace}
        sections={sections}
        setCredentialId={setCredentialId}
        openCredentialModal={openCredentialModal}
        toggleSection={toggleSection}
        credentials={credentials}
        showValues={showValues}
        toggleValue={toggleValue}
        refetch={fetchCredentials}
        isWizard={isWizard}
        workspaceId={workspaceId}
      />
      <CredentialModal />
    </div>
  ) : (
    <Layout
      breadcrumbs={[{ name: 'Workspaces', link: '/workspaces' }]}
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4 ">
          {!credError && user.isPlatformAdmin && (
            <>
              <Button
                type="button"
                onClick={() => generateWorkspaceApi()}
                className="ml-3 outline-none bottom-[-4px] flex items-center justify-center h-[38px] border border-gray-300 shadow-sm rounded-md relative min-w-[38px]"
              >
                Generate API Key
              </Button>
            </>
          )}
          {qs['require-creds'] === 'false' && !credError && (
            <Button
              type="button"
              onClick={() => window.location.replace('/')}
              className="ml-3 outline-none bottom-[-4px] flex items-center justify-center h-[38px] border border-gray-300 shadow-sm rounded-md relative min-w-[38px]"
            >
              Go To Home
            </Button>
          )}
          <Button
            onClick={() => {
              setCredentialId(null)
              openCredentialModal()
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3 "
          >
            Add New
          </Button>

          {credError ? (
            <Selection
              onChange={value => {
                switchWorkspace(value)
              }}
              className="min-w-[200px] mr-4"
              value={currentWorkspace.id}
              list={workspaces}
              title="Switch Workspace"
            />
          ) : null}

          {user.isPlatformAdmin && credError && (
            <Popover
              width={150}
              height={200}
              content={close => {
                return (
                  <>
                    <p className="font-bold mb-3">Select a Workspace:</p>
                    {workspaces?.map(workspace => {
                      return (
                        <button
                          key={workspace.id}
                          onClick={() => {
                            cloneCredentials(workspace)
                            close()
                          }}
                          className={`hover:bg-dark-600 hover:text-white border group flex w-full items-center rounded-md px-2 py-2 text-sm mb-2 ${
                            includes(userData?.workspaces, workspace.id) ? 'bg-dark-600 text-white' : ''
                          }`}
                        >
                          {workspace.name}
                        </button>
                      )
                    })}
                  </>
                )
              }}
            >
              <Button
                type="button"
                className="ml-3 outline-none bottom-[-4px] flex items-center justify-center h-[38px] border border-gray-300 shadow-sm rounded-md relative min-w-[38px]"
              >
                Clone Credentials
              </Button>
            </Popover>
          )}

          {credError ? (
            <Button
              onClick={handleLogout}
              type="button"
              className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
            >
              Logout
            </Button>
          ) : null}
        </div>
      }
      title="Workspaces credentials"
    >
      {credError && (
        <div className="px-3 pt-3">
          <div className="bg-red-500 p-2 rounded-sm flex items-center text-white">
            <IoAlertCircle className="h-4 w-4 text-white mr-2" />
            Fill in the required values to access your workspace
          </div>
        </div>
      )}
      <div className="flex p-3 overflow-auto">
        <div className=" flex w-full overflow-hidden">
          <div className="w-full">
            <CredentialsList
              apiKey={apiKey}
              currentWorkspace={currentWorkspace}
              sections={sections}
              setCredentialId={setCredentialId}
              openCredentialModal={openCredentialModal}
              toggleSection={toggleSection}
              credentials={credentials}
              showValues={showValues}
              toggleValue={toggleValue}
              refetch={fetchCredentials}
            />
          </div>
        </div>
      </div>
      <CredentialModal />
    </Layout>
  )
}

export default Credentials
