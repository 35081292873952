import { useMutation, useQuery } from 'react-query'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import apiPut from 'lib/network/apiPut'
import toUpper from 'lodash/toUpper'
/*
  query = the name of API
  body = any payload/variables for the API Request
  queryParameters = any custom parameters for react query
*/

const useGet = (
  apiName,
  queryParameters = {
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  }
) => {
  const { data: response, error, ...rest } = useQuery([toUpper(apiName) + 'get'], async () => apiGet(apiName), queryParameters)

  return { response, error, ...rest }
}

const useFileMutate = (
  apiName,
  queryParameters = {
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  }
) => {
  const { mutate, data: response, error, ...rest } = useMutation(async formData => apiPut(apiName, formData), queryParameters)

  return { response, error, mutate, ...rest }
}

const useMutate = (
  apiName,
  queryParameters = {
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  }
) => {
  const { mutate, data: response, error, ...rest } = useMutation(async body => apiPost(apiName, body), queryParameters)

  return { response, error, mutate, ...rest }
}

const usePost = (
  apiName,
  body,
  queryParameters = {
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  }
) => {
  const { refetch, data: response, error, ...rest } = useQuery([toUpper(apiName) + 'post'], async () => apiPost(apiName, body), queryParameters)

  return { response, error, refetch, ...rest }
}

export { useGet, usePost, useMutate, useFileMutate }

// Following values are returned in the query
// https://react-query.tanstack.com/reference/useQuery
/*
   const {
   data,
   dataUpdatedAt,
   error,
   errorUpdatedAt,
   failureCount,
   isError,
   isFetched,
   isFetchedAfterMount,
   isFetching,
   isIdle,
   isLoading,
   isLoadingError,
   isPlaceholderData,
   isPreviousData,
   isRefetchError,
   isStale,
   isSuccess,
   refetch,
   remove,
   status,
 }
*/
