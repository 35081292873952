import Input from 'components/atoms/Input'
import { Form, Formik } from 'formik'
import apiPost from 'lib/network/apiPost'
import notify from 'components/atoms/Notify'
import { googleTwoFaAuth } from 'validations/twoFaAuth.validation'

const GoogleAuth = ({ close, googleAuthData, refetch }) => {
  const handleSubmit = async values => {
    const payload = {
      code: `${values.code}`,
      secret: googleAuthData.secret,
    }
    const response = await apiPost('google-verify-auth-qr', payload)
    if (response.success) {
      notify.success('Google Two FA has been enabled successfully')
      close()
      refetch()
    }
  }
  return (
    <Formik initialValues={{ code: '' }} enableReinitialize={true} validateOnChange={true} validationSchema={googleTwoFaAuth} onSubmit={handleSubmit}>
      {props => (
        <Form>
          <ol className="relative border-l border-gray-200 dark:border-gray-700 mb-4">
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Step 1</time>
              <h3 className="text-md font-semibold text-gray-900 dark:text-white">Download an Authenticator App</h3>
              <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                Download and install{' '}
                <a href="https://support.google.com/accounts/answer/1066447?hl=en" rel="noreferrer" target="_blank" className="text-blue-600">
                  Google Authenticator
                </a>{' '}
                for your phone or tablet.
              </p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Step 2</time>
              <h3 className="text-md font-semibold text-gray-900 dark:text-white">Scan the QR Code</h3>
              <p className="text-base font-normal text-gray-500 dark:text-gray-400">Open the authentication app and scan this QR code using your phone’s camera.</p>
              <div className="flex justify-center">
                {googleAuthData?.qr && <img src={googleAuthData?.qr} alt="" className="flex-shrink-0 p-2 shadow-lg h-[200px] w-[200px] rounded" />}
              </div>
            </li>
            <li className="ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Step 3</time>
              <h3 className="text-md font-semibold text-gray-900 dark:text-white">Verify your code</h3>
              <p className="text-base font-normal text-gray-500 dark:text-gray-400">Enter the 6-digit verification generated on your authentication app.</p>
            </li>
          </ol>

          <Input
            type={'text'}
            placeholder={'OTP Code'}
            name="code"
            className="mb-3 mt-5 block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
          />

          <div className="mt-4 flex justify-center">
            <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out">
              Verify OTP
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default GoogleAuth
