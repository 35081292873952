import Input from 'components/atoms/Input'
import { Form } from 'formik'
import { upperFirst } from 'lodash'
import OtpTimer from 'components/atoms/OtpTimer'

const SmsAuth = ({ twoFaData, sendTwoFAAuthCode, seconds, minutes, formik, setSeconds, setMinutes }) => {
  return (
    <Form onSubmit={formik.handleSubmit}>
      <p className="text-center text-md mb-3 font-medium">Please enter 4-digit OTP sent to your phone number</p>
      <Input
        name="code"
        type={'text'}
        placeholder={'OTP Code'}
        className="mb-3 block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
        onChange={formik.handleChange}
        value={formik.values.code}
      />
      {formik.errors?.code && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {upperFirst(formik.errors?.code)}
        </p>
      )}
      <span className="cursor-pointe text-xs font-medium pl-2 mt-1 block text-center">
        Didn’t receive the code?{' '}
        <span
          className={`mr-1 ${seconds === 0 && minutes === 0 ? ' hover:underline text-blue-600 cursor-pointer' : 'text-gray-700'}`}
          onClick={() => {
            if (seconds === 0 && minutes === 0) {
              sendTwoFAAuthCode(twoFaData?.phone)
            }
          }}
        >
          Resend code
        </span>
        <OtpTimer seconds={seconds} minutes={minutes} setSeconds={setSeconds} setMinutes={setMinutes} />
      </span>
      <div className="mt-4 flex justify-center">
        <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
        >
          Verify OTP
        </button>
      </div>
    </Form>
    //   )}
    // </Formik>
  )
}

export default SmsAuth
