import Layout from 'containers/Layout'
import { useHistory, useParams } from 'react-router-dom'
import apiGet from 'lib/network/apiGet'
import PopupMenu from 'components/molecules/PopupMenu'
import { IoEllipsisVertical } from 'react-icons/io5'
import { useEffect, useState } from 'react'
import moment from 'moment'
import Button from 'components/atoms/Button'
import { startCase, get } from 'lodash'
import notify from 'components/atoms/Notify'
import { useSelector } from 'react-redux'
import apiDelete from 'lib/network/apiDelete'

const Posts = () => {
  const [postData, setPostData] = useState({})
  const history = useHistory()

  const { id } = useParams()
  const types = useSelector(state => get(state, 'user.types', []))
  const post = types?.post.find(post => parseInt(id) === post.id) /* eslint-disable-line */

  useEffect(() => {
    fetchPosts()
  }, [id])

  const fetchPosts = async () => {
    const response = await apiGet(`post/${id}/list`, { size: 100, page: 1 })
    setPostData(response?.data)
  }

  const menuOptions = [[{ name: 'Edit', key: 'edit' }], [{ name: 'Delete', key: 'delete' }]]

  const menuClickHandler = (option, id) => {
    if (option.key === 'edit') {
      history.push(`/posts/edit/${id}`)
    }
    if (option.key === 'delete') {
      notify.deleteConfirm({
        title: 'Delete post',
        message: 'Are you sure you want to delete this post?',
        onConfirm: async () => {
          const response = await apiDelete(`post/${id}/delete`)
          if (response?.success) {
            notify.success('Post deleted successfully')
            fetchPosts()
          }
        },
      })
    }
  }

  return (
    <Layout
      breadcrumbs={[{ name: 'Posts', link: '/posts' }]}
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Button
            onClick={() => {
              history.push('/posts/add')
            }}
          >
            Add new post
          </Button>
        </div>
      }
      title={`List of ${startCase(post?.name)}`}
    >
      <div className="hidden sm:block">
        <div className="align-middle inline-block min-w-full">
          <table className="min-w-full">
            <thead>
              <tr className="border-gray-200">
                <th style={{ width: '20%' }} className="px-6 py-4 border-b border-gray-200 bg-gray-50 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">
                  <span className="lg:pl-2">Title</span>
                </th>
                <th className="px-6 py-4 border-b border-gray-200 bg-gray-50 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">Creator</th>
                <th className="px-6 py-4 border-b border-gray-200 bg-gray-50 text-left text-xs text-normal text-gray-500 uppercase tracking-wider">Project</th>
                <th className="hidden whitespace-nowrap md:table-cell px-6 py-4 border-b border-gray-200 bg-gray-50 text-right text-xs text-normal text-gray-500 uppercase tracking-wider">
                  Last updated
                </th>
                <th className="pr-6 py-4 border-b border-gray-200 bg-gray-50 text-right text-xs text-normal text-gray-500 uppercase tracking-wider" />
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {postData?.posts?.map(post => {
                return (
                  <tr key={post.id}>
                    <td className="px-6 py-3 whitespace-nowrap text-sm text-normal text-gray-900">
                      <div onClick={() => history.push(`/posts/edit/${post.id}`)} className="flex cursor-pointer items-center space-x-3 lg:pl-2">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-12 w-12">
                            <img className="h-12 w-12 rounded object-contain" src={post?.image?.url} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm text-normal text-gray-900">{startCase(post?.title)}</div>
                            <div className="text-sm ellipse text-gray-500">{startCase(post?.content?.slice(0, 30))}...</div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3 text-sm text-gray-500 font-medium">{startCase(post?.creator?.displayName)}</td>
                    <td className="px-6 py-3 text-sm text-gray-500 font-medium">{startCase(post?.project?.name)}</td>
                    <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">{moment(post?.updatedAt).fromNow()}</td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm text-normal">
                      <PopupMenu onClick={option => menuClickHandler(option, post?.id)} menu={menuOptions}>
                        <IoEllipsisVertical className="h-5 w-5" aria-hidden="true" />
                      </PopupMenu>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default Posts
