import Selection from 'components/molecules/Selection'
import { Field } from 'formik'
import { xor } from 'lodash'

const AuthMethods = ({ eventConfig, setEventConfig, events, setWorkspaceData, workspaceData }) => {
  return (
    <div className="space-y-6 sm:space-y-5">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Active Event
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="flex max-w-lg w-full items-center space-x-2">
            <Selection
              className="w-full max-w-lg"
              title="Select Event"
              value={eventConfig?.id}
              onChange={event => setEventConfig({ ...eventConfig, id: event?.id })}
              list={events}
            />
          </div>
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Steps Config
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="bg-gray-100 p-4 max-w-lg rounded-lg">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left">Steps</th>
                  <td className="py-2">Required</td>
                  <td className="py-2">Hidden</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-2" style={{ width: '33.33%' }}>
                    Sign-up: Tokens
                  </td>
                  <td className="py-2" style={{ width: '33.33%' }}>
                    <input type="checkbox" checked={eventConfig?.tokensRequired} onChange={e => setEventConfig(old => ({ ...old, tokensRequired: e.target.checked }))} />
                  </td>
                  <td className="py-2" style={{ width: '33.33%' }}>
                    <input type="checkbox" checked={eventConfig?.tokensSkip} onChange={e => setEventConfig(old => ({ ...old, tokensSkip: e.target.checked }))} />
                  </td>
                </tr>
                <tr>
                  <td className="py-2">Sign-up: IRL</td>
                  <td className="py-2">
                    <input type="checkbox" checked={eventConfig?.irlRequired} onChange={e => setEventConfig(old => ({ ...old, irlRequired: e.target.checked }))} />
                  </td>
                  <td className="py-2">
                    <input type="checkbox" checked={eventConfig?.irlSkip} onChange={e => setEventConfig(old => ({ ...old, irlSkip: e.target.checked }))} />
                  </td>
                </tr>
                <tr>
                  <td className="py-2">Sign-up: Wallet</td>
                  <td className="py-2">
                    <input type="checkbox" checked={eventConfig?.walletRequired} onChange={e => setEventConfig(old => ({ ...old, walletRequired: e.target.checked }))} />
                  </td>
                  <td className="py-2">
                    <input type="checkbox" checked={eventConfig?.walletSkip} onChange={e => setEventConfig(old => ({ ...old, walletSkip: e.target.checked }))} />
                  </td>
                </tr>
                <tr>
                  <td className="py-2">Sign-up: Address</td>
                  <td className="py-2">
                    <input type="checkbox" checked={eventConfig?.addressRequired} onChange={e => setEventConfig(old => ({ ...old, addressRequired: e.target.checked }))} />
                  </td>
                  <td className="py-2">
                    <input type="checkbox" checked={eventConfig?.addressSkip} onChange={e => setEventConfig(old => ({ ...old, addressSkip: e.target.checked }))} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 border-t">
        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Mint Type
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="flex max-w-lg w-full items-center space-x-2">
            <span className="mr-3">
              <Field
                className="mr-2"
                type="checkbox"
                name="mintType"
                value="pre-mint"
                id="pre-mint"
                onClick={e => {
                  setWorkspaceData(d => ({ ...d, loginConfig: { ...d?.loginConfig, preMint: e.target.checked } }))
                }}
                checked={workspaceData?.loginConfig?.preMint}
              />
              <label htmlFor="pre-mint">Pre Mint</label>
            </span>
            <span>
              <Field
                className="mr-2"
                type="checkbox"
                name="mintType"
                id="post-mint"
                value="post-mint"
                onClick={e => setWorkspaceData(d => ({ ...d, loginConfig: { ...d?.loginConfig, postMint: e.target.checked } }))}
                checked={workspaceData?.loginConfig?.postMint}
              />
              <label htmlFor="post-mint">Post Mint</label>
            </span>
          </div>
        </div>
      </div>
      {workspaceData?.loginConfig?.preMint && (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 border-t">
          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Pre Mint Methods
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="flex max-w-lg w-full items-center space-x-2">
              <Selection
                value={workspaceData?.loginConfig?.preMintMethods}
                multiple
                onChange={value => setWorkspaceData(d => ({ ...d, loginConfig: { ...d?.loginConfig, preMintMethods: xor(d.loginConfig?.preMintMethods, [value.id]) } }))}
                className="w-full max-w-lg"
                title="Select Pre Mint Login/Sign-up Methods"
                list={[
                  { name: 'Metamask', id: 'metamask' },
                  { name: 'Coinbase', id: 'coinbase' },
                  { name: 'Wallet Connect', id: 'wallet-connect' },
                  { name: "I don't have wallet", id: 'no-wallet' },
                ]}
              />
            </div>
          </div>
        </div>
      )}
      {workspaceData?.loginConfig?.postMint && (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 border-t">
          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Post Mint Methods
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="flex max-w-lg w-full items-center space-x-2">
              <Selection
                value={workspaceData?.loginConfig?.postMintMethods}
                multiple
                onChange={value => setWorkspaceData(d => ({ ...d, loginConfig: { ...d?.loginConfig, postMintMethods: xor(d.loginConfig?.postMintMethods, [value.id]) } }))}
                className="w-full max-w-lg"
                title="Select Post Mint Login/Sign-up Methods"
                list={[
                  { name: 'Email', id: 'email' },
                  { name: 'Phone', id: 'phone' },
                  { name: 'Twitter', id: 'twitter' },
                  { name: 'Facebook', id: 'facebook' },
                  { name: 'Discord', id: 'discord' },
                  { name: 'Google', id: 'google' },
                  { name: 'Apple', id: 'apple' },
                  { name: 'Google Auto Login', id: 'google-auto' },
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AuthMethods
