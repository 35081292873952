import * as types from 'state/redux/constants'

export const setDrawerToggle = toggle => async dispatch => {
  try {
    dispatch({ type: types.SET_DRAWER_TOGGLE, data: toggle })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}

export const setToggles = toggles => async dispatch => {
  try {
    dispatch({ type: types.SET_MENU_TOGGLES, data: toggles })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}

export const setMainToggles = toggles => async dispatch => {
  try {
    dispatch({ type: types.SET_MAIN_MENU_TOGGLES, data: toggles })
  } catch (error) {
    console.error(error) // eslint-disable-line no-console
  }
}
