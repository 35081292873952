import React from 'react'
const OtpTimer = ({ minutes, setMinutes, seconds, setSeconds, showText = true, className }) => {
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])
  return seconds > 0 || minutes > 0 ? (
    <div className={((seconds > 0 || minutes > 0) && 'text-dark', className)}>
      <span>
        {showText && 'Time Remaining:'} {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </span>
    </div>
  ) : null
}

export default OtpTimer
