import Button from 'components/atoms/Button'
import LoadingIcon from 'components/atoms/LoadingIcon'
import Selection from 'components/molecules/Selection'
import EmptyState from 'components/pageComponents/EmptyState'
import Table from 'components/pageComponents/Table'
import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PopupMenu from 'components/molecules/PopupMenu'
import { IoEllipsisVertical } from 'react-icons/io5'
import notify from 'components/atoms/Notify'
import apiDelete from 'lib/network/apiDelete'
import { getDefaultProject } from 'lib/utils'
import { get, startCase } from 'lodash'

const DataList = () => {
  const [dataList, setDataList] = useState([])
  const [projectList, setProjectList] = useState(null)
  const [project, setProject] = useState(null)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const fetchDataList = async project => {
    setLoading(true)
    const response = await apiGet('datalist', { project, type: 'simple-array' })
    setDataList(response.data?.list)
    setLoading(false)
  }

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    const projects = response.data
    setProject(getDefaultProject(projects))
    setProjectList(projects)
  }

  const menuClickHandler = (option, id, index) => {
    if (option.key === 'edit') {
      history.push(`/datalist/drop/edit/${id}`)
    }

    if (option.key === 'delete') {
      notify.deleteConfirm({
        title: 'Delete Data List',
        message: 'Are you sure you want to delete this DataList?',
        onConfirm: async () => {
          const response = await apiDelete(`/dataList/${id}`)
          if (response?.success) {
            notify.success('DataList deleted successfully')
            fetchDataList(project)
          }
        },
      })
    }
  }

  const menuOptions = [[{ name: 'Edit', key: 'edit' }], [{ key: 'delete', name: 'Delete' }]]

  useEffect(() => {
    fetchProjects()
  }, [])

  useEffect(() => {
    if (project) {
      fetchDataList(project)
    }
  }, [project])

  const columns = [
    {
      width: '10%',
      name: 'Description',
      key: 'name',
      sortable: false,
      rendered: item => <span onClick={() => history.push(`/dataList/drop/edit/${item.id}`)}>{item.description}</span>,
    },

    {
      width: '10%',
      name: 'Project',
      key: 'project',
      sortable: false,
      rendered: item => <span className="text-blue-500">{item.project ? item.project?.name : 'No Project Found'}</span>,
    },
    {
      width: '10%',
      name: 'List Data Type',
      key: 'type',
      sortable: false,
      rendered: item => <span className="text-blue-500">{startCase(item.type)}</span>,
    },
    {
      width: '10%',
      name: 'Count',
      key: 'count',
      sortable: false,
      rendered: item => <span className="text-blue-500">{get(item, 'data.length', 0)}</span>,
    },
    {
      width: '10%',
      name: 'Actions',
      align: 'right',
      sortable: false,
      rendered: (item, index) => (
        <PopupMenu onClick={option => menuClickHandler(option, item.id, index)} menu={menuOptions}>
          <IoEllipsisVertical className="h-5 w-5" aria-hidden="true" />
        </PopupMenu>
      ),
    },
  ]

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Selection
            className="w-[250px]"
            title="Select Project"
            onChange={value => {
              setProject(value.id)
            }}
            list={projectList}
            value={project}
          />
          <Button onClick={() => history.push('/dataList/drop/add')}>Add DataList</Button>
        </div>
      }
      title="NFT DataLists"
    >
      <div className="min-w-full">
        {!loading ? (
          <>
            {dataList?.length === 0 ? (
              <EmptyState v2 description="No data list found" />
            ) : (
              <Table hasMore={false} fetchData={() => fetchDataList(project)} data={dataList} columns={columns} />
            )}
          </>
        ) : (
          <div className="w-full h-[200px] border-0 flex justify-center items-center">
            <LoadingIcon />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default DataList
