import Input from 'components/atoms/Input'
import classNames from 'classnames'

const CreateWorkspace = ({ props }) => {
  return (
    <div className="space-y-6 sm:space-y-5">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-2">
        <label htmlFor="first-name" className="block  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Name of the Workspace <span className="text-red-500">*</span>
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <Input
            type="text"
            name="name"
            placeholder="eg. My Workspace"
            id="name"
            autoComplete="name"
            className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label htmlFor="first-name" className="block  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Workspace domain <span className="text-red-500">*</span>
        </label>
        <div>
          <div className="mt-1 flex sm:mt-0 sm:col-span-2">
            <div className="relative w-[350px]" aria-describedby="Text input with .id.club appended to the text value.">
              <div
                className="c-input_text_overlay c-input_text_overlay--large"
                data-overlay-prefix=""
                data-overlay-suffix=".id.club"
                aria-hidden="true"
                data-qa="text_overlay_suffix"
              >
                {props.values.subdomain || 'your-app'}
              </div>
              <input
                onChange={e => props.setValues({ ...props.values, subdomain: e.target.value })}
                value={props.values.subdomain}
                data-qa="signin_subdomain_input"
                spellCheck="false"
                min="0"
                max="0"
                width="0"
                aria-describedby="subdomain_hint"
                aria-invalid="false"
                aria-labelledby="subdomain_label"
                aria-required="false"
                aria-label=""
                style={{ marginBottom: 0 }}
                autoComplete="off"
                className={classNames('c-input_text c-input_text--large full_width margin_bottom_100')}
                id="subdomain"
                name="subdomain"
                placeholder="your-app"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateWorkspace
