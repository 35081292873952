import Selection from 'components/molecules/Selection'
import EmptyState from 'components/pageComponents/EmptyState'
import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getDefaultProject } from 'lib/utils'

const NFTSeries = () => {
  const [series, setSeries] = useState([])
  const [projectList, setProjectList] = useState(null)
  const [project, setProject] = useState(null)

  const history = useHistory()

  const fetchProjects = async () => {
    const response = await apiGet('projects')
    const projects = response.data

    setProjectList(projects)
    setProject(getDefaultProject(projects))
  }

  const getSeries = async project => {
    const response = await apiGet('series', { project })
    setSeries(response.data)
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  useEffect(() => {
    if (project) {
      getSeries(project)
    }
  }, [project])

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <Selection
            className="w-[250px]"
            title="Select Project"
            onChange={value => {
              setProject(value.id)
            }}
            list={projectList}
            value={project}
          />
        </div>
      }
      title="NFT Series"
    >
      <div className={series.length > 0 ? 'grid grid-cols-4 p-8 gap-8' : ''}>
        {series.length > 0 ? (
          series.map(series => (
            <div
              onClick={() => {
                history.push(`/series/edit/${series.id}`)
              }}
              className="flex bg-white cursor-pointer p-4 border shadow-sm rounded-lg"
              key={series.id}
            >
              <div className="justify-center items-center">
                <img className="h-[200px] w-[250px]" src={series?.meta?.image?.url} alt={series.name} />
                <div className="text-center text-lg font-medium mt-2">{series.name}</div>
                <div className="text-center">
                  {series.price} {series.currency}
                </div>
              </div>
            </div>
          ))
        ) : (
          <EmptyState description="No series added" v2 />
        )}
      </div>
    </Layout>
  )
}

export default NFTSeries
