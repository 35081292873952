import notify from 'components/atoms/Notify'
import Selection from 'components/molecules/Selection'
import Layout from 'containers/Layout'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { xor } from 'lodash'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const TierNotification = () => {
  const history = useHistory()
  const [admins, setAdmins] = useState([])
  const [data, setData] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const fetchNFTCreators = async () => {
    const response = await apiGet(`users`, { minimal: true, admin: true, concierge: true })
    setAdmins(response?.data.map(d => ({ ...d, name: d.displayName })))
  }

  useEffect(() => {
    fetchNFTCreators()
    fetchTierNotificationSettings()
  }, [])

  const fetchTierNotificationSettings = async () => {
    const response = await apiGet(`tier-notification-settings`)
    setData(response?.data?.tierNotificationSettings)
  }

  const handleUpdate = () => {
    setIsSubmitting(true)

    apiPost(`tier-notification-settings`, data)
      .then(() => {
        setIsSubmitting(false)
        notify.success('Tier Notification Settings Updated')
      })
      .catch(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <Layout
      headerRight={
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <button
            onClick={() => {
              history.goBack()
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500 sm:order-1 sm:ml-3"
          >
            Go Back
          </button>
        </div>
      }
      title="Tier Notification"
    >
      <div className="flex p-3">
        <div className="bg-white w-full p-4">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Notifications Settings</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Configure the notification channels for tier updates to specified admins</p>
            </div>
          </div>
          <div className="mt-6 sm:mt-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                  Notification Channels
                  <span className="block text-xs text-gray-400 font-normal">Select channels through which the admins will be updated</span>
                </label>
                <div className="mt-1 -ml-5 sm:mt-0 sm:col-span-2">
                  <div className="flex items-center">
                    <fieldset className="space-x-5 flex">
                      <legend className="sr-only">Notifications</legend>
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="whatsapp"
                            aria-describedby="whatsapp-description"
                            name="whatsapp"
                            disabled
                            type="checkbox"
                            onChange={e => {
                              setData({ ...data, channels: { ...data?.channels, sms: e.target.checked } })
                            }}
                            checked={data?.channels?.sms || false}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="text-message" className="font-medium mr-1 text-gray-700">
                            WhatsApp Notification
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="email"
                            aria-describedby="email-description"
                            name="email"
                            checked={data?.tierNotificationSettings?.email || false}
                            onChange={e => {
                              setData({ ...data, tierNotificationSettings: { ...data?.tierNotificationSettings, email: e.target.checked } })
                            }}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="email" className="font-medium  mr-1 text-gray-700">
                            Email
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="in-app"
                            checked={true}
                            disabled
                            aria-describedby="in-app-description"
                            name="in-app"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="in-app" className="font-medium  mr-1 text-gray-700">
                            In-App
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                  Send Notification to
                  <span className="block text-xs text-gray-400 font-normal">Select channels through which the admins will be updated</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Selection
                    list={admins}
                    title="Select admins"
                    value={data?.admins}
                    onAllSelect={values => {
                      setData({ ...data, admins: values })
                    }}
                    multiple
                    onChange={value => {
                      setData({ ...data, admins: xor([value.id], data.admins || []) })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="pt-5 mt-5">
              <div className="flex justify-start">
                <button
                  onClick={handleUpdate}
                  type="submit"
                  className="mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dark-600 hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500"
                >
                  {isSubmitting ? (
                    <span className="flex items-start">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.tier/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span>Updating...</span>
                    </span>
                  ) : (
                    <span>Update</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TierNotification
