/* eslint-disable */
import * as Yup from 'yup'
const slugRegex = new RegExp('^[A-Za-z0-9-_/]+$')

const GlobalSlug = Yup.object().shape({
  path: Yup.string().trim().matches(slugRegex, 'Invalid slug').required('Slug is required'),
  type: Yup.string().trim().required('Type is required'),
  project: Yup.number().when('type', {
    is: 'collection',
    then: Yup.number().required('Project is required'),
    otherwise: Yup.number().nullable(),
  }),
  componentName: Yup.string().when('type', {
    is: 'lander',
    then: Yup.string().required('Component name is required'),
    otherwise: Yup.string().nullable(),
  }),
  redirectToUrl: Yup.string().when('type', {
    is: 'redirect',
    then: Yup.string().required('Redirect URL is required'),
    otherwise: Yup.string().nullable(),
  }),
})

export default GlobalSlug
