const ToolTip = ({ children, content }) => {
  return (
    <div className="relative">
      <button className="tooltip text-gray-700 font-medium">{children}</button>
      {content && (
        <div className="z-[99999] tooltip-text absolute mt-2 border border-gray-200 bg-white shadow-lg text-dark text-xs rounded py-1 px-2 opacity-0 pointer-events-none">
          {content}
        </div>
      )}
    </div>
  )
}

export default ToolTip
