import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { MenuAlt1Icon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { destroyCookie } from 'nookies'
import { setUser } from 'state/redux/actions/user.actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const MobileHeader = ({ setSidebarOpen, user, openChangePasswordModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLogout = e => {
    dispatch(setUser({}))

    destroyCookie({}, 'impersonateToken', { path: '/' })
    destroyCookie({}, 'isImpersonated', { path: '/' })
    destroyCookie({}, 'impersonateRole', { path: '/' })

    destroyCookie({}, 'accessToken', { path: '/' })
    destroyCookie({}, 'role', { path: '/' })
    history.push('/login')
  }

  return (
    <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden">
      <button
        type="button"
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
        <div className="flex-1 flex">
          <form className="w-full flex md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                name="search-field"
                className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm"
                placeholder="Search"
                type="search"
              />
            </div>
          </form>
        </div>
        <div className="flex items-center">
          <Menu as="div" className="ml-3 relative">
            <div>
              <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <span className="sr-only">Open user menu</span>
                <img className="h-8 w-8 rounded-full" src={user.userProfile?.profile} alt="" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                <div className="py-1">
                  <Menu.Item
                    onClick={e => {
                      history.push(`/users/edit/${user.id}/user-profile`)
                    }}
                  >
                    {({ active }) => <a className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>View profile</a>}
                  </Menu.Item>
                </div>

                <div className="py-1">
                  <Menu.Item onClick={() => openChangePasswordModal()}>
                    {({ active }) => <a className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Change Password</a>}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item onClick={handleLogout}>
                    {({ active }) => <a className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Logout</a>}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  )
}

export default MobileHeader
