import Input from 'components/atoms/Input'
import { Form, Formik } from 'formik'
import apiGet from 'lib/network/apiGet'
import apiPost from 'lib/network/apiPost'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setForms } from 'state/redux/actions/user.actions'

const AddEditForm = ({ close, id, setId, fetchData }) => {
  const [formData, setFormData] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      fetchForm()
    }
  }, [id])

  const fetchForm = async () => {
    const response = await apiGet(`forms/${id}`)
    setFormData(response.data)
  }

  const forms = useSelector(state => get(state, 'user.forms', []))

  const saveFormData = async typeData => {
    const data = await apiPost(id ? `forms/${id}` : 'forms', typeData)
    if (data.success) {
      dispatch(setForms([...forms, data.data]))
      history.push(`/forms/${data.data.id}`)
    }
  }

  const initialValues = {
    name: formData?.name,
  }

  return (
    <Formik initialValues={id ? initialValues : {}} enableReinitialize={true}>
      {props => (
        <Form>
          <div className="mb-8 space-y-4">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-0">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  type="text"
                  name="name"
                  placeholder="eg: collaborations, etc."
                  id="name"
                  autoComplete="new-password"
                  className="max-w-lg block w-full shadow-sm focus:ring-dark-500 focus:border-dark-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 flex justify-end">
            <button
              onClick={() => close()}
              className="inline-flex items-center px-4 py-2 border shadow mr-4 text-sm leading-5 font-medium rounded-md text-dark-500 bg-white transition duration-150 ease-in-out"
            >
              Close
            </button>
            <button
              onClick={() => saveFormData(props.values)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark-600 hover:bg-dark-500 focus:outline-none focus:shadow-outline-dark focus:border-dark-700 active:bg-dark-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddEditForm
