import moment from 'moment'
import { forwardRef } from 'react'
import WithReports from './WithReports'
import { formateDate, startEndTruncate } from 'utils'
import { Tooltip } from 'antd'
import { IoCopyOutline } from 'react-icons/io5'
import notify from 'components/atoms/Notify'
import { truncate } from 'lodash'

// eslint-disable-next-line
const ExternalTransactions = forwardRef(({ ...props }, ref) => {
  const copyText = text => {
    navigator.clipboard.writeText(text)
    notify.success('Copied to clipboard', truncate(text, { length: 35 }))
  }

  const onColumnClick = item => {
    return null
    // window.open(`/dataList/drop/edit/${item.id}`)
  }

  const columns = [
    {
      name: 'Id',
      width: '10%',
      key: 'id',
      rendered: item => (
        <div className="text-[13px] text-gray-500" onClick={() => onColumnClick(item)}>
          {item.id}
        </div>
      ),
    },
    {
      name: 'HASH / TXN ID',
      key: 'hash',
      width: '25%',
      rendered: item => {
        return (
          <>
            <div>
              <span className="text-[13px]" onClick={() => onColumnClick(item)}>
                {item?.data?.txnHash || item.data?.txnId}
              </span>
              {item?.data?.txnHash || item.data?.txnId ? (
                <button
                  id="disable-export"
                  className="hover:text-indigo-500"
                  onClick={() => {
                    copyText(item?.data?.txnHash || item.data?.txnId)
                  }}
                >
                  <IoCopyOutline className="ml-2" />
                </button>
              ) : null}
            </div>
          </>
        )
      },
    },
    {
      name: 'Price',
      key: 'price',
      width: '25%',
      rendered: item => (
        <span className="text-[13px]" onClick={() => onColumnClick(item)}>
          {item?.data?.price}
        </span>
      ),
    },
    {
      name: 'currency',
      width: '10%',
      key: 'cLocation',
      rendered: item => <span className="text-[13px]">{item?.data?.currency}</span>,
    },
    {
      name: 'Created At',
      width: 'auto',
      key: 'createdAt',
      rendered: item => <div className="text-[13px] text-gray-500 overflow-scroll ">{formateDate(moment(item.createdAt))}</div>,
    },
  ]

  return (
    <WithReports
      // onRowClick={(item) => window.open(`/users/edit/${item.id}/user-profile`)}
      {...props}
      apiURL="datalist"
      apiDownloadURL="datalist/csv"
      apiQueryString={{ type: 'external', value: 'transaction' }}
      defaultFileName="External Users"
      forwardedRef={ref}
      columns={columns}
      isExternalUser
    />
  )
})

export default ExternalTransactions
