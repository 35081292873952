import { useEffect, useState } from 'react'
import startCase from 'lodash/startCase'
import Layout from 'containers/Layout'
import moment from 'moment'
import { debounce, get, size, truncate } from 'lodash'
import apiGet from 'lib/network/apiGet'
import { startEndTruncate } from 'utils'
import { SearchIcon } from '@heroicons/react/solid'
import Table from 'components/pageComponents/Table'
import LoadingIcon from 'components/atoms/LoadingIcon'
import EmptyState from 'components/pageComponents/EmptyState'
import { IoCopyOutline } from 'react-icons/io5'
import notify from 'components/atoms/Notify'

const MintedNFTs = () => {
  const [filter, setFilter] = useState({})
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [nfts, setNfts] = useState([])

  useEffect(() => {
    fetchNfts()
  }, [filter])

  const fetchNfts = async (noLoading = false) => {
    if (!noLoading) setLoading(true)
    const response = await apiGet('nfts/minted', { size: 20, page: (pagination?.currentPage || 0) + 1, ...filter })

    setPagination(response?.data?.pagination)
    const data = response.data?.nfts
    setNfts([...nfts, ...data])

    setLoading(false)
  }

  const searchList = async value => {
    if (size(value) >= 2) {
      setPagination({ currentPage: 0 })
      setNfts([])
      setFilter({ ...filter, search: value })
    } else {
      setPagination({ currentPage: 0 })
      setNfts([])
      setFilter({ ...filter, search: null })
    }
  }

  const searchFn = debounce(e => {
    searchList(e.target.value)
  }, 1000)

  function handleChange(event) {
    searchFn(event)
  }

  const copyText = text => {
    navigator.clipboard.writeText(text)
    notify.success('Copied to clipboard', truncate(text, { length: 35 }))
  }

  const columns = [
    {
      name: 'NFT Details',
      width: '20%',
      key: 'name',
      sortable: false,
      rendered: item => {
        return (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10 relative">
              <img className="h-10" src={item?.image?.url ?? item.series?.meta?.image?.url} alt="" />
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                #{item.tokenId} ∙ {item.series.name}
              </div>
              {item?.user ? (
                <div className="text-sm text-gray-500">
                  Minted by {item?.user?.displayName} ({item?.user?.id})
                </div>
              ) : (
                'Idle'
              )}
              <div className="text-sm text-gray-500">
                {item?.user?.email}
                <span>
                  {item?.user?.email && (
                    <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item?.user?.email)}>
                      <IoCopyOutline className="ml-2" />
                    </button>
                  )}
                </span>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      width: '10%',
      name: 'Paid using',
      key: 'type',
      sortable: false,
      rendered: item => (
        <a target="_blank" href={`https://${process.env.REACT_APP_ENV === 'production' ? '' : 'goerli.'}etherscan.io/address/${item.user?.walletAddress}`} rel="noreferrer">
          {(item.tx?.type || 'direct') === 'direct' ? (
            'ETH'
          ) : (
            <>
              {item.moonpay ? (
                <a target="_blank" rel="noreferrer" href={`https://buy.moonpay.com/transaction_receipt?transactionId=${item.moonpay?.transactionId}`}>
                  Moonpay (TX)
                </a>
              ) : item.tx?.type === 'transfer' ? (
                'Transferred'
              ) : (
                'Moonpay'
              )}
            </>
          )}
        </a>
      ),
    },
    {
      width: '10%',
      name: 'Wallet',
      key: 'type',
      sortable: false,
      rendered: item => (
        <>
          <a target="_blank" href={`https://${process.env.REACT_APP_ENV === 'production' ? '' : 'goerli.'}etherscan.io/address/${item.user?.walletAddress}`} rel="noreferrer">
            {startEndTruncate(item.user?.walletAddress)}
          </a>
          <span>
            {item.user?.walletAddress && (
              <button id="disable-export" className="hover:text-indigo-500" onClick={() => copyText(item.user?.walletAddress)}>
                <IoCopyOutline className="ml-2" />
              </button>
            )}
          </span>
        </>
      ),
    },
    {
      width: '10%',
      name: 'Transactions',
      key: 'type',
      sortable: false,
      rendered: item => (
        <a target="_blank" href={`https://${process.env.REACT_APP_ENV === 'production' ? '' : 'goerli.'}etherscan.io/tx/${item.tx?.transactionHash}`} rel="noreferrer">
          View on Explorer
        </a>
      ),
    },
    { width: '10%', name: 'Project', key: 'type', sortable: false, rendered: item => startCase(item.project?.name) },

    {
      width: '10%',
      name: 'Minted',
      key: 'created',
      sortable: false,
      rendered: item => {
        const createdTime = get(item, 'createdAt', '')
        const createdAt = createdTime ? moment(createdTime).fromNow() : ''
        return createdAt
      },
    },
  ]

  return (
    <Layout
      breadcrumbs={[{ name: 'Minted NFT List', pathname: '/minted-nfts' }]}
      toolbar={<div className="mr-6 w-full justify-end flex"></div>}
      headerRight={
        <div className="mt-4 flex w-full justify-end sm:mt-0 sm:ml-4">
          <div className="max-w-xs w-full lg:max-w-xs">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                onChange={handleChange}
                id="search"
                name="search"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
                placeholder="Search"
                type="search"
              />
            </div>
          </div>
        </div>
      }
      title="Minted Tokens"
    >
      <div className="flex flex-col">
        <div className="flex-1 flex items-stretch">
          <main className="flex-1">
            <div className="align-middle inline-block min-w-full">
              <div className="min-w-full">
                {!loading ? (
                  <>
                    {size(nfts) === 0 ? (
                      <EmptyState v2 description="No Token Found" />
                    ) : (
                      <Table
                        hasMore={pagination?.currentPage !== pagination?.totalPage}
                        fetchData={() => fetchNfts(true)}
                        sortBy={filter.sortBy}
                        sortOrder={filter.sortOrder}
                        handleSort={(sortBy, sortOrder) => {
                          setFilter({ ...filter, sortBy, sortOrder })
                          setPagination({ currentPage: 0 })
                          setNfts([])
                        }}
                        data={nfts}
                        columns={columns}
                      />
                    )}
                  </>
                ) : (
                  <div className="w-full h-[200px] border-0 flex justify-center items-center">
                    <LoadingIcon />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default MintedNFTs
