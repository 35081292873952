import * as Yup from 'yup'

const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is Required.'),
  newPassword: Yup.string().min(6, 'New Password must contain 6 characters').required('New Password is Required.'),
  confirmPassword: Yup.string()
    .required('Enter Confirm Password')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

export default changePasswordSchema
