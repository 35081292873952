import * as types from 'state/redux/constants'

const initialState = {
  user: {},
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_DATA:
      return { ...state, user: action.data }
    case types.SET_USER_PROJECTS:
      return { ...state, projects: action.data }
    case types.SET_WORKSPACE_LIST:
      return { ...state, workspaces: action.data }
    case types.SET_COMMUNITY_LIST:
      return { ...state, communities: action.data }
    case types.SET_WORKSPACE:
      return { ...state, workspace: action.data }
    case types.SET_TYPES:
      return { ...state, types: action.data }
    case types.SET_FORMS:
      return { ...state, forms: action.data }
    case types.SET_TIERS:
      return { ...state, tiers: action.data }
    case types.SET_NOTIFICATION_LIST:
      return { ...state, notifications: action.data }
    case types.SET_EVENT_LIST:
      return { ...state, events: action.data }

    default:
      return state
  }
}

export default userReducer
